export const MANAGE_USER = 'MANAGE_USER';
export const MANAGE_GROUP = 'MANAGE_GROUP';
export const MANAGE_USER_GROUP = 'MANAGE_USER_GROUP';
export const APPEND_USER_GROUP = 'APPEND_USER_GROUP';
export const MANAGE_ROLE = 'MANAGE_ROLE';
export const MANAGE_GROUP_ROLE = 'MANAGE_GROUP_ROLE';
export const MANAGE_APPLICATION = 'MANAGE_APPLICATION';
export const MANAGE_APP_FORM = 'MANAGE_APP_FORM';
export const MANAGE_ROLE_APP_PRIVILAGE = 'MANAGE_ROLE_APP_PRIVILAGE'

export const manageUser = (data: any) => ({
    type: MANAGE_USER,
    payload: { data }
})

export const manageGroup = (data: any) => ({
    type: MANAGE_GROUP,
    payload: { data }
})

export const manageUserGroup = (data: any) => ({
    type: MANAGE_USER_GROUP,
    payload: { data }
})

export const appendUserGroup = (data: any) => ({
    type: APPEND_USER_GROUP,
    payload: { data }
});

export const manageRole = (data: any) => ({
    type: MANAGE_ROLE,
    payload: { data }
})

export const manageGroupRole = (data: any) => ({
    type: MANAGE_GROUP_ROLE,
    payload: { data }
})

export const manageApplication = (data: any) => ({
    type: MANAGE_APPLICATION,
    payload: { data }
})

export const manageAppForm = (data: any) => ({
    type: MANAGE_APP_FORM,
    payload: { data }
})

export const manageRoleApp = (data: any) => ({
    type: MANAGE_ROLE_APP_PRIVILAGE,
    payload: { data }
})



