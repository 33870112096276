import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import gulfCountry from "../../../../../../country.json"

import readAPI from '../../../../data/api/read';
import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";

interface Country {
    name: string;
    capital: string;
    population: string;
    area_sq_km: string;
    currency: string;
    languages: string[];
    flag: string;
}

interface ViewAllCourseTypeData {
    timestamp: string;
    transactionId: string;
    status: string;
    code: string;
    message: string[];
    obj: {
        createdBy: string;
        creationDateAndTime: number[];
        updatedBy: string | null;
        updateDateAndTime: number[] | null;
        active: boolean;
        courseTypeDesc: string;
        courseTypeName: string;
        courseTypeRemarks: string;
        id: string;
    }[];
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateCourseType = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // newValues = {
        //     "roleId": values.roleId,
        //     "roleName": values.roleName,
        //     "roleDescription": values.roleDescription,
        //     "loggedInUser": loggedInUser,
        //     "active": values.active === "true" ? true : false
        // };

        /// Handling the data of the form
        // For field validation
        // const [courseCode, setCourseCode] = useState<string>(originalValues.courseCode);
        // const [roleDescription, setRoleDescription] = useState<string>(originalValues.roleDescription);

        // Error messages
        // const [roleNameErrorMessage, setRoleNameErrorMessage] = useState<string>("");
        // const [roleDescriptionErrorMessage, setRoleDescriptionErrorMessage] = useState<string>("");

        // For field validation
        // const [roleNameError, setRoleNameError] = useState<boolean>(false);
        // const [roleDescriptionError, setRoleDescriptionError] = useState<boolean>(false);

        // Fetching data using axios

        const [viewAllCourseTypeData, setViewAllCourseTypeData] = useState<ViewAllCourseTypeData | null>(null);

        const [courseTypeDesc, setCourseTypeDesc] = useState<string>(originalValues.courseTypeDesc);
        const [courseTypeName, setCourseTypeName] = useState<string>(originalValues.courseTypeName);
        const [courseTypeRemarks, setCourseTypeRemarks] = useState<string>(originalValues.courseTypeRemarks);

        // For handling errors

        const [courseTypeDescError, setCourseTypeDescError] = useState<boolean>(false);
        const [courseTypeNameError, setCourseTypeNameError] = useState<boolean>(false);
        const [courseTypeRemarksError, setCourseTypeRemarksError] = useState<boolean>(false);


        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }
            if (accessToken !== null) {
                // @1) Fetching Users
                axios.get(readAPI.Campus, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        if (res.data.code === "200.200") {
                            setViewAllCourseTypeData(res.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (viewAllCourseTypeData !== null && accessToken !== null) {
                    // const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    // const numberRegex = /^[0-9]{10}/;
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    // const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

                    if (courseTypeDesc === "") {
                        setCourseTypeDescError(true)
                    }
                    if (courseTypeName === "") {
                        setCourseTypeDescError(true)
                    }

                    if (courseTypeRemarks === "") {
                        setCourseTypeRemarksError(true)
                    }





                    // Set the validation errors

                    if (courseTypeDesc !== "" && courseTypeName !== "" && courseTypeRemarks !== "") {
                        const formState = {
                            "id": originalValues.id,
                            "courseTypeName": courseTypeName,
                            "courseTypeDesc": courseTypeDesc,
                            "courseTypeRemarks": courseTypeRemarks,
                            "active": true
                            // "active": (status === "Active") ? true : false
                        }

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `${courseTypeName} data has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    const m = response.data.message;
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                {/* <div style={{ marginTop: 5, flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row") }} className={`${(windowSize[0] > 990) ? ("d-flex justify-content-between") : ("d-flex flex-column justify-content-start")}`}>
                <div>
                    {(currentLang === "ar") ? (
                        <>
                            <span style={{ color: "#4f747a" }}> {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f4')} </span> / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f3')} / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f2')} / EQA
                        </>
                    ) : (
                        <>
                            EQA / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f2')} / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f3')} / <span style={{ color: "#4f747a" }}> {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f4')} </span>
                        </>
                    )}
                </div>
                <div>
                    <span style={{ color: "#4f747a", paddingRight: 10 }}>{currentFormatedDate}</span>
                </div>
            </div> */}

                {/* <hr /> */}

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                    {/* <Box sx={{
                    // border: "1px solid red",
                    display: "flex",
                    marginBottom: 2,
                    alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                    flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                }}>
                    <Box sx={{
                        // border: "1px solid black",
                        // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                        // boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;",
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                        width: 60,
                        height: 60,
                        borderRadius: 1.25,
                        backgroundColor: "#fffefe",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <PeopleOutlineIcon
                            sx={{
                                color: "#4f747a",
                                fontSize: 35,
                                // boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;", 
                            }} />
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Typography variant="h5" sx={{
                            // color: "#312a2c",
                            color: "#3c6766",
                            fontWeight: 500,
                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                            display: "flex",
                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                        }}>
                            {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.title')}
                        </Typography>
                        <Typography variant="body1" sx={{
                            // color: "#4f747a" 
                            // color: "#C0C0C0"
                            color: "#696969",
                            fontWeight: 300
                        }}>
                            {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.subTitle')}
                        </Typography>
                    </Box>
                </Box> */}

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="courseTypeNameTextField"
                                    label={t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeName.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeName.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={courseTypeNameError}
                                    // helperText={courseCodeErrorMessage}
                                    value={courseTypeName}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (courseTypeNameError) {
                                            setCourseTypeNameError(false);
                                        }
                                        setCourseTypeName(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="courseTypeDescTextField"
                                    label={t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeDesc.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeDesc.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={courseTypeDescError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={courseTypeDesc}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (courseTypeDescError) {
                                            setCourseTypeDescError(false);
                                        }
                                        setCourseTypeDesc(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="courseTypeRemarksTextField"
                                    label={t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeRemarks.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeRemarks.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={courseTypeRemarksError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={courseTypeRemarks}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (courseTypeRemarksError) {
                                            setCourseTypeRemarksError(false);
                                        }
                                        setCourseTypeRemarks(e.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                {/* <Box
                sx={{
                    display: "flex",
                    flexDirection: (currentLang === "ar") ? ('row-reverse') : ('row')
                }}
                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
            >
                <Button
                    dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                    variant="contained"
                    sx={{
                        backgroundColor: "#d5b254",
                        // textTransform: "none",
                        fontWeight: "bold",
                        height: 40,
                        mt:
                        // Give margins based on screen size
                        {
                            xs: 3, // theme.breakpoints.up('xs')
                            sm: 2, // theme.breakpoints.up('sm')
                            md: 2, // theme.breakpoints.up('md')
                            lg: 3, // theme.breakpoints.up('lg')
                            xl: 3, // theme.breakpoints.up('xl')
                        },
                        display: "flex",
                        // justifyContent: (currentLang === "ar") ? ('flex-start') : ('center'),
                        // alignItems:(currentLang === "ar") ? ('flex-end') : ('center'),
                        "&:hover": {
                            backgroundColor: "#d5b254",
                        },
                        // border:"1px solid red"
                    }}
                    // Give full width if screen size if less than 600px otherwise give auto width
                    fullWidth={(
                        windowSize[0] < 600
                    ) ? true : false}
                    // onClick={() => {
                    //     navigate("/");
                    // }}
                    startIcon={
                        (currentLang === "ar") ? (
                            null
                        ) : (
                            <SendIcon />
                        )
                    }
                    endIcon={
                        (currentLang === "ar") ? (
                            <SendIcon />
                        ) : (
                            null
                        )
                    }
                    onClick={submitForm}
                >
                    <Typography
                        style={{ display: "block" }}
                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                    >
                        {t('Home.Sidebar.list.userManagement.subMenu.Users.details.submit')}
                    </Typography>
                </Button>
            </Box> */}

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCourseType;