import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    Autocomplete,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    ListItemText,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FaPlus, FaWindowClose, FaEllipsisV } from "react-icons/fa";
import SnackBar from '../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import styles from "./style.module.css";
import readAPI from '../../data/api/read';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import levelJson from "../../../studyPlan/data/json/level.json";
import semesterJson from "../../../studyPlan/data/json/semester.json"
import yearJson from "../../../studyPlan/data/json/year.json"
import { CourseCodeListType, SOEvidenceService } from '../../../report/service/SOEvidenceService';
import { setYear } from 'date-fns';
import { CampusDetails, CollegeDetails, DepartmentDetails, GlobalService, ProgramDetails } from '../../../../Service/GlobalService';
import { styled } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
        marginTop: theme.spacing(5),
    },
}));

interface Row {
    collegeId: string;
    campusId: string;
    departmentId: string;
}

interface collegeOption {
    label: string;
    value: string;
}

interface campusOption {
    label: string;
    value: string;
}

interface departmentOption {
    label: string;
    value: string;
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
    setFetchUpdate: any,
    studyPlanOfferedProgram?: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}
const UpdateStudyPlan = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        studyPlanOfferedProgram
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);

        const [programList, setProgramList] = useState<ProgramDetails>({
            data: [],
            errMessage: ""
        })
        const [departmentList, setDepartmentList] = useState<DepartmentDetails>({
            data: [],
            errMessage: ""
        })
        const [campusList, setCampusList] = useState<CampusDetails>({
            data: [],
            errMessage: ""
        })
        const [collegeList, setCollegeList] = useState<CollegeDetails>({
            data: [],
            errMessage: ""
        })

        const [studyPlanTitle, setStudyPlanTitle] = useState<any>(originalValues.title);
        const [program, setProgram] = useState<any>(originalValues.programId);
        const [department, setDepartment] = useState<any>([]); // Explicitly typed as string[]
        const [campus, setCampus] = useState<any>([]);
        const [college, setCollege] = useState<any>([]);
        const [approvalDate, setApprovalDate] = useState<any>(originalValues.approvalDate);
        const [creditHours, setCreditHours] = useState<any>(originalValues.creditHours);

        const [isChecked, setIsChecked] = useState(originalValues.active);

        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
        };

        // set state for error
        const [studyPlanTitleError, setStudyPlanTitleError] = useState<boolean>(false);
        const [creditHoursError, setCreditHoursError] = useState<boolean>(false);
        const [programError, setProgramError] = useState<boolean>(false);
        const [departmentError, setDepartmentError] = useState<boolean>(false);
        const [campusError, setCampusError] = useState<boolean>(false);
        const [collegeError, setCollegeError] = useState<boolean>(false);
        const [approvalDateError, setApprovalDateError] = useState<boolean>(false);

        const [campusErrorMessage, setCampusErrorMessage] = useState<string>("");
        const [departmentErrorMessage, setDepartmentErrorMessage] = useState<string>("");


        //@ program list options
        const programListOptions = programList && programList.data.map((program: any) => ({
            key: program.programId,
            label: `${program.programTitle}`,
            value: program.programId

        }));
        const handleProgramChange = (event: any, newValue: any) => {
            setProgram(newValue ? newValue.value : null);
        };


        // //@ college list options
        // const collegeListOptions = collegeList && collegeList.data.map((college: any) => ({
        //     key: college.collegeId,
        //     label: `${college.collegeName}`,
        //     value: college.collegeId

        // }));

        //@ college list options
        const collegeListOptions: collegeOption[] = collegeList && collegeList.data.map((item: any) => ({
            key: item.collegeId,
            label: `${item.collegeName}`,
            value: item.collegeId

        }));

        //@ department list options
        const departmentListOptions: departmentOption[] = departmentList && departmentList?.data.map((item: any) => ({
            key: item.departmentId,
            label: `${item.departmentName}`,
            value: item.departmentId

        }));

        //@ campus list options
        const campusListOptions: campusOption[] = campusList && campusList.data.map((item: any) => ({
            key: item.campusId,
            label: `${item.campusName}`,
            value: item.campusId

        }));

        const handleCollegeChange = (event: any, newValue: any) => {
            setCollege(newValue ? newValue.value : null);
        };

        const handleChangeCampus = (event: SelectChangeEvent<typeof campus>) => {
            const {
                target: { value },
            } = event;
            if (campusError === true) {
                setCampusError(false);
                // setRoleNameErrorMessage("");
            }
            setCampus(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        };

        const handleChangeDepartment = (event: SelectChangeEvent<typeof department>) => {
            const {
                target: { value },
            } = event;
            if (campusError === true) {
                setDepartmentError(false);
                // setRoleNameErrorMessage("");
            }
            setDepartment(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        };


        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        //@ Fetching data on load of component
        useEffect(() => {
            if (accessToken !== null) {

                //@college 

                axios.get(readAPI.college, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        // setCampusList(res.data.obj);
                        let collegeList = res.data.obj;
                        const collegeIds = originalValues.collegeId.split(',');
                        let updatedCollege = [];

                        for (let i = 0; i < collegeList.length; i++) {
                            if (collegeIds[i] && collegeList[i].collegeId === collegeIds[i].trim()) {
                                const value = collegeList[i];
                                updatedCollege.push(value.collegeId);
                            }
                        }
                        setCollege(updatedCollege);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                //@campus
                axios.get(readAPI.campus, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        // setCampusList(res.data.obj);
                        let campusList = res.data.obj;
                        const campusIds = originalValues.campusId.split(',');
                        let updatedCampus = [];

                        for (let i = 0; i < campusList.length; i++) {
                            if (campusIds[i] && campusList[i].campusId === campusIds[i].trim()) {
                                const value = campusList[i];
                                updatedCampus.push(value.campusId);
                            }
                        }
                        setCampus(updatedCampus);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                //@department
                axios.get(readAPI.department, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        // setDepartmentList(res.data.obj);
                        let departmentList = res.data.obj;
                        const departmentIds = originalValues.departmentId.split(',');
                        let updatedDepartment = [];

                        for (let i = 0; i < departmentList.length; i++) {
                            if (departmentIds[i] && departmentList[i].departmentId === departmentIds[i].trim()) {
                                const value = departmentList[i];
                                updatedDepartment.push(value.departmentId);
                            }
                        }
                        setDepartment(updatedDepartment);
                    })
                    .catch((err) => {
                        console.log(err);
                    });


                const fetchCollege = async () => {
                    const collegeData = await GlobalService.getCollege(accessToken);
                    setCollegeList(collegeData);
                }
                const fetchCampus = async () => {
                    const campusData = await GlobalService.getCampus(accessToken);
                    setCampusList(campusData);
                }
                const fetchDepartment = async () => {
                    const departmentData = await GlobalService.getDepartment(accessToken);
                    setDepartmentList(departmentData);
                }

                //@fetching program
                const fetchProgram = async () => {
                    const programData = await GlobalService.getProgram(accessToken);
                    setProgramList(programData);
                }


                fetchCollege();
                fetchProgram();
                fetchCampus();
                fetchDepartment();

            }
        }, []);



        const [rows, setRows] = useState<Row[]>(studyPlanOfferedProgram?.length > 0 ? studyPlanOfferedProgram : [{ collegeId: '', campusId: '', departmentId: '' }]);
        const [errors, setErrors] = useState(() => rows.map(() => ({})));
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [modalOpen, setModalOpen] = useState(false);
        const [numOfRowsToAdd, setNumOfRowsToAdd] = useState(1);

        const handleOpenMenu = (event: any) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        const handleOpenModal = () => {
            handleCloseMenu();
            setModalOpen(true);
        };

        const handleCloseModal = () => {
            setModalOpen(false);
        };

        const handleToggle = (index: number, fieldName: keyof Row) => {
            setRows((prevRows) =>
                prevRows.map((row, rowIndex) =>
                    index === rowIndex ? { ...row, [fieldName]: !row[fieldName] } : row
                )
            );
        };

        const handleAddRow = (count: any) => {
            const lastRow = rows[rows.length - 1];
            const newRows: Row[] = Array.from({ length: count }, () => (lastRow));
            setRows([...rows, ...newRows]);
            handleCloseModal();
        };

        const handleDeleteRow = (index: number) => {
            const updatedRows = [...rows];
            updatedRows.splice(index, 1);
            setRows(updatedRows);
        };

        const handleFieldChange = (index: number, field: any, value: string) => {
            setRows((prevData) =>
                prevData.map((item, i) => (i === index ? { ...item, [field]: value } : item))
            );

            const newErrors = [...errors];
            newErrors[index] = '';
            setErrors(newErrors);
        };

        function formatDate(inputDate: any) {
            const parts = inputDate.split('-');
            // Construct the formatted date string directly
            const formattedDateString = `${parts[2]}/${parts[1]}/${parts[0]}`;
            return formattedDateString;
        }

        const formattedDate = formatDate(approvalDate);

        const submitForm = () => {
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken !== null) {
                    setIsLoadingSpinner(true);
                    let hasError = false;
                    const isValidateForm = () => {
                        if (studyPlanTitle === "") {
                            setStudyPlanTitleError(true);
                            hasError = true;
                        }
                        else {
                            setStudyPlanTitleError(false);
                        }
                        if (program === "") {
                            setProgramError(true);
                            hasError = true;
                        }
                        else {
                            setProgramError(false);
                        }
                        // if (department === []) {
                        //     setDepartmentError(true);
                        //     hasError = true;
                        // } else {
                        //     setDepartmentError(false);
                        // }
                        // if (campus === "") {
                        //     setCampusError(true);
                        //     hasError = true;
                        // } else {
                        //     setCampusError(false);
                        // }
                        if (college === "") {
                            setCollegeError(true);
                            hasError = true;
                        } else {
                            setCollegeError(false);
                        }
                        if (approvalDate === "") {
                            setApprovalDateError(true);
                            hasError = true;
                        } else {
                            setApprovalDateError(false);
                        }

                        if (creditHours === "") {
                            setCreditHoursError(true);
                            hasError = true;
                        } else {
                            setCreditHoursError(false);
                        }

                        return !hasError;
                    }


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    }
                    else {

                        const formState: any = {
                            id: originalValues.id,
                            title: studyPlanTitle,
                            programId: program,
                            approvalDate: formattedDate,
                            creditHours: parseInt(creditHours),
                            offeredProgram: rows
                        };

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.status === 200) ? "success" : "error",
                                        message: (response.status === 200) ? `Study Plan has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    if (response.status === 200) {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log('error=>>>', error);
                            });
                    }


                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>

                            <Root style={{ marginLeft: "16px" }}>
                                <Divider textAlign="left" sx={{fontWeight: "bold", fontSize: "16px"}}>STUDY PLAN DETAIL</Divider>
                            </Root>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.studyPlanTitle.label')}`}
                                    placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.studyPlanTitle.placeholder')}`}
                                    variant="standard"
                                    fullWidth
                                    error={studyPlanTitleError}
                                    helperText={(studyPlanTitleError) ? ("* Please Enter Study Plan Title.") : ("")}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={studyPlanTitle}
                                    onChange={(e) => setStudyPlanTitle(e.target.value)}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ paddingLeft: "30px", mt: 1 }}>
                                <Autocomplete
                                    options={programListOptions}
                                    getOptionLabel={option => option.label}
                                    value={programListOptions.find((option: any) => option.value == program) || null}
                                    onChange={handleProgramChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.program.label')}`}
                                            placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.program.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={programError}
                                            helperText={(programError) ? ("* Please Select Any Program.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.approvalDate.label')}`}
                                    // placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.approvalDate.placeholder')}`}
                                    variant="standard"
                                    fullWidth
                                    error={approvalDateError}
                                    helperText={(approvalDateError) ? ("* Please Select Date.") : ("")}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={approvalDate}
                                    onChange={(e) => setApprovalDate(e.target.value)}
                                    defaultValue="2023-05-24"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />


                            </Grid>
                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.creditHours.label')}`}
                                    placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.creditHours.placeholder')}`}
                                    variant="standard"
                                    fullWidth
                                    error={creditHoursError}
                                    helperText={(creditHoursError) ? ("* Please Enter Credit Hours.") : ("")}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={creditHours}
                                    onChange={(e) => setCreditHours(e.target.value)}
                                />
                            </Grid>

                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 2 }}>
                                <FormControlLabel
                                    label="Active"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>
                            <Root style={{ marginTop: "30px", marginLeft: "16px" }}>
                                <Divider textAlign="left" sx={{fontWeight: "bold", fontSize: "16px"}}>OFFER PROGRAM</Divider>
                            </Root>


                            <TableContainer>
                                <Table dir={(currentLang === "ar") ? "rtl" : "ltr"}>
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell>{`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.placeholder')}`}</TableCell>
                                            <TableCell>{`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.campus.placeholder')}`}</TableCell>
                                            <TableCell>{`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.department.placeholder')}`}</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row: any, index: any) => (
                                            <TableRow key={index} style={{ borderStyle: "hidden" }}>
                                                <TableCell>
                                                    <Autocomplete
                                                        options={collegeListOptions}
                                                        getOptionLabel={option => option.label}
                                                        value={collegeListOptions.find((option: any) => option.value === row.collegeId) || null}
                                                        // onChange={(event, newValue: any) => handleFieldChange(index, 'courseCode', newValue?.courseCode || '')}
                                                        onChange={(event, newValue: collegeOption | null) => {
                                                            if (newValue) {
                                                                handleFieldChange(index, 'collegeId', newValue.value);
                                                            } else {
                                                                handleFieldChange(index, 'collegeId', '');
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.label')}`}
                                                                placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.placeholder')}`}
                                                                variant="standard"
                                                                // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                                // error={courseTypeNamesError}
                                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Autocomplete
                                                        options={campusListOptions}
                                                        getOptionLabel={option => option.label}
                                                        value={campusListOptions.find((option: any) => option.value === row.campusId) || null}
                                                        onChange={(event, newValue: campusOption | null) => {
                                                            if (newValue) {
                                                                handleFieldChange(index, 'campusId', newValue.value);
                                                            } else {
                                                                handleFieldChange(index, 'campusId', '');
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.campus.label')}`}
                                                                placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.campus.placeholder')}`}
                                                                variant="standard"
                                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Autocomplete
                                                        options={departmentListOptions}
                                                        getOptionLabel={option => option.label}
                                                        value={departmentListOptions.find((option: any) => option.value === row.departmentId) || null}
                                                        // onChange={(event, newValue: any) => handleFieldChange(index, 'courseCode', newValue?.courseCode || '')}
                                                        onChange={(event, newValue: collegeOption | null) => {
                                                            if (newValue) {
                                                                handleFieldChange(index, 'departmentId', newValue.value);
                                                            } else {
                                                                handleFieldChange(index, 'departmentId', '');
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.department.label')}`}
                                                                placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.department.placeholder')}`}
                                                                variant="standard"
                                                                // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                                // error={courseTypeNamesError}
                                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {index === rows.length - 1 ? (
                                                        <Button
                                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                            onClick={() => handleAddRow(1)}><FaPlus size={20} style={{ marginTop: "4px" }} color="#029c2b" /></Button>

                                                    ) : <Button
                                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                        onClick={() => handleDeleteRow(index)}><FaWindowClose size={20} style={{ marginTop: "4px" }} color="#e60923" /></Button>}
                                                </TableCell>
                                                {/* {college.map((collegeId: string, collegeIndex: number) => {
                                                    return (
                                                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ paddingLeft: "30px" }}>
                                                            <Autocomplete
                                                                key={`college-${index}-${collegeIndex}`}
                                                                options={collegeListOptions}
                                                                getOptionLabel={option => option.label}
                                                                value={collegeListOptions.find((college: any) => college.value === collegeId) || null}
                                                                onChange={(event, newValue: collegeOption | null) => {
                                                                    if (newValue) {
                                                                        handleFieldChange(index, `collegeId${collegeIndex}`, newValue.value);
                                                                    } else {
                                                                        handleFieldChange(index, `collegeId${collegeIndex}`, '');
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.label')}`}
                                                                        placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.placeholder')}`}
                                                                        variant="standard"
                                                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    );
                                                })} */}
                                                {/* <Grid item xl={3}
                                        lg={3}
                                        md={3}
                                        sm={12}
                                        xs={12} sx={{ paddingLeft: "30px" }}>
                                        <Autocomplete
                                            options={collegeListOptions}
                                            getOptionLabel={option => option.label}
                                            value={collegeListOptions.find((option: any) => option.value === row.collegeId) || null}
                                            // onChange={(event, newValue: any) => handleFieldChange(index, 'courseCode', newValue?.courseCode || '')}
                                            onChange={(event, newValue: collegeOption | null) => {
                                                if (newValue) {
                                                    handleFieldChange(index, 'collegeId', newValue.value);
                                                } else {
                                                    handleFieldChange(index, 'collegeId', '');
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.label')}`}
                                                    placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.placeholder')}`}
                                                    variant="standard"
                                                    // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                    // error={courseTypeNamesError}
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                />
                                            )}
                                        />
                                    </Grid> */}
                                                {/* {campus.map((campusId: string, campusIndex: number) => {
                                                    return (
                                                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ paddingLeft: "30px" }}>
                                                            <Autocomplete
                                                                key={`campus-${index}-${campusIndex}`} // Check if `index` is defined and accessible here
                                                                options={campusListOptions}
                                                                getOptionLabel={option => option.label}
                                                                value={campusListOptions.find((option: any) => option.value === campusId) || null}
                                                                onChange={(event, newValue: campusOption | null) => {
                                                                    if (newValue) {
                                                                        handleFieldChange(index, `campusId${campusIndex}`, newValue.value); // Check if `index` is defined and accessible here
                                                                    } else {
                                                                        handleFieldChange(index, `campusId${campusIndex}`, ''); // Check if `index` is defined and accessible here
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.campus.label')}`}
                                                                        placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.campus.placeholder')}`}
                                                                        variant="standard"
                                                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    );
                                                })} */}


                                                {/* <Grid item xl={4}
                                        lg={4}
                                        md={4}
                                        sm={12}
                                        xs={12} sx={{ paddingLeft: "30px" }}>
                                        <Autocomplete
                                            options={campusListOptions}
                                            getOptionLabel={option => option.label}
                                            value={campusListOptions.find((option: any) => option.value === row.campusId) || null}
                                            onChange={(event, newValue: campusOption | null) => {
                                                if (newValue) {
                                                    handleFieldChange(index, 'campusId', newValue.value);
                                                } else {
                                                    handleFieldChange(index, 'campusId', '');
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.campus.label')}`}
                                                    placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.campus.placeholder')}`}
                                                    variant="standard"
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                />
                                            )}
                                        />
                                    </Grid> */}

                                                {/* {department.map((departmentId: string, departmentIndex: number) => {
                                                    return (
                                                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} sx={{ paddingLeft: "30px" }}>
                                                            <Autocomplete
                                                                key={`department-${index}-${departmentIndex}`}
                                                                options={departmentListOptions}
                                                                getOptionLabel={option => option.label}
                                                                value={departmentListOptions.find((department: any) => department.value === departmentId) || null}
                                                                onChange={(event, newValue: departmentOption | null) => {
                                                                    if (newValue) {
                                                                        handleFieldChange(index, `departmentId${departmentIndex}`, newValue.value);
                                                                    } else {
                                                                        handleFieldChange(index, `departmentId${departmentIndex}`, '');
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.department.label')}`}
                                                                        placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.department.placeholder')}`}
                                                                        variant="standard"
                                                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    );
                                                })} */}

                                                {/* <Grid item xl={4}
                                        lg={4}
                                        md={4}
                                        sm={12}
                                        xs={12} sx={{ paddingLeft: "30px" }}>
                                        <Autocomplete
                                            options={departmentListOptions}
                                            getOptionLabel={option => option.label}
                                            value={departmentListOptions.find((option: any) => option.value === row.departmentId) || null}
                                            // onChange={(event, newValue: any) => handleFieldChange(index, 'courseCode', newValue?.courseCode || '')}
                                            onChange={(event, newValue: collegeOption | null) => {
                                                if (newValue) {
                                                    handleFieldChange(index, 'departmentId', newValue.value);
                                                } else {
                                                    handleFieldChange(index, 'departmentId', '');
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.department.label')}`}
                                                    placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.department.placeholder')}`}
                                                    variant="standard"
                                                    // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                    // error={courseTypeNamesError}
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                />
                                            )}
                                        />
                                    </Grid> */}

                                                {/* <Grid item xl={1}
                                                    lg={1}
                                                    md={1}
                                                    sm={12}
                                                    xs={12} sx={{ paddingLeft: "30px" }}>
                                                    {index === rows.length - 1 ? (
                                                        <Button
                                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                            onClick={() => handleAddRow(1)}><FaPlus size={20} style={{ marginTop: "4px" }} color="#029c2b" /></Button>

                                                    ) : <Button
                                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                        onClick={() => handleDeleteRow(index)}><FaWindowClose size={20} style={{ marginTop: "4px" }} color="#e60923" /></Button>}
                                                </Grid> */}

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>




                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateStudyPlan;