import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    FormControl,
    FormControlLabel,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import courseJson from "../../../../../../courseCode.json"
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';

import readAPI from '../../../../data/api/read';
import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import sortOrderJson from "../../../../data/json/sortOrder.json";
import requiredJson from "../../../../data/json/required.json"
import { ReportSettingService, SessionType } from '../../../../service/ReportSettingService';
import { CollegeType, FarCoordinatorService } from '../../../../../report/service/FarCoordinatorService';

interface SessionTypeOption {
    label: string;
    value: string;
}

interface CollegeOption {
    label: string;
    value: string;
}
interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateUploadDocStatus = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [collegeId, setCollegeId] = useState<string>(originalValues.collegeId);
        const [title, setTitle] = useState<string>(originalValues.title);
        const [displayOrder, setDisplayOrder] = useState<string>(originalValues.displayOrder)
        const [sessionTypeId, setSessionTypeId] = useState<string>(originalValues.sessionId);
        const [required, setRequired] = useState<string>(originalValues.required);
        const [isChecked, setIsChecked] = useState(true);
        const [sessionTypeList, setSessionTypeList] = useState<SessionType>({
            data: [],
            errMessage: ""
        });
        const [collegeList, setCollegeList] = useState<CollegeType>({
            data: [],
            errMessage: ""
        });


        const sessionTypeListOptions: SessionTypeOption[] = sessionTypeList.data.map((item: any) => ({
            label: `${item.name}`,
            value: item.id,
        }));

        const collegeListOptions: CollegeOption[] = collegeList.data.map((item: any) => ({
            label: `${item.collegeName}`,
            value: item.collegeId,
        }));

        // For handling errors

        const [titleError, setTitleError] = useState<boolean>(false);
        const [displayOrderError, setDisplayOrderError] = useState<boolean>(false);
        const [sessionTypeError, setSessonTypeError] = useState<boolean>(false);
        const [requiredError, setRequiredError] = useState<boolean>(false);
        const [collegeIdError, setCollegeIdError] = useState<boolean>(false);


        // For handling error messages
        // const [courseCodeErrorMessage, setCourseCodeErrorMessage] = useState("");



        const handleCollegeChange = (event: any, newValue: any) => {
            setCollegeId(newValue ? newValue.value : null);
        };

        const handleSessionTypeChange = (event: any, newValue: any) => {
            setSessionTypeId(newValue ? newValue.value : null);
        }

        const handleOrderChange = (event: any, newValue: any) => {
            setDisplayOrder(newValue ? newValue.value : null);
        }
        const handleRequiredChange = (event: any, newValue: any) => {
            setRequired(newValue ? newValue.value : null);
        }



        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);



        useEffect(() => {
            if (accessToken !== null) {

                //@ fetching session Type
                const fetchSessionType = async () => {
                    const sessionData = await ReportSettingService.getSessionType(accessToken);
                    setSessionTypeList(sessionData);
                }
                //@ fetching college
                const fetchCollege = async () => {
                    const collegeData = await FarCoordinatorService.getCollege(accessToken);
                    setCollegeList(collegeData);
                }

                //calling function
                fetchSessionType();
                fetchCollege();
            }
        }, []);

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {

                    if (title !== "" && collegeId !== "" && sessionTypeId !== "" && displayOrder !== "" && required !== "") {
                        const formState = {
                            "id": originalValues.id,
                            "title": title,
                            "sessionType": sessionTypeId,
                            "required": required,
                            "collegeId": "All",
                            "displayOrder": displayOrder,
                            "active": isChecked
                        }

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.data.code === "201") {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "201") ? "success" : "error",
                                        message: (response.data.code === "201") ? `DocumentType has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    const m = response.data.message;
                                    if (response.data.code === "201") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                    
                                }
                            })
                            .catch(function (error) {
                                console.log('error=>>>', error);
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="Title"
                                    placeholder="{`${t('Home.Header.Modals.systemPreferences.UploadDocStatus.policy.Inputs.docTypeName.placeholder')}`}"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={titleError}
                                    value={title}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (titleError) {
                                            setTitleError(false);
                                        }
                                        setTitle(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ mt: 4 }}>
                                <Autocomplete
                                    options={sortOrderJson.sortOrder}
                                    getOptionLabel={(option: any) => option.value}
                                    // value={sortOrderJson.sortOrder.find((item: any) => item.id === displayOrder) || null}
                                    value={sortOrderJson.sortOrder.find((item) => item.value == displayOrder) || null}
                                    onChange={handleOrderChange}
                                    dir={currentLang === "ar" ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder={`${t('Home.Header.Modals.systemPreferences.UploadDocStatus.policy.Inputs.sortOrder.placeholder')}`}
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} >
                                <Autocomplete
                                    options={sessionTypeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={sessionTypeListOptions.find((option: any) => option.value == sessionTypeId) || null}
                                    onChange={handleSessionTypeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={`${t('Home.Header.Modals.systemPreferences.UploadDocStatus.policy.Inputs.sessionTypeId.placeholder')}`}
                                            variant="standard"
                                            // error={sessionTypeError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />




                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    options={requiredJson.required}
                                    getOptionLabel={(option: any) => option.value}
                                    // value={requiredJson.required.find(item => item.value == required) || null}
                                    value={requiredJson.required.find(item => item.value == required) || null}
                                    onChange={handleRequiredChange}
                                    dir={currentLang === "ar" ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder={`${t('Home.Header.Modals.systemPreferences.UploadDocStatus.policy.Inputs.required.placeholder')}`}
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    options={collegeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={
                                        collegeId === "All"
                                        ? collegeListOptions.find(option => option.value === "All")
                                        : collegeListOptions.find(option => option.value === collegeId) || { label: "All", value: "All" }
                                    }
                                    onChange={handleCollegeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={`${t('Home.Header.Modals.systemPreferences.UploadDocStatus.policy.Inputs.college.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}
                                />




                            </Grid>





                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>

                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />

                                {/* <Switch
                                    checked={isChecked}
                                    onChange={handleSwitchChange}
                                    inputProps={{ 'aria-label': 'Toggle' }}
                                /> */}
                            </Grid>










                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateUploadDocStatus;