import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css"

const Home = lazy(() => import("../../../Pages/Home"));
const AssessmentDashboard = lazy(() => import("../../../Components/Pages/Home/AssessmentDashboard"));
const ViewAssessment = lazy(() => import("../Components/ManageAssessment/ViewAssessment"));
const EnterDetailMarks = lazy(() => import("../Components/DetailMarks/EnterDetailMarks"));
const EnterOverallMarks = lazy(() => import("../Components/OverallMarks/EnterOverallMarks"))

interface AssessmentRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    openAssessmentModal: any,
    setOpenAssessmentModal: any


}

const AssessmentRouter: FC<AssessmentRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,
    openAssessmentModal,
    setOpenAssessmentModal

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
        <Routes>
            <Route
                path="view"
                element={<Home
                    setShowHeader={setShowHeader}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    // For minified sidebar
                    isMinified={isMinified}
                    setIsMinified={setIsMinified}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}

                    // Sidebar Apps List
                    sidebarAppsListArray={sidebarAppsListArray}
                    setSidebarAppsListArray={setSidebarAppsListArray}

                    // @subComponent
                    subComponent={
                        <AssessmentDashboard
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />}
            />


            <Route path={"manage"}>
                <Route
                    path={"view"}
                    element={
                        <ViewAssessment
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}

                            openAssessmentModal={openAssessmentModal}
                            setOpenAssessmentModal={setOpenAssessmentModal}
                            setCurrentLang={setCurrentLang}
                        />
                    }
                />
            </Route>

            <Route path={"enterDetailMarks"}>
                <Route
                    path={"view"}
                    element={
                        <EnterDetailMarks
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}

                            // openAssessmentModal={openAssessmentModal}
                            // setOpenAssessmentModal={setOpenAssessmentModal}
                            setCurrentLang={setCurrentLang}
                        />
                    }
                />
            </Route>

            <Route path={"enterOverAllMarks"}>
                <Route
                    path={"view"}
                    element={
                        <EnterOverallMarks
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}

                            // openAssessmentModal={openAssessmentModal}
                            // setOpenAssessmentModal={setOpenAssessmentModal}
                            setCurrentLang={setCurrentLang}
                        />
                    }
                />
            </Route>


            <Route path={"dashboard"}>
                <Route
                    path={"view"}
                    element={
                        <AssessmentDashboard
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
            </Route>
        </Routes>
        </Suspense>
    )

}

export default AssessmentRouter;
