import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';

// import Home from "../../../Pages/Home";
// import ViewSystemPreferences from "../Components/ViewSystemPreferences";
// import ManageReportSetting from "../Pages/ManageReportSetting";
// import MOMSetting from "../Components/MOMSetting";

const Home = lazy(() => import("../../../Pages/Home"));
const ViewSystemPreferences = lazy(() => import("../Components/ViewSystemPreferences"));
const ManageReportSetting = lazy(() => import("../Pages/ManageReportSetting"));
const MOMSetting = lazy(() => import("../Components/MOMSetting"));


interface SystemPreferencesRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    openManageLocationModal: any,
    setOpenManageLocationModal: any
}

const SystemPreferenceRouter: FC<SystemPreferencesRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,
    openManageLocationModal,
    setOpenManageLocationModal

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense fallback={<h1>Loading...</h1>}>
        <Routes>
            <Route
                path="view"
                element={
                        <ViewSystemPreferences
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />


            <Route path={"reportSetting"}>
                <Route
                    path={"view"}
                    element={
                            <ManageReportSetting
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                />
            </Route>


            <Route path={"momSetting"}>
                <Route
                    path={"view"}
                    element={<Home
                        setShowHeader={setShowHeader}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        // For minified sidebar
                        isMinified={isMinified}
                        setIsMinified={setIsMinified}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}

                        // Sidebar Apps List
                        sidebarAppsListArray={sidebarAppsListArray}
                        setSidebarAppsListArray={setSidebarAppsListArray}

                        // @subComponent
                        subComponent={
                            <MOMSetting
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}
                                openManageLocationModal={openManageLocationModal}
                                setOpenManageLocationModal={setOpenManageLocationModal}
                            />
                        }
                    />}
                />
            </Route>

        </Routes>
        </Suspense>
    )

}

export default SystemPreferenceRouter;
