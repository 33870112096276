import Swal from "sweetalert2";

export const alertDeleteConfirm = () => {
  return Swal.fire({
    title: "Are you sure want to delete?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  });
};

export const alertCancelConfirm = () => {
  return Swal.fire({
    title: "Are you sure you want to cancel the meeting ?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, cancel the meeting!",
  });
};

export const alertError = (msg) => {
  return Swal.fire("Error!", msg, "error");
};

export const alertSuccess = (msg) => {
  return Swal.fire("Success", msg, "success");
};

export const alertWarning = (title, msg) => {
  return Swal.fire({
    icon: "error",
    title: title,
    text: msg,
  });
};
