export const CREATE_MEETING_REQUEST = 'CREATE_MEETING_REQUEST';
export const CREATE_MEETING_SUCCESS = 'CREATE_MEETING_SUCCESS';
export const CREATE_MEETING_FAILURE = 'CREATE_MEETING_FAILURE';

export const UPDATE_MEETING_REQUEST = 'UPDATE_MEETING_REQUEST';
export const UPDATE_MEETING_SUCCESS = 'UPDATE_MEETING_SUCCESS';
export const UPDATE_MEETING_FAILURE = 'UPDATE_MEETING_FAILURE';

export const GET_MEETING_LIST = 'GET_MEETING_LIST';
export const ADD_SINGLE_MEETING = 'ADD_SINGLE_MEETING';

export const createMeetingRequest = () => ({ type: CREATE_MEETING_REQUEST });
export const createMeetingSuccess = (message: any) => ({ type: CREATE_MEETING_SUCCESS, payload: message });
export const createMeetingFailure = (error: any) => ({ type: CREATE_MEETING_FAILURE, payload: error });


export const updateMeetingRequest = () => ({type: UPDATE_MEETING_REQUEST });
export const updateMeetingSuccess = (message: any) => ({ type: UPDATE_MEETING_SUCCESS, payload: message });
export const updateMeetingFailure = (error: any) => ({ 
    type: UPDATE_MEETING_FAILURE, 
    payload: error 
})

export const getMeetingListData = (data: any) => ({
    type: GET_MEETING_LIST,
    payload: {data}
  })

  export const addSingleMeeting = (data: any) => ({
    type: ADD_SINGLE_MEETING,
    payload: {data}
  })
