import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Autocomplete,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Switch
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FaPlus, FaWindowClose, FaEllipsisV } from "react-icons/fa";
import SnackBar from '../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import styles from "./style.module.css";
import readAPI from '../../../data/api/read';
import { useSelector } from 'react-redux';
import { CollegeDetails, DepartmentDetails, GlobalService, ProgramDetails } from '../../../../../Service/GlobalService';
import { RootState } from '../../../../../redux/store';
import { styled } from '@mui/material/styles';
import academicYearJson from "../../../../../Apps/systemPreferences/data/json/academicYear.json";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
        marginTop: theme.spacing(5),
    },
}));

interface InstructorNameOption {
    label: string;
    value: string;
    userName: string;
}
interface Row {
    collegeId: string;
    campusId: string;
    departmentId: string;
    active: boolean;
}

interface collegeOption {
    label: string;
    value: string;
}

interface departmentOption {
    label: string;
    value: string;
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
    setFetchUpdate: any,
    assignTaskTasks?: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}
const UpdateAssignTask = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        assignTaskTasks
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
        const [instructorName, setInstructorName] = useState<any>([]);
        const [section, setSection] = useState<any>([]);

        

        const assignTaskdata = useSelector((state: RootState) => state.assignTaskData.data)

        console.log("assignTaskTasks", assignTaskdata);

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        const [programList, setProgramList] = useState<ProgramDetails>({
            data: [],
            errMessage: ""
        })
        const [departmentList, setDepartmentList] = useState<DepartmentDetails>({
            data: [],
            errMessage: ""
        })
        const [collegeList, setCollegeList] = useState<CollegeDetails>({
            data: [],
            errMessage: ""
        })

        const [departmentId, setDepartmentId] = useState<any>(originalValues.departmentId);
        const [programId, setProgramId] = useState<any>(originalValues.programId);
        const [collegeId, setCollegeId] = useState(originalValues.collegeId)
        const [academicYear, setAcademicYear] = useState(originalValues.academicYear)

        const [program, setProgram] = useState<any>(originalValues.programId);
        const [department, setDepartment] = useState<any>([]);
        const [college, setCollege] = useState<any>([]);

        const [isChecked, setIsChecked] = useState(originalValues.active);

        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
            console.log('Switch value:', newValue);
        };

        // set state for error
        const [programError, setProgramError] = useState<boolean>(false);
        const [departmentError, setDepartmentError] = useState<boolean>(false);
        const [collegeError, setCollegeError] = useState<boolean>(false);


        const handleYearChange = (event: any, newValue: any) => {
            setAcademicYear(newValue ? newValue.value : null);
        };

        //@ program list options
        const programListOptions = programList && programList.data.map((program: any) => ({
            key: program.programId,
            label: `${program.programTitle}`,
            value: program.programId

        }));
        const handleProgramChange = (event: any, newValue: any) => {
            setProgram(newValue ? newValue.value : null);
        };

        //@ college list options
        const collegeListOptions: collegeOption[] = collegeList && collegeList.data.map((item: any) => ({
            key: item.collegeId,
            label: `${item.collegeName}`,
            value: item.collegeId

        }));

        //@ department list options
        const departmentListOptions: departmentOption[] = departmentList && departmentList?.data.map((item: any) => ({
            key: item.departmentId,
            label: `${item.departmentName}`,
            value: item.departmentId

        }));
        const handleDepartmentChange = (event: any, newValue: any) => {
            setDepartmentId(newValue ? newValue.value : null);
        };

        const handleCollegeChange = (event: any, newValue: any) => {
            setCollege(newValue ? newValue.value : null);
        };

        const instructorNameOptions: InstructorNameOption[] = instructorName.map((item: any) => ({
            label: `${item.firstName} ${item.lastName} ${item.emailId}`,
            value: item.userName,
            userName: item.userName
        }));

        const sectionOptions = section.map((item: any) => ({
            label: `${item.sectionName}`,
            value: item.id
        }));




        useEffect(() => {
            if (accessToken !== null) {
                axios.get(readAPI.fetchUsers, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setInstructorName(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        useEffect(() => {
            if (accessToken !== null) {
                axios.get(readAPI.getSectionForAssignTask, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setSection(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        //@ Fetching data on load of component
        useEffect(() => {
            if (accessToken !== null) {
                //@fetching college
                const fetchCollege = async () => {
                    const collegeData = await GlobalService.getCollege(accessToken);
                    setCollegeList(collegeData);
                }
                //@fetching department
                const fetchDepartment = async () => {
                    const departmentData = await GlobalService.getDepartment(accessToken);
                    setDepartmentList(departmentData);
                }
                //@fetching program
                const fetchProgram = async () => {
                    const programData = await GlobalService.getProgram(accessToken);
                    setProgramList(programData);
                    console.log('global program', programData);
                }
                fetchCollege();
                fetchProgram();
                fetchDepartment();
            }
        }, []);



        const [rows, setRows] = useState<Row[]>(assignTaskdata?.length > 0 ? assignTaskdata : [{id: '', responsible: '', sectionId: '', active: true }]);
        const [errors, setErrors] = useState(() => rows.map(() => ({})));
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [modalOpen, setModalOpen] = useState(false);
        const [numOfRowsToAdd, setNumOfRowsToAdd] = useState(1);

        const handleOpenMenu = (event: any) => {
            setAnchorEl(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setAnchorEl(null);
        };

        const handleOpenModal = () => {
            handleCloseMenu();
            setModalOpen(true);
        };

        const handleCloseModal = () => {
            setModalOpen(false);
        };
        const handleToggle = (index: any) => {
            const updatedRows = rows.map((row, rowIndex) =>
                index === rowIndex ? { ...row, active: !row.active } : row
            );
            setRows(updatedRows);
        };

        // const handleToggle = (index: number, fieldName: keyof Row) => {
        //     setRows((prevRows) =>
        //         prevRows.map((row, rowIndex) =>
        //             index === rowIndex ? { ...row, [fieldName]: !row[fieldName] } : row
        //         )
        //     );
        // };

        const handleAddRow = (count: any) => {
            const lastRow = rows[rows.length - 1];
            console.log('lastrow', lastRow)
            const newRows: Row[] = Array.from({ length: count }, () => (lastRow));
            console.log('newRows', newRows);
            console.log('rows', rows);

            setRows([...rows, ...newRows]);
            handleCloseModal();
        };

        const handleDeleteRow = (index: number) => {
            console.log("delete row", index);
            const updatedRows = [...rows];
            updatedRows.splice(index, 1);
            setRows(updatedRows);
        };

        const handleFieldChange = (index: number, field: any, value: string) => {
            setRows((prevData) =>
                prevData.map((item, i) => (i === index ? { ...item, [field]: value } : item))
            );

            const newErrors = [...errors];
            newErrors[index] = '';
            setErrors(newErrors);
        };

        console.log("rows", rows);

        const submitForm = () => {
            if (accessToken !== null) {
                setIsLoadingSpinner(true);
                let hasError = false;
                const isValidateForm = () => {

                    if (program === "") {
                        setProgramError(true);
                        hasError = true;
                    }
                    else {
                        setProgramError(false);
                    }
                    // if (department === []) {
                    //     setDepartmentError(true);
                    //     hasError = true;
                    // } else {
                    //     setDepartmentError(false);
                    // }
                    // if (campus === "") {
                    //     setCampusError(true);
                    //     hasError = true;
                    // } else {
                    //     setCampusError(false);
                    // }
                    if (college === "") {
                        setCollegeError(true);
                        hasError = true;
                    } else {
                        setCollegeError(false);
                    }


                    return !hasError;
                }


                // Set the validation errors

                if (!isValidateForm()) {
                    setSnackBarHandler({
                        severity: "warning",
                        open: true,
                        message: "Please enter all the fields"
                    });
                    return;
                }
                else {

                    const formState: any = {
                        reportId: originalValues.reportId,
                        programId: programId,
                        departmentId: departmentId,
                        collegeId: collegeId,
                        academicYear: academicYear,
                        tasks: rows
                    };

                    // for (let i = 0; i < campus.length; i++) {
                    //     formState.offeredProgram.push({ collegeId: college, campusId: campus[i], departmentId: department[i] });
                    // }

                    

                    axios.post(url,
                        formState
                        , {
                            headers: {
                                'x-api-key': accessToken
                            }
                        })
                        .then(function (response) {
                            
                            if (response.status === 201) {
                                setSnackBarHandler({
                                    severity: (response.status === 201) ? "success" : "error",
                                    message: (response.status === 201) ? `Task has been updated successfully` : (response.data.message),
                                    open: true
                                })
                                const m = response.data.message;
                                if (response.status === 201) {
                                    setTimeout(() => {
                                        setOpenUpdateTableModal(false);
                                        setFetchUpdate(true);
                                    }, 3000);
                                }
                                
                            }
                        })
                        .catch(function (error) {
                            console.log('error=>>>', error);
                        });
                }


            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>

                            <Root style={{ marginLeft: "16px" }}>
                                <Divider textAlign="left" sx={{ fontWeight: "bold", fontSize: "16px" }}>Filter</Divider>
                            </Root>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <Autocomplete
                                    options={collegeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={collegeListOptions.find((option: any) => option.value === collegeId) || null}
                                    onChange={handleCollegeChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.label')}`}
                                            placeholder={`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        // error={programError}
                                        // helperText={(programError) ? ("* Please Select Any Program.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <Autocomplete
                                    options={departmentListOptions}
                                    getOptionLabel={option => option.label}
                                    value={departmentListOptions.find(option => option.value === departmentId) || null}
                                    onChange={handleDepartmentChange}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Home.Sidebar.list.report.subMenu.FARCoordinator.details.department')}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <Autocomplete
                                    options={programListOptions}
                                    getOptionLabel={option => option.label}
                                    value={programListOptions.find(option => option.value == programId) || null}
                                    onChange={handleProgramChange}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Home.Sidebar.list.report.subMenu.FARCoordinator.details.program')}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>

                                <Autocomplete
                                    options={academicYearJson.academicYear}
                                    getOptionLabel={(option) => option.value}
                                    value={academicYearJson.academicYear.find(item => item.value == academicYear) || null}
                                    onChange={handleYearChange}
                                    dir={currentLang === "ar" ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder={`${t('Home.Header.Modals.systemPreferences.AssignTaskForAnnualProgram.policy.Inputs.academicYear.placeholder')}`}
                                            label={t('Home.Header.Modals.systemPreferences.AssignTaskForAnnualProgram.policy.Inputs.academicYear.label')}
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Root style={{ marginTop: "30px", marginLeft: "16px" }}>
                                <Divider textAlign="left" sx={{ fontWeight: "bold", fontSize: "16px" }}>Tasks Detail</Divider>
                            </Root>


                            <TableContainer>
                                <Table dir={(currentLang === "ar") ? "rtl" : "ltr"}>
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell>{`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.college.placeholder')}`}</TableCell>
                                            <TableCell>{`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.campus.placeholder')}`}</TableCell>
                                            <TableCell>{`${t('Home.Header.Modals.studyPlan.CreateStudyPlan.policy.Inputs.department.placeholder')}`}</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row: any, index: any) => (
                                            <TableRow key={index} style={{ borderStyle: "hidden" }}>
                                                <TableCell>
                                                    <Autocomplete
                                                        options={instructorNameOptions}
                                                        getOptionLabel={option => option.label}
                                                        value={instructorNameOptions.find((option: any) => option.value === row.responsible) || null}
                                                        onChange={(event, newValue: InstructorNameOption | null) => {
                                                            if (newValue) {
                                                                // setSelectedCourseCode(newValue.value);
                                                                handleFieldChange(index, 'responsible', newValue.value);
                                                            } else {
                                                                // setSelectedCourseCode(null);
                                                                handleFieldChange(index, 'responsible', '');
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder={`${t('Home.Header.Modals.systemPreferences.AssignTaskForAnnualProgram.policy.Inputs.responsible.placeholder')}`}
                                                                variant="standard"
                                                                // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                                // error={courseTypeNamesError}
                                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                style={{ width: '400px' }}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ padding: "7px 20px 0px 16px", width: "100px" }}>


                                                    <Autocomplete
                                                        options={sectionOptions}
                                                        getOptionLabel={option => option.label}
                                                        value={sectionOptions.find((option: any) => option.value === row.sectionId) || null}
                                                        onChange={(event, newValue: InstructorNameOption | null) => {
                                                            if (newValue) {
                                                                // setSelectedCourseCode(newValue.value);
                                                                handleFieldChange(index, 'sectionId', newValue.value);
                                                            } else {
                                                                // setSelectedCourseCode(null);
                                                                handleFieldChange(index, 'sectionId', '');
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder={`${t('Home.Header.Modals.systemPreferences.AssignTaskForAnnualProgram.policy.Inputs.sections.placeholder')}`}
                                                                variant="standard"
                                                                // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                                // error={courseTypeNamesError}
                                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                style={{ width: '400px' }}
                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ padding: "15px 3px 0px 6px", borderBottom: '1px solid #949494', display: "inline-block", width: "100px" }}>
                                                    <Switch
                                                        checked={row.active}
                                                        onChange={() => handleToggle(index)}
                                                        inputProps={{ 'aria-label': 'Toggle' }}
                                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    />
                                                </TableCell>


                                                <TableCell>
                                                    {index === rows.length - 1 ? (
                                                        <Button
                                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                            onClick={() => handleAddRow(1)}><FaPlus size={20} style={{ marginTop: "4px" }} color="#029c2b" /></Button>

                                                    ) : <Button
                                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                        onClick={() => handleDeleteRow(index)}><FaWindowClose size={20} style={{ marginTop: "4px" }} color="#e60923" /></Button>}
                                                </TableCell>


                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>




                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateAssignTask;