interface PortalState {
    data: any | null;
    documentType: any | null;
  }
  
  const initialState: PortalState = {
    data: null,
    documentType: null,
  };

  const portalReducer = (state = initialState, action: any): PortalState => {
    switch (action.type) {
        case 'GET_UPLOADED_DOCUMENT':
            return {
                ...state,
                data: action.payload.data,
            };
        case 'GET_UPLOADED_DOCUMENT_TYPE':
            return {
                ...state,
                documentType: action.payload.data
            }
        default:
            return state;
    }
};

export default portalReducer;