import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css";


const ViewUsers = lazy(() => import("../Users/ViewUsers"));
const AddUser = lazy(() => import("../Users/AddUser"));
const ViewGroups = lazy(() => import("../Groups/ViewGroups"));
const AddGroup = lazy(() => import("../Groups/AddGroup"));
const ViewApps = lazy(() => import("../Application/ViewApps"));
const AddApp = lazy(() => import("../Application/AddApp"));
const ViewRoles = lazy(() => import("../Roles/ViewRoles"));
const AddRole = lazy(() => import("../Roles/AddRole"));
const ViewAppForm = lazy(() => import("../AppForm/ViewAppForm"));
const AddAppForm = lazy(() => import("../AppForm/AddAppForm"));
const ViewRoleApp = lazy(() => import("../RoleAppPrivilege/ViewRoleApp"));
const AddRoleApp = lazy(() => import("../RoleAppPrivilege/AddRoleApp"));
const ViewUserGroup = lazy(() => import("../UserGroup/ViewUserGroup"));
const AddUserGroup = lazy(() => import("../UserGroup/AddUserGroup"));
const ViewGroupRole = lazy(() => import("../GroupRole/ViewGroupRole"));
const AddGroupRole = lazy(() => import("../GroupRole/AddGroupRole"));


interface AccountProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any
}

const AccountRouter: FC<AccountProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
        <Routes>
            <Route
                path={"view"}
                element={
                    <ViewUsers
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        // For minified sidebar
                        isMinified={isMinified}
                        setIsMinified={setIsMinified}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}

                    />
                }
            />


            <Route path={"users"}>
                <Route
                    path={"view"}
                    element={
                        <ViewUsers
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                <Route
                    path={"adduser"}
                    element={
                        <AddUser
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                        />
                    }
                />
            </Route>
            <Route path={"groups"}>
                <Route
                    path={"view"}
                    element={
                        <ViewGroups
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                <Route
                    path={"addgroup"}
                    element={
                        <AddGroup
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                        />
                    }
                />
            </Route>
            <Route path={"apps"}>
                <Route
                    path={"view"}
                    element={
                        <ViewApps
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                <Route
                    path={"addapp"}
                    element={
                        <AddApp
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                        />
                    }
                />
            </Route>
            <Route path={"roles"}>
                <Route
                    path={"view"}
                    element={
                        <ViewRoles
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                <Route
                    path={"addrole"}
                    element={
                        <AddRole
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                        />
                    }
                />
            </Route>
            <Route path={"appForm"}>
                <Route
                    path={"view"}
                    element={
                        <ViewAppForm
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                <Route
                    path={"addAppForm"}
                    element={
                        <AddAppForm
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                        />
                    }
                />
            </Route>
            <Route path={"role-app"}>
                <Route
                    path={"view"}
                    element={
                        <ViewRoleApp
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                <Route
                    path={"addRoleApp"}
                    element={
                        <AddRoleApp
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                        />
                    }
                />
            </Route>
            <Route path={"user-group"}>
                <Route
                    path={"view"}
                    element={
                        <ViewUserGroup
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                <Route
                    path={"addUserGroup"}
                    element={
                        <AddUserGroup
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                        />
                    }
                />
            </Route>
            <Route path={"group-role"}>
                <Route
                    path={"view"}
                    element={
                        <ViewGroupRole
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                <Route
                    path={"addGroupRole"}
                    element={
                        <AddGroupRole
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                        />
                    }
                />
            </Route>
        </Routes>
        </Suspense>
    )

}

export default AccountRouter;
