import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControlLabel,
    Autocomplete,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import axios from 'axios';

import styles from "./style.module.css";

import gulfCountry from "../../../../../../country.json"

import readAPI from '../../../../data/api/read';
import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";

interface Country {
    name: string;
    capital: string;
    population: string;
    area_sq_km: string;
    currency: string;
    languages: string[];
    flag: string;
}

interface ViewAllCampusData {
    timestamp: string;
    transactionId: string;
    status: string;
    code: string;
    message: string[];
    obj: {
        createdBy: string;
        creationDateAndTime: number[];
        updatedBy: string | null;
        updateDateAndTime: number[] | null;
        campusId: string;
        campusName: string;
        campusEmailId: string;
        campusWebAddress: string;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        zipCode: string;
        province: string;
        country: string;
        campusPhoneNumber: string;
        campusFaxNumber: string;
        campusMission: string;
        campusVision: string;
        active: boolean;
    }[];
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateCampus = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // Fetching data using axios

        const [viewAllCampusData, setViewAllCampusData] = useState<ViewAllCampusData | null>(null);

        const [countryList, setCountryList] = useState<Country[]>(gulfCountry.countries);
        const [countryDropDownOpen, setCountryDropDownOpen] = useState(false);
        const getOptionLabel = (option: Country) => option.name;

        const handleChange = (event: React.ChangeEvent<{}>, value: Country | null) => {
            if (value) {
                setCountry(value.name);
                if (countryError) {
                    setCountryError(false);
                }
            } else {
                console.log("No country selected.");
            }
        };

        const [CampusName, setCampusName] = useState<string>(originalValues.campusName);
        const [CampusEmailId, setCampusEmailId] = useState<string>(originalValues.campusEmailId);
        const [CampusPhoneNumber, setCampusPhoneNumber] = useState<string>(originalValues.campusPhoneNumber);
        const [CampusFaxNumber, setCampusFaxNumber] = useState<string>(originalValues.campusFaxNumber);
        const [CampusMission, setCampusMission] = useState<string>(originalValues.campusMission);
        const [CampusVision, setCampusVision] = useState<string>(originalValues.campusVision);
        const [CampusWebAddress, setCampusWebAddress] = useState<string>(originalValues.campusWebAddress);
        const [addressLine1, setAddressLine1] = useState<string>(originalValues.addressLine1);
        const [addressLine2, setAddressLine2] = useState<string>(originalValues.addressLine2);
        const [addressLine3, setAddressLine3] = useState<string>(originalValues.addressLine3);
        const [zipCode, setZipCode] = useState<string>(originalValues.zipCode);
        const [province, setProvince] = useState<string>(originalValues.province);
        const [country, setCountry] = useState<string>(originalValues.country);

        // For handling errors

        const [CampusNameError, setCampusNameError] = useState<boolean>(false);
        const [CampusEmailIdError, setCampusEmailIdError] = useState<boolean>(false);
        const [CampusPhoneNumberError, setCampusPhoneNumberError] = useState<boolean>(false);
        const [CampusFaxNumberError, setCampusFaxNumberError] = useState<boolean>(false);
        const [CampusMissionError, setCampusMissionError] = useState<boolean>(false);
        const [CampusVisionError, setCampusVisionError] = useState<boolean>(false);
        const [CampusWebAddressError, setCampusWebAddressError] = useState<boolean>(false);
        const [addressLine1Error, setAddressLine1Error] = useState<boolean>(false);
        const [addressLine2Error, setAddressLine2Error] = useState<boolean>(false);
        const [addressLine3Error, setAddressLine3Error] = useState<boolean>(false);
        const [zipCodeError, setZipCodeError] = useState<boolean>(false);
        const [provinceError, setProvinceError] = useState<boolean>(false);
        const [countryError, setCountryError] = useState<boolean>(false);

        // For handling error messages
        const [CampusNameErrorMessage, setCampusNameErrorMessage] = useState("");
        const [CampusWebAddressErrorMessage, setCampusWebAddressErrorMessage] = useState("");
        const [addressLine1ErrorMessage, setAddressLine1ErrorMessage] = useState("");
        const [addressLine2ErrorMessage, setAddressLine2ErrorMessage] = useState("");
        const [addressLine3ErrorMessage, setAddressLine3ErrorMessage] = useState("");
        const [CampusEmailIdErrorMessage, setCampusEmailIdErrorMessage] = useState("");
        const [CampusPhoneNumberErrorMessage, setCampusPhoneNumberErrorMessage] = useState("");
        const [zipCodeErrorMessage, setCampusZipCodeErrorMessage] = useState("");
        const [CampusVisionErrorMessage, setCampusVisionErrorMessage] = useState("");
        const [CampusFaxNumberErrorMessage, setCampusFaxNumberErrorMessage] = useState("");
        const [CampusMissionErrorMessage, setCampusMissionErrorMessage] = useState("");

        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);
        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
            // setIsChecked(!isChecked);
            // console.log('Switch value:', newValue);
          };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {
                // @1) Fetching Users
                axios.get(readAPI.Campus, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        if (res.data.code === "200.200") {
                            setViewAllCampusData(res.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (viewAllCampusData !== null && accessToken !== null) {
                    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    const numberRegex = /^[0-9]{10}/;
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

                    if (CampusFaxNumber === "") {
                        setCampusFaxNumberError(true);
                    }
                    if (!numberRegex.test(CampusFaxNumber) || CampusFaxNumber.length === 0 || CampusFaxNumber.length > 10) {
                        setCampusFaxNumberErrorMessage("Please enter valid fax number")
                        setCampusFaxNumberError(true);
                    }
                    else {
                        setCampusFaxNumberError(false);
                        setCampusFaxNumberErrorMessage("")
                    }
                    if (CampusEmailId === "") {
                        setCampusEmailIdError(true);
                    }
                    if (!emailRegex.test(CampusEmailId) && CampusEmailId.length !== 0) {
                        setCampusEmailIdErrorMessage("Please enter valid email")
                        setCampusEmailIdError(true)
                    }
                    else {
                        setCampusEmailIdErrorMessage("")
                    }

                    if (CampusPhoneNumber === "") {
                        setCampusPhoneNumberError(true)
                    }
                    if (!numberRegex.test(CampusPhoneNumber) || CampusPhoneNumber.length === 0 || CampusPhoneNumber.length > 10) {
                        setCampusPhoneNumberErrorMessage("Please enter valid phone number")
                        setCampusPhoneNumberError(true);
                    }
                    else {
                        setCampusPhoneNumberError(false);
                        setCampusPhoneNumberErrorMessage("")
                    }
                    if (CampusName === "") {
                        setCampusNameErrorMessage("Campus Name is required")
                        setCampusNameError(true);
                    }
                    if (CampusMission === "") {
                        setCampusMissionError(true)
                    }
                    if (!textRegex.test(CampusMission) && CampusMission.length !== 0) {
                        setCampusMissionErrorMessage("Please Enter Only Alphabets")
                        setCampusMissionError(true);
                    } else {
                        setCampusMissionErrorMessage("")
                        setCampusMissionError(false);
                    }
                    if (CampusVision === "") {
                        setCampusVisionError(true)
                    }
                    if (!textRegex.test(CampusVision) && CampusVision.length !== 0) {
                        setCampusVisionErrorMessage("Please Enter Only Alphabets")
                        setCampusVisionError(true);
                    } else {
                        setCampusVisionErrorMessage("")
                    }


                    if (CampusWebAddress === "") {
                        setCampusWebAddressError(true);
                    }
                    if (!urlRegex.test(CampusWebAddress) && CampusWebAddress.length !== 0) {
                        setCampusWebAddressErrorMessage("Invalid URL format")
                        setCampusWebAddressError(true);
                    }
                    else {
                        setCampusWebAddressErrorMessage("")
                    }
                    if (addressLine1 === "") {
                        setAddressLine1Error(true);
                    }
                    if (!textRegex.test(addressLine1) && addressLine1.length !== 0) {
                        setAddressLine1ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine1Error(true);
                    } else {
                        setAddressLine1ErrorMessage("")
                    }
                    if (addressLine1.length > 100) {
                        setAddressLine1ErrorMessage("Character Limit Exceeded Enter Less Than 100 Characters")
                        setAddressLine1Error(true);
                    }
                    if (addressLine2 === "") {
                        setAddressLine2Error(true);
                    }
                    if (addressLine2.length > 100) {
                        setAddressLine2ErrorMessage("Character Limit Exceeded enter less than 100 characters")
                        setAddressLine2Error(true);
                    }
                    if (!textRegex.test(addressLine2) && addressLine2.length !== 0) {
                        setAddressLine2ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine2Error(true);
                    } else {
                        setAddressLine2ErrorMessage("")
                    }
                    if (addressLine3 === "") {
                        setAddressLine3Error(true);
                    }
                    if (addressLine3.length > 100) {
                        setAddressLine3ErrorMessage("Character Limit Exceeded Enter Less Than 100 Characters")
                        setAddressLine3Error(true);
                    }
                    if (!textRegex.test(addressLine3) && addressLine3.length !== 0) {
                        setAddressLine3ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine3Error(true);
                    } else {
                        setAddressLine3ErrorMessage("")
                    }

                    if (zipCode === "") {
                        setZipCodeError(true)
                    }
                    if (province === "") {
                        setProvinceError(true);
                    }
                    if (country === "") {
                        setCountryError(true);
                    }

                    // Set the validation errors

                    if (CampusName !== "" && CampusEmailId !== "" && CampusFaxNumber !== "" && CampusMission !== "" &&
                        CampusVision !== "" && CampusPhoneNumber !== "" && CampusWebAddress !== "" && addressLine1 !== ""
                        && addressLine2 !== "" && addressLine3 !== "" && zipCode !== "" && province !== "" && country !== ""
                    ) {
                        const formState = {
                            "campusId": originalValues.campusId,
                            "campusName": CampusName,
                            "campusEmailId": CampusEmailId,
                            "campusMission": CampusMission,
                            "campusVision": CampusVision,
                            "campusFaxNumber": CampusFaxNumber,
                            "campusPhoneNumber": CampusPhoneNumber,
                            "campusWebAddress": CampusWebAddress,
                            "addressLine1": addressLine1,
                            "addressLine2": addressLine2,
                            "addressLine3": addressLine3,
                            "province": province,
                            "zipCode": zipCode,
                            "country": country,
                            "active": isChecked
                        };

                        

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `${CampusName} data has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    const m = response.data.message;
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                    
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                   

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CampusNameTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.CampusName.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.CampusName.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CampusNameError}
                                    helperText={CampusNameErrorMessage}
                                    value={CampusName}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CampusNameError) {
                                            setCampusNameError(false);
                                        }
                                        setCampusName(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CampusEmailIdTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.CampusEmailId.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.CampusEmailId.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CampusEmailIdError}
                                    helperText={CampusEmailIdErrorMessage}
                                    value={CampusEmailId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CampusEmailIdError) {
                                            setCampusEmailIdError(false);
                                        }
                                        setCampusEmailId(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CampusWebAddressTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.CampusWebAddress.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.CampusWebAddress.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CampusWebAddressError}
                                    helperText={CampusWebAddressErrorMessage}
                                    value={CampusWebAddress}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CampusWebAddressError) {
                                            setCampusWebAddressError(false);
                                        }
                                        setCampusWebAddress(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CampusPhoneNumberTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.CampusPhoneNumber.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.CampusPhoneNumber.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CampusPhoneNumberError}
                                    helperText={CampusPhoneNumberErrorMessage}
                                    value={CampusPhoneNumber}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CampusPhoneNumberError) {
                                            setCampusPhoneNumberError(false);
                                        }
                                        setCampusPhoneNumber(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CampusFaxIdTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.CampusFaxNumber.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.CampusFaxNumber.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CampusFaxNumberError}
                                    helperText={CampusFaxNumberErrorMessage}
                                    value={CampusFaxNumber}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CampusFaxNumberError) {
                                            setCampusFaxNumberError(false);
                                        }
                                        setCampusFaxNumber(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CampusMissionTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.CampusMission.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.CampusMission.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CampusMissionError}
                                    helperText={CampusMissionErrorMessage}
                                    value={CampusMission}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CampusMissionError) {
                                            setCampusMissionError(false);
                                        }
                                        setCampusMission(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CampusVisionTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.CampusVision.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.CampusVision.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CampusVisionError}
                                    helperText={CampusVisionErrorMessage}
                                    value={CampusVision}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CampusVisionError) {
                                            setCampusVisionError(false);
                                        }
                                        setCampusVision(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine1TextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.AddressLine1.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.AddressLine1.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine1Error}
                                    helperText={addressLine1ErrorMessage}
                                    value={addressLine1}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine1Error) {
                                            setAddressLine1Error(false);
                                        }
                                        setAddressLine1(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine2TextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.AddressLine2.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.AddressLine2.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine2Error}
                                    helperText={addressLine1ErrorMessage}
                                    value={addressLine2}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine2Error) {
                                            setAddressLine2Error(false);
                                        }
                                        setAddressLine2(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine3TextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.AddressLine3.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.AddressLine3.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine3Error}
                                    helperText={addressLine1ErrorMessage}
                                    value={addressLine3}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine3Error) {
                                            setAddressLine3Error(false);
                                        }
                                        setAddressLine3(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="zipCodeTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.ZipCode.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.ZipCode.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={zipCodeError}
                                    helperText={zipCodeErrorMessage}
                                    value={zipCode}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (zipCodeError) {
                                            setZipCodeError(false);
                                        }
                                        setZipCode(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="provinceTextField"
                                    label={t('Home.Header.Modals.Campus.policy.Inputs.Province.label')}
                                    placeholder={`${t('Home.Header.Modals.Campus.policy.Inputs.Province.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={provinceError}
                                    // helperText={provinceErrorMessage}
                                    value={province}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (provinceError) {
                                            setProvinceError(false);
                                        }
                                        setProvince(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>

                                <Autocomplete
                                    // {...countryDefaultProps}
                                    options={countryList}
                                    getOptionLabel={getOptionLabel}
                                    id="countryAutoComplete"
                                    autoHighlight
                                    value={countryList.find((Country) => Country.name === country)}
                                    open={countryDropDownOpen}
                                    // onOpen={() => {
                                    //     setcountryDropDownOpen(!countryDropDownOpen);
                                    // }}
                                    onClick={() => {
                                        setCountryDropDownOpen(true);
                                    }}
                                    onClose={() => {
                                        setCountryDropDownOpen(!countryDropDownOpen);
                                    }}
                                    // onOpen={() => {
                                    //     setOpen(true);
                                    // }}
                                    // onClose={() => {
                                    //     setOpen(false);
                                    // }}
                                    onChange={handleChange}
                                    // onChange={(newValue: any) => {
                                    //     setCountry(newValue);
                                    //     if (countryError) {
                                    //         setCountryError(false);
                                    //     }
                                    // }}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label={t('Home.Header.Modals.Campus.policy.Inputs.Country.label')}
                                            variant="standard"
                                            placeholder='Select Country ...'
                                            helperText={(countryError) ? ("* Please select any Country.") : ("")}
                                            error={countryError}
                                            onClick={() => {
                                                setCountryDropDownOpen(true);
                                            }}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                    <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            // value={isChecked}
                                            // onChange={()=> setIsChecked(!isChecked)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCampus;