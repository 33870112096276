import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";
interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateBook = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        //For setting form value in state

        const [websiteUrl, setwebsiteUrl] = useState<string>(originalValues.websiteUrl);
        const [publisher, setpublisher] = useState<string>(originalValues.publisher);
        const [description, setdescription] = useState<string>(originalValues.description);
        const [edition, setedition] = useState<string>(originalValues.edition);
        const [editor, seteditor] = useState<string>(originalValues.editor);
        const [author, setauthor] = useState<string>(originalValues.author);
        const [name, setname] = useState<string>(originalValues.name);
        const [isbn13, setisbn13] = useState<string>(originalValues.isbn13);
        const [yearOfPublication, setyearOfPublication] = useState<string>(originalValues.yearOfPublication);
        const [isbn10, setisbn10] = useState<string>(originalValues.isbn10);


        // For handling errors

        const [websiteUrlError, setwebsiteUrlError] = useState<boolean>(false);
        const [publisherError, setpublisherError] = useState<boolean>(false);
        const [descriptionError, setdescriptionError] = useState<boolean>(false);
        const [editionError, seteditionError] = useState<boolean>(false);
        const [editorError, seteditorError] = useState<boolean>(false);
        const [authorError, setauthorError] = useState<boolean>(false);
        const [nameError, setnameError] = useState<boolean>(false);
        const [isbn13Error, setisbn13Error] = useState<boolean>(false);
        const [yearOfPublicationError, setyearOfPublicationError] = useState<boolean>(false);
        const [isbn10Error, setisbn10Error] = useState<boolean>(false);

        // Status radio buttons
        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    // Set the validation errors
                    const numberRegex = /^\d+$/;
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

                    const validateForm = () => {
                        let hasError = false;
                        if (websiteUrl === "") {
                            setwebsiteUrlError(true);
                            hasError = true;
                        }
                        if (publisher === "") {
                            setpublisherError(true);
                            hasError = true;
                        }
                        if (description === "") {
                            setdescriptionError(true);
                            hasError = true;
                        }
                        if (edition === "") {
                            seteditionError(true);
                            hasError = true;
                        }
                        if (editor === "") {
                            seteditorError(true);
                            hasError = true;
                        }
                        if (author === "") {
                            setauthorError(true);
                            hasError = true;
                        }
                        if (name === "") {
                            setnameError(true);
                            hasError = true;
                        }
                        if (isbn13 === "") {
                            setisbn13Error(true);
                            hasError = true;
                        }
                        if (yearOfPublication === "") {
                            setyearOfPublicationError(true);
                            hasError = true;
                        }
                        if (isbn10 === "") {
                            setisbn10Error(true)
                            hasError = true;
                        }

                        // Return true if no errors, false if any error occurred
                        return !hasError;
                    }
                    // Set the validation errors

                    if (validateForm()) {
                        const formState = [{
                            "id": originalValues.id,
                            "author": author,
                            "description": description,
                            "edition": edition,
                            "editor": editor,
                            "isbn10": isbn10,
                            "isbn13": isbn13,
                            "name": name,
                            "publisher": publisher,
                            "websiteUrl": websiteUrl,
                            "yearOfPublication": yearOfPublication,
                            // "active": (status === "Active") ? true : false
                        }];

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `Role ${author} has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    const m = response.data.message;
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                        }, 3000);
                                    }
                                    
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
               

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                   

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="authorTextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.author.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.author.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={authorError}
                                    // helperText={authorErrorMessage}
                                    value={author}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (authorError) {
                                            setauthorError(false);
                                        }
                                        setauthor(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="descriptionTextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.description.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.description.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={descriptionError}
                                    // helperText={descriptionErrorMessage}
                                    value={description}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (descriptionError) {
                                            setdescriptionError(false);
                                        }
                                        setdescription(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="editionTextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.edition.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.edition.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={editionError}
                                    // helperText={editionErrorMessage}
                                    value={edition}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (editionError) {
                                            seteditionError(false);
                                        }
                                        setedition(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="editorTextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.editor.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.editor.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={editorError}
                                    // helperText={editorErrorMessage}
                                    value={editor}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (editorError) {
                                            seteditorError(false);
                                        }
                                        seteditor(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="isbn10TextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.isbn10.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.isbn10.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={isbn10Error}
                                    // helperText={isbn10ErrorMessage}
                                    value={isbn10}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (isbn10Error) {
                                            setisbn10Error(false);
                                        }
                                        setisbn10(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="isbn13TextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.isbn13.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.isbn13.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={isbn13Error}
                                    // helperText={isbn13ErrorMessage}
                                    value={isbn13}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (isbn13Error) {
                                            setisbn13Error(false);
                                        }
                                        setisbn13(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="nameTextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.name.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.name.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={nameError}
                                    // helperText={nameErrorMessage}
                                    value={name}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (nameError) {
                                            setnameError(false);
                                        }
                                        setname(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="publisherTextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.publisher.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.publisher.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={publisherError}
                                    // helperText={publisherErrorMessage}
                                    value={publisher}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (publisherError) {
                                            setpublisherError(false);
                                        }
                                        setpublisher(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="websiteUrlTextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.websiteUrl.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.websiteUrl.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={websiteUrlError}
                                    // helperText={websiteUrlErrorMessage}
                                    value={websiteUrl}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (websiteUrlError) {
                                            setwebsiteUrlError(false);
                                        }
                                        setwebsiteUrl(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="yearOfPublicationTextField"
                                    label={t('Home.Header.Modals.Books.policy.Inputs.yearOfPublication.label')}
                                    placeholder={`${t('Home.Header.Modals.Books.policy.Inputs.yearOfPublication.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={yearOfPublicationError}
                                    // helperText={yearOfPublicationErrorMessage}
                                    value={yearOfPublication}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (yearOfPublicationError) {
                                            setyearOfPublicationError(false);
                                        }
                                        setyearOfPublication(e.target.value);
                                    }}
                                />
                            </Grid>



                        </Grid>
                    </Box>
                </Box>

                {/* <Box
                sx={{
                    display: "flex",
                    flexDirection: (currentLang === "ar") ? ('row-reverse') : ('row')
                }}
                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
            >
                <Button
                    dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                    variant="contained"
                    sx={{
                        backgroundColor: "#d5b254",
                        // textTransform: "none",
                        fontWeight: "bold",
                        height: 40,
                        mt:
                        // Give margins based on screen size
                        {
                            xs: 3, // theme.breakpoints.up('xs')
                            sm: 2, // theme.breakpoints.up('sm')
                            md: 2, // theme.breakpoints.up('md')
                            lg: 3, // theme.breakpoints.up('lg')
                            xl: 3, // theme.breakpoints.up('xl')
                        },
                        display: "flex",
                        // justifyContent: (currentLang === "ar") ? ('flex-start') : ('center'),
                        // alignItems:(currentLang === "ar") ? ('flex-end') : ('center'),
                        "&:hover": {
                            backgroundColor: "#d5b254",
                        },
                        // border:"1px solid red"
                    }}
                    // Give full width if screen size if less than 600px otherwise give auto width
                    fullWidth={(
                        windowSize[0] < 600
                    ) ? true : false}
                    // onClick={() => {
                    //     navigate("/");
                    // }}
                    startIcon={
                        (currentLang === "ar") ? (
                            null
                        ) : (
                            <SendIcon />
                        )
                    }
                    endIcon={
                        (currentLang === "ar") ? (
                            <SendIcon />
                        ) : (
                            null
                        )
                    }
                    onClick={submitForm}
                >
                    <Typography
                        style={{ display: "block" }}
                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                    >
                        {t('Home.Sidebar.list.userManagement.subMenu.Users.details.submit')}
                    </Typography>
                </Button>
            </Box> */}

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateBook;