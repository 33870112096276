import * as React from 'react';
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import axios from 'axios';
import styles from "./style.module.css";
import readAPI from '../../../data/api/read';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import initiative from "../../../../../assets/Images/strategicPlan/initiative.jpeg"
import { updateInitiativeByIndex, updateKpiByIndex } from '../../../../../redux/action/spsActions';

const degreeOptions = [
    { label: 'Diploma', value: 'D' },
    { label: 'Bachelor', value: 'B' },
    { label: 'Master', value: 'M' },
    { label: 'PHD', value: 'P' }
];

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    currentRowIndex: any,
    setForUpdate: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateKpi = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        currentRowIndex,
        setForUpdate
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });


        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        const [kpiName, setKpiName] = useState<string>(originalValues.kpiName);
        const [description, setKpiDescription] = useState<string>(originalValues.description);
        const [degree, setDegree] = useState<string>(originalValues.degree);


        // error state
        const [kpiNameError, setKpiNameError] = useState<boolean>(false);
        const [kpiDescriptionError, setKpiDescriptionError] = useState<boolean>(false);
        const [degreeError, setDegreeError] = useState<boolean>(false);

        // error message state

        const [kpiNameErrorMessage, setKpiNameErrorMessage] = useState("");
        const [kpiDescriptionErrorMessage, setKpiDescriptionErrorMessage] = useState("");
        const [degreeErrorMessage, setDegreeErrorMessage] = useState("");

        let instructorName = useSelector((state: RootState) => state.userListData.data);
        const instructorNameOptions = instructorName?.map((item: any) => ({
            label: `${item.firstName} ${item.lastName} ${item.emailId}`,
            value: item.userId,
            userName: item.userName
        }));

        const handleInitiativeMangerChange = (event: any, newValue: any) => {
            setKpiDescription(newValue ? newValue.value : null);
        }

        // const handleInitiativeMangerChange = (event: any, newValue: any) => {
        //     setKpiDescription(newValue ? newValue.value : null);
        //     if (newValue) {
        //         setKpiDescriptionError(false);
        //         setKpiDescriptionErrorMessage("");
        //     }
        // }
        const handleExecutiveManagerChange = (event: any, newValue: any) => {
            setDegree(newValue ? newValue.value : null);
            if (newValue) {
                setDegreeError(false);
                setDegreeErrorMessage("");
            }
        }


        



        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    const isValidateForm = () => {
                        let hasErrors = false;

                        if (kpiName === "") {
                            hasErrors = true;
                            setKpiNameError(true);
                            setKpiNameErrorMessage("Please Enter Name of Initiative");
                        }
                        if (description === "") {
                            hasErrors = true;
                            setKpiDescriptionError(true);
                            setKpiDescriptionErrorMessage("Please Enter Description");
                        }
                        if (degree === "") {
                            hasErrors = true;
                            setDegreeError(true);
                            setDegreeErrorMessage("Please Enter Initiative Executive Manager")
                        }

                        return !hasErrors;
                    };


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    } else {
                        const formState = {
                            kpiName,
                            message: "message",
                            kpiDescription: description,
                            degree,
                            strategicPlanId: originalValues.strategicPlanId,
                            active: true
                        };
                        axios.put(`${url}/${originalValues.kpiId}`,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken,
                                    "accept-language": "en"
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    dispatch(updateKpiByIndex(currentRowIndex, formState))
                                    setFetchUpdate(true);
                                    setForUpdate(true);
                                    setOpenUpdateTableModal(false);
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>
                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6} style={{ padding: "26px" }}>
                                <Grid container>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            dir="ltr"
                                            value={kpiName}
                                            placeholder={`${t('Home.Header.Modals.SPS.KPI.policy.Inputs.kpiName.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.KPI.policy.Inputs.kpiName.label')}`}
                                            error={kpiNameError}
                                            helperText={kpiNameErrorMessage}
                                            onChange={(e) => {
                                                if (kpiNameError) {
                                                    setKpiNameError(false);
                                                    setKpiNameErrorMessage("");
                                                }
                                                setKpiName(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            dir="ltr"
                                            value={description}
                                            placeholder={`${t('Home.Header.Modals.SPS.KPI.policy.Inputs.kpiDescription.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.KPI.policy.Inputs.kpiDescription.label')}`}
                                            error={kpiDescriptionError}
                                            helperText={kpiDescriptionErrorMessage}
                                            onChange={(e) => {
                                                if (kpiDescriptionError) {
                                                    setKpiDescriptionError(false);
                                                    setKpiDescriptionErrorMessage("");
                                                }
                                                setKpiDescription(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <Autocomplete
                                            options={degreeOptions}
                                            getOptionLabel={(option: any) => option.label}
                                            value={degreeOptions.find((option: any) => option.value === degree) || null}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    setDegree(newValue.value);
                                                    setDegreeError(false);
                                                    setDegreeErrorMessage("");
                                                } else {
                                                    setDegree("");
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    style={{ backgroundColor: "#fff" }}
                                                    margin="normal"
                                                    fullWidth
                                                    dir="ltr"
                                                    // placeholder={t('Home.Header.Modals.SPS.KPI.policy.Inputs.degree.placeholder')}
                                                    label={t('Home.Header.Modals.SPS.KPI.policy.Inputs.degree.label')}
                                                    error={degreeError}
                                                    helperText={degreeErrorMessage}
                                                />
                                            )}
                                        />
                                    </Grid>



                                </Grid>

                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6} style={{ padding: "0px", margin: "0px", paddingTop: "0px" }}>
                                <img src={initiative} height="100%" width="100%" />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default React.memo(UpdateKpi);