import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    FormControl,
    FormControlLabel,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import courseJson from "../../../../../../courseCode.json"

import readAPI from '../../../../data/api/read';
import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";


interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateCourseTopics = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });



        // const [viewAllCampusData, setViewAllCampusData] = useState<ViewAllCampusData | null>(null);

        const [courseCodeList, setCourseCodeList] = useState<any>([]);

        const [selectedWeek, setSelectedWeek] = useState(null);

        const [topic, settopic] = useState<string>(originalValues.topic);
        const [closCovered, setClosCovered] = useState<string>(originalValues.closCovered);
        const [courseCode, setCourseCode] = useState<string>(originalValues.courseCode);
        const [weeksDelivered, setWeeksDelivered] = useState<string>(originalValues.weeksDelivered);

        const [isChecked, setIsChecked] = useState(true);

        // For handling errors

        const [topicError, settopicError] = useState<boolean>(false);
        const [closCoveredError, setClosCoveredError] = useState<boolean>(false);
        const [courseCodeError, setCourseCodeError] = useState<boolean>(false);
        const [weeksDeliveredError, setWeeksDeliveredError] = useState<boolean>(false);


        // For handling error messages
        // const [courseCodeErrorMessage, setCourseCodeErrorMessage] = useState("");


        const courseCodeListOptions = courseCodeList.map((courseCode: any) => ({
            label: `${courseCode.courseCode}: ${courseCode.courseName}`,
            value: courseCode.courseCode,
        }));
        const handleCourseCodeChange = (event: any, newValue: any) => {
            setCourseCode(newValue ? newValue.value : null);
        };

        const handleWeekChange = (event: any, newValue: any) => {
            setWeeksDelivered(newValue ? newValue.id : null);
        };

        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }
            if (accessToken !== null) {
                // @1) Fetching courses
                axios.get(readAPI.Courses, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setCourseCodeList(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    // Set the validation errors

                    if (courseCode !== "" && closCovered !== "") {
                        const formState = [{
                            "id": originalValues.id,
                            "courseCode": courseCode,
                            "weeksDelivered": weeksDelivered,
                            "topic": topic,
                            "closCovered": closCovered,
                            "active": isChecked
                        }]

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `${weeksDelivered} data has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="courseCodeComplete"
                                    options={courseCodeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={courseCodeListOptions.find((option: any) => option.value === courseCode) || null}
                                    onChange={handleCourseCodeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.Topics.policy.Inputs.courseCode.label')}`}
                                            placeholder={`${t('Home.Header.Modals.Topics.policy.Inputs.courseCode.placeholder')}`}
                                            variant="standard"
                                            // helperText={(coursePrerequisiteErrorMessage) ? ("* Please select any College.") : ("")}
                                            error={courseCodeError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    value={courseJson.week.find(course => course.id == weeksDelivered)}
                                    onChange={handleWeekChange}
                                    options={courseJson.week}
                                    getOptionLabel={(option: any) => option.week}
                                    dir={currentLang === "ar" ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={`${t('Home.Header.Modals.Topics.policy.Inputs.weeksDelivered.label')}`}
                                            placeholder={`${t('Home.Header.Modals.Topics.policy.Inputs.weeksDelivered.placeholder')}`}
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}

                                />


                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="topicTextField"
                                    label={t('Home.Header.Modals.Topics.policy.Inputs.topic.label')}
                                    placeholder={`${t('Home.Header.Modals.Topics.policy.Inputs.topic.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={topicError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={topic}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (topicError) {
                                            settopicError(false);
                                        }
                                        settopic(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="closCoveredTextField"
                                    label={t('Home.Header.Modals.Topics.policy.Inputs.closCovered.label')}
                                    placeholder={`${t('Home.Header.Modals.Topics.policy.Inputs.closCovered.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={closCoveredError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={closCovered}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (closCoveredError) {
                                            setClosCoveredError(false);
                                        }
                                        setClosCovered(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>

                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />

                                {/* <Switch
                                    checked={isChecked}
                                    onChange={handleSwitchChange}
                                    inputProps={{ 'aria-label': 'Toggle' }}
                                /> */}
                            </Grid>










                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCourseTopics;