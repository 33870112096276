import { BASE_URL } from "../../../constants";
const deleteAPI = {
    Users:
      `${BASE_URL}/IDENTITY-SERVICE/privileges/deleteUser/`,
    Apps: `${BASE_URL}/IDENTITY-SERVICE/privileges/deleteAppDetails/`,
    Groups:
      `${BASE_URL}/IDENTITY-SERVICE/privileges/deleteGroup/`,
    Roles:
      `${BASE_URL}/IDENTITY-SERVICE/privileges/deleteRole/`,
    AppForm:
      `${BASE_URL}/IDENTITY-SERVICE/privileges/deleteAppForm/`,
    RoleApp:
      `${BASE_URL}/IDENTITY-SERVICE/privileges/deletePrivilege/`,
    GroupRole:
      `${BASE_URL}/IDENTITY-SERVICE/privileges/deleteGroupRole/`,
    UserGroup:
      `${BASE_URL}/IDENTITY-SERVICE/privileges/deleteUserGroup/`,
      DeleteCollege:
      `${BASE_URL}/EQACORE-SERVICE/deleteCollege/`,
  };
  
  export default deleteAPI;