import axios from 'axios'
import {IUsersType, IGroupsType, IUserGroupType, IRoleType, IGroupRoleType, IApplicationType, IAppForm, IRoleAppPrevilege } from "../model/Account"
import readAPI from '../../../../../Data/API/READ';
import createAPI from '../../../../../Data/API/CREATE';
import updateAPI from '../../../../../Data/API/UPDATE';

export type UsersType = {
    data: IUsersType[],
    errMessage: ""
}

export type ResponseType = {
    success: boolean;
    errMessage: string;
}

export type Groupstype = {
    data: IGroupsType[],
    errMessage: ""
}

export type UserGroupType = {
    data: IUserGroupType[],
    errMessage: ""
}

export type RoleType = {
    data: IRoleType[],
    errMessage: ""
}

export type GroupRoleType = {
    data: IGroupRoleType[],
    errMessage: ""
}

export type AppsRoleType = {
    data: IApplicationType[],
    errMessage: ""
}

export type AppFormType = {
    data: IAppForm[],
    errMessage: ""
}

export type RoleAppPrivilegeType = {
    data: IRoleAppPrevilege[],
    errMessage: ""
}


//@ calling get users api ============================================================

async function getAllUsers(accessToken: string): Promise<UsersType> {
    let url: string = readAPI.Users;
    let header: string = accessToken;
    const userType: UsersType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            userType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                userType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            userType.errMessage = err.message;
        })

    return userType;
}


//@ calling add user api ============================================================

async function createUser(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Users;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.post(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })
    return userResponse;
}


//@ calling update user api ===============================================================

async function updateUser(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Users;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling get group api ===============================================================

async function getAllGroups(accessToken: string): Promise<Groupstype> {
    let url: string = readAPI.Groups;
    let header: string = accessToken;
    const groupTypeData: any = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            groupTypeData.data = res.data;
            if (res.data.code !== "200.200") {
                groupTypeData.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            groupTypeData.errMessage = err.message;
        })

    return groupTypeData;
}

//@ calling add group api =====================================================================

async function createGroup(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Groups;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.post(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })
    return userResponse;
}

//@ calling update group api ===============================================================

async function updateGroup(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Groups;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling get user group api ============================================================

async function getAllUserGroup(accessToken: string): Promise<UserGroupType> {
    let url: string = readAPI.UserGroup;
    let header: string = accessToken;
    const userGroupType: UserGroupType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            userGroupType.data = res.data.obj;
            console.log("res data", res.data.obj);
            if (res.data.code !== "200.200") {
                userGroupType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            userGroupType.errMessage = err.message;
        })

    return userGroupType;
}


//@ calling update user group api ===============================================================

async function updateUserGroup(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.UserGroup;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling create user group api ===============================================================

async function createUserGroup(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.UserGroup;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling get role api ===============================================================

async function getAllRole(accessToken: string): Promise<RoleType> {
    let url: string = readAPI.Roles;
    let header: string = accessToken;
    const roleTypeData: RoleType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            roleTypeData.data = res.data;
            if (res.data.code !== "200.200") {
                roleTypeData.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            roleTypeData.errMessage = err.message;
        })

    return roleTypeData;
}

//@ calling create role api ===============================================================

async function createRole(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Roles;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.post(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling update role api ===============================================================

async function updateRole(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Roles;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling get group role api ============================================================

async function getAllGroupRole(accessToken: string): Promise<GroupRoleType> {
    let url: string = readAPI.GroupRole;
    let header: string = accessToken;
    const groupRoleType: GroupRoleType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            groupRoleType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                groupRoleType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            groupRoleType.errMessage = err.message;
        })

    return groupRoleType;
}

//@ calling create role api ===============================================================

async function createGroupRole(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Roles;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.post(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling update role api ===============================================================

async function updateGroupRole(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Roles;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling get application api ===============================================================

async function getAllApplication(accessToken: string): Promise<AppsRoleType> {
    let url: string = readAPI.Apps;
    let header: string = accessToken;
    const applicationTypeData: AppsRoleType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            applicationTypeData.data = res.data;
            if (res.data.code !== "200.200") {
                applicationTypeData.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            applicationTypeData.errMessage = err.message;
        })

    return applicationTypeData;
}


//@ calling create role api ===============================================================

async function createApps(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Apps;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.post(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling update role api ===============================================================

async function updateApps(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Apps;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling get app form api ============================================================

async function getAllAppForm(accessToken: string): Promise<AppFormType> {
    let url: string = readAPI.AppForm;
    let header: string = accessToken;
    const appFormType: AppFormType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            appFormType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                appFormType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            appFormType.errMessage = err.message;
        })

    return appFormType;
}

//@ calling create app form api ===============================================================

async function createAppForm(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.AppForm;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.post(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

//@ calling update role api ===============================================================

async function updateAppForm(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.AppForm;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}


//@ calling get role app privilege api ============================================================

async function getAllRoleAppPrivilege(accessToken: string): Promise<RoleAppPrivilegeType> {
    let url: string = readAPI.RoleApp;
    let header: string = accessToken;
    const roleAppDataType: RoleAppPrivilegeType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            roleAppDataType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                roleAppDataType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            roleAppDataType.errMessage = err.message;
        })

    return roleAppDataType;
}


//@ calling add user api ============================================================

async function createRoleApp(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.RoleApp;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.post(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })
    return userResponse;
}


//@ calling update user api ===============================================================

async function updateRoleApp(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.RoleApp;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: ""
    }
    await axios.put(url, userData, {
        headers: {
            'x-api-key': header
        }
    })
    .then((res) => {
        if(res.status === 200 && res.data.code === "200.200"){
            userResponse.success = true;
        }
        else {
            userResponse.errMessage = res.data.message;
        }
    })
    .catch((err) => {
        userResponse.errMessage = err.message;
    })

    return userResponse;
}

export const AccountService = {
    getAllUsers,
    createUser,
    updateUser,
    getAllGroups,
    createGroup,
    updateGroup,
    getAllUserGroup,
    createUserGroup,
    updateUserGroup,
    getAllRole,
    createRole,
    updateRole,
    getAllGroupRole,
    createGroupRole,
    updateGroupRole,
    getAllApplication,
    createApps,
    updateApps,
    getAllAppForm,
    createAppForm,
    updateAppForm,
    getAllRoleAppPrivilege,
    createRoleApp,
    updateRoleApp
}