import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Autocomplete,
    FormControlLabel,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import styles from "./style.module.css";
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { RootState } from '../../../../../../redux/store';
import academicYearJson from "../../../../data/json/academicYear.json";
import orderJson from "../../../../data/json/order.json"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
        marginTop: theme.spacing(5),
    },
}));



interface UpdateAnnualProgramReportProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
    setFetchUpdate: any,
    studyPlanOfferedProgram?: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}
const UpdateAnnualProgramReport = React.forwardRef<UpdateRef, UpdateAnnualProgramReportProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        studyPlanOfferedProgram
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);


        const [sectionName, setSectionName] = useState<string>(originalValues.sectionName);
        const [description, setDescription] = useState<string>(originalValues.description);
        const [orderValue, setOrderValue] = useState<any>(originalValues.orderValue);
        const [academicYear, setAcademicYear] = useState<string>(originalValues.academicYear);
        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);

        const [sectionNameError, setSectionNameError] = useState<boolean>(false);
        const [descriptionError, setDescriptionError] = useState<boolean>(false);
        const [orderValueError, setOrderValueError] = useState<boolean>(false);
        const [academicYearError, setAcademicYearError] = useState<boolean>(false);

        const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        const handleOrderChange = (event: any, newValue: any) => {
            setOrderValue(newValue ? newValue.id : null);
        };
        const handleAcademicYearChange = (event: any, newValue: any) => {
            setAcademicYear(newValue ? newValue.id : null);
        };

        // useEffect(() => {
        //     if (accessToken !== null) {
        //         const fetchCommittee = async () => {
        //             const committeeData = await GlobalService.getCommittee(accessToken);
        //             setCommitteeList(committeeData);
        //         }

        //         //calling function
        //         fetchCommittee();
        //     }
        // }, []);

        const submitForm = () => {
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken !== null) {
                    setIsLoadingSpinner(true);
                    let hasError = false;
                    const isValidateForm = () => {
                        if (sectionName === "") {
                            setSectionNameError(true);
                            hasError = true;
                        }
                        else {
                            setSectionNameError(false);
                        }
                        if (description === "") {
                            setDescriptionError(true);
                            hasError = true;
                        }
                        else {
                            setDescriptionError(false);
                        }
                        if (orderValue === "") {
                            setOrderValueError(true);
                            hasError = true;
                        }
                        else {
                            setOrderValueError(false);
                        }
                        if (academicYear === "") {
                            setAcademicYearError(true);
                            hasError = true;
                        }
                        else {
                            setAcademicYearError(false);
                        }
                        return !hasError;
                    }


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    }
                    else {

                        const formState: any = {
                            sectionName: sectionName,
                            description: description,
                            orderValue: orderValue,
                            academicYear: academicYear,
                            active: true
                        };

                        axios.put(`${url}/${originalValues.id}`,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken,
                                    'Accept-Language': 'ar'
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.status === 200) ? "success" : "error",
                                        message: (response.status === 200) ? `Section has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    if (response.status === 200) {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log('error=>>>', error);
                            });
                    }


                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label={`${t('Home.Header.Modals.systemPreferences.AnnualProgramReport.policy.Inputs.sections.label')}`}
                                    placeholder={`${t('Home.Header.Modals.systemPreferences.AnnualProgramReport.policy.Inputs.sections.placeholder')}`}
                                    variant="standard"
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={sectionName}
                                    onChange={(e) => setSectionName(e.target.value)}
                                    error={sectionNameError}
                                    helperText={(sectionNameError) ? ("* Please Enter Section Name.") : ("")}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label={`${t('Home.Header.Modals.systemPreferences.AnnualProgramReport.policy.Inputs.description.label')}`}
                                    placeholder={`${t('Home.Header.Modals.systemPreferences.AnnualProgramReport.policy.Inputs.description.placeholder')}`}
                                    variant="standard"
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    error={descriptionError}
                                    helperText={(descriptionError) ? ("* Please Enter Description.") : ("")}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    options={orderJson.order}
                                    getOptionLabel={(option) => option.value}
                                    value={orderJson.order.find(item => item.id == orderValue) || null}
                                    onChange={handleOrderChange}
                                    dir={currentLang === "ar" ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={t('Home.Header.Modals.systemPreferences.AnnualProgramReport.policy.Inputs.order.label')}
                                            placeholder={`${t('Home.Header.Modals.systemPreferences.AnnualProgramReport.policy.Inputs.order.placeholder')}`}
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={orderValueError}
                                            helperText={orderValueError ? "* Please Select Order Value." : ""}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    options={academicYearJson.academicYear}
                                    getOptionLabel={(option: any) => option.value}
                                    value={academicYearJson.academicYear.find(item => item.id == academicYear) || null}
                                    onChange={handleAcademicYearChange}
                                    dir={currentLang === "ar" ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={`${t('Home.Header.Modals.systemPreferences.AnnualProgramReport.policy.Inputs.academicYear.label')}`}
                                            placeholder={`${t('Home.Header.Modals.systemPreferences.AnnualProgramReport.policy.Inputs.academicYear.placeholder')}`}
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={academicYearError}
                                            helperText={(academicYearError) ? ("* Please Select Academic Year.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>




                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 2 }}>
                                <FormControlLabel
                                    label="Active"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }
                                />
                            </Grid>










                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateAnnualProgramReport;