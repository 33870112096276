import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";
import readAPI from '../../../data/api/read';
import { columnValues } from '../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../redux/store";

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateSessionType = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // Fetching data using axios


        const [name, setname] = useState<string>(originalValues.name);
        const [description, setdescription] = useState<string>(originalValues.description);

        // For handling errors

        const [nameError, setnameError] = useState<boolean>(false);
        const [descriptionError, setdescriptionError] = useState<boolean>(false);

        const [isChecked, setIsChecked] = useState(true);
        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
          };

          let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                // let accessToken: any = Cookies.get("accessToken");

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    if (name === "") {
                        setnameError(true)
                    }
                    if (description === "") {
                        setnameError(true)
                    }

                    // Set the validation errors

                    if (name !== "" && description !== "") {
                        const formState = {
                            "id": originalValues.id,
                            "name": name,
                            "description": description,
                            "active": isChecked
                        }

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `Session Type: ${name} has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error: any) {
                                console.log(error)
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>

                {/* <hr /> */}

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="nameTextField"
                                    label={t('Home.Header.Modals.SessionType.policy.Inputs.name.label')}
                                    placeholder={`${t('Home.Header.Modals.SessionType.policy.Inputs.name.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={nameError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={name}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (nameError) {
                                            setnameError(false);
                                        }
                                        setname(e.target.value);
                                    }}
                                />
                            </Grid>
                            
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="descriptionTextField"
                                    label={t('Home.Header.Modals.SessionType.policy.Inputs.description.label')}
                                    placeholder={`${t('Home.Header.Modals.SessionType.policy.Inputs.description.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={descriptionError}
                                    // helperText={courseCodeErrorMessage}
                                    value={description}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (descriptionError) {
                                            setdescriptionError(false);
                                        }
                                        setdescription(e.target.value);
                                    }}
                                />
                            </Grid>

                            
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                    <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                                {/* <Switch
                                    checked={isChecked}
                                    onChange={handleSwitchChange}
                                    inputProps={{ 'aria-label': 'Toggle' }}
                                /> */}
                            </Grid>
                            
                        </Grid>
                    </Box>
                </Box>

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateSessionType;