import { STUDYPLAN_URL } from "../../../../../constants";
import { BASE_URL } from "../../../../../constants";
const readAPI = {
    getStudyPlan: `${STUDYPLAN_URL}/study-plan/all?pageNo=0&pageSize=10`,
    getCourseStudyPlan: `${STUDYPLAN_URL}/study-course-detail?pageNo=0&pageSize=500`,
    campus: `${BASE_URL}/EQACORE-SERVICE/campus`,
    department: `${BASE_URL}/EQACORE-SERVICE/department`,
    college: `${BASE_URL}/EQACORE-SERVICE/colleges`,
    getStudyPlanByCourseCode : `${STUDYPLAN_URL}/study-course-detail/search?courseCode=`,
    getStudyPlanByPlanId: `${STUDYPLAN_URL}/study-course-detail/plan`,
    drawnStudyPlan: `${STUDYPLAN_URL}/study-plan/draw?designId`,
    getStudyPlanAll: `${STUDYPLAN_URL}/study-plan/draw/all`,
    getDropdown: `${STUDYPLAN_URL}/study-plan/all`,
    existStudyPlan: `${STUDYPLAN_URL}/study-plan/draw/exist?studyPlanId`,
    deleteStudyPlan : `${STUDYPLAN_URL}/study-plan/draw/remove?designId`,


    // https://69.48.179.170:8766/studyplanservice/api/v1.0/study-plan/draw/all


    

    // draw/exist?studyPlanId=${id}
  };
  
  export default readAPI;