import { BASE_URL } from "../../../../../constants";
const readAPI = {
    Offeredcourse : `${BASE_URL}/EQACORE-SERVICE/schedule/activeOfferedCourse`,
    OfferedCourseByCriteria: `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourse`,
    ScheduleOfferedcourses : `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourses`,
    Courses: 
    `${BASE_URL}/EQACORE-SERVICE/courses`,
    College: 
    `${BASE_URL}/EQACORE-SERVICE/colleges`,
    Campus: 
    `${BASE_URL}/EQACORE-SERVICE/campus`,
    Department: 
    `${BASE_URL}/EQACORE-SERVICE/department`,
    FetchUsers: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUsers`,
    Program: `${BASE_URL}/EQACORE-SERVICE/programs`,
    SessionType: `${BASE_URL}/EQACORE-SERVICE/schedule/sessionTypes`,
    AcademicYear : `${BASE_URL}/EQACORE-SERVICE/schedule/academicYear`,
    CourseCoordinator: `${BASE_URL}/EQACORE-SERVICE/core/coordinator`
    
  };
  
  export default readAPI;
  