import { BASE_URL } from "../../../constants";
const updateAPI = {
  Users:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/updateUser`,
  Apps: `${BASE_URL}/IDENTITY-SERVICE/privileges/updateAppDetails`,
  Groups:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/updateGroup`,
  Roles:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/updateRole`,
  AppForm:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/updateAppForm`,
  RoleApp:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/updatePrivilege`,
  GroupRole:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/updateGroupRole`,
  UserGroup:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/updateUserGroup`,
  Password:
    `${BASE_URL}/IDENTITY-SERVICE/login/updatePassword`,

  UpdateCollege: `${BASE_URL}/EQACORE-SERVICE/updateCollege`
};

export default updateAPI;