import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css"

const MyDrive = lazy(() => import("../Components/MyDrive"));
const HomePage = lazy(() => import("../Components/Home"));
const SharedWithMe = lazy(() => import("../Components/SharedWithMe"));
const Recent = lazy(() => import("../Components/Recent"));
const Starred = lazy(() => import("../Components/Starred"));
const Bin = lazy(() => import("../Components/Bin"));
const Spam = lazy(() => import("../Components/Spam"));
const Computers = lazy(() => import("../Components/Computers"));
const Storage = lazy(() => import("../Components/Storage"));
const Folders = lazy(() => import("../Components/Folders"));
const ExcelSheet = lazy(() => import("../Components/Modal/PreviewModal/ExcelSheet"));


interface EqaDriveRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
}

const EqaDriveRouter: FC<EqaDriveRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,

    // courseCodeProps

    // openUniversityModal,
    // setOpenUniversityModal,

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
            <Routes>
                <Route
                    path="view"
                    element={
                        <HomePage
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />

                <Route path="home">
                    <Route
                        path="view"
                        element={
                            <HomePage
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="myDrive">
                    <Route
                        path="view"
                        element={
                            <MyDrive
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="computers">
                    <Route
                        path="view"
                        element={
                            <Computers
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="sharedWithMe">
                    <Route
                        path="view"
                        element={
                            <SharedWithMe
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="recent">
                    <Route
                        path="view"
                        element={
                            <Recent
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="starred">
                    <Route
                        path="view"
                        element={
                            <Starred
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="spam">
                    <Route
                        path="view"
                        element={
                            <Spam
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="bin">
                    <Route
                        path="view"
                        element={
                            <Bin
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="storage">
                    <Route
                        path="view"
                        element={
                            <Storage
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="folders">
                    <Route
                        path="view/:id"
                        element={
                            <Folders
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path={"/excelSheet"} element={<ExcelSheet
                    isMinified={isMinified}
                    setIsMinified={setIsMinified}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}
                />}
                />











            </Routes>
        </Suspense>
    )

}

export default EqaDriveRouter;
