interface AccountState {
    manageUser: any | null;
    manageGroup: any | null;
    manageUserGroup: any | null;
    manageRole: any | null;
    manageGroupRole: any | null;
    manageApplication: any | null;
    manageAppForm: any | null;
    manageRoleApp: any | null;
}

const initialState: AccountState = {
    manageUser: null,
    manageGroup: null,
    manageUserGroup: null,
    manageRole: null,
    manageGroupRole: null,
    manageApplication: null,
    manageAppForm: null,
    manageRoleApp: null
};

const accountReducer = (state = initialState, action: any): AccountState => {
    switch (action.type) {
        case 'MANAGE_USER':
            return {
                ...state,
                manageUser: action.payload.data,
            };
        case 'MANAGE_GROUP':
            return {
                ...state,
                manageGroup: action.payload.data,
            };
        case 'MANAGE_USER_GROUP':
            return {
                ...state,
                manageUserGroup: action.payload.data,
            };
        case 'APPEND_USER_GROUP':
            return {
                ...state,
                manageUserGroup: state.manageUserGroup
                    ? [...state.manageUserGroup, action.payload.data] // Append new data
                    : [action.payload.data] // Initialize as array if null
            };
        case 'MANAGE_ROLE':
            return {
                ...state,
                manageRole: action.payload.data,
            };
        case 'MANAGE_GROUP_ROLE':
            return {
                ...state,
                manageGroupRole: action.payload.data,
            };
        case 'MANAGE_APPLICATION':
            return {
                ...state,
                manageApplication: action.payload.data,
            };
        case 'MANAGE_APP_FORM':
            return {
                ...state,
                manageAppForm: action.payload.data,
            };
        case 'MANAGE_ROLE_APP_PRIVILAGE':
            return {
                ...state,
                manageRoleApp: action.payload.data,
            };
        default:
            return state;
    }
};

export default accountReducer;