import React from 'react';
import Directory from './Directory';

// MyDrive component to render the entire directory structure
const MyDrive = ({ directories }: any) => {
  return (
    <div>
      {directories.map((dir: any) => (
        <Directory key={dir.dirId} directory={dir} />
      ))}
    </div>
  );
};

export default MyDrive;
