import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css";


const ManageMoM = lazy(() => import("../ManageMoM"));
const Inbox = lazy(() => import("../Components/Inbox"));

interface MoMRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    // courseCodeProps: any,
    // openUniversityModal: boolean,
    // setOpenUniversityModal: any,
}

const MoMRouter: FC<MoMRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,

    // courseCodeProps

    // openUniversityModal,
    // setOpenUniversityModal,

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
        <Routes>
            {/* <Route path="portal"> */}
            <Route
                path="view"
                element={
                    <Inbox
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        // For minified sidebar
                        isMinified={isMinified}
                        setIsMinified={setIsMinified}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}

                    />
                }
            />
            {/* </Route> */}

            <Route path="inbox">
                <Route
                    path="view"
                    element={
                            <Inbox
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                />
            </Route>

            <Route path="view">
                <Route
                    path="meeting"
                    element={
                            <ManageMoM
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                />
            </Route>



        </Routes>
        </Suspense>
    )

}

export default MoMRouter;
