import * as React from 'react';
import styles from "./style.module.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import {
    Typography,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { BsEye } from 'react-icons/bs';

interface ViewKpisModalProps {
    openViewKpisModal: any,
    currentLang: string,
    setOpenViewKpisModal: any,
    kpisData: any
}

const ViewKpisModal: React.FC<ViewKpisModalProps> = ({
    openViewKpisModal,
    currentLang,
    setOpenViewKpisModal,
    kpisData
}) => {



    const handleCloseViewKpisModal = () => {
        setOpenViewKpisModal(false)
    };

    const degreeOptions = [
        { id: '1', label: 'Diploma', value: 'D' },
        { id: '2', label: 'Bachelor', value: 'B' },
        { id: '3', label: 'Master', value: 'M' },
        { id: '4', label: 'PHD', value: 'P' }
    ];
    const degreeMap: any = {};
        degreeOptions.forEach((item: any) => {
            degreeMap[item.value] = item.label;
        });

    const user = useSelector((state: RootState) => state.userListData.data);
    
    const userMap: any = {};
    user?.forEach((userItem: any) => {
        userMap[userItem.userId] = userItem.firstName + " " + userItem.lastName;
    });


    return (
        <>
            <Dialog style={{ whiteSpace: 'normal', wordBreak: 'break-word' }} open={openViewKpisModal} onClose={handleCloseViewKpisModal} maxWidth="md" fullWidth>
                <Box className={styles.modalStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <DialogTitle>
                        <Box sx={{
                                    display: "flex",
                                    // alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                                    flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                                }}>
                                    <Box sx={{
                                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                                        width: 60,
                                        height: 60,
                                        borderRadius: 1.25,
                                        backgroundColor: "#fffefe",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <BsEye style={{ height: "35px", width: "35px", color: "#4f747a" }} />
                                    </Box>
                                    <Box sx={{ ml: 3, mt: 2 }}>
                                        <Typography variant="h5" sx={{
                                            color: "#3c6766",
                                            fontWeight: 500,
                                            // marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                                            display: "flex",
                                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                                        }}>
                                            KPI
                                        </Typography>
                                        
                                    </Box>
                                </Box>
                        </DialogTitle>
                        <span style={{ padding: "20px" }}>
                            {new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                        </span>
                    </div>

                    <hr />

                    <Box sx={{
                        boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                    }}>

                        <DialogContent>

                            {/* <span style={{ fontWeight: "500", fontSize: "16px" }}>STRATEGIC PLAN DETAILS</span> */}

                            <TableContainer style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <Table style={{ border: "1px solid grey" }}>
                                    <TableBody>
                                        <TableRow className={styles.tableRow}>
                                            <TableCell style={{ minWidth: "200px", verticalAlign: "top", fontWeight: "bold" }}>
                                                KPI Name :
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: "top" }}>
                                                {kpisData?.kpiName}</TableCell>
                                        </TableRow>
                                        <TableRow className={styles.tableRow}>
                                            <TableCell style={{
                                                verticalAlign: "top",
                                                fontWeight: "bold"
                                            }}>
                                                Description :
                                            </TableCell>
                                            <TableCell style={{ verticalAlign: "top" }}>{kpisData?.kpiDescription}</TableCell>
                                        </TableRow>
                                        <TableRow className={styles.tableRow}>
                                            <TableCell style={{ width: "200px", fontWeight: "bold" }}>
                                                Degree :
                                            </TableCell>
                                            <TableCell>{kpisData?.degree}</TableCell>
                                        </TableRow>
                                        
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>

                    </Box>
                    <DialogActions>
                        <Button
                            // variant="contained"
                            sx={{
                                backgroundColor: '#fff',
                                // border: '1px solid #3c6766',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: '#fff',
                                },
                            }}
                            onClick={handleCloseViewKpisModal}
                        >
                            <Typography sx={{ color: "black" }} dir={currentLang === 'ar' ? 'rtl' : 'ltr'}>
                                Close
                            </Typography>
                        </Button>


                    </DialogActions>
                </Box>

            </Dialog>



        </>

    )

}
export default ViewKpisModal;