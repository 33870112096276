// store.ts
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
    // Optionally, you can add configuration options here
  };
  
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);

  // const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  
  const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
  // const store = createStore(persistedReducer, applyMiddleware(thunk));
  const persistor = persistStore(store);

  
  
  export { store, persistor };
  export type RootState = ReturnType<typeof store.getState>;



