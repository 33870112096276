import { 
    CREATE_MEETING_REQUEST, 
    CREATE_MEETING_SUCCESS,
    CREATE_MEETING_FAILURE, 
    UPDATE_MEETING_REQUEST,
    UPDATE_MEETING_SUCCESS,
    UPDATE_MEETING_FAILURE,
    GET_MEETING_LIST,
    ADD_SINGLE_MEETING
} from '../action/meetingActions';

const initialState = {
    data: [],
    meetings: [],
    loading: false,
    error: null,
    message: null
};

const meetingReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_MEETING_LIST:
            return {
                ...state,
                data: action.payload.data,
              };
        case ADD_SINGLE_MEETING:
            return {
                ...state,
                data: [...state.data, action.payload.data]
            }
        case CREATE_MEETING_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_MEETING_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload
            };
        case CREATE_MEETING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case UPDATE_MEETING_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_MEETING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_MEETING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default meetingReducer;




