import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Autocomplete,
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import styles from "./style.module.css";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { CampusDetails, CollegeDetails, GlobalService } from '../../../../../../Service/GlobalService';

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
    setFetchUpdate: any,
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}
const UpdateLocation = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);

        const [campusList, setCampusList] = useState<CampusDetails>({
            data: [],
            errMessage: ""
        })
        const [collegeList, setCollegeList] = useState<CollegeDetails>({
            data: [],
            errMessage: ""
        })

        const [roomNo, setRoomNo] = useState<any>(originalValues.roomNo);
        const [floorNo, setFloorNo] = useState<any>(originalValues.floorNo);
        const [buildingNo, setBuildingNo] = useState<any>(originalValues.buildingNo);
        const [college, setCollege] = useState<any>(originalValues.collegeId);
        const [campus, setCampus] = useState<any>(originalValues.campusId);

        const [roomNoError, setRoomNoError] = useState<boolean>(false);
        const [floorNoError, setFloorNoError] = useState<boolean>(false);
        const [buildingNoError, setBuildingNoError] = useState<boolean>(false);
        const [collegeError, setCollegeError] = useState<boolean>(false);
        const [campusError, setCampusError] = useState<boolean>(false);

        const [isChecked, setIsChecked] = useState(originalValues.active);

        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
        };

        //@ college list options
        const collegeListOptions = collegeList && collegeList.data.map((item: any) => ({
            key: item.collegeId,
            label: `${item.collegeName}`,
            value: item.collegeId
        }));
        const handleCollegeChange = (event: any, newValue: any) => {
            setCollege(newValue ? newValue.value : null);
        };

        //@ campus list options
        const campusListOptions = campusList && campusList.data.map((item: any) => ({
            key: item.campusId,
            label: `${item.campusName}`,
            value: item.campusId
        }));
        const handleChangeCampus = (event: any, newValue: any) => {
            setCampus(newValue ? newValue.value : null);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        //@ Fetching data on load of component
        useEffect(() => {
            if (accessToken !== null) {

                const fetchCollege = async () => {
                    const collegeData = await GlobalService.getCollege(accessToken);
                    setCollegeList(collegeData);
                }
                const fetchCampus = async () => {
                    const campusData = await GlobalService.getCampus(accessToken);
                    setCampusList(campusData);
                }

                fetchCollege();
                fetchCampus();
            }
        }, []);

        const submitForm = () => {
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken !== null) {
                    setIsLoadingSpinner(true);
                    let hasError = false;
                    const isValidateForm = () => {
                        if (floorNo === "") {
                            setFloorNoError(true);
                            hasError = true;
                        }
                        else {
                            setFloorNoError(false);
                        }
                        if (roomNo === "") {
                            setRoomNoError(true);
                            hasError = true;
                        }
                        else {
                            setRoomNoError(false);
                        }

                        if (buildingNo === "") {
                            setBuildingNoError(true);
                            hasError = true;
                        } else {
                            setBuildingNoError(false);
                        }

                        if (college === "") {
                            setCollegeError(true);
                            hasError = true;
                        } else {
                            setCollegeError(false);
                        }

                        if (campus === "") {
                            setCampusError(true);
                            hasError = true;
                        } else {
                            setCampusError(false);
                        }

                        return !hasError;
                    }


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    }
                    else {

                        const formState: any = {
                            id: originalValues.id,
                            collegeId: college,
                            campusId: campus,
                            buildingNo: buildingNo,
                            floorNo: floorNo,
                            roomNo: roomNo,
                            active: true
                        };

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken,
                                    'Accept-Language' : 'en'
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.status === 200) ? "success" : "error",
                                        message: (response.status === 200) ? `Location has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    if (response.status === 200) {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.log('error=>>>', error);
                            });
                    }


                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>

                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    label="Room No"
                                    placeholder="Room No"
                                    variant="standard"
                                    fullWidth
                                    error={roomNoError}
                                    helperText={(roomNoError) ? ("* Please Enter Room No.") : ("")}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={roomNo}
                                    onChange={(e) => setRoomNo(e.target.value)}
                                />
                            </Grid>

                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    label="Floor No"
                                    placeholder="Floor No"
                                    variant="standard"
                                    fullWidth
                                    error={floorNoError}
                                    helperText={(floorNoError) ? ("* Please Select Date.") : ("")}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={floorNo}
                                    onChange={(e) => setFloorNo(e.target.value)}
                                />
                            </Grid>

                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    label="Building No"
                                    placeholder="Building No"
                                    variant="standard"
                                    fullWidth
                                    error={buildingNoError}
                                    helperText={(buildingNoError) ? ("* Please Enter Credit Hours.") : ("")}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={buildingNo}
                                    onChange={(e) => setBuildingNo(e.target.value)}
                                />
                            </Grid>


                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ paddingLeft: "30px", mt: 1 }}>
                                <Autocomplete
                                    options={collegeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={collegeListOptions.find((option: any) => option.value === college) || null}
                                    onChange={handleCollegeChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="College"
                                            placeholder="College"
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={collegeError}
                                            helperText={(collegeError) ? ("* Please Select Any Program.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ paddingLeft: "30px", mt: 1 }}>
                                <Autocomplete
                                    options={campusListOptions}
                                    getOptionLabel={option => option.label}
                                    value={campusListOptions.find((option: any) => option.value === campus) || null}
                                    onChange={handleChangeCampus}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Campus"
                                            placeholder="Campus"
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={campusError}
                                            helperText={(campusError) ? ("* Please Select Any Program.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateLocation;