const initialState = {
    data: null,
  };
  
  const userPictureReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'USER_PICTURE':
        return {
          ...state,
          data: action.payload.data,
        };
      case 'CLEAR_PICTURE':
        return {
          data: null
        }
      default:
        return state;
    }
  };
  
  export default userPictureReducer;

 

  