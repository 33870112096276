import axios from 'axios'
import readAPI from '../data/api/read'
import { ICollege, ICampus, IProgram, IProgramType, ICoordinatorFarSection, IDepartment, IOverAllGradeSummary } from '../model/FarCoordinator'

export type CollegeType = {
    data: ICollege[],
    errMessage: string
}
export type CampusType = {
    data: ICampus[],
    errMessage: string
}

export type DepartmentType = {
    data: IDepartment[],
    errMessage: string
}

export type OverAllGradeSummaryType = {
    data: IOverAllGradeSummary[],
    errMessage: string
}

export type CoordinatorFarSectionType = {
    data: ICoordinatorFarSection[],
    errMessage: string
}

export type ProgramType = {
    data: IProgram[],
    errMessage: string
}

export type ProgramObjectiveType = {
    data: IProgramType[],
    errMessage: string
}


//@ function for fetching college
async function getCollege(accessToken: string): Promise<CollegeType> {
    let url: string = readAPI.college;
    let header: string = accessToken;
    let collegeType: CollegeType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            collegeType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                collegeType.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            collegeType.errMessage = err.message;
            collegeType.data = [];
        })

    return collegeType;
}

//@ function for fetching campus
async function getCampus(accessToken: string): Promise<CampusType> {
    let url: string = readAPI.campus;
    let header: string = accessToken;
    let campusType: CampusType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            campusType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                campusType.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            campusType.errMessage = err.message;
            campusType.data = [];
        })

    return campusType;
}

//@ function for fetching department
async function getDepartment(accessToken: string): Promise<DepartmentType> {
    let url: string = readAPI.department;
    let header: string = accessToken;
    let departmentType: DepartmentType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            departmentType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                departmentType.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            departmentType.errMessage = err.message;
            departmentType.data = [];
        })

    return departmentType;
}

//@ function for fetching program
async function getProgram(accessToken: string): Promise<ProgramType> {
    let url: string = readAPI.program;
    let header: string = accessToken;
    let programType: ProgramType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            programType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                programType.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            programType.errMessage = err.message;
            programType.data = [];
        })

    return programType;
}

//@function for fetching overallgrade summary by offeredCourseId
async function getOverAllGradeSummaryByOfferedCourseId(accessToken: string, offeredCourseId: string): Promise<OverAllGradeSummaryType> {
    let url: string = readAPI.getOverallGradeSummary;
    let header: string = accessToken;
    let overAllGradeSummaryType: OverAllGradeSummaryType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}/${offeredCourseId}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            overAllGradeSummaryType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                overAllGradeSummaryType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            overAllGradeSummaryType.errMessage = err.message;
        })
    return overAllGradeSummaryType;
}


// async function calculateAverage(overAllGradeSummary: IOverAllGradeSummary[]): Promise<IOverAllGradeSummary> {
//     let average: IOverAllGradeSummary = {
//         id: 0,
//         offeredCourseId: 0,
//         apCount: 0,
//         bpCount: 0,
//         cpCount: 0,
//         dpCount: 0,
//         mahroomCount: 0,
//         incompleteCount: 0,
//         withdrawnCount: 0,
//         noOfStudent: 0,
//         maximumMarks: 0,
//         minimumMarks: 0,
//         averageMarks: 0,
//         gpa: 0,
//         standardDeviation: 0,
//         departmentId: "",
//         campusId: "",
//         collegeId: "",
//         semester: "",
//         academicYear: 0,
//         acount: 0,
//         bcount: 0,
//         ccount: 0,
//         dcount: 0,
//         fcount: 0,
//         createdBy: "",
//         creationDateTime: "",
//         updatedBy: "",
//         updateDateTime: "",
//         active: true
//     }
//     // let fcount = 0;
//     let totalSection = overAllGradeSummary.length;
//     overAllGradeSummary.map((grade: any) => {
//         average.gpa = average.gpa + grade.gpa;
//         average.fcount = average.fcount + grade.fcount;
//         average.apCount = average.apCount + grade.apCount;
//         average.acount = average.acount + grade.acount;
//         average.bcount = average.bcount + grade.bcount;
//         average.ccount = average.ccount + grade.ccount;
//         average.dcount = average.dcount + grade.dcount;
//         average.bpCount = average.bpCount + grade.bpCount;
//         average.cpCount = average.cpCount + grade.cpCount;
//         average.dpCount = average.dpCount + grade.dpCount;
//         average.mahroomCount = average.mahroomCount + grade.mahroomCount;
//         average.incompleteCount = average.incompleteCount + grade.incompleteCount;
//         average.withdrawnCount = average.withdrawnCount + grade.withdrawnCount;
//         average.noOfStudent = average.noOfStudent + grade.noOfStudent;
//         average.maximumMarks = average.maximumMarks + grade.maximumMarks;
//         average.minimumMarks = average.minimumMarks + grade.minimumMarks;
//         average.averageMarks = average.averageMarks + grade.averageMarks;
//         average.standardDeviation = average.standardDeviation + grade.standardDeviation;
//     })

//     average.fcount = average.fcount / totalSection;
//     average.gpa = average.gpa / totalSection;
//     average.apCount = average.apCount / totalSection;
//     average.acount = average.acount / totalSection;
//     average.bcount = average.bcount / totalSection;
//     average.ccount = average.ccount / totalSection;
//     average.dcount = average.dcount / totalSection;
//     average.bpCount = average.bpCount / totalSection;
//     average.cpCount = average.cpCount / totalSection;
//     average.dpCount = average.dpCount / totalSection;
//     average.mahroomCount = average.mahroomCount / totalSection;
//     average.incompleteCount = average.incompleteCount / totalSection;
//     average.withdrawnCount = average.withdrawnCount / totalSection;
//     average.noOfStudent = average.noOfStudent / totalSection;
//     average.maximumMarks = average.maximumMarks / totalSection;
//     average.minimumMarks = average.minimumMarks / totalSection;
//     average.averageMarks = average.averageMarks / totalSection;
//     average.standardDeviation = average.standardDeviation / totalSection;

//     return average;
// }


async function calculateAverage(overAllGradeSummary: (IOverAllGradeSummary | null)[]): Promise<IOverAllGradeSummary> {
    let average: IOverAllGradeSummary = {
                id: 0,
                offeredCourseId: 0,
                apCount: 0,
                bpCount: 0,
                cpCount: 0,
                dpCount: 0,
                mahroomCount: 0,
                incompleteCount: 0,
                withdrawnCount: 0,
                noOfStudent: 0,
                maximumMarks: 0,
                minimumMarks: 0,
                averageMarks: 0,
                gpa: 0,
                standardDeviation: 0,
                departmentId: "",
                campusId: "",
                collegeId: "",
                semester: "",
                academicYear: 0,
                acount: 0,
                bcount: 0,
                ccount: 0,
                dcount: 0,
                fcount: 0,
                createdBy: "",
                creationDateTime: "",
                updatedBy: "",
                updateDateTime: "",
                active: true
            }

    let totalSection = 0;

    overAllGradeSummary.forEach((grade: any) => {
        if (grade) {
            totalSection++;

            average.gpa += grade.gpa || 0;
            average.fcount += grade.fcount || 0;
            average.apCount += grade.apCount || 0;
            average.acount += grade.acount || 0;
            average.bcount += grade.bcount || 0;
            average.ccount += grade.ccount || 0;
            average.dcount += grade.dcount || 0;
            average.bpCount += grade.bpCount || 0;
            average.cpCount += grade.cpCount || 0;
            average.dpCount += grade.dpCount || 0;
            average.mahroomCount += grade.mahroomCount || 0;
            average.incompleteCount += grade.incompleteCount || 0;
            average.withdrawnCount += grade.withdrawnCount || 0;
            average.noOfStudent += grade.noOfStudent || 0;
            average.maximumMarks += grade.maximumMarks || 0;
            average.minimumMarks += grade.minimumMarks || 0;
            average.averageMarks += grade.averageMarks || 0;
            average.standardDeviation += grade.standardDeviation || 0;
        }
    });

    if (totalSection === 0) return average;

    average.gpa /= totalSection;
    average.fcount /= totalSection;
    average.apCount /= totalSection;
    average.acount /= totalSection;
    average.bcount /= totalSection;
    average.ccount /= totalSection;
    average.dcount /= totalSection;
    average.bpCount /= totalSection;
    average.cpCount /= totalSection;
    average.dpCount /= totalSection;
    average.mahroomCount /= totalSection;
    average.incompleteCount /= totalSection;
    average.withdrawnCount /= totalSection;
    average.noOfStudent /= totalSection;
    average.maximumMarks /= totalSection;
    average.minimumMarks /= totalSection;
    average.averageMarks /= totalSection;
    average.standardDeviation /= totalSection;

    return average;
}


async function getCoordinatorFarSection(accessToken: string): Promise<CoordinatorFarSectionType> {
    let url: string = readAPI.getCoordinatorFarSection;
    let header: string = accessToken;
    let coordiantorFarSection: CoordinatorFarSectionType = {
        data: [],
        // {
        //     coordiantorFarSection: [
        //         {
        //             id: 1,
        //             sectionTitle: "SYLLABUS",
        //             description: "Desc goes here",
        //             active: true,
        //             order: 1,
        //             question: [
        //                 {
        //                     id: 1,
        //                     question: "question 1",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 1
        //                 },
        //                 {
        //                     id: 2,
        //                     question: "question 2",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 2
        //                 },
        //             ]
        //         },
        //         {
        //             id: 2,
        //             sectionTitle: "TEACHING MATERIAL",
        //             description: "Desc goes here",
        //             active: true,
        //             order: 2,
        //             question: [
        //                 {
        //                     id: 1,
        //                     question: "question 1",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 1
        //                 },
        //                 {
        //                     id: 2,
        //                     question: "question 2",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 2
        //                 }
        //             ]
        //         },
        //         {
        //             id: 3,
        //             sectionTitle: "EXAM",
        //             description: "Desc goes here",
        //             active: true,
        //             order: 3,
        //             question: [
        //                 {
        //                     id: 1,
        //                     question: "question 1",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 1
        //                 },
        //                 {
        //                     id: 2,
        //                     question: "question 2",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 2
        //                 },
        //                 {
        //                     id: 3,
        //                     question: "question 3",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 3
        //                 },
        //                 {
        //                     id: 4,
        //                     question: "question 4",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 2
        //                 }
        //             ]
        //         },
        //         {
        //             id: 4,
        //             sectionTitle: "SO FORM",
        //             description: "Desc goes here",
        //             active: true,
        //             order: 4,
        //             question: [
        //                 {
        //                     id: 1,
        //                     question: "question 1",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 1
        //                 },
        //                 {
        //                     id: 2,
        //                     question: "question 2",
        //                     yesNo: true,
        //                     active: true,
        //                     order: 2
        //                 }
        //             ]
        //         }
        //     ]
        // },
        errMessage: ""
    }

    await axios.get(url, {
        headers: {
            "x-api-key": header,
            "Access-Control-Allow-Origin": "*"
        }
    })
        .then((res) => {
            coordiantorFarSection.data = res.data.obj;
            if (res.data.code !== "200.200") {
                coordiantorFarSection.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            coordiantorFarSection.errMessage = err.message;
        })

    return coordiantorFarSection;


}

//@function for fetching overallgrade summary by offeredCourseId
async function getProgramObjective(accessToken: string, programId: string): Promise<ProgramObjectiveType> {
    let url: string = readAPI.getProgramObjective;
    let header: string = accessToken;
    let programObjectiveType: ProgramObjectiveType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}/${programId}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            programObjectiveType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                programObjectiveType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            programObjectiveType.errMessage = err.message;
        })
    return programObjectiveType;
}

export const FarCoordinatorService = {
    getCollege,
    getCampus,
    getDepartment,
    getOverAllGradeSummaryByOfferedCourseId,
    calculateAverage,
    getCoordinatorFarSection,
    getProgram,
    getProgramObjective
}