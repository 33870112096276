import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css";
// import SpsDashboard from "../../sps/Components/Dashboard"
const SpsDashboard = lazy(() => import("../../sps/Components/Dashboard"));
const Manage = lazy(() => import("../Components/Manage"));
const Goal = lazy(() => import("../Components/Goal"));
const Initiative = lazy(() => import("../Components/Initiative"));
const ManageKpi = lazy(() => import("../Pages/ManageKpi"));
const Manager = lazy(() => import("../Components/Manager"));
const ExecutiveManager = lazy(() => import("../Components/ExecutiveManager"));
const StrategicPlan = lazy(() => import("../Components/StrategicPlan"));
const StrategicPlanDetail = lazy(() => import("../Components/StrategicPlan/StrategicPlanDetail"));
const StrategicPlanningReport = lazy(() => import("../Pages/Report/StrategicPlanReport"));



interface SpsRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    openAddInitiativeModal: any,
    setOpenAddInitiativeModal: any,
    openAddGoalModal: any,
    setOpenAddGoalModal: any,
    openAddStrategicPlanModal: boolean,
    setOpenAddStrategicPlanModal: any
}

const SpsRouter: FC<SpsRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,
    openAddInitiativeModal,
    setOpenAddInitiativeModal,
    openAddGoalModal,
    setOpenAddGoalModal,
    openAddStrategicPlanModal,
    setOpenAddStrategicPlanModal

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
            <Routes>
                {/* <Route path="portal"> */}
                <Route
                    path="view"
                    element={
                        <SpsDashboard
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                {/* </Route> */}

                <Route path="dashboard">
                    <Route
                        path="view"
                        element={
                            <SpsDashboard
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="executiveManager">
                    <Route
                        path="view"
                        element={
                            <ExecutiveManager
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="manager">
                    <Route
                        path="view"
                        element={
                            <Manager
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="manage">
                    <Route
                        path="view"
                        element={
                            <Manage
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="goal">
                    <Route
                        path="view"
                        element={
                            <Goal
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="strategicPlan">
                    <Route
                        path="view"
                        element={
                            <StrategicPlan
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}
                                openAddStrategicPlanModal={openAddStrategicPlanModal}
                                setOpenAddStrategicPlanModal={setOpenAddStrategicPlanModal}

                            />
                        }
                    />
                </Route>

                <Route path="initiative">
                    <Route
                        path="view"
                        element={
                            <Initiative
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}
                            // openAddInitiativeModal={openAddInitiativeModal}
                            // setOpenAddInitiativeModal={setOpenAddInitiativeModal}

                            />
                        }
                    />
                </Route>

                <Route path="kpi">
                    <Route
                        path="view"
                        element={
                            <ManageKpi
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="strategicPlanDetail">
                    <Route
                        path="view"
                        element={
                            <StrategicPlanDetail
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="strategicPlanningReport">
                    <Route
                        path="view"
                        element={
                            <StrategicPlanningReport
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>





            </Routes>
        </Suspense>
    )

}

export default SpsRouter;
