import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//Importing useTranslation and Trans from react-i18next
import { useTranslation } from 'react-i18next';

import {
    Alert,
    TextField
} from '@mui/material';

import SnackBar from "../../Components/SnackBar";

// Importing Logo
import logo from '../../assets/Images/Login/login_logo.png';
import forgetPassword_logo from '../../assets/Images/ForgetPassword/lock.png';

// Importing CSS
import styles from './style.module.css';
import createAPI from '../../Data/API/CREATE';
import axios from 'axios';

interface ForgetPasswordProps {
    setShowHeader: any;
    currentLang: string;
}

const ForgetPassWord: React.FC<ForgetPasswordProps> = ({ setShowHeader, currentLang }) => {
    const location = useLocation();

    ///////////////////////////////// Snackbar State /////////////////////////////////
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    ///////////////////////////////// Snackbar State /////////////////////////////////

    useEffect(() => {
        // The current location.
        // console.clear();
        // console.log('The current location is: ', location.pathname);
        const url = location.pathname;

        if ((url === '/login' || url === '/' || url === '/forgetpassword')) {
            setShowHeader(true);
        } else {
            setShowHeader(false);
        }
    }, [location, setShowHeader]);

    const [email, setEmail] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [failureError, setFailureError] = useState(false);
    const [isSetError, setIsError] = useState(false);
    const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [severity, setSeverity] = useState<any>();

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (emailError) {
            setEmailError(false);
        }
        setEmail(event.target.value);
    };

    const handleCloseAlert = () => {
        setEmailError(false);
    }

    const { t } = useTranslation();
    
    const enterEmail = t('forgetPassword.errorMessage.enterEmail');
    const validEmail = t('forgetPassword.errorMessage.validEmail');
    const success = t('forgetPassword.errorMessage.success');
    const registeredEmail = t('forgetPassword.errorMessage.registeredEmail');
    const error = t('forgetPassword.errorMessage.error');
    const handleForgotPassword = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (email === '') {
            setEmailError(true);
            setErrorMessage(enterEmail)
            setSeverity("warning");
            return;
        }
        if (!emailRegex.test(email)) {
            setEmailError(true);
            setErrorMessage(validEmail);
            setSeverity("warning");
            return;
        }
        else {
            setEmailError(false);
            setIsLoadingSpinner(true)
            axios.post(`${createAPI.ForgotPassword}/${email}`, {}, {
                headers: {}
            })
                .then((response) => {
                    if (response.data.code === "200.200") {
                        setEmailError(true);
                        setErrorMessage(success);
                        setSeverity("success");
                        setIsLoadingSpinner(false);
                    }
                    if(response.data.code === "200.400"){
                        setEmailError(true);
                        setErrorMessage(registeredEmail);
                        setSeverity("error");
                        setIsLoadingSpinner(false);
                    }
                })
                .catch((err) => {
                    setEmailError(true);
                    setErrorMessage(error);
                    setSeverity("error");
                });


        }
    };

    

    return (
        <div className={styles.containerCustom}>
            <div className={styles.forgetPasswordContainer}>
                <form className={styles.loginContainer} action="return false">
                    <div className={styles.logo_mobile_container}>
                        <img
                            className={styles.logo}
                            src={logo}
                            alt={`${t('forgetPassword.img.img1.alt')}`}
                            title={`${t('forgetPassword.img.img1.title')}`}
                        />
                    </div>
                    <div>
                        <img
                            className={styles.logoLock}
                            src={forgetPassword_logo}
                            alt={`${t('forgetPassword.img.img2.alt')}`}
                            title={`${t('forgetPassword.img.img2.title')}`}
                        />
                    </div>
                    <h2 className={styles.titleForgetPassword}>{t('forgetPassword.title')}</h2>
                    <p className={styles.infoForgotPassword}>{t('forgetPassword.subTitle')}</p>
                    <section className={styles.form_inputs}>
                        <div>
                            <br />
                            <TextField
                                id="standard-basic"
                                className={styles.emails}
                                label={t('forgetPassword.formInputs.userName.label')}
                                variant="standard"
                                value={email}
                                onChange={handleEmailChange}
                                error={emailError}
                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                sx={{
                                    width: '84%',
                                    ml: "8%",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                    }
                                }}
                                placeholder={`${t('forgetPassword.formInputs.userName.placeHolder')}`}
                            />
                        </div>

                        <button
                            type='button'
                            className={`btn ${styles.btn_forgetPassword}`}
                            onClick={handleForgotPassword}
                        >
                            {t('forgetPassword.formInputs.btnResetPassword')}
                        </button>


                        {emailError ?
                            <Alert severity={severity} onClose={() => { handleCloseAlert() }}>
                                {errorMessage}
                            </Alert> : ""
                        }
                        <p style={{ textAlign: "center" }}>
                            {isLoadingSpinner ? (<i className="fa fa-spinner fa-spin fa-2x"></i>) : ""}
                        </p>

                    </section>


                </form>
                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />
            </div>
        </div>
    )
}
export default ForgetPassWord;