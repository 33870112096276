import * as React from "react";
import { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AppsIcon from '@mui/icons-material/Apps';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StartIcon from '@mui/icons-material/Start';
import { AiOutlineAim } from "react-icons/ai";
import EditIcon from '@mui/icons-material/Edit';

// import Cookies from "js-cookie";

// importing from material ui
import {
    Box,
    Typography,
    Modal,
    Backdrop,
    Fade,
    Button,
    // FormControl,
    // InputLabel,
    // InputAdornment,
    // OutlinedInput,
    // IconButton,
} from "@mui/material";

import Grid2 from "@mui/material/Unstable_Grid2";
import { FaBuilding, FaSchool, FaUniversity, FaBook, FaUserEdit } from "react-icons/fa";
import { MdGroup } from "react-icons/md";
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';


// Importing icons
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useTranslation } from "react-i18next";

//Importing Components
// (1) Importing the UpdateUser component
import UpdateUser from "../../Pages/Home/UserManagement/Users/UpdateUser";
// (2) Importing the UpdateRole component
import UpdateRole from "../../Pages/Home/UserManagement/Roles/UpdateRole";
// (3) Importing the UpdateUserGroup component
import UpdateUserGroup from "../../Pages/Home/UserManagement/UserGroup/UpdateUserGroup";
// (4) Importing the UpdateGroup component
import UpdateGroup from "../../Pages/Home/UserManagement/Groups/UpdateGroup";
// (5) Importing the UpdateRoleApp component
import UpdateRoleApp from "../../Pages/Home/UserManagement/RoleAppPrivilege/UpdateRoleApp";
// (6) Importing the UpdateAppForm component
import UpdateAppForm from "../../Pages/Home/UserManagement/AppForm/UpdateAppForm";
// (7) Importing the UpdateApp component
import UpdateApp from "../../Pages/Home/UserManagement/Application/UpdateApp";
// (8) Importing the UpdateGroupRole component
import UpdateGroupRole from "../../Pages/Home/UserManagement/GroupRole/UpdateGroupRole";
// (9)Importing Update college
import UpdateCollege from "../../../Apps/eqa-core/Components/Profile/College/UpdateCollege";
// (10)Importing Update campus
import UpdateCampus from "../../../Apps/eqa-core/Components/Profile/Campus/UpdateCampus";
// (11)Importing Update university
import UpdateUniversity from "../../../Apps/eqa-core/Components/Profile/University/UpdateUniversityModal";
// (12)Importing Update university
import UpdateDepartment from "../../../Apps/eqa-core/Components/Profile/Department/UpdateDepartment";
// (13)Importing Update program
import UpdateProgram from "../../../Apps/eqa-core/Components/Profile/Program/UpdateProgram";
// (14)Importing Update program type
import UpdateProgramType from "../../../Apps/eqa-core/Components/Profile/ProgramType/UpdateProgramType";
import UpdateProgramObjective from "../../../Apps/eqa-core/Components/Profile/ProgramObjective/UpdateProgramObjective";

import styles from "./style.module.css";
import UpdateCourse from "../../../Apps/eqa-core/Components/Course/CourseDetails/UpdateCourse";
import UpdateCourseObjective from "../../../Apps/eqa-core/Components/Course/CourseObjective/UpdateCourseObjective";
import UpdateCLO from "../../../Apps/eqa-core/Components/Course/CourseLearningOutcome/UpdateCLO";
import UpdateCourseTopics from "../../../Apps/eqa-core/Components/Course/Topics/UpdateCourseTopics";
import UpdateCourseBook from "../../../Apps/eqa-core/Components/Course/Books/UpdateCourseBook";
import UpdateBook from "../../../Apps/eqa-core/Components/Course/Books/UpdateBook";
import UpdateCourseType from "../../../Apps/eqa-core/Components/Course/CourseDetails/UpdateCourseType";
import UpdateAssessment from "../../../Apps/assessment/Components/ManageAssessment/UpdateAssessment";
import UpdateAssessmentType from "../../../Apps/assessment/Components/ManageAssessment/UpdateAssessmentType";
import UpdateManualScheduleCourse from "../../../Apps/scheduleCourse/Components/ManualScheduleCourse/UpdateManualScheduleCourse";
import UpdateSessionType from "../../../Apps/scheduleCourse/Components/ManualScheduleCourse/UpdateSessionType";
import UpdateAcademicYear from "../../../Apps/scheduleCourse/Components/ManualScheduleCourse/UpdateAcademicYear";
import UpdateCourseCoordinator from "../../../Apps/scheduleCourse/Components/ManualScheduleCourse/UpdateCourseCoordinator";
import UpdateProjectSubComponent from "../../../Apps/eqa-core/Components/ProjectConfiguration/UpdateProjectSubComponent";
import UpdateCoordinator from "../../../Apps/eqa-core/Components/Coordinator/ManageCoordinator/UpdateCoordinator";
import ViewMeeting from "../../../Apps/mom/Components/Inbox/View";
import UpdateSurveyFormLink from "../../../Apps/survey/Components/UpdateSurvey";
import UpdateUploadDocStatus from "../../../Apps/systemPreferences/Components/ReportSetting/UploadDocStatus/UpdateUploadDocStatus";
import UpdateStudyPlan from "../../../Apps/studyPlan/Components/UpdateStudyPlan";
import UpdateLocation from "../../../Apps/systemPreferences/Components/MOMSetting/ManageLocation/UpdateLocation";
import UpdateCommittee from "../../../Apps/committee/Components/UpdateCommittee";
import UpdateCommitteeMember from "../../../Apps/committee/Components/UpdateCommitteeMember";
import UpdateAnnualProgramReport from "../../../Apps/systemPreferences/Components/ReportSetting/AnnualProgram/UpdateAnnualProgramReport";
import UpdateAssignTask from "../../../Apps/report/components/ManageReport/UpdateAssignTask";
import { useDispatch } from "react-redux";
import { assignTaskData } from "../../../redux/action/authActions";
import { AppRegistration } from "@mui/icons-material";
import UpdateStrategicPlan from "../../../Apps/sps/Components/StrategicPlan/UpdateStrategicPlan";
import { FcPlanner } from "react-icons/fc";
import UpdateInitiative from "../../../Apps/sps/Components/Initiative/UpdateInitiative";
import UpdateKpi from "../../../Apps/sps/Components/Kpi/UpdateKpi";
import UpdateKpiValue from "../../../Apps/sps/Components/KpiValue/UpdateKpiValue";
import UpdateGoal from "../../../Apps/sps/Components/Goal/UpdateGoal";

interface EditTableModalModalProps {
    openUpdateTableModal: boolean;
    setOpenUpdateTableModal: React.Dispatch<React.SetStateAction<boolean>>;
    originalValues: any;
    columnName: string;
    url: string;
    currentRowIndex: number;
    tableData: any;
    setTableData: any;
    setFetchUpdate: any;
    setForUpdate?: any;
    studyPlanOfferedProgram?: any;
    assignTaskTasks?: any;
    goalDatas?: any;
    goalMappingToKpi?: any;
    // Current Language
    // currentLang: string;
}

const EditTableModal: React.FC<EditTableModalModalProps> = ({
    openUpdateTableModal,
    setOpenUpdateTableModal,
    originalValues,
    columnName,
    url,
    currentRowIndex,
    tableData,
    setTableData,
    setFetchUpdate,
    setForUpdate,
    studyPlanOfferedProgram,
    assignTaskTasks,
    goalDatas,
    goalMappingToKpi
    // // Current Language
    // currentLang,
}) => {
    const { t } = useTranslation();

    const childRef = useRef<any>(null);

    const handleUpdate = () => {
        childRef.current?.submitForm();
    }



    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        function handleResize() {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);




    const dispatch = useDispatch();
    const handleCloseUpdateModal = () => {
        setOpenUpdateTableModal(false);
        // assignTaskData
        // dispatch(assignTaskDa(res.data.obj[0].tasks))
    }

    let modalContent = null;

    if (columnName === "ViewUsers") {
        modalContent = <UpdateUser
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    } else if (columnName === "ViewRoles") {
        modalContent = <UpdateRole
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    } else if (columnName === "ViewGroups") {
        modalContent = <UpdateGroup
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    } else if (columnName === "ViewApps") {
        modalContent = <UpdateApp
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    } else if (columnName === "ViewAppForm") {
        modalContent = <UpdateAppForm
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    } else if (columnName === "ViewGroupRole") {
        modalContent = <UpdateGroupRole
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    } else if (columnName === "ViewUserGroup") {
        modalContent = <UpdateUserGroup
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    } else if (columnName === "ViewRoleApp") {
        modalContent = <UpdateRoleApp
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    } else if (columnName === "ViewCollege") {
        modalContent = <UpdateCollege
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewCampus") {
        modalContent = <UpdateCampus
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewDepartment") {
        modalContent = <UpdateDepartment
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewProgram") {
        modalContent = <UpdateProgram
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewProgramType") {
        modalContent = <UpdateProgramType
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewProgramObjective") {
        modalContent = <UpdateProgramObjective
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewUniversity") {
        modalContent = <UpdateUniversity
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }

    else if (columnName === "ViewCourseDetails") {
        modalContent = <UpdateCourse
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }

    else if (columnName === "ViewCourseType") {
        modalContent = <UpdateCourseType
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }

    else if (columnName === "ViewCourseObjective") {
        modalContent = <UpdateCourseObjective
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }
    else if (columnName === "ViewCourseLearningOutcome") {
        modalContent = <UpdateCLO
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }
    else if (columnName === "ViewTopics") {
        modalContent = <UpdateCourseTopics
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }

    else if (columnName === "ViewCourseBook") {
        modalContent = <UpdateCourseBook
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }
    else if (columnName === "ViewBook") {
        modalContent = <UpdateBook
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }


    else if (columnName === "ViewAssessments") {
        modalContent = <UpdateAssessment
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }

    else if (columnName === "ViewAssessmentType") {
        modalContent = <UpdateAssessmentType
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }

    // else if (columnName === "ViewStrategicPlan") {
    //     modalContent = <UpdateStrategicPlan
    //         currentLang={""}
    //         originalValues={originalValues}
    //         url={url}
    //         ref={childRef}
    //         setOpenUpdateTableModal={setOpenUpdateTableModal}
    //         setFetchUpdate={setFetchUpdate}
    //         setForUpdate={setForUpdate}
    //         currentRowIndex={currentRowIndex}
    //     />
    // }
    else if (columnName === "ViewInitiative") {
        modalContent = <UpdateInitiative
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            currentRowIndex={currentRowIndex}
            setForUpdate={setForUpdate}
        />
    }
    else if (columnName === "ViewKPI") {
        modalContent = <UpdateKpi
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            currentRowIndex={currentRowIndex}
            setForUpdate={setForUpdate}
        />
    }
    else if (columnName === "ViewKPIValue") {
        modalContent = <UpdateKpiValue
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            currentRowIndex={currentRowIndex}
            setForUpdate={setForUpdate}
        />
    }
    else if (columnName === "ViewGoalData") {
        modalContent = <UpdateGoal
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            currentRowIndex={currentRowIndex}
            goalDatas={goalDatas}
            goalMappingToKpi={goalMappingToKpi}
            setForUpdate={setForUpdate}
        />
    }


    else if (columnName === "ViewManualScheduleCourse") {
        modalContent = <UpdateManualScheduleCourse
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }

    else if (columnName === "ViewSessionTypeData") {
        modalContent = <UpdateSessionType
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewAcademicYearData") {
        modalContent = <UpdateAcademicYear
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewCourseCoordinatorData") {
        modalContent = <UpdateCourseCoordinator
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewProjectTemplate") {
        modalContent = <UpdateProjectSubComponent
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewCoordinator") {
        modalContent = <UpdateCoordinator
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }

    else if (columnName === "ViewAllMeeting") {
        modalContent = <ViewMeeting
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
        />
    }

    else if (columnName === "ViewSurvey") {
        modalContent = <UpdateSurveyFormLink
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }

    else if (columnName === "ViewLocation") {
        modalContent = <UpdateLocation
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }

    else if (columnName === "ViewUploadDocStatus") {
        modalContent = <UpdateUploadDocStatus
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewCreatedStudyPlan") {
        modalContent = <UpdateStudyPlan
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            studyPlanOfferedProgram={studyPlanOfferedProgram}
        />
    }
    else if (columnName === "ViewCommittee") {
        modalContent = <UpdateCommittee
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewCommitteeMember") {
        modalContent = <UpdateCommitteeMember
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewAnnualProgramReport") {
        modalContent = <UpdateAnnualProgramReport
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
        />
    }
    else if (columnName === "ViewAssignTaskReport") {
        modalContent = <UpdateAssignTask
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            assignTaskTasks={assignTaskTasks}
        />
    }


    else {
        modalContent = <UpdateUser
            currentLang={""}
            originalValues={originalValues}
            url={url}
            ref={childRef}
            setOpenUpdateTableModal={setOpenUpdateTableModal}
            setFetchUpdate={setFetchUpdate}
            setForUpdate={setForUpdate}
        />
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openUpdateTableModal}
            onClose={() => setOpenUpdateTableModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={openUpdateTableModal}>
                <Box className={styles.modalStyle}
                    sx={{
                        height: (columnName === "ViewUsers") ? "90vh" :
                            (columnName === "ViewRoles") ? "fit-content" :
                                (columnName === "ViewGroups") ? "auto" :
                                    (columnName === "ViewApps") ? "auto" :
                                        (columnName === "ViewAppForm") ? "auto" :
                                            (columnName === "ViewGroupRole") ? "auto" :
                                                (columnName === "ViewUserGroup") ? "auto" :
                                                    (columnName === "ViewRoleApp") ? "auto" :
                                                        (columnName === "ViewCollege") ? "auto" :
                                                            (columnName === "ViewCampus") ? "auto" :
                                                                (columnName === "ViewDepartment") ? "auto" :
                                                                    (columnName === "ViewProgram") ? "auto" :
                                                                        (columnName === "ViewProgramType") ? "auto" :
                                                                            (columnName === "ViewProgramObjective") ? "auto" :
                                                                                (columnName === "ViewUniversity") ? "auto" :
                                                                                    (columnName === "ViewCourseType") ? "auto" :
                                                                                        (columnName === "ViewCourseDetails") ? "auto" :
                                                                                            (columnName === "ViewCourseObjective") ? "auto" :
                                                                                                (columnName === "ViewCourseLearningOutcome") ? "auto" :
                                                                                                    (columnName === "ViewTopics") ? "auto" :
                                                                                                        (columnName === "ViewCourseBook") ? "auto" :
                                                                                                            (columnName === "ViewBook") ? "auto" :
                                                                                                                (columnName === "ViewAssessments") ? "auto" :
                                                                                                                    (columnName === "ViewAssessmentType") ? "auto" :
                                                                                                                        (columnName === "ViewStrategicPlan") ? "auto" :
                                                                                                                            (columnName === "ViewInitiative") ? "auto" :
                                                                                                                                (columnName === "ViewKPI") ? "auto" :
                                                                                                                                    (columnName === "ViewKPIValue") ? "auto" :
                                                                                                                                        (columnName === "ViewGoalData") ? "auto" :
                                                                                                                                            (columnName === "ViewCloSoMapping") ? "auto" :
                                                                                                                                                (columnName === "ViewManualScheduleCourse") ? "auto" :
                                                                                                                                                    (columnName === "ViewSessionTypeData") ? "auto" :
                                                                                                                                                        (columnName === "ViewAcademicYearData") ? "auto" :
                                                                                                                                                            (columnName === "ViewCourseCoordinatorData") ? "auto" :
                                                                                                                                                                (columnName === "ViewProjectTemplate") ? "auto" :
                                                                                                                                                                    (columnName === "ViewCoordinator") ? "auto" :
                                                                                                                                                                        (columnName === "ViewAllMeeting") ? "auto" :
                                                                                                                                                                            (columnName === "ViewSurvey") ? "auto" :
                                                                                                                                                                                (columnName === "ViewLocation") ? "auto" :
                                                                                                                                                                                    (columnName === "ViewUploadDocStatus") ? "auto" :
                                                                                                                                                                                        (columnName === "ViewCreatedStudyPlan") ? "auto" :
                                                                                                                                                                                            (columnName === "ViewCommittee") ? "auto" :
                                                                                                                                                                                                (columnName === "ViewCommitteeMember") ? "auto" :
                                                                                                                                                                                                    (columnName === "ViewAnnualProgramReport") ? "auto" :
                                                                                                                                                                                                        (columnName === "ViewAssignTaskReport") ? "auto" :
                                                                                                                                                                                                            "auto",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            // borderBottom: "1px solid #54787e",
                            width: "100%",

                        }}
                    >

                        <Typography className={styles.transition_modal_title} variant="h4" component="h2">
                            {/* {t('Home.Header.Modals.ChangePassword.title')} */}
                            {(columnName === "ViewUsers") ? (
                                <div style={{ display: "flex" }}>
                                    <EditIcon style={{ marginTop: "3px", marginRight: "10px", color: "#4f747a" }} />
                                    {/* <FaUserEdit size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" /> */}
                                    <span>Update User</span>
                                </div>
                            ) : (columnName === "ViewRoles") ? (
                                <div style={{ display: "flex" }}>
                                    {/* <FaUserEdit size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" /> */}
                                    <EditIcon style={{ marginTop: "3px", marginRight: "10px", color: "#4f747a" }} />
                                    <span>Update Role</span>
                                </div>
                            ) : (columnName === "ViewGroups") ? (
                                <div style={{ display: "flex" }}>
                                    <MdGroup size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                    <span>Update Group</span>
                                </div>
                            ) : (columnName === "ViewApps") ? (
                                <div style={{ display: "flex" }}>
                                    <AppRegistration sx={{ marginTop: "3px", color: "#4f747a", fontSize: 27 }} />
                                    <span>Update App</span>
                                </div>
                            ) : (columnName === "ViewAppForm") ? (
                                <div style={{ display: "flex" }}>
                                    <AppRegistration sx={{ marginTop: "3px", color: "#4f747a", fontSize: 27 }} />
                                    <span>Update App Form</span>
                                </div>
                            ) : (columnName === "ViewGroupRole") ? (
                                <div style={{ display: "flex" }}>
                                    <MdGroup size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                    <span>Update Group Role</span>
                                </div>
                            ) : (columnName === "ViewUserGroup") ? (
                                <div style={{ display: "flex" }}>
                                    <AppRegistration sx={{ marginTop: "3px", color: "#4f747a", fontSize: 27 }} />
                                    <span>Update User Group</span>
                                </div>
                            ) : (columnName === "ViewRoleApp") ? (
                                <div style={{ display: "flex" }}>
                                    <AppRegistration sx={{ marginTop: "3px", color: "#4f747a", fontSize: 27 }} />
                                    <span>Update Role App</span>
                                </div>
                            ) :
                                (columnName === "ViewCollege") ? (
                                    <div style={{ display: "flex" }}>
                                        <FaSchool size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                        <span>Update College</span>
                                    </div>
                                ) :
                                    (columnName === "ViewCampus") ? (
                                        <div style={{ display: "flex" }}>
                                            <FaSchool size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                            <span>Update Campus</span>
                                        </div>
                                    ) :
                                        (columnName === "ViewDepartment") ? (
                                            <div style={{ display: "flex" }}>
                                                <FaBuilding size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                                <span>Update Department</span>
                                            </div>
                                        ) :
                                            (columnName === "ViewProgram") ? (
                                                <div style={{ display: "flex" }}>
                                                    <FaBook size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                                    <span>Update Program</span>
                                                </div>
                                            ) :
                                                (columnName === "ViewProgramType") ? (
                                                    <div style={{ display: "flex" }}>
                                                        <FaBook size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                                        <span>Update Program Type</span>
                                                    </div>
                                                ) :
                                                    (columnName === "ViewProgramObjective") ? (
                                                        <div style={{ display: "flex" }}>
                                                            <FaBook size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                                            <span>Update Program Objective</span>
                                                        </div>
                                                    ) :
                                                        (columnName === "ViewUniversity") ? (
                                                            <div style={{ display: "flex" }}>
                                                                <FaUniversity size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                                                <span>Update University</span>
                                                            </div>
                                                        ) :
                                                            (columnName === "ViewCourseDetails") ? (
                                                                <div style={{ display: "flex" }}>
                                                                    <FaBook size={27} style={{ marginTop: "3px", marginRight: "10px" }} color="#4f747a" />
                                                                    <span>Update Course</span>
                                                                </div>
                                                            ) :
                                                                (columnName === "ViewCourseType") ? (
                                                                    "Update Course Type"
                                                                ) :
                                                                    (columnName === "ViewCourseObjective") ? (
                                                                        "Update Course"
                                                                    ) :
                                                                        (columnName === "ViewCourseLearningOutcome") ? (
                                                                            "Update CLO"
                                                                        ) :
                                                                            (columnName === "ViewTopics") ? (
                                                                                "Update Topics"
                                                                            ) :
                                                                                (columnName === "ViewCourseBook") ? (
                                                                                    "Update Course Book"
                                                                                ) :
                                                                                    (columnName === "ViewBook") ? (
                                                                                        "Update Book"
                                                                                    ) :
                                                                                        (columnName === "ViewAssessments") ? (
                                                                                            <div style={{ display: "flex" }}>
                                                                                                <AssessmentIcon sx={{
                                                                                                    color: "#4f747a",
                                                                                                    fontSize: 35,
                                                                                                    size: 27
                                                                                                }} />
                                                                                                <span>Update Assessment</span>
                                                                                            </div>
                                                                                        ) :
                                                                                            (columnName === "ViewAssessmentType") ? (
                                                                                                "Update Assessment Type"
                                                                                            ) :
                                                                                                (columnName === "ViewStrategicPlan") ? (
                                                                                                    <div style={{ display: "flex" }}>
                                                                                                        {/* <FcPlanner style={{ marginRight: "10px", height: "35px", width: "35px" }} />
                                                                                                        <span>Update Strategic Plan</span> */}

                                                                                                        <Box sx={{
                                                                                                            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                                                                                                            width: 60,
                                                                                                            height: 60,
                                                                                                            borderRadius: 1.25,
                                                                                                            backgroundColor: "#fffefe",
                                                                                                            display: "flex",
                                                                                                            justifyContent: "center",
                                                                                                            alignItems: "center",
                                                                                                        }}>
                                                                                                            <EditIcon style={{ marginTop: "3px", color: "#4f747a" }} />
                                                                                                        </Box>
                                                                                                        <Box sx={{ ml: 3 }}>
                                                                                                            <Typography variant="h5" sx={{
                                                                                                                color: "#3c6766",
                                                                                                                fontWeight: 500,
                                                                                                                display: "flex",
                                                                                                            }}>
                                                                                                                {t('Home.Header.Modals.SPS.StrategicPlan.updateTitle')}
                                                                                                            </Typography>
                                                                                                            <Typography variant="body1" sx={{
                                                                                                                // color: "#4f747a" 
                                                                                                                // color: "#C0C0C0"
                                                                                                                color: "#696969",
                                                                                                                fontWeight: 300
                                                                                                            }}>
                                                                                                                {t('Home.Header.Modals.SPS.StrategicPlan.updateSubTitle')}
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                    </div>
                                                                                                ) :
                                                                                                    (columnName === "ViewInitiative") ? (
                                                                                                        <div style={{ display: "flex" }}>
                                                                                                            {/* <StartIcon style={{ marginRight: "10px", height: "35px", width: "35px" }} />
                                                                                                            <span>Update Initiative</span> */}
                                                                                                            <Box sx={{
                                                                                                                boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                                                                                                                width: 60,
                                                                                                                height: 60,
                                                                                                                borderRadius: 1.25,
                                                                                                                backgroundColor: "#fffefe",
                                                                                                                display: "flex",
                                                                                                                justifyContent: "center",
                                                                                                                alignItems: "center",
                                                                                                            }}>
                                                                                                                <EditIcon style={{ marginTop: "3px", color: "#4f747a" }} />
                                                                                                            </Box>
                                                                                                            <Box sx={{ ml: 3 }}>
                                                                                                                <Typography variant="h5" sx={{
                                                                                                                    color: "#3c6766",
                                                                                                                    fontWeight: 500,
                                                                                                                    display: "flex",
                                                                                                                }}>
                                                                                                                    {t('Home.Header.Modals.SPS.Initiative.updateTitle')}
                                                                                                                </Typography>
                                                                                                                <Typography variant="body1" sx={{
                                                                                                                    // color: "#4f747a" 
                                                                                                                    // color: "#C0C0C0"
                                                                                                                    color: "#696969",
                                                                                                                    fontWeight: 300
                                                                                                                }}>
                                                                                                                    {t('Home.Header.Modals.SPS.Initiative.updateSubTitle')}
                                                                                                                </Typography>
                                                                                                            </Box>
                                                                                                        </div>
                                                                                                    ) :
                                                                                                        (columnName === "ViewKPI") ? (
                                                                                                            <div style={{ display: "flex" }}>
                                                                                                                <Box sx={{
                                                                                                                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                                                                                                                    width: 60,
                                                                                                                    height: 60,
                                                                                                                    borderRadius: 1.25,
                                                                                                                    backgroundColor: "#fffefe",
                                                                                                                    display: "flex",
                                                                                                                    justifyContent: "center",
                                                                                                                    alignItems: "center",
                                                                                                                }}>

                                                                                                                    <StartIcon style={{ marginRight: "10px", height: "35px", width: "35px" }} />
                                                                                                                </Box>
                                                                                                                <Box sx={{ ml: 3 }}>
                                                                                                                    <Typography variant="h5" sx={{
                                                                                                                        color: "#3c6766",
                                                                                                                        fontWeight: 500,
                                                                                                                        display: "flex",
                                                                                                                    }}>
                                                                                                                        {t('Home.Header.Modals.SPS.KPI.updateTitle')}
                                                                                                                    </Typography>
                                                                                                                    <Typography variant="body1" sx={{
                                                                                                                        color: "#696969",
                                                                                                                        fontWeight: 300
                                                                                                                    }}>
                                                                                                                        {t('Home.Header.Modals.SPS.KPI.updateSubTitle')}
                                                                                                                    </Typography>
                                                                                                                </Box>

                                                                                                            </div>
                                                                                                        ) :
                                                                                                            (columnName === "ViewKPIValue") ? (
                                                                                                                <div style={{ display: "flex" }}>
                                                                                                                    <Box sx={{
                                                                                                                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                                                                                                                        width: 60,
                                                                                                                        height: 60,
                                                                                                                        borderRadius: 1.25,
                                                                                                                        backgroundColor: "#fffefe",
                                                                                                                        display: "flex",
                                                                                                                        justifyContent: "center",
                                                                                                                        alignItems: "center",
                                                                                                                    }}>
                                                                                                                        <StartIcon style={{ marginRight: "10px", height: "35px", width: "35px" }} />
                                                                                                                    </Box>

                                                                                                                    <Box sx={{ ml: 3 }}>
                                                                                                                        <Typography variant="h5" sx={{
                                                                                                                            color: "#3c6766",
                                                                                                                            fontWeight: 500,
                                                                                                                            display: "flex",
                                                                                                                        }}>
                                                                                                                            {t('Home.Header.Modals.SPS.KPIValue.updateTitle')}
                                                                                                                        </Typography>
                                                                                                                        <Typography variant="body1" sx={{
                                                                                                                            color: "#696969",
                                                                                                                            fontWeight: 300
                                                                                                                        }}>
                                                                                                                            {t('Home.Header.Modals.SPS.KPIValue.updateSubTitle')}
                                                                                                                        </Typography>
                                                                                                                    </Box>
                                                                                                                </div>
                                                                                                            ) :
                                                                                                                (columnName === "ViewGoalData") ? (
                                                                                                                    <div style={{ display: "flex" }}>
                                                                                                                        <Box sx={{
                                                                                                                            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                                                                                                                            width: 60,
                                                                                                                            height: 60,
                                                                                                                            borderRadius: 1.25,
                                                                                                                            backgroundColor: "#fffefe",
                                                                                                                            display: "flex",
                                                                                                                            justifyContent: "center",
                                                                                                                            alignItems: "center",
                                                                                                                        }}>

                                                                                                                            <AiOutlineAim style={{ marginRight: "10px", height: "35px", width: "35px" }} />
                                                                                                                        </Box>
                                                                                                                        <Box sx={{ ml: 3 }}>
                                                                                                                            <Typography variant="h5" sx={{
                                                                                                                                color: "#3c6766",
                                                                                                                                fontWeight: 500,
                                                                                                                                display: "flex",
                                                                                                                            }}>
                                                                                                                                {t('Home.Header.Modals.SPS.AddGoal.updateTitle')}
                                                                                                                            </Typography>
                                                                                                                            <Typography variant="body1" sx={{
                                                                                                                                color: "#696969",
                                                                                                                                fontWeight: 300
                                                                                                                            }}>
                                                                                                                                {t('Home.Header.Modals.SPS.AddGoal.updateSubTitle')}
                                                                                                                            </Typography>
                                                                                                                        </Box>
                                                                                                                    </div>
                                                                                                                ) :

                                                                                                                    (columnName === "ViewCloSoMapping") ? (
                                                                                                                        "Update CloSoMapping"
                                                                                                                    ) :
                                                                                                                        (columnName === "ViewManualScheduleCourse") ? (
                                                                                                                            "Update Schedule Course"
                                                                                                                        ) :
                                                                                                                            (columnName === "ViewSessionTypeData") ? (
                                                                                                                                "Update Session Type"
                                                                                                                            ) :
                                                                                                                                (columnName === "ViewAcademicYearData") ? (
                                                                                                                                    "Update Academic Year"
                                                                                                                                ) :
                                                                                                                                    (columnName === "ViewCourseCoordinatorData") ? (
                                                                                                                                        "Update Coures Co-ordinator"
                                                                                                                                    ) :
                                                                                                                                        (columnName === "ViewProjectTemplate") ? (
                                                                                                                                            "Update Project SubComponent"
                                                                                                                                        ) :
                                                                                                                                            (columnName === "ViewCoordinator") ? (
                                                                                                                                                "Update Coordinator"
                                                                                                                                            ) :
                                                                                                                                                (columnName === "ViewAllMeeting") ? (
                                                                                                                                                    "Meeting Detail"
                                                                                                                                                ) :
                                                                                                                                                    (columnName === "ViewSurvey") ? (
                                                                                                                                                        <div style={{ display: "flex" }}>
                                                                                                                                                            <DatasetLinkedIcon style={{ marginTop: "5px", marginRight: "10px" }} />
                                                                                                                                                            <span>Update Survey</span>
                                                                                                                                                        </div>

                                                                                                                                                    ) :
                                                                                                                                                        (columnName === "ViewLocation") ? (
                                                                                                                                                            <div style={{ display: "flex" }}>
                                                                                                                                                                <DatasetLinkedIcon style={{ marginTop: "5px", marginRight: "10px" }} />
                                                                                                                                                                <span>Update Location</span>
                                                                                                                                                            </div>

                                                                                                                                                        ) :
                                                                                                                                                            (columnName === "ViewUploadDocStatus") ? (
                                                                                                                                                                <div style={{ display: "flex" }}>
                                                                                                                                                                    <DatasetLinkedIcon style={{ marginTop: "5px", marginRight: "10px" }} />
                                                                                                                                                                    <span>Update Document Type</span>
                                                                                                                                                                </div>

                                                                                                                                                            ) :

                                                                                                                                                                (columnName === "ViewCreatedStudyPlan") ? (


                                                                                                                                                                    <div style={{ display: "flex" }}>
                                                                                                                                                                        <DatasetLinkedIcon style={{ marginTop: "5px", marginRight: "10px" }} />
                                                                                                                                                                        <span>Update Study Plan</span>
                                                                                                                                                                    </div>

                                                                                                                                                                ) :

                                                                                                                                                                    (columnName === "ViewCommittee") ? (


                                                                                                                                                                        <div style={{ display: "flex" }}>
                                                                                                                                                                            <Diversity3Icon style={{ marginTop: "3px", marginRight: "10px", color: "#4f747a" }} />
                                                                                                                                                                            <span>Update Committee</span>
                                                                                                                                                                        </div>

                                                                                                                                                                    ) :
                                                                                                                                                                        (columnName === "ViewCommitteeMember") ? (


                                                                                                                                                                            <div style={{ display: "flex" }}>
                                                                                                                                                                                <Diversity3Icon style={{ marginTop: "5px", marginRight: "10px" }} />
                                                                                                                                                                                <span>Update Committee</span>
                                                                                                                                                                            </div>

                                                                                                                                                                        ) :

                                                                                                                                                                            (columnName === "ViewAnnualProgramReport") ? (


                                                                                                                                                                                <div style={{ display: "flex" }}>
                                                                                                                                                                                    <DatasetLinkedIcon style={{ marginTop: "5px", marginRight: "10px" }} />
                                                                                                                                                                                    <span>Update Section in annual program report</span>
                                                                                                                                                                                </div>

                                                                                                                                                                            ) :

                                                                                                                                                                                (columnName === "ViewAssignTaskReport") ? (


                                                                                                                                                                                    <div style={{ display: "flex" }}>
                                                                                                                                                                                        <DatasetLinkedIcon style={{ marginTop: "5px", marginRight: "10px" }} />
                                                                                                                                                                                        <span>Update Task in annual program report</span>
                                                                                                                                                                                    </div>

                                                                                                                                                                                ) :




                                                                                                                                                                                    (
                                                                                                                                                                                        ""
                                                                                                                                                                                    )}
                        </Typography>
                        <Box>
                            {new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                            {/* <CancelIcon
                                sx={{
                                    // color: "#d5b254",
                                    ml: 2,
                                    color: "#332D2D",
                                    fontSize: "2rem",
                                    cursor: "pointer",
                                }}
                                onClick={() => setOpenUpdateTableModal(false)}
                            /> */}
                        </Box>
                    </Box>

                    <hr />

                    <Grid2
                        sx={{
                            mt: (windowDimensions.width < 400) ? 0 : 2,
                        }}
                        container
                        spacing={2}
                    >
                        <Grid2 xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* {modalContent} */}
                            <Box
                                className={styles.modalContent}
                                sx={{
                                    height: (columnName === "ViewUsers") ? "70vh" :
                                        (columnName === "ViewRoles") ? "fit-content" :
                                            (columnName === "ViewGroups") ? "auto" :
                                                (columnName === "ViewApps") ? "auto" :
                                                    (columnName === "ViewAppForm") ? "auto" :
                                                        (columnName === "ViewGroupRole") ? "auto" :
                                                            (columnName === "ViewUserGroup") ? "auto" :
                                                                (columnName === "ViewRoleApp") ? "auto" :
                                                                    (columnName === "ViewCollege") ? "auto" :
                                                                        (columnName === "ViewCampus") ? "auto" :
                                                                            (columnName === "ViewDepartment") ? "auto" :
                                                                                (columnName === "ViewProgram") ? "auto" :
                                                                                    (columnName === "ViewProgramType") ? "auto" :
                                                                                        (columnName === "ViewProgramObjective") ? "auto" :
                                                                                            (columnName === "ViewUniversity") ? "auto" :
                                                                                                (columnName === "ViewCourseDetails") ? "auto" :
                                                                                                    (columnName === "ViewCourseType") ? "auto" :
                                                                                                        (columnName === "ViewCourseObjective") ? "auto" :
                                                                                                            (columnName === "ViewCourseLearningOutcome") ? "auto" :
                                                                                                                (columnName === "ViewTopics") ? "auto" :
                                                                                                                    (columnName === "ViewCourseBook") ? "auto" :
                                                                                                                        (columnName === "ViewBook") ? "auto" :
                                                                                                                            (columnName === "ViewCloSoMapping") ? "auto" :
                                                                                                                                (columnName === "ViewManualScheduleCourse") ? "auto" :
                                                                                                                                    (columnName === "ViewSessionTypeData") ? "auto" :
                                                                                                                                        (columnName === "ViewAcademicYearData") ? "auto" :
                                                                                                                                            (columnName === "ViewCourseCoordinatorData") ? "auto" :
                                                                                                                                                (columnName === "ViewProjectTemplate") ? "auto" :
                                                                                                                                                    (columnName === "ViewCoordinator") ? "auto" :
                                                                                                                                                        (columnName === "ViewAllMeeting") ? "auto" :
                                                                                                                                                            (columnName === "ViewAssessments") ? "auto" :
                                                                                                                                                                (columnName === "ViewAssessmentType") ? "auto" :
                                                                                                                                                                    (columnName === "ViewStrategicPlan") ? "auto" :
                                                                                                                                                                        (columnName === "ViewInitiative") ? "auto" :
                                                                                                                                                                            (columnName === "ViewKPI") ? "auto" :
                                                                                                                                                                                (columnName === "ViewKPIValue") ? "auto" :
                                                                                                                                                                                    (columnName === "ViewGoalData") ? "auto" :
                                                                                                                                                                                        (columnName === "ViewSurvey") ? "auto" :
                                                                                                                                                                                            (columnName === "ViewLocation") ? "auto" :
                                                                                                                                                                                                "auto",
                                }}
                            >
                                {modalContent}
                            </Box>
                        </Grid2>
                    </Grid2>
                    <Box className={styles.buttonsContainer}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexDirection: "row",
                                width: (windowDimensions.width > 900) ? ("40%") : ("100%"),
                            }}
                        >

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                sx={{
                                    backgroundColor: "#d5b254",
                                    // textTransform: "none",
                                    fontWeight: "bold",
                                    height: 40,
                                    width: "200px",
                                    display: "flex",
                                    "&:hover": {
                                        backgroundColor: "#d5b254",
                                    },
                                }}
                                onClick={handleUpdate}
                            >
                                {/* {t('Home.Header.Modals.ChangePassword.policy.Buttons.ChangePassword')} */}
                                {(columnName === "ViewUsers") ? (
                                    "Update User"
                                ) : (columnName === "ViewRoles") ? (
                                    "Update Role"
                                ) : (columnName === "ViewGroups") ? (
                                    "Update Group"
                                ) : (columnName === "ViewApps") ? (
                                    "Update App"
                                ) : (columnName === "ViewAppForm") ? (
                                    "Update Record"
                                ) : (columnName === "ViewGroupRole") ? (
                                    "Update Record"
                                ) : (columnName === "ViewUserGroup") ? (
                                    "Update Record"
                                ) : (columnName === "ViewRoleApp") ? (
                                    "Update Record"
                                ) : (columnName === "ViewCollege") ? (
                                    "Update Record"
                                ) : (columnName === "ViewCampus") ? (
                                    "Update Record"
                                ) : (columnName === "ViewDepartment") ? (
                                    "Update Record"
                                ) : (columnName === "ViewProgram") ? (
                                    "Update Record"
                                ) : (columnName === "ViewProgramType") ? (
                                    "Update Record"
                                ) : (columnName === "ViewProgramObjective") ? (
                                    "Update Record"
                                )
                                    : (columnName === "ViewUniversity") ? (
                                        "Update Record"
                                    ) : (columnName === "ViewCourseDetails") ? (
                                        "Update Record"
                                    ) : (columnName === "ViewCourseType") ? (
                                        "Update Record"
                                    ) :
                                        (columnName === "ViewCourseObjective") ? (
                                            "Update Record"
                                        ) :
                                            (columnName === "ViewCourseLearningOutcome") ? (
                                                "Update Record"
                                            ) :
                                                (columnName === "ViewTopics") ? (
                                                    "Update Record"
                                                ) :
                                                    (columnName === "ViewCourseBook") ? (
                                                        "Update Record"
                                                    ) :
                                                        (columnName === "ViewBook") ? (
                                                            "Update Record"
                                                        ) :
                                                            (columnName === "ViewAssessments") ? (
                                                                "Update Record"
                                                            ) :
                                                                (columnName === "ViewAssessmentType") ? (
                                                                    "Update Record"
                                                                ) :
                                                                    (columnName === "ViewStrategicPlan") ? (
                                                                        "Update Record"
                                                                    ) :
                                                                        (columnName === "ViewInitiative") ? (
                                                                            "Update Record"
                                                                        ) :
                                                                            (columnName === "ViewKPI") ? (
                                                                                "Update Record"
                                                                            ) :
                                                                                (columnName === "ViewKPIValue") ? (
                                                                                    "Update Record"
                                                                                ) :
                                                                                    (columnName === "ViewGoalData") ? (
                                                                                        "Update Record"
                                                                                    ) :

                                                                                        (columnName === "ViewCloSoMapping") ? (
                                                                                            "Update Record"
                                                                                        ) :
                                                                                            (columnName === "ViewManualScheduleCourse") ? (
                                                                                                "Update Record"
                                                                                            ) :
                                                                                                (columnName === "ViewSessionTypeData") ? (
                                                                                                    "Update Record"
                                                                                                ) :
                                                                                                    (columnName === "ViewAcademicYearData") ? (
                                                                                                        "Update Record"
                                                                                                    ) :
                                                                                                        (columnName === "ViewCourseCoordinatorData") ? (
                                                                                                            "Update Record"
                                                                                                        ) :
                                                                                                            (columnName === "ViewProjectTemplate") ? (
                                                                                                                "Update Record"
                                                                                                            ) :
                                                                                                                (columnName === "ViewCoordinator") ? (
                                                                                                                    "Update Record"
                                                                                                                ) :
                                                                                                                    (columnName === "ViewSurvey") ? (
                                                                                                                        "Update Record"
                                                                                                                    ) :
                                                                                                                        (columnName === "ViewLocation") ? (
                                                                                                                            "Update Location"
                                                                                                                        ) :
                                                                                                                            (columnName === "ViewUploadDocStatus") ? (
                                                                                                                                "Update Record"
                                                                                                                            ) :
                                                                                                                                (columnName === "ViewCreatedStudyPlan") ? (
                                                                                                                                    "Update Record"
                                                                                                                                ) :
                                                                                                                                    (columnName === "ViewCommittee") ? (
                                                                                                                                        "Update Record"
                                                                                                                                    ) :
                                                                                                                                        (columnName === "ViewCommitteeMember") ? (
                                                                                                                                            "Update Record"
                                                                                                                                        ) :
                                                                                                                                            (columnName === "ViewAnnualProgramReport") ? (
                                                                                                                                                "Update Record"
                                                                                                                                            ) :
                                                                                                                                                (columnName === "ViewAssignTaskReport") ? (
                                                                                                                                                    "Update Record"
                                                                                                                                                ) :



                                                                                                                                                    // (columnName === "ViewAllMeeting") ? (
                                                                                                                                                    //     "Update Record"
                                                                                                                                                    // ) :


                                                                                                                                                    ""
                                }
                            </Button>
                            <Button
                                fullWidth
                                variant="outlined"
                                color="error"
                                size="large"
                                className={styles.cancelButton}
                                sx={{
                                    color: "black",
                                    // fontWeight: "bold",
                                    height: 40,
                                    width: "100px",
                                    '&.MuiButton-outlined': {
                                        border: '2px solid #d5b254',
                                    },
                                    '&:hover': {
                                        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)', // Add the shadow effect on hover
                                    },
                                }}
                                onClick={handleCloseUpdateModal}
                            >
                                {t('Home.Header.Modals.ChangePassword.policy.Buttons.Cancel')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
export default EditTableModal;