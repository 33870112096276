interface CommitteeReducerState {
    committee: any | null;
  }
  
  const initialState: CommitteeReducerState = {
    committee: null,
  };

  const committeeReducer = (state = initialState, action: any): CommitteeReducerState => {
    switch (action.type) {
        case 'COMMITTEE':
            return {
                ...state,
                committee: action.payload.data,
            };
        default:
            return state;
    }
};

export default committeeReducer;