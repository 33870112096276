import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css";

const QmsDashboard = lazy(() => import("../Components/Dashboard"));
const CollegeQms = lazy(() => import("../Components/College/CollegeQms"));
const AddCollegeQMS = lazy(() => import("../Components/College/AddCollegeQMS"));
const ViewCollegeQMS = lazy(() => import("../Components/College/ViewCollegeQMS"));
const UpdateCollegeQMS = lazy(() => import("../Components/College/UpdateCollegeQMS"));
const ManageProgramCoordinatorQMS = lazy(() => import("../Components/College/ManageProgramCoordinatorQMS"));
const UniversityQmsReviewer = lazy(() => import("../Components/University/UniversityQmsReviewer"));
const ViewUniversityQMSReviewer = lazy(() => import("../Components/University/ViewUniversityQMSReviewer"));
const UpdateUniversityQMSReviewer = lazy(() => import("../Components/University/UpdateUniversityQMSReviewer"));
const UniversityQmsAdmin = lazy(() => import("../Components/UniversityQmsAdmin/UniversityQmsAdmin"));
const UniversityCollegeQMSAdmin = lazy(() => import("../Components/UniversityQmsAdmin/UniversityCollegeQmsAdmin"));
const UniversityProgramQmsAdmin = lazy(() => import("../Components/UniversityQmsAdmin/UniversityProgramQmsAdmin"));
const ManageTask = lazy(() => import("../Components/UniversityQmsAdmin/ManageTask"));
const AssignTaskQmsAdmin = lazy(() => import("../Components/UniversityQmsAdmin/AssignTask"));
const AnnouncementQmsAdmin = lazy(() => import("../Components/UniversityQmsAdmin/AnnouncementQmsAdmin"));
const AddAnnouncementQmsAdmin = lazy(() => import("../Components/UniversityQmsAdmin/AddAnnouncementQmsAdmin"));

interface SpsRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    // courseCodeProps: any,
    // openUniversityModal: boolean,
    // setOpenUniversityModal: any,
    openAddInitiativeModal: any,
    setOpenAddInitiativeModal: any,
    openAddGoalModal: any,
    setOpenAddGoalModal: any,
    openAddStrategicPlanModal: boolean,
    setOpenAddStrategicPlanModal: any
}

const QmsRouter: FC<SpsRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,
    openAddInitiativeModal,
    setOpenAddInitiativeModal,
    openAddGoalModal,
    setOpenAddGoalModal,
    openAddStrategicPlanModal,
    setOpenAddStrategicPlanModal

    // courseCodeProps

    // openUniversityModal,
    // setOpenUniversityModal,

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
            <Routes>
                {/* <Route path="portal"> */}
                <Route
                    path="view"
                    element={
                        <QmsDashboard
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />
                {/* </Route> */}

                <Route path="dashboard">
                    <Route
                        path="view"
                        element={
                            <QmsDashboard
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="collegeQMS">
                    <Route
                        path="view"
                        element={
                            <CollegeQms
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="addCollegeQms">
                    <Route
                        path="view"
                        element={
                            <AddCollegeQMS
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>
                <Route path="viewCollegeQms">
                    <Route
                        path="view/:index"
                        element={
                            <ViewCollegeQMS
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="updateCollegeQms">
                    <Route
                        path="view"
                        element={
                            <UpdateCollegeQMS
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="manageProgramCoordinatorQms">
                    <Route
                        path="view"
                        element={
                            <ManageProgramCoordinatorQMS
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="universityQmsReviewer">
                    <Route
                        path="view"
                        element={
                            <UniversityQmsReviewer
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="viewUniversityQmsReviewer">
                    <Route
                        path="view"
                        element={
                            <ViewUniversityQMSReviewer
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>
                <Route path="updateUniversityQmsReviewer">
                    <Route
                        path="view"
                        element={
                            <UpdateUniversityQMSReviewer
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="universityQmsAdmin">
                    <Route
                        path="view"
                        element={
                            <UniversityQmsAdmin
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>
                <Route path="universityCollegeQmsAdmin">
                    <Route
                        path="view"
                        element={
                            <UniversityCollegeQMSAdmin
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>
                <Route path="universityProgramQmsAdmin">
                    <Route
                        path="view"
                        element={
                            <UniversityProgramQmsAdmin
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="task">
                    <Route
                        path="view"
                        element={
                            <ManageTask
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="assignTaskQmsAdmin">
                    <Route
                        path="view"
                        element={
                            <AssignTaskQmsAdmin
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="announcement">
                    <Route
                        path="view"
                        element={
                            <AnnouncementQmsAdmin
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="addAnnouncementQmsAdmin">
                    <Route
                        path="view"
                        element={
                            <AddAnnouncementQmsAdmin
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                // isMinified={isMinified}
                                // setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


            </Routes>
        </Suspense>
    )

}

export default QmsRouter;
