import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    Typography,
    FormControlLabel,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import courseJson from "../../../../../../courseCode.json"

import readAPI from '../../../../data/api/read';
import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";

interface CourseNameOption {
    label: string;
    value: string;
}

interface ViewAllCampusData {
    timestamp: string;
    transactionId: string;
    status: string;
    code: string;
    message: string[];
    obj: {
        createdBy: string;
        creationDateAndTime: number[];
        updatedBy: string | null;
        updateDateAndTime: number[] | null;
        id: string;
        courseCode: string;
        courseObjectiveId: string;
        description: string;
        active: boolean;
    }[];
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    // setFetchUpdate: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateCourseObjective = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        // setFetchUpdate
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });



        // Fetching data using axios

        const [viewAllCampusData, setViewAllCampusData] = useState<ViewAllCampusData | null>(null);

        const [CourseName, setCourseName] = useState<any>([]);

        const [courseCode, setCourseCode] = useState<string>(originalValues.courseCode);
        const [courseObjectiveId, setCourseObjectiveId] = useState<string>(originalValues.courseObjectiveId);
        const [description, setDescription] = useState<string>(originalValues.description);

        const [isChecked, setIsChecked] = useState(true);

        // For handling errors

        const [courseCodeError, setCourseCodeError] = useState<boolean>(false);
        const [courseObjectiveIdIdError, setCourseObjectiveIdError] = useState<boolean>(false);
        const [descriptionError, setDescriptionError] = useState<boolean>(false);


        // For handling error messages
        // const [courseCodeErrorMessage, setCourseCodeErrorMessage] = useState("");



        const courseNameOptions: CourseNameOption[] = CourseName.map((course: any) => ({
            label: `${course.courseCode} : ${course.courseName} `,
            value: course.courseCode
        }));
        const handleCourseCodeChange = (event: any, newValue: any) => {
            setCourseCode(newValue ? newValue.value : null);
        };
        const [selectedCourseCode, setSelectedCourseCode] = useState<string | null>(null);

        const handleCourseObjectiveChange = (event: any, newValue: any) => {
            setCourseObjectiveId(newValue ? newValue.courseObjectiveId : null);
        };

        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }
            if (accessToken !== null) {
                // @1) Fetching Users
                axios.get(readAPI.Campus, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        if (res.data.code === "200.200") {
                            setViewAllCampusData(res.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                axios.get(readAPI.Courses, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setCourseName(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    if (courseCode === "") {
                        setCourseCodeError(true);
                    }
                    if (courseObjectiveId === "") {
                        setCourseObjectiveIdError(true)
                    }
                    if (!textRegex.test(courseObjectiveId) && courseObjectiveId.length == 0) {
                        setCourseObjectiveIdError(true);
                    } else {
                        setCourseObjectiveIdError(false);
                    }
                    if (description === "") {
                        setDescriptionError(true)
                    }
                    if (!textRegex.test(description) && description.length == 0) {
                        setDescriptionError(true);
                    } else {
                        // setDescriptionErrorMessage("")
                    }
                    // Set the validation errors

                    if (courseCode !== "" && courseObjectiveId !== "" && description !== "") {
                        const formState = [{
                            "id": originalValues.id,
                            "courseCode": courseCode,
                            "courseObjectiveId": courseObjectiveId,
                            "description": description,
                            "active": isChecked
                        }]

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `${courseObjectiveId} data has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>

                                <Autocomplete
                                    id="courseCodeComplete"
                                    options={courseNameOptions}
                                    getOptionLabel={option => option.label}
                                    value={courseNameOptions.find((option: any) => option.value === courseCode) || null}
                                    onChange={handleCourseCodeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.CourseBook.policy.Inputs.courseCode.label')}`}
                                            placeholder={`${t('Home.Header.Modals.CourseBook.policy.Inputs.courseCode.placeholder')}`}
                                            variant="standard"
                                            // helperText={(coursePrerequisiteErrorMessage) ? ("* Please select any College.") : ("")}
                                            error={courseCodeError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />




                            </Grid>
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12} sx={{mt: 2}}>
                                <Autocomplete
                                    value={{ courseObjectiveId }}
                                    onChange={handleCourseObjectiveChange}
                                    options={courseJson.courseObjective}
                                    getOptionLabel={(option: any) => option.courseObjectiveId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder={`${t('Home.Header.Modals.CourseObjective.policy.Inputs.courseObjectiveId.placeholder')}`}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="descriptionTextField"
                                    label={t('Home.Header.Modals.CourseObjective.policy.Inputs.description.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseObjective.policy.Inputs.description.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={descriptionError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={description}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (descriptionError) {
                                            setDescriptionError(false);
                                        }
                                        setDescription(e.target.value);
                                    }}
                                />
                            </Grid>


                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }
                                />

                                {/* <Switch
                                    checked={isChecked}
                                    onChange={handleSwitchChange}
                                    inputProps={{ 'aria-label': 'Toggle' }}
                                /> */}
                            </Grid>



                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCourseObjective;