import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//Importing useTranslation and Trans from react-i18next
import { useTranslation } from 'react-i18next';

import {
    Alert,
    TextField
} from '@mui/material';

import SnackBar from "../../Components/SnackBar";

// Importing Logo
import logo from '../../assets/Images/Login/login_logo.png';
import forgetPassword_logo from '../../assets/Images/ForgetPassword/lock.png';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Importing CSS
import styles from './style.module.css';
import { InfoOutlined } from '@mui/icons-material';
import createAPI from '../../Data/API/CREATE';
import axios from 'axios';

interface ResetPasswordProps {
    setShowHeader: any;
    currentLang: string;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ setShowHeader, currentLang }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    ///////////////////////////////// Snackbar State /////////////////////////////////
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    ///////////////////////////////// Snackbar State /////////////////////////////////

    useEffect(() => {
        // The current location.
        // console.clear();
        // console.log('The current location is: ', location.pathname);
        const url = location.pathname;

        if ((url === '/login' || url === '/' || url === '/resetPassword')) {
            setShowHeader(true);
        } else {
            setShowHeader(false);
        }
    }, [location, setShowHeader]);

    const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
    const [newPassword, setNewPassword] = useState<any>("");
    const [confirmNewPassword, setConfirmNewPassword] = useState<any>("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState<boolean>(false);

    const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState("");
    const [severity, setSeverity] = useState<any>();

    const query = new URLSearchParams(location.search);
    const p = query.get('p');
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const handleClickShowConfirmNewPassword = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };
    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value);
    };
    const handleConfirmNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmNewPassword(event.target.value);
    };

    const handleCloseAlert = () => {
        setNewPasswordError(false);
    }

    const handleLinkClick = (event: any) => {
        event.preventDefault();
        navigate("/forgetPassword");
    };

    const renderErrorMessage = () => {
        if (newPasswordErrorMessage.includes("click here")) {
            const parts = newPasswordErrorMessage.split('click here');
            return (
                <>
                    {parts[0]}
                    <a href="/forgetPassword" onClick={handleLinkClick}>click here</a>
                    {parts[1]}
                </>
            );
        }
        return newPasswordErrorMessage;
    };

    const enterAllFields = t('resetPassword.errorMessage.enterAllFields');
    const newAndConfirm = t('resetPassword.errorMessage.newAndConfirm');
    const validPassword = t('resetPassword.errorMessage.validPassword');
    const pNotAvailable = t('resetPassword.errorMessage.pNotAvailable');
    const success = t('resetPassword.errorMessage.success');
    const error = t('resetPassword.errorMessage.error');
    const handleResetPassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (newPassword === "" || confirmNewPassword === "" || newPassword === null || confirmNewPassword === null) {
            setNewPasswordError(true);
            setNewPasswordErrorMessage(enterAllFields);
            setSeverity("warning");
            return;
        }
        if (newPassword !== confirmNewPassword) {
            setNewPasswordError(true);
            setNewPasswordErrorMessage(newAndConfirm);
            setSeverity("warning");
            return;
        }
        if (!passwordRegex.test(newPassword)) {
            setNewPasswordError(true);
            setNewPasswordErrorMessage(validPassword);
            setSeverity("warning");
            return;
        }
        if (p === "" || p === null) {
            setNewPasswordError(true);
            setNewPasswordErrorMessage(pNotAvailable);
            setSeverity("error");
        }
        else {
            setNewPasswordError(false);
            setIsLoadingSpinner(true)
            const formState = {
                "newPassword": newPassword,
                "confirmNewPassword": confirmNewPassword
            }
            axios.post(`${createAPI.ResetPassword}`,
                formState
                , {
                    headers: {
                        'x-api-key': p
                    }
            })
                .then((response) => {
                    if (response.data.code === "200.200") {
                        setNewPasswordError(true);
                        setNewPasswordErrorMessage(success);
                        setSeverity("success");

                        setTimeout(() => {
                            setIsLoadingSpinner(false)
                            navigate("/");
                        }, 3000)
                    }
                })
                .catch((error) => {
                    setNewPasswordError(true);
                    setNewPasswordErrorMessage(error);
                    setSeverity("error");
                });
        }
    };

    

    return (
        <div className={styles.containerCustom}>
            <div className={styles.forgetPasswordContainer}>
                <form className={styles.loginContainer} action="return false">
                    <div className={styles.logo_mobile_container}>
                        <img
                            className={styles.logo}
                            src={logo}
                            alt={`${t('forgetPassword.img.img1.alt')}`}
                            title={`${t('forgetPassword.img.img1.title')}`}
                        />
                    </div>
                    <div>
                        <img
                            className={styles.logoLock}
                            src={forgetPassword_logo}
                            alt={`${t('forgetPassword.img.img2.alt')}`}
                            title={`${t('forgetPassword.img.img2.title')}`}
                        />
                    </div>
                    <h2 className={styles.titleForgetPassword}>{t('resetPassword.title')}</h2>
                    <p className={styles.infoForgotPassword}>{t('resetPassword.subTitle')}</p>
                    <section className={styles.form_inputs}>

                        <div className={styles.tooltipContainer}>
                            <IconButton className={styles.icon}>
                                <InfoOutlined style={{ height: "35px", width: "35px" }} />
                            </IconButton>
                            <div className={styles.tooltipContent}>
                                <ul>
                                    <li>1. Password must be at least 8 characters long.</li>
                                    <li>2. Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.</li>
                                    <li>3. Password must not contain any spaces.</li>
                                </ul>
                            </div>
                        </div>
                        <div>

                            <br />
                            <TextField
                                id="standard-basic"
                                className={styles.newPassword}
                                label={t('resetPassword.formInputs.newPassword.label')}
                                variant="standard"
                                type={showNewPassword ? 'text' : 'password'}
                                value={newPassword}
                                onChange={handleNewPasswordChange}
                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                sx={{
                                    width: '84%',
                                    ml: "8%",
                                    marginBottom: "10px",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                    }
                                }}
                                placeholder={`${t('resetPassword.formInputs.newPassword.placeHolder')}`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                edge="end"
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                id="standard-basic"
                                className={styles.confirmNewPassword}
                                label={t('resetPassword.formInputs.confirmNewPassword.label')}
                                variant="standard"
                                type={showConfirmNewPassword ? 'text' : 'password'}
                                value={confirmNewPassword}
                                onChange={handleConfirmNewPasswordChange}
                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                sx={{
                                    width: '84%',
                                    ml: "8%",
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#E5E5E5',
                                        },
                                    }
                                }}
                                placeholder={`${t('resetPassword.formInputs.confirmNewPassword.placeHolder')}`}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmNewPassword}
                                                edge="end"
                                            >
                                                {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />


                        </div>

                        <button
                            type='button'
                            className={`btn ${styles.btn_resetPassword}`}
                            onClick={handleResetPassword}
                        >
                            {t('resetPassword.formInputs.btnResetPassword')}
                        </button>
                        {newPasswordError ?
                            <Alert severity={severity} onClose={() => { handleCloseAlert() }}>
                                {renderErrorMessage()}
                            </Alert> : ""
                        }

                        <p style={{ textAlign: "center" }}>
                            {isLoadingSpinner ? (<i className="fa fa-spinner fa-spin fa-2x"></i>) : ""}
                        </p>

                    </section>


                </form>
                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />
            </div>
        </div>
    )
}
export default ResetPassword;