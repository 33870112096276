import * as React from 'react';
import { Modal, Box, TableContainer, Table, Typography, TableHead, TableRow, TableCell, TableBody, Checkbox, TextField, Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./style.module.css"
import { MdChecklist } from "react-icons/md";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';


interface ViewGoalModalProps {
    open: any,
    handleClose: any,
    handleCheckboxKpiChange: any,
    // checkedKPIs: any,
    selectedKpi: any
}



const CustomKpiModal: React.FC<ViewGoalModalProps> = ({
    open, handleClose, handleCheckboxKpiChange, selectedKpi
}) => {

    const kpiNames = useSelector((state: RootState) => state.sps.strategicPlanDetailed[0]?.data?.kpiDetails);

    return (

        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <Box sx={{
                    // border: "1px solid red",
                    display: "flex"
                    // alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                    // flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                }}>
                    <Box sx={{
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                        width: 60,
                        height: 60,
                        borderRadius: 1.25,
                        backgroundColor: "#fffefe",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <MdChecklist style={{ height: "35px", width: "35px" }} />
                    </Box>
                    <Box sx={{ ml: 3, mt: 2 }}>
                        <Typography variant="h5" sx={{
                            color: "#3c6766",
                            fontWeight: 500
                        }}>
                            {/* {t('Home.Header.Modals.SPS.AddGoal.subTitle')} */}
                            List of KPI
                        </Typography>
                    </Box>
                </Box>

            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <TableContainer style={{ border: "1px solid grey", paddingLeft: "0px", paddingRight: "0px" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: "50px" }}>Action</TableCell>
                                <TableCell style={{ width: "400px" }}>KPI Name</TableCell>
                                <TableCell>KPI Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {kpiNames?.map((item: any, index: any) => (
                                <TableRow key={index} className={styles.tableRow}>
                                    <TableCell>
                                        {/* <Checkbox
                                            name={`kpi${index + 1}`}
                                            checked={checkedKPIs[`kpi${index + 1}`] || false}
                                            onChange={handleCheckboxKpiChange}
                                        /> */}
                                        <Checkbox
                                            checked={selectedKpi.includes(item)}
                                            onChange={() => handleCheckboxKpiChange(item)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {item.kpiName}
                                        {/* KPI {index + 1} */}
                                    </TableCell>
                                    <TableCell>{item.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>

    );
};


export default CustomKpiModal;
