import axios from 'axios';
import readAPI from '../data/api/read';
import { IBinListType, IDirectoryList, IFileList, IDirectoryListById, IRecentListType, IUserStorage, IOwnerType, IModifiedType, ISearchFileType, IStarredListType, IFileInformation } from '../model/EqaDrive';
import createAPI from '../data/api/create';

export type DirectoryListType = {
    data: IDirectoryList[],
    errMessage: string;
}

export type DirectoryListTypeById = {
    data: IDirectoryListById[],
    errMessage: string;
}
export type FileListType = {
    data: IFileList[],
    errMessage: string
}

export type BinListType = {
    data: IBinListType[],
    errMessage: string
}

export type RecentListType = {
    data: IRecentListType[],
    errMessage: string
}

export type StarredListType = {
    data: IStarredListType[],
    errMessage: string
}

export type UserStorageListType = {
    data: IUserStorage[],
    errMessage: string
}

export type SearchFileListType = {
    data: ISearchFileType[],
    errMessage: string
}

export type OwnerListType = {
    data: IOwnerType[],
    errMessage: string
}

export type ModifiedListType = {
    data: IModifiedType[],
    errMessage: string
}

export type FileInformationListType = {
    data: IFileInformation[],
    errMessage: string
}


//@function for fetching all getDirectoryList
async function getDirectoryList(accessToken: string): Promise<DirectoryListType> {
    let url: string = readAPI.getDirectoryList;
    let header: string = accessToken;
    let diretoryListType: DirectoryListType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            diretoryListType.data = res.data.obj.subDirectories[0].subDirectories;
            if (res.data.code !== "200") {
                diretoryListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            diretoryListType.errMessage = err.message;
        })
    return diretoryListType;
}


//@function for fetching file list
async function getFileList(accessToken: string, page: any): Promise<FileListType> {
    let url: string = readAPI.getFileList;
    let header: string = accessToken;
    let fileListType: FileListType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}pageNumber=${page}&pageSize=100`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            fileListType.data = res.data.obj;
            if (res.data.code !== "200") {
                fileListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            fileListType.errMessage = err.message;
        })
    return fileListType;
}


//@function for fetching bin list
async function getBinList(accessToken: string): Promise<BinListType> {
    let url: string = readAPI.getBinList;
    let header: string = accessToken;
    // let binListType: BinListType = {
    //     data: [],
    //     errMessage: ""
    // }
    // await axios.get(`${url}`, {
    //     headers: {
    //         "x-api-key": header
    //     }
    // })
    //     .then((res) => {
    //         binListType.data = res.data.obj;
    //         if (res.data.code !== "200") {
    //             binListType.errMessage = res.data.error;
    //         }
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //         binListType.errMessage = err.message;
    //     })
    // return binListType;

    try {
        const response = await axios.get(`${url}`, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for fetching recent list
async function getRecentList(accessToken: string, page: any): Promise<RecentListType> {
    let url: string = readAPI.getRecentList;

    try {
        const response = await axios.get(`${url}pageNumber=${page}&pageSize=3`, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for fetching starred list
async function getStarredList(accessToken: string) {
    let url: string = readAPI.getStarredList;
    let header: string = accessToken;
    // let starredListType: StarredListType = {
    //     data: [],
    //     errMessage: ""
    // }
    // await axios.get(`${url}`, {
    //     headers: {
    //         "x-api-key": header
    //     }
    // })
    //     .then((res) => {
    //         starredListType.data = res.data.obj;
    //         if (res.data.code !== "200") {
    //             starredListType.errMessage = res.data.error;
    //         }
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //         starredListType.errMessage = err.message;
    //     })
    // return starredListType;

    try {
        const response = await axios.get(`${url}`, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for download file
async function downloadFile(accessToken: string, fileId: any) {
    let url: string = createAPI.DownloadFiles;
    let header: string = accessToken;

    try {
        const formData = {
            "dirId": [],
            "fileId": [fileId]
        };
        const response = await axios.post(`${url}`, formData, {
            headers: {
                'x-api-key': header,
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
        const fileBlob = new Blob([response.data]);
        const fileUrl = URL.createObjectURL(fileBlob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'downloaded_file.zip');
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileUrl);

    } catch (error) {
        console.error('Error downloading file:', error);
    }
}

async function downloadFiless(accessToken: string, fileId: any, fileName: any) {
    const url = createAPI.DownloadFiles;
    const header = accessToken;
    const parts = fileName.split('.');
    const fileExtension = parts[parts.length - 1];
    try {
        const formData = {
            dirId: [],
            fileId: [fileId]
        };
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': header,
                'Content-Type': 'application/json'
            },
            responseType: 'blob' // Set the response type to blob
        });
        const fileBlob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(fileBlob);
        link.setAttribute('download', `file_${fileId}.${fileExtension}`); 
        document.body.appendChild(link);
        link.click();

        // Cleanup
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);

    } catch (error) {
        console.error('Error downloading file:', error);
    }
}




//@function for download folder
async function downloadFolder(accessToken: string, dirId: any) {
    let url: string = createAPI.DownloadFiles;
    let header: string = accessToken;

    try {
        const formData = {
            "dirId": [dirId],
            "fileId": []
        };
        const response = await axios.post(`${url}`, formData, {
            headers: {
                'x-api-key': header,
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        });
        const fileBlob = new Blob([response.data]);
        const fileUrl = URL.createObjectURL(fileBlob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'downloaded_file.zip');
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(fileUrl);

    } catch (error) {
        console.error('Error downloading file:', error);
    }
}


//@function for make a copy
async function makeACopy(accessToken: string, fileId: any) {
    let url: string = createAPI.MakeACopy;
    const formData = {
        "fileId": [fileId]
    }
    // axios.put(`${url}`,
    //     formData
    //     , {
    //         headers: {
    //             'x-api-key': accessToken
    //         }
    //     })
    //     .then(function (response) {
    //         if (response.data.code == 201) {
    //             resMessage = response.data.message[0];
    //         }
    //     })
    //     .catch(function (error: any) {
    //         console.log('error:', error);
    //     });
    //     return resMessage;

    try {
        const response = await axios.put(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 201) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for file move to bin
async function moveToBin(accessToken: string, fileId: any) {
    let url: string = createAPI.MoveToBin;
    const formData = {
        "fileIds": fileId,
        "dirIds": []
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for rename file
async function renameFile(accessToken: string, fileId: any, fileName: any) {
    let url: string = createAPI.RenameFileName;
    let header: string = accessToken;
    let renameMessage: any = {
        data: [],
        errMessage: ""
    }

    try {
        const formData = {
            "fileId": fileId,
            "title": fileName
        }
        const response = await axios.put(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for rename directory
async function renameDirectory(accessToken: string, fileId: any, fileName: any) {
    let url: string = createAPI.RenameDirectoryName;
    let header: string = accessToken;
    let renameMessage: any = {
        data: [],
        errMessage: ""
    }

    try {
        const formData = {
            "dirId": fileId,
            "dirName": fileName
        }
        const response = await axios.patch(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for restore
async function restore(accessToken: string, fileId: any) {
    let url: string = createAPI.Restore;
    const formData = {
        "fileIds": [fileId],
        "dirIds": []
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for delete forever
async function deleteForever(accessToken: string, fileId: any) {
    let url: string = createAPI.DeleteForever;
    const formData = {
        "fileIds": [fileId],
        "dirIds": []
    }

    try {
        const response = await axios.delete(url, {
            data: formData,
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for delete all forever
async function deleteAllForever(accessToken: string) {
    let url: string = createAPI.DeleteAllForever;
    try {
        const response = await axios.delete(url, {
            headers: {
                'x-api-key': accessToken
            }
        });
        if (response.status === 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for create directory
async function createDirectory(accessToken: string, folderName: any, path: any) {
    let url: string = createAPI.CreateDirectory;
    const formData = {
        dirPath: `${path}/${folderName}`
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 201) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for create directory
async function createDirectoryHome(accessToken: string, folderName: any) {
    let url: string = createAPI.CreateDirectory;
    const formData = {
        dirPath: `/${folderName}`
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 201) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for directory move to bin
async function moveToBinDirectory(accessToken: string, dirIds: any) {
    let url: string = createAPI.MoveToBinDirectory;
    const formData = {
        "fileIds": [],
        "dirIds": [dirIds]
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for add to starred
async function addToStarred(accessToken: string, fileId: any) {
    let url: string = createAPI.AddToStarred;
    const formData = {
        "fileId": [fileId],
        "dirId": [],
        "mark": true
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 201) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for add to starred directory
async function addToStarredDir(accessToken: string, dirId: any) {
    let url: string = createAPI.AddToStarred;
    const formData = {
        "fileId": [],
        "dirId": [dirId],
        "mark": true
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 201) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for remove from starred
async function removeFromStarred(accessToken: string, fileId: any) {
    let url: string = createAPI.RemoveFromStarred;
    const formData = {
        "fileId": [fileId],
        "dirId": [],
        "mark": false
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.status === 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for change directory color
async function changeDirectoryColor(accessToken: string, dirId: any, colorCode: any) {
    let url: string = createAPI.ChangeDirectoryColor;

    try {
        const formData = {
            "dirIds": [dirId],
            "colorCode": colorCode
        }
        const response = await axios.put(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for lock file
async function lockFile(accessToken: string, fileId: any) {
    let url: string = createAPI.LockFile;
    try {
        const response = await axios.get(`${url}${fileId}?lock=true`, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for fetching user storage
async function getUserStorage(accessToken: string): Promise<UserStorageListType> {
    let url: string = readAPI.getUserStorage;
    let header: string = accessToken;
    let userStorageListType: UserStorageListType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            userStorageListType.data = res.data.obj;
            if (res.data.code !== "200") {
                userStorageListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            userStorageListType.errMessage = err.message;
        })
    return userStorageListType;
}


//@function for search files
async function searchFun(accessToken: string, searchTerm: any): Promise<FileListType> {
    let url: string = createAPI.SearchFile;
    let searchFileListType: FileListType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}=${searchTerm}`, {
        headers: {
            "x-api-key": accessToken
        }
    })
        .then((res) => {
            searchFileListType.data = res.data.obj;
            if (res.data.code !== "200") {
                searchFileListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            searchFileListType.errMessage = err.message;
        })
    return searchFileListType;
}


//@function for fetching owner
async function getOwner(accessToken: string): Promise<OwnerListType> {
    let url: string = readAPI.getOwnerList;
    let header: string = accessToken;
    let ownerListType: OwnerListType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            ownerListType.data = res.data.obj;
            if (res.data.code !== "200") {
                ownerListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            ownerListType.errMessage = err.message;
        })
    return ownerListType;
}


//@function for fetching modified
async function getModifiedList(accessToken: string): Promise<ModifiedListType> {
    let url: string = readAPI.getModifiedList;
    let header: string = accessToken;
    let modifiedListType: ModifiedListType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            modifiedListType.data = res.data.obj;
            if (res.data.code !== "200") {
                modifiedListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            modifiedListType.errMessage = err.message;
        })
    return modifiedListType;
}

//@function for fetching share list
async function getShareList(accessToken: string, email: any): Promise<RecentListType> {
    let url: string = readAPI.getShareList;

    try {
        const response = await axios.get(`${url}`, {
            headers: {
                'email': email,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === 'EQA-DIR.200') {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for share file
async function shareFile(accessToken: string, fileId: any, singleEmail: any, emailId: any) {
    let url: string = createAPI.ShareFile;
    const formData = {
        "fileIds": [fileId],
        "dirIds": [],
        "shareWithEmails": emailId
    }

    try {

        const response = await axios.post(url, formData, {
            headers: {
                'email': singleEmail,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === 'EQA-DIR.3001') {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for share directory
async function shareDirectory(accessToken: string, dirId: any, singleEmail: any, emailId: any) {
    let url: string = createAPI.ShareFile;
    const formData = {
        "fileIds": [],
        "dirIds": [dirId],
        "shareWithEmails": emailId
    }

    try {

        const response = await axios.post(url, formData, {
            headers: {
                'email': singleEmail,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === 'EQA-DIR.3001') {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for fetching file type
async function getFileType(accessToken: string, email: any): Promise<RecentListType> {
    let url: string = readAPI.getFileType;

    try {
        const response = await axios.get(`${url}`, {
            headers: {
                'email': email,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === '200') {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for fetching people
async function getPeople(accessToken: string, email: any): Promise<RecentListType> {
    let url: string = readAPI.getPeople;

    try {
        const response = await axios.get(`${url}`, {
            headers: {
                'email': email,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === '200') {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for fetching modified
async function getModified(accessToken: string, email: any): Promise<RecentListType> {
    let url: string = readAPI.getModified;

    try {
        const response = await axios.get(`${url}`, {
            headers: {
                'email': email,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === '200') {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for fetching people
async function getLocation(accessToken: string, email: any): Promise<RecentListType> {
    let url: string = readAPI.getLocation;

    try {
        const response = await axios.get(`${url}`, {
            headers: {
                'email': "shabbir@datadimens.com",
                'x-api-key': accessToken
            }
        });

        if (response.data.code === 'EQA-DIR.200') {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for global search
async function globalSearch(accessToken: string, emailId: any, type: any) {
    let url: string = createAPI.globalSearch;
    const formData = {
        "type": type
    }

    try {

        const response = await axios.post(url, formData, {
            headers: {
                'email': emailId,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === '200') {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


async function globalSearchType(accessToken: string, type: any) {
    let url: string = readAPI.globalSearchType;

    try {

        const response = await axios.get(`${url}${type}`, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code === '200') {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

async function globalSearchPeople(accessToken: string, emailId: any, type: any) {
    let url: string = createAPI.globalSearch;
    const formData = {
        "people": type
    }

    try {

        const response = await axios.post(url, formData, {
            headers: {
                'email': emailId,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === '200') {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

async function globalSearchModified(accessToken: string, emailId: any, type: any) {
    let url: string = createAPI.globalSearch;
    const formData = {
        "modified": type
    }

    try {

        const response = await axios.post(url, formData, {
            headers: {
                'email': emailId,
                'x-api-key': accessToken
            }
        });

        if (response.data.code === '200') {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for fetching file information
async function getFileInformation(accessToken: string, fileId: any): Promise<FileInformationListType> {
    let url: string = readAPI.getFileInformation;
    try {
        const response = await axios.get(`${url}/${fileId}`, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}

//@function for fetching file information
async function getDirectoryInformation(accessToken: string, fileId: any) {
    let url: string = readAPI.getDirectoryInformation;
    try {
        const response = await axios.get(`${url}/${fileId}/details`, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code == 200) {
            return {
                data: response.data.obj,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


async function getDirectoryListByDirId(accessToken: string, directoryId: any): Promise<DirectoryListTypeById> {
    let url: string = readAPI.getDirectoryListByDirId;
    let header: string = accessToken;
    let diretoryListType: DirectoryListTypeById = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}/${directoryId}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            diretoryListType.data = res.data.obj;
            if (res.data.code !== "200") {
                diretoryListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            diretoryListType.errMessage = err.message;
        })
    return diretoryListType;
}


async function getDirectoryListByDirIds(accessToken: string, directoryId: any): Promise<DirectoryListTypeById> {
    let url: string = readAPI.getDirectoryListByDirId;
    let header: string = accessToken;
    let diretoryListType: DirectoryListTypeById = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}/${directoryId}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            // diretoryListType.data = res.data.obj[0].subDirectories;
            diretoryListType.data = res.data.obj;
            if (res.data.code !== "200") {
                diretoryListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            diretoryListType.errMessage = err.message;
        })
    return diretoryListType;
}


//@function for move files
async function moveFile(accessToken: string, dirId: any, fileId: any) {
    let url: string = createAPI.moveFile;

    try {
        const formData = {
            "dirId": [],
            "fileId": [fileId],
            "moveDestinationFolderId": dirId
        }
        const response = await axios.put(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code === "202") {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for move directory
async function moveDirectory(accessToken: string, openedDirectoryId: any, dirId: any) {
    let url: string = createAPI.moveFile;

    try {
        const formData = {
            "dirId": [dirId],
            "fileId": [],
            "moveDestinationFolderId": openedDirectoryId
        }
        const response = await axios.put(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });

        if (response.data.code === "202") {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


//@function for shortcut
async function addShortCut(accessToken: string, fileId: any, openedDirectoryId: any) {
    let url: string = createAPI.ShortCut;
    const formData = {
        "dirId": [],
        "fileId": [fileId],
        "targetFolderId": openedDirectoryId
    }

    try {
        const response = await axios.post(url, formData, {
            headers: {
                'x-api-key': accessToken
            }
        });
        console.log("response", response)
        if (response.status === 201) {
            return {
                data: response.data,
                errMessage: ""
            };
        } else {
            return {
                data: [],
                errMessage: response.data.error || "An error occurred"
            };
        }
    } catch (error) {
        console.log('Error:', error);
        return {
            data: [],
            errMessage: "An error occurred"
        };
    }
}


export const EqaDriveService = {
    getDirectoryList,
    getFileList,
    downloadFile,
    downloadFolder,
    makeACopy,
    moveToBin,
    renameFile,
    renameDirectory,
    getBinList,
    getRecentList,
    getStarredList,
    restore,
    deleteForever,
    deleteAllForever,
    createDirectory,
    createDirectoryHome,
    moveToBinDirectory,
    addToStarred,
    addToStarredDir,
    removeFromStarred,
    changeDirectoryColor,
    lockFile,
    getUserStorage,
    searchFun,
    getOwner,
    getModifiedList,
    getShareList,
    shareFile,
    shareDirectory,
    getFileType,
    getPeople,
    getModified,
    getLocation,
    globalSearchType,
    globalSearch,
    globalSearchPeople,
    globalSearchModified,
    getFileInformation,
    getDirectoryInformation,
    getDirectoryListByDirId,
    getDirectoryListByDirIds,
    moveFile,
    moveDirectory,
    addShortCut,
    downloadFiless
}