/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, FC, useEffect } from "react";
import axios from 'axios';

// Importing Icons
import { BsPrinter } from "react-icons/bs";

import { ExportToCsv } from 'export-to-csv';

import { useTranslation } from "react-i18next";

// For copying to clipboard
import copy from 'copy-to-clipboard';

import SnackBar from "../../Components/SnackBar";

import {
    Button,
    Box,
    Typography,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    Switch,
    FormGroup,
    Select,
    MenuItem
} from '@mui/material';

// Importing Ripples
import { createRipples } from 'react-ripples';

import { BsCardChecklist } from "react-icons/bs";

import RefreshIcon from '@mui/icons-material/Refresh';

// For printing the table.
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

// Importing components
// import CustomTable from "./CustomTable";
import CustomTableCrud from "./CustomTableCrud";

// Importing Styles
import styles from "./style.module.css";
import { ButtonBase } from "@mui/material";
import CourseObjective from "../../Apps/eqa-core/Components/Course/CourseObjective/AddCourseObjective";
import { useSelector } from 'react-redux';
import { RootState } from "../../redux/store";
import readAPI from "../../Data/API/READ";
import { CommitteeDetails, GlobalService } from "../../Service/GlobalService";

// Creating Button Ripples
const ButtonRipples = createRipples({
    color: 'rgba(0, 0, 0, .1)',
    during: 600,
})

const colorArray = [
    "#4f598c",
    "#70519f",
    "#0d8aee",
    "#0b7cd6",
    "#0e769e",
    "#0d6886",
    "#26848a",
    "#18a193",
    "#be0a67"
];

interface DataTableMDProps {
    isOpen: Boolean
    data: any
    states: any
    columnName: string
    buttonTitle: string,
    tableTitle: string,
    tableInfo: any,
    currentLang: string,
    setFetchUpdate: any,
    setForUpdate?: any,
    setCurrentLang: any,
    setEnableEditing?: boolean,
    onEnterPressed?: (searchText: string) => void,
    studyPlanCompleteData?: any,
    assignTaskCompleteData?: any,
    loadingStatus?: boolean,
    deletedFun?: () => void;
}

const DataTableMD: FC<DataTableMDProps> = ({
    isOpen,
    data,
    states,
    columnName,
    buttonTitle,
    tableTitle,
    tableInfo,
    currentLang,
    setFetchUpdate,
    setForUpdate,
    setCurrentLang,
    setEnableEditing = true,
    onEnterPressed,
    studyPlanCompleteData,
    assignTaskCompleteData,
    loadingStatus,
    deletedFun
}): JSX.Element => {
    const { t } = useTranslation();

    ///////////////////////////////// Snackbar State /////////////////////////////////
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    ///////////////////////////////// Snackbar State /////////////////////////////////

    const [colHeader, setColHeader] = useState<any>([]);


    const [searchText, setSearchText] = useState<any>("");

    // console.log("setEnableEditing", setEnableEditing)
    // const [tableColumnName, setTableColumnName] = useState(columnName)
    const [committeeId, setCommitteeId] = useState("");
    const [committeeList, setCommitteeList] = useState<CommitteeDetails>({
        data: [],
        errMessage: ""
    })
    const committeeListOptions = committeeList && committeeList?.data?.map((user: any) => ({
        key: user.committeeId,
        label: `${user.committeeName}`,
        value: user.committeeId

    }));

    const handleCommitteeChange = (event: any, newValue: any) => {
        setSearchText(newValue ? newValue.value : null);
        setCommitteeId(newValue ? newValue.value : null);
        if (onEnterPressed) {
            onEnterPressed(searchText);
        }
    }

    const printTable = () => {
        window.print();
        // console.clear();
        // console.log("Print Table DATA ===> ", data);
        // console.log(colHeader);

        const doc = new jsPDF();

        // Converting the data into the format required by the autoTable function.
        // Into Array of Arrays.
        const bodyData = data.map((obj: any) => {
            const keys = Object.keys(obj);
            return keys.map(key => obj[key]);
        });

        const keys = new Set();

        data.forEach((obj: any) => {
            for (const key in obj) {
                keys.add(key);
            }
        });

        // console.log();

        let localColNames = Array.from(keys);

        // console.log("Body Data ===> ", bodyData);

        // remove the 'creationDateAndTime','updateDateAndTime' from the colHeader array.
        // const index = colHeader.indexOf('creationDateAndTime');
        // if (index > -1) {
        //     colHeader.splice(index, 1);
        // }

        // const index2 = colHeader.indexOf('updateDateAndTime');
        // if (index2 > -1) {
        //     colHeader.splice(index2, 1);
        // }

        let localColHeader: any = null;

        if (columnName === "ViewUsers") {
            localColHeader = {
                "createdBy": 'تم الإنشاء بواسطة',
                "creationDateAndTime": 'تاريخ ووقت الإنشاء',
                "updatedBy": 'تم التحديث بواسطة',
                "updateDateAndTime": 'تاريخ ووقت التحديث',
                "userId": 'معرّف المستخدم',
                "firstName": 'الاسم الأول',
                "lastName": 'اسم العائلة',
                "userName": 'اسم المستخدم',
                "password": 'كلمة السر',
                "emailId": 'البريد الإلكتروني',
                "collegeId": 'معرّف الكلية',
                "campusId": 'معرّف الحرم الجامعي',
                "departmentId": 'معرّف القسم',
                "emailStatus": 'حالة البريد الإلكتروني',
                "staff": 'الموظفين',
                "superUser": 'المستخدم الفائق',
                "active": 'نشط'
            };
        }
        else if (columnName === "ViewApps") {
            localColHeader = {
                "createdBy": "تم الإنشاء بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "appId": "معرّف التطبيق",
                "appName": "اسم التطبيق",
                "appName_Ar": "اسم التطبيق بالعربية",
                "appIcon": "رمز التطبيق",
                "appDescription": "وصف التطبيق",
                "appUrl": "رابط التطبيق",
                "appOrder": "ترتيب التطبيق",
                "active": "نشط"
            }
        }
        else if (columnName === "ViewGroups") {
            localColHeader = {
                "createdBy": "المُنشئ",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "المُحدّث",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "grpId": "معرّف المجموعة",
                "grpName": "اسم المجموعة",
                "grpDescription": "وصف المجموعة",
                "active": "نشط"
            };
        }
        else if (columnName === "ViewRoles") {
            localColHeader = {
                "createdBy": 'المنشئ',
                "creationDateAndTime": 'تاريخ الإنشاء والوقت',
                "updatedBy": 'المحدث',
                "updateDateAndTime": 'تاريخ التحديث والوقت',
                "roleId": 'رقم الدور',
                "roleName": 'اسم الدور',
                "roleDescription": 'وصف الدور',
                "active": 'نشط'
            };
        }
        else if (columnName === "ViewAppForm") {
            localColHeader = {
                "createdBy": "صُنِّعَ بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تحديث بواسطة",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "formId": "معرّف النموذج",
                "moduleName": "اسم الوحدة النمطية",
                "formName": "اسم النموذج",
                "formName_Ar": "اسم النموذج بالعربية",
                "formIcon": "رمز النموذج",
                "formUrl": "عنوان URL للنموذج",
                "appDetails": "وصف التطبيق",
                "active": "نشط؟",
                "appId": "معرّف التطبيق"
            }
        }
        else if (columnName === "ViewRoleApp") {
            localColHeader = {
                "createdBy": "المنشئ",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "المحدث",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "privilegeId": "معرّف الصلاحية",
                "role": "الدور",
                "appDetails": "تفاصيل التطبيق",
                "appForms": "أشكال التطبيق",
                "createPermission": "صلاحية الإنشاء",
                "readPermission": "صلاحية القراءة",
                "updatePermission": "صلاحية التحديث",
                "deletePermission": "صلاحية الحذف",
                "active": "نشط؟"
            }
        } else if (columnName === "ViewGroupRole") {
            localColHeader = {
                "createdBy": "صُنِّعَ بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تحديث بواسطة",
                "updateDateAndTime": "تاريخ ووقت التحديث",
                "groupRoleId": "معرف دور المجموعة",
                "groupDetails": "تفاصيل المجموعة",
                "role": "الدور",
                "grpRoleDescription": "وصف دور المجموعة",
                "grpId": "معرّف المجموعة",
                "roleId": "معرّف الدور",
                "active": "نشط؟"
            }
        } else if (columnName === "ViewUserGroup") {
            localColHeader = {
                // "createdBy": "تم إنشاؤه بواسطة",
                // "creationDateAndTime": "تاريخ ووقت الإنشاء",
                // "updatedBy": "تم التحديث بواسطة",
                // "updateDateAndTime": "تاريخ ووقت التحديث",
                "userGroupId": "معرّف مجموعة المستخدمين",
                "user": "المستخدم",
                "group": "المجموعة",
                "active": "نشط",
                "userId": "معرف المستخدم",
                "grpId": "معرف المجموعة"
            }
        }

        else if (columnName === "ViewCollege") {
            localColHeader = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "collegeId": "كلية",
                "collegeName": "اسم الكلية",
                "collegeEmailId": "معرف البريد الإلكتروني للكلية",
                "collegeWebAddress": "المجموعة",
                "addressLine1": "العنوان سطر 1",
                "addressLine2": "سطر العنوان 2",
                "addressLine3": "العنوان",
                "zipCode": "الرمز البريدي",
                "province": "مقاطعة",
                "country": "دولة",
                "collegePhoneNumber": "الكلية",
                "collegeFaxNumber": "الكليةفاكسرقم",
                "collegeMission": "الكلية",
                "collegeVision": "كلية الرؤية",
                "active": "نشيط",
            }
        }
        else if (columnName === "ViewUniversity") {
            localColHeader = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "universityId": "جامعة",
                "universityName": "اسم الجامعة",
                "universityEmailId": "الجامعة",
                "universityWebAddress": "عنوان الجامعة",
                "addressLine1": "العنوان سطر 1",
                "addressLine2": "سطر العنوان 2",
                "addressLine3": "العنوان",
                "zipCode": "الرمز البريدي",
                "province": "مقاطعة",
                "country": "دولة",
                "universityPhoneNumber": "رقم الهاتف بالجامعة",
                "universityFaxId": "جامعةفاكس رقم",
                "universityMission": "الجامعة",
                "universityVision": "رؤية الجامعة",
                "active": "نشيط",
            }
        }
        else if (columnName === "ViewCampus") {
            localColHeader = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "campusId": "الحرم الجامعي",
                "campusName": "الاسم",
                "campusEmailId": "معرف البريد الإلكتروني في الحرم الجامعي",
                "campusWebAddress": "عنوان الجامعة",
                "addressLine1": "العنوان سطر 1",
                "addressLine2": "سطر العنوان 2",
                "addressLine3": "العنوان",
                "zipCode": "الرمز البريدي",
                "province": "مقاطعة",
                "country": "دولة",
                "campusPhoneNumber": "رقم الهاتف",
                "campusFaxNumber": "رقم فاكس الحرم الجامعي",
                "campusMission": "الحرم الجامعي",
                "campusVision": "رؤية الحرم الجامعي",
                "active": "نشيط",
            }
        }
        else if (columnName === "ViewDepartment") {
            localColHeader = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "departmentId": "معرف القسم",
                "departmentName": "اسم القسم",
                "departmentEmailId": "القسم",
                "departmentWebAddress": "عنوان موقع القسم",
                "addressLine1": "العنوان سطر 1",
                "addressLine2": "سطر العنوان 2",
                "addressLine3": "العنوان",
                "departmentChair": "رئيس القسم",
                "departmentPhoneNumber": "رقم الهاتف",
                "departmentFaxNumber": "القسمالفاكسعدد",
                "departmentMission": "القسم",
                "departmentVision": "قسم الرؤية",
                "active": "نشط",
            }
        }
        else if (columnName === "ViewProgram") {
            localColHeader = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "programApprovalDate": "تاريخ الموافقة البرنامج",
                "programDesc": "برنامج",
                "programDuration": "مدة البرنامج",
                "programId": "معرف البرنامج",
                "programMission": "البرنامج",
                "programStartDate": "تاريخ بدء البرنامج",
                "programTitle": "عنوان البرنامج",
                "programTotalCredits": "إجمالي الرصيد الدراسي للبرنامج",
                "programVision": "رؤية البرنامج",
                "programWebAddress": "عنوان موقع البرنامج",
                "active": "نشط",
            }
        }
        else if (columnName === "ViewProgramType") {
            localColHeader = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "programDesc": "برنامج",
                "programTypeId": "نوع البرنامج",
                "programName": "إسم البرنامج",
                "programRemarks": "رالبرنامج",
                "active": "نشط",
            }
        }
        else if (columnName === "ViewProgramObjective") {
            localColHeader = {
                "createdBy": "تم إنشاؤه بواسطة",
                "creationDateTime": "تاريخ ووقت الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تاريخ ووقت التحديث",
                "remarks": "نشط",
                "soDescription": "نشط",
                "soId": "نشط",
                "programId": "نشط",
                "id": "رؤية",
                "active": "نشط",
            }
        }

        else if (columnName === "ViewCourseDetails") {
            localColHeader = {
                "academicYearOffered": "السنة الأكاديمية المقدمة",
                "alhChLaboratory": "المختبر",
                "alhChLecture": "محاضرة",
                "alhChOther": "محاضرة",
                "alhChTutorial": "alhCh تعليمي",
                "alhOlhAssignment": "alhOlh التنازل",
                "alhOlhLibrary": "مكتبة الحلة",
                "alhOlhOther": "alhOlh أخرى",
                "alhOlhResearch": "alhOlh Research",
                "alhOlhStudy": "دراسة alhOlh",
                "courseCoRequisite": "بالطبع",
                "courseCode": "كود الدورة",
                "courseCreditHours": "الدورات الائتمانية",
                "courseDesc": "بالطبع",
                "courseLevel": "المستوى",
                "courseName": "اسم الدورة التدريبية",
                "coursePrerequisite": "بالطبع شرط أساسي",
                "courseTypeName": "نوع الدورة",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "frAccomodation": "الإقامة",
                "frOtherResources": "fr مصادر أخرى",
                "frTechnologyResources": "مصادر التكنولوجيا",
                "labHoursPerWeek": "ساعات العمل في الأسبوع",
                "moiBlended": "موي المخلوطة",
                "moiCorrespondence": "مراسلات وزارة الداخلية",
                "moiElearning": "moi التعلم الإلكتروني",
                "moiOthers": "آخرون",
                "moiTraditionalClassroom": "الفصول الدراسية التقليدية moi",
                "requiredElective": "مطلوب",
                "sadCommittee": "اللجنة الحزينة",
                "sadDate": "التاريخ",
                "sadReferenceNo": "حزين المرجع",
                "studentAcademicSupport": "الطالب الأكاديميالدعم",
                "studyPlan": "خطة دراسية",
                "teachingHoursPerWeek": "التدريس في الأسبوع",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط",
            }
        }

        else if (columnName === "ViewCourseType") {
            localColHeader = {
                "courseTypeDesc": "نوع الدورة",
                "courseTypeName": "نوع الدورة",
                "courseTypeRemarks": "نوع الدورة",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط",
            }
        }

        else if (columnName === "ViewCourseObjective") {
            localColHeader = {
                "courseCode": "كود الدورة",
                "courseObjectiveId": "الهدف المقرر",
                "description": "وصف",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewCourseLearningOutcome") {
            localColHeader = {
                "assessmentMethod": "طريقة التقييم",
                "cloCategory": "فئة clo",
                "cloId": "معرف clo",
                "courseCode": "كود الدورة",
                "description": "وصف",
                "teachingStrategies": "استراتيجيات التدريس",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewTopics") {
            localColHeader = {
                "closCovered": "مغلق",
                "courseCode": "كود الدورة",
                "courseTopicId": "بالطبع",
                "topic": "عنوان",
                "weeksDelivered": "تم التسليم",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewCourseBook") {
            localColHeader = {
                "courseCode": "كود الدورة",
                "electronicMaterials": "المواد الإلكترونية",
                "otherLearningMaterials": "مواد تعليمية أخرى",
                "otherReferenceBook": "كتاب مرجعي آخر",
                "referenceBook": "كتاب مرجعي",
                "referenceMaterials": "مرجعالمواد",
                "textBook": "كتاب مدرسي",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewBook") {
            localColHeader = {
                "author": "مؤلف",
                "description": "وصف",
                "edition": "الإصدار",
                "editor": "محرر",
                "isbn10": "isbn10",
                "isbn13": "isbn13",
                "name": "اسم",
                "publisher": "الناشر",
                "websiteUrl": "رابط الموقع",
                "yearOfPublication": "سنة النشر",
                "id": "بطاقة تعريف",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewAssessments") {
            localColHeader = {
                "assessmentDate": "تاريخ التقييم",
                "assessmentId": "معرف التقييم",
                "assessmentTime": "وقت التقييم",
                "assessmentTypeId": "التقييم",
                "createdBy": "انشأ من قبل",
                "creationDateAndTime": "تاريخ الإنشاء والوقت",
                "maximumMarks": "علامات قصوى",
                "noOfQuestions": "لا من الأسئلة",
                "offeredCourseId": "معروض كورس",
                "projectDescription": "وصف المشروع",
                "projectTitle": "عنوان المشروع",
                "courseCode": "رمز الدورة",
                "section": "قسم",
                "assessmentName": "اسم التقييم",
                "supervisorName": "اسم المشرف",
                "examiner1Name": "اسم الممتحن1",
                "examiner2Name": "اسم الممتحن 2",
                "sessionType": "نوع الجلسة",
                "collegeName": "اسم الكلية",
                "departmentName": "اسم القسم",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewAssessmentType") {
            localColHeader = {
                "assessmentTypeId": "التقييم",
                "assessmentTypeName": "نوع التقييم",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "description": "وصف",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewStrategicPlan") {
            localColHeader = {
                "strategicPlanName": "اسم الخطة الاستراتيجية",
                "description": "وصف",
                "startDate": "تاريخ البدء",
                "endDate": "تاريخ الانتهاء",
                "manager": "مدير",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        
        else if (columnName === "ViewInitiative") {
            localColHeader = {
                "initiativeName": "اسم المبادرة",
                "manager": "مدير",
                "executiveManager": "المدير التنفيذي",
                "startDate": "تاريخ البدء",
                "endDate": "تاريخ الانتهاء",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewKPI") {
            localColHeader = {
                "kpiName": "اسم مؤشر الأداء الرئيسي",
                "description": "وصف",
                "degree": "درجة",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewKPIValue") {
            localColHeader = {
                "program": "برنامج",
                "kpiName": "اسم مؤشر الأداء الرئيسي",
                "target": "درجة",
                "benchmark": "المعيار",
                "newtarget": "هدف جديد",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewGoalData") {
            localColHeader = {
                "goalName" : "اسم الهدف",
                "goalDescription" : "وصف الهدف",
                "goalManager" : "مدير الأهداف",
                "initiative" : "مبادرة",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewManualScheduleCourse") {
            localColHeader = {
                "courseCode": "رمز الدورة",
                "collegeId": "معرف الكلية",
                "campusId": "معرف الحرم الجامعي",
                "departmentId": "معرف القسم",
                "instructorId": "معرف المدرب",
                "noOfStudent": "لا من الطالب",
                "section": "قسم",
                "offeredCourseId": "معرف الدورة المقدمة",
                "semester": "نصف السنة",
                "academicYear": "السنة الأكاديمية",
                "sessionType": "نوع الجلسة",
                "sessionName": "اسم الجلسة",
                "departmentName": "اسم القسم",
                "campusName": "اسم الحرم الجامعي",
                "collegeName": "اسم الكلية",
                "courseName": "اسم الدورة التدريبية",
                "programName": "إسم البرنامج",
                "instructorName": "اسم المدرب",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewSessionTypeData") {
            localColHeader = {
                "name": "اسم",
                "description": "وصف",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewAcademicYearData") {
            localColHeader = {
                "year": "سنة",
                "semester": "نصف السنة",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewCourseCoordinatorData") {
            localColHeader = {
                "courseCode": "رمز الدورة",
                "coordinatorId": "معرف المنسق",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewOfferedCourses") {
            localColHeader = {
                "offeredCourseId": "معرف الدورة المقدمة",
                "section": "قسم",
                "noOfStudent": "لا من الطالب",
                "sessionType": "نوع الجلسة",
                "instructorId": "معرف المدرب",
                "semester": "نصف السنة",
                "academicYear": "السنة الأكاديمية",
                "collegeName": "اسم الكلية",
                "campusName": "اسم الحرم الجامعي",
                "departmentName": "اسم القسم",
                "courseCode": "رمز الدورة",
                "sessionName": "اسم الجلسة",
                "emailId": "عنوان الايميل",
                "programName": "إسم البرنامج",
                "year": "سنة",
                "instructorName": "اسم المدرب",
                "courseName": "اسم الدورة التدريبية",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewProjectTemplate") {
            localColHeader = {
                "subComponentName": "اسم المكون الفرعي",
                "clos": "مغلق",
                "maxMarks": "ماكس ماركس",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "CourseOfferingTypes") {
            localColHeader = {
                "courseCode": "رمز الدورة",
                "courseName": "اسم الدورة التدريبية",
                "section": "قسم",
                "sessionName": "اسم الجلسة",
                "instructorName": "اسم المدرب",
                "emailId": "عنوان الايميل",
                "programName": "إسم البرنامج",
                "departmentName": "اسم القسم",
                "campusName": "اسم الحرم الجامعي",
                "collegeName": "اسم الكلية",
                "semester": "نصف السنة",
                "year": "سنة",
                "active": "نشيط"
            }
        }


        else if (columnName === "ViewCoordinator") {
            localColHeader = {
                "courseCode": "رمز الدورة",
                "coordinatorId": "معرف المنسق",
                "createdBy": "تم الإنشاء بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewSurvey") {
            localColHeader = {
                "courseCode": "رمز الدورة",
                "sectionNumber": "رقم المقطع",
                "url": "عنوان",
                "noOfDays": "لا أيام",
                "semester": "نصف السنة",
                "year": "سنة",
                "collegeId": "معرف الكلية",
                "campusId": "معرف الحرم الجامعي",
                "departmentId": "معرف القسم",
                "closCount": "كلوسكونت",
                "studentCount": "عدد الطلاب",
                "conductedSurveyCount": "أجرى عدد المسح",
                "createdBy": "تم الإنشاء بواسطة",
                "creationDateAndTime": "تاريخ ووقت الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewLocation") {
            localColHeader = {
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تحديث التاريخ والوقت",
                "active": "نشيط",
                "collegeId": "معرف الكلية",
                "campusId": "معرف الحرم الجامعي",
            }
        }
        else if (columnName === "ViewCommittee") {
            localColHeader = {
                "committeeName": "اسم اللجنة",
                "committeeDescription": "وصف اللجنة",
                "committeeChair": "كرسي اللجنة",
                "department": "قسم",
                "campus": "حرم الجامعة",
                "college": "كلية",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDateTime": "تحديث التاريخ والوقت",
                "active": "نشيط"
            }
        }

        else if (columnName === "ViewCommitteeMember") {
            localColHeader = {
                "memberId": "معرف العضو",
                "committeeId": "معرف اللجنة",
                "committeeName": "اسم اللجنة",
                "committeeDescription": "وصف اللجنة",
                "memberList": "قائمة الأعضاء",
                "createdBy": "انشأ من قبل",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
        else if (columnName === "ViewAnnualProgramReport") {
            localColHeader = {
                "id": "",
                "sectionName": "اسم القسم",
                "description": "وصف",
                "orderValue": "ثمن الطلب",
                "active": "نشيط",
                "academicYear": "السنة الأكاديمية",
                "createdBy": "انشأ من قبل",
                "creationDatetime": "تاريخ الإنشاء",
                "updatedBy": "تم التحديث بواسطة",
                "updateDatetime": "تحديث التاريخ والوقت"
            }
        }
        else if (columnName === "ViewAssignTaskReport") {
            localColHeader = {
                "program": "برنامج",
                "kpiName": "اسم مؤشر الأداء الرئيسي",
                "target": "درجة",
                "benchmark": "المعيار",
                "newtarget": "هدف جديد",
                "createdBy": "انشأ من قبل",
                "creationDateTime": "تاريخ الإنشاء",
                "updateDateTime": "تحديث التاريخ والوقت",
                "updatedBy": "تم التحديث بواسطة",
                "active": "نشيط"
            }
        }
       


        let finalColHeader: any = [];

        let EnglishColHeader = Object.keys(localColHeader);

        let localBodyData: any = [];

        if (currentLang === "ar") {
            finalColHeader = Object.values(localColHeader);

            // the value or columns names that finalColHeader has if it 
            // matches with the keys in bodyData then it will be placed in the
            // bodyData array in the same order as finalColHeader
            // If not present then it will not be pushed to the array

            // let newlocalBodyData: any = [];

            // // const bodyData = data.map((obj: any) => {
            // //     const keys = Object.keys(obj);
            // //     return keys.map(key => obj[key]);
            // // });

            // for (let i = 0; i < EnglishColHeader.length; i++) {
            //     if (EnglishColHeader[i] === localColNames[i]) {
            //         newlocalBodyData.push(localBodyData[EnglishColHeader[i]]);
            //     }
            // }

        } else if (currentLang === "en") {
            finalColHeader = Object.keys(localColHeader);
        }

        let newLocalBodyData = [];

        for (const obj of data) {
            let row = [];
            for (let i = 0; i < EnglishColHeader.length; i++) {
                if (EnglishColHeader[i] === localColNames[i]) {
                    row.push(obj[EnglishColHeader[i]]);
                }
            }
            newLocalBodyData.push(row);
        }

        // console.log(newLocalBodyData);

        localBodyData = newLocalBodyData;

        // autoTable(doc, {
        //     head: [EnglishColHeader],
        //     body: bodyData,
        // });

        // doc.save(columnName);
        // doc.setFillColor(60, 103, 102);
        // console.log(`./${columnName}.pdf generated`);
    }

    const refreshTable = () => {
        setSnackBarHandler({
            open: true,
            message: "Table Refreshed Successfully",
            severity: 'success'
        });
        setFetchUpdate(true);
    }


    const [selectedCourse, setSelectedCourse] = useState("Course Code");
    const [selectedInstructor, setSelectedInstructor] = useState('');
    const [selectedCollege, setSelectedCollege] = useState('');
    const [showCourseInput, setShowCourseInput] = useState("");
    const [collegeList, setCollegeList] = useState<any>([]);
    const [collegeId, setCollegeId] = useState<any>("");

    const handleCourseChange = (event: any) => {
        setSelectedCourse(event.target.value);
        // setShowCourseInput(true);
    };

    // const handleInstructorChange = (event: any) => {
    //     setSelectedInstructor(event.target.value);
    // };

    const collegeListOptions = collegeList && collegeList.map((item: any) => ({
        key: item.collegeId,
        label: `${item.collegeName}`,
        value: item.collegeId

    }));

    const handleCollegeChange = (event: any, newValue: any) => {
        setCollegeId(newValue ? newValue.value : null);
        setSearchText(newValue ? newValue.value : null);
        if (onEnterPressed) {
            onEnterPressed(searchText);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && onEnterPressed) {
            onEnterPressed(searchText);
        }
    };

    let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
    // useEffect(() => {
    //     const fetchCommittee = async () => {
    //         const committeeData = await GlobalService.getCommittee(accessToken);
    //         setCommitteeList(committeeData);
    //     }

    //     //calling function
    //     fetchCommittee();
    // }, [])

    useEffect(() => {
        if (columnName === 'ViewCommitteeMember') {
            const fetchCommittee = async () => {
                const committeeData = await GlobalService.getCommittee(accessToken);
                setCommitteeList(committeeData);
            }

            //calling function
            fetchCommittee();
        }
    }, [])
    // if(columnName === 'ViewManualScheduleCourse') {
    //     axios.get(readAPI.College, {
    //         headers: {
    //             "x-api-key": accessToken
    //         }
    //     })
    //         .then((res) => {
    //             if (res.data.code === "200.200") {
    //                 setCollegeList(res.data.obj);
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // }
    const college = useSelector((state: RootState) => state.collegeListData.data)
    useEffect(() => {
        setCollegeList(college);
    }, [])

    const generateCSV = () => {
        // console.clear();
        // console.log("Generate CSV DATA ===> ", data);
        // console.log(ColHeader);

        // Please generate a csv where first row is the column names and the rest of the rows are the data.
        // The data should be in the same order as the column names.

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: tableTitle + ' Table',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(data);
    }

    const copyToClipboard = () => {
        // console.clear();
        // console.log("Copy to Clipboard DATA ===> ", data);
        // console.log(ColHeader);

        // Please copy the data in the same format as the csv.
        // The data should be in the same order as the column names.

        setSnackBarHandler({
            open: true,
            message: `Copied to clipboard`,
            severity: 'info'
        });

        const bodyData = data.map((obj: any) => Object.keys(obj).map(key => obj[key]));

        const csvData = colHeader.concat(bodyData);

        // console.log(csvData);

        copy(csvData);

        // Copy with options
        copy(csvData, {
            debug: true,
            message: `${tableTitle} copied to clipboard`,
        });
        // console.log("Copied to clipboard");
    }

    const [currentColor, setCurrentColor] = useState("#3C6766");

    return (
        <div className={styles.container}>
            {/* Header Starts here */}
            <header
                className={styles.headerContainer}
                style={{
                    backgroundColor: currentColor,
                    color: (currentColor === "white" ? "black" : "white")
                }}
            >
                <section className={styles.headerLeft}>
                    <h5
                        className={styles.headingTopLeft}
                        dangerouslySetInnerHTML={{ __html: tableTitle }}
                    />
                </section>
                <section className={styles.headerRight}>
                    <div className={styles.headerButtonContainer}>
                        <ButtonBase className={styles.btnControls} style={{ backgroundColor: "#6cc561" }}>
                        </ButtonBase>
                        <ButtonBase className={styles.btnControls} style={{ backgroundColor: "#ffcc9f" }}>
                        </ButtonBase>
                        <ButtonBase className={styles.btnControls} style={{ backgroundColor: "#ff5969" }}>
                        </ButtonBase>
                    </div>
                    <Box className={styles.btnDropDownTableBtn}
                        sx={{
                            backgroundColor: currentColor,
                            // Hover
                            "&:hover": {
                                backgroundColor: (currentColor === "white" ? "#f4f4f4" : "white"),
                            },
                        }}
                    >
                        <div className="dropdown">
                            <a className="dropdown-toggle hidden-arrow" type="button" id="dropdownMenuicon" data-mdb-toggle="dropdown" aria-expanded="false">
                                <ButtonRipples>
                                    <div style={{ width: "30px", display: "flex", justifyContent: "center", height: "48px" }}>
                                        <i
                                            className="fas fa-ellipsis-v"
                                            style={{
                                                paddingTop: 14,
                                                fontSize: 20,
                                                color: (currentColor === "white" ? "grey" : "white")
                                            }}
                                        />
                                    </div>
                                </ButtonRipples>
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuicon"
                                style={{
                                    transition: "all 0.3s ease-in-out",
                                }}
                            >
                                {/* // eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <li>
                                    <a className="dropdown-item" href="#"> <i className="fas fa-exchange-alt pe-2" /> &nbsp;&nbsp;&nbsp; Panel Style &raquo; </a>
                                    <ul className="dropdown-menu dropdown-submenu" style={{
                                        // marginTop: 40,
                                        marginLeft: (currentLang === "ar") ? 0 : -160,
                                        marginRight: (currentLang === "ar") ? -100 : 0
                                    }}>
                                        <li>
                                            <a className="dropdown-item" href="#">
                                                <Box sx={{ width: 120, display: "flex", flexDirection: "row", justifyContent: "space-between" }} className="flex mt-2">
                                                    {colorArray.slice(0, 3).map((color: string, index: number) => {
                                                        return (
                                                            <Box
                                                                onClick={() => {
                                                                    setCurrentColor(color);
                                                                }}
                                                                key={index}
                                                            >
                                                                <div key={index} style={{ width: 30, height: 30, backgroundColor: color, borderRadius: 5, margin: 2 }} />
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>

                                                <Box sx={{ width: 120, display: "flex", flexDirection: "row", justifyContent: "space-between" }} className="flex mt-2">
                                                    {colorArray.slice(3, 6).map((color: string, index: number) => {
                                                        return (
                                                            <Box
                                                                onClick={() => {
                                                                    setCurrentColor(color);
                                                                }}
                                                            >
                                                                <div key={index} style={{ width: 30, height: 30, backgroundColor: color, borderRadius: 5, margin: 2 }} />
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>

                                                <Box sx={{ width: 120, display: "flex", flexDirection: "row", justifyContent: "space-between" }} className="flex mt-2">
                                                    {colorArray.slice(6, 9).map((color: string, index: number) => {
                                                        return (
                                                            <Box
                                                                onClick={() => {
                                                                    setCurrentColor(color);
                                                                }}
                                                            >
                                                                <div key={index} style={{ width: 30, height: 30, backgroundColor: color, borderRadius: 5, margin: 2 }} />
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#"> <i className="fas fa-redo pe-2" /> &nbsp;&nbsp;&nbsp; Reset Panel</a>
                                </li>
                            </ul>
                        </div>
                    </Box>
                </section>
            </header >
            {/* Header Ends here */}

            {/* Body Starts here */}
            <section className={styles.bodyContainer}>
                {/* Body of Header Starts Here */}
                <header className={styles.containerbodyHeader}>
                    <div className="d-flex" style={{ marginTop: 3 }}>
                        <div>
                            <BsCardChecklist style={{ color: "#4f747a", fontSize: 28 }} />
                        </div>
                        <h5 className={styles.tableSubTitleTopLeft}>
                            <b>
                                {tableInfo}
                            </b>
                        </h5>
                    </div>
                    <div className="d-flex justify-content-between">
                        {/* Standard */}
                        <div className={styles.btnContainerTable}>
                            <div >
                                {columnName === 'ViewManualScheduleCourse' ? (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <span style={{ fontSize: "22px", marginRight: "10px" }}>Filter By:</span>
                                        <Select
                                            style={{ marginRight: "10px" }}
                                            labelId="course-select-label"
                                            id="course-select"
                                            value={selectedCourse}
                                            onChange={handleCourseChange}
                                        >
                                            <MenuItem value="Course Code">Course Code</MenuItem>
                                            {/* <MenuItem value="Instructor Name">Instructor Name or Email Id</MenuItem> */}
                                            <MenuItem value="College Name">College Name</MenuItem>
                                        </Select>

                                        {selectedCourse === "Course Code" ? (
                                            <>
                                                <TextField
                                                    value={searchText}
                                                    onChange={event => setSearchText(event.target.value)}
                                                    placeholder="Enter Course Code"
                                                    variant="outlined"
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </>
                                        )
                                            // : selectedCourse === "Instructor Name" ? (
                                            //     <TextField
                                            //         value={searchText}
                                            //         onChange={event => setSearchText(event.target.value)}
                                            //         placeholder="Enter Instructor Name"
                                            //         variant="outlined"
                                            //         onKeyDown={handleKeyDown}
                                            //     />
                                            // )
                                            : (

                                                <Autocomplete
                                                    options={collegeListOptions}
                                                    getOptionLabel={option => option.label}
                                                    value={collegeListOptions.find((option: any) => option.value === collegeId) || null}
                                                    onChange={handleCollegeChange}
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            // label={t('Home.Sidebar.list.userManagement.subMenu.Users.details.Add.Users.Inputs.College.label')}
                                                            variant="outlined"
                                                            placeholder='Select College ...'
                                                            // helperText={(collegeIdError) ? ("* Please select any College.") : ("")}
                                                            // error={collegeIdError}
                                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                            style={{ width: "200px" }}
                                                        />
                                                    )}
                                                />
                                            )}

                                    </div>
                                ) :

                                    columnName === "ViewCommitteeMember" ?
                                        (
                                            <>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <span style={{ fontSize: "22px", marginRight: "10px" }}>Filter By:</span>
                                                    <Autocomplete
                                                        options={committeeListOptions}
                                                        getOptionLabel={option => option.label}
                                                        value={committeeListOptions.find((option: any) => option.value === committeeId) || null}
                                                        onChange={handleCommitteeChange}
                                                        onBlur={(event) => {
                                                            event.preventDefault();
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label={`${t('Home.Header.Modals.committee.committeeMember.policy.Inputs.committeeName.label')}`}
                                                                placeholder={`${t('Home.Header.Modals.committee.committeeMember.policy.Inputs.committeeName.placeholder')}`}
                                                                variant="outlined"
                                                                dir={currentLang === "ar" ? "rtl" : "ltr"}
                                                                style={{ width: '200px' }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </>
                                        ) :
                                        ""}
                            </div>
                            <div className={styles.btnControl}>
                                <ButtonRipples>
                                    <button className={`btn btn-light ${styles.insideBtnControl}`} onClick={() => generateCSV()}>
                                        {t('Home.Sidebar.list.userManagement.subMenu.Users.details.table.Buttons.btnCSV')}
                                    </button>
                                </ButtonRipples>
                            </div>
                            <div className={styles.btnControl}>
                                <ButtonRipples>
                                    <button className={`btn btn-light ${styles.insideBtnControl}`} onClick={() => copyToClipboard()}>
                                        {t('Home.Sidebar.list.userManagement.subMenu.Users.details.table.Buttons.btnCopy')}
                                    </button>
                                </ButtonRipples>
                            </div>
                            <div className={styles.btnControl}>
                                <ButtonRipples>
                                    <button className={`btn btn-light ${styles.insideBtnControl}`} onClick={() => printTable()}>
                                        <BsPrinter style={{ marginTop: -2 }} size={20} />
                                    </button>
                                </ButtonRipples>
                            </div>
                            <div className={styles.btnControl}>
                                <ButtonRipples>
                                    <button className={`btn btn-light ${styles.insideBtnControl}`} onClick={() => refreshTable()}>
                                        {/* <BsPrinter style={{ marginTop: -5 }} size={20} /> */}
                                        <RefreshIcon style={{ display: "block", color: "blue" }} />
                                    </button>
                                </ButtonRipples>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Body of Header Ends Here */}

                {/* Body of Body Container Starts Here */}
                <div className={styles.bodyOfBodyContainer}>
                    <CustomTableCrud
                        data={data}
                        states={states}
                        columnName={columnName}
                        buttonTitle={buttonTitle}
                        isOpen={isOpen}
                        currentLang={currentLang}
                        colHeader={colHeader}
                        setColHeader={setColHeader}
                        setFetchUpdate={setFetchUpdate}
                        setForUpdate={setForUpdate}
                        setEnableEditing={setEnableEditing}
                        studyPlanCompleteData={studyPlanCompleteData}
                        assignTaskCompleteData={assignTaskCompleteData}
                        loadingStatus={loadingStatus}
                        deletedFun={deletedFun}
                    />
                </div>
                {/* Body of Body Container Ends Here */}

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

            </section>
            {/* Body Ends here */}
        </div >
    )
}
export default DataTableMD;