import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    FormControl,
    FormControlLabel,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import readAPI from '../../../data/api/read';
import { columnValues } from '../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../redux/store";


interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateManualScheduleCourse = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });



        // const [viewAllCampusData, setViewAllCampusData] = useState<ViewAllCampusData | null>(null);

        const [courseCodeList, setCourseCodeList] = useState<any>([]);
        const [collegeName, setCollegeName] = useState<any>([]);
        const [campusName, setCampusName] = useState<any>([]);
        const [departmentName, setDepartmentName] = useState<any>([]);
        const [programName, setProgramName] = useState<any>([]);
        const [sessionTypeName, setSessionTypeName] = useState<any>([]);



        //set state for form value
        const [courseCode, setCourseCode] = useState<string>(originalValues.courseCode);
        const [collegeId, setCollegeId] = useState<string>(originalValues.collegeId);
        const [campusId, setCampusId] = useState<string>(originalValues.campusId);
        const [departmentId, setDepartmentId] = useState<string>(originalValues.departmentId);
        const [programId, setProgramId] = useState<string>(originalValues.programId);
        const [instructorId, setInstructorId] = useState<string>(originalValues.instructorId);
        const [noOfStudent, setNoOfStudent] = useState<string>(originalValues.noOfStudent);
        const [section, setSection] = useState<string>(originalValues.section);
        const [sessionType, setSessionType] = useState<string>(originalValues.sessionType);
        const [isChecked, setIsChecked] = useState(originalValues.active);

        // For handling errors

        const [courseCodeError, setCourseCodeError] = useState<boolean>(false)
        const [collegeIdError, setCollegeIdError] = useState<boolean>(false);
        const [campusIdError, setCampusIdError] = useState<boolean>(false);
        const [departmentIdError, setDepartmentIdError] = useState<boolean>(false);
        const [instructorIdError, setInstructorIdError] = useState<boolean>(false);
        const [programIdError, setProgramIdError] = useState<boolean>(false);
        const [noOfStudentError, setNoOfStudentError] = useState<boolean>(false);
        const [sectionError, setSectionError] = useState<boolean>(false);
        const [sessionTypeError, setSessionTypeError] = useState<boolean>(false);


        //For handling message

        const [courseCodeErrorMessage, setCourseCodeErrorMessage] = useState<string>("")
        const [collegeIdErrorMessage, setCollegeIdErrorMessage] = useState<string>("");
        const [campusIdErrorMessage, setCampusIdErrorMessage] = useState<string>("");
        const [departmentIdErrorMessage, setDepartmentIdErrorMessage] = useState<string>("");
        const [instructorIdErrorMessage, setInstructorIdErrorMessage] = useState<string>("");
        const [programIdErrorMessage, setProgramIdErrorMessage] = useState<string>("");
        const [noOfStudentErrorMessage, setNoOfStudentErrorMessage] = useState<string>("");
        const [sectionErrorMessage, setSectionErrorMessage] = useState<string>("");
        const [sessionTypeErrorMessage, setSessionTypeErrorMessage] = useState<string>("");


        // For handling error messages
        // const [courseCodeErrorMessage, setCourseCodeErrorMessage] = useState("");


        const courseCodeListOptions = courseCodeList.map((courseCode: any) => ({
            label: `${courseCode.courseCode}: ${courseCode.courseName}`,
            value: courseCode.courseCode,
        }));
        const handleCourseCodeChange = (event: any, newValue: any) => {
            setCourseCode(newValue ? newValue.value : null);
        };

        const collegeNameOptions = collegeName.map((course: any) => ({
            label: `${course.collegeName} `,
            value: course.collegeId
        }));
        const handleCollegeNameChange = (event: any, newValue: any) => {
            setCollegeId(newValue ? newValue.value : null);
        };
        const campusNameOptions = campusName.map((course: any) => ({
            label: `${course.campusName} `,
            value: course.campusId
        }));
        const handleCampusNameChange = (event: any, newValue: any) => {
            setCampusId(newValue ? newValue.value : null);
        };
        const departmentNameOptions = departmentName.map((course: any) => ({
            label: `${course.departmentName} `,
            value: course.departmentId
        }));
        const handleDepartmentNameChange = (event: any, newValue: any) => {
            setDepartmentId(newValue ? newValue.value : null);
        };

        const programNameOptions = programName.map((item: any) => ({
            label: `${item.programTitle} `,
            value: item.programId
        }));
        const handleProgramNameChange = (event: any, newValue: any) => {
            setProgramId(newValue ? newValue.value : null);
        };

        const sessionTypeOptions = sessionTypeName.map((item: any) => ({
            label: `${item.name}`,
            value: item.id
        }));
        const handleSessionTypeNameChange = (event: any, newValue: any) => {
            setSessionType(newValue ? newValue.value : null);
        };

        
        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(!isChecked);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {
                // @1) Fetching courses
                axios.get(readAPI.Courses, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setCourseCodeList(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err)
                    });

                // @2) Fetching college
                axios.get(readAPI.College, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setCollegeName(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err)
                    });
                // @2) Fetching course
                axios.get(readAPI.Campus, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setCampusName(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err)
                    });
                // @4) Fetching course
                axios.get(readAPI.Department, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setDepartmentName(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err)
                    });

                // @4) Fetching Program
                axios.get(readAPI.Program, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setProgramName(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err)
                    });


                // @5) Fetching session type
                axios.get(readAPI.SessionType, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setSessionTypeName(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            }
        }, [])

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                // let accessToken: any = Cookies.get("accessToken");

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {

                    
                    const isValidateForm = () => {
                        let hasError = false;
                        if (courseCode === "" || courseCode === null) {
                            setCourseCodeError(true);
                            setCourseCodeErrorMessage("Please Select Course");
                            hasError = true;
                        } else {
                            setCourseCodeError(false);
                            setCourseCodeErrorMessage("");
                        }
                    
                        if (collegeId === "") {
                            setCollegeIdError(true);
                            setCollegeIdErrorMessage("Please Select College");
                            hasError = true;
                        } else {
                            setCollegeIdError(false);
                            setCollegeIdErrorMessage("");
                        }

                        if (campusId === "") {
                            setCampusIdError(true);
                            setCampusIdErrorMessage("Please Select Campus");
                            hasError = true;
                        } else {
                            setCampusIdError(false);
                            setCampusIdErrorMessage("");
                        }

                        if (departmentId === "") {
                            setDepartmentIdError(true);
                            setDepartmentIdErrorMessage("Plese Select Department");
                            hasError = true;
                        } else {
                            setDepartmentIdError(false);
                            setDepartmentIdErrorMessage("");
                        }

                        if (programId === "") {
                            setProgramIdError(true);
                            setProgramIdErrorMessage("Please Select Program");
                            hasError = true;
                        } else {
                            setProgramIdError(false);
                            setProgramIdErrorMessage("");
                        }

                        if (instructorId === "") {
                            setInstructorIdError(true);
                            setInstructorIdErrorMessage("Please Enter Instructor Id");
                            hasError = true;
                        } else {
                            setInstructorIdError(false);
                            setInstructorIdErrorMessage("");
                        }

                        if (noOfStudent === "") {
                            setNoOfStudentError(true);
                            setNoOfStudentErrorMessage("Please Enter No Of Student");
                            hasError = true;
                        } else {
                            setNoOfStudentError(false);
                            setNoOfStudentErrorMessage("");
                        }

                        if (section === "") {
                            setSectionError(true);
                            setSectionErrorMessage("Please Enter Section");
                            hasError = true;
                        } else {
                            setSectionError(false);
                            setSectionErrorMessage("");
                        }

                        if (sessionType === "") {
                            setSessionTypeError(true);
                            setSessionTypeErrorMessage("Please Select Session Type");
                            hasError = true;
                        } else {
                            setSessionTypeError(false);
                            setSessionTypeErrorMessage("");
                        }
                        return !hasError;
                    };
                    

                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    }
                    else {
                        const formState = [{
                            "offeredCourseId": originalValues.offeredCourseId,
                            "courseCode": courseCode,
                            "collegeId": collegeId,
                            "campusId": campusId,
                            "programId": programId,
                            "departmentId": departmentId,
                            "instructorId": instructorId,
                            "noOfStudent": noOfStudent,
                            "section": section,
                            "sessionType": sessionType,
                            "active": isChecked
                        }]

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `Schedule Course has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    const m = response.data.message;
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    }


                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="courseCodeComplete"
                                    options={courseCodeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={courseCodeListOptions.find((option: any) => option.value === courseCode) || null}
                                    onChange={handleCourseCodeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.Topics.policy.Inputs.courseCode.label')}`}
                                            placeholder={`${t('Home.Header.Modals.Topics.policy.Inputs.courseCode.placeholder')}`}
                                            variant="standard"
                                            helperText={courseCodeErrorMessage}
                                            error={courseCodeError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="collegeNamesComplete"
                                    options={collegeNameOptions}
                                    getOptionLabel={option => option.label}
                                    value={collegeNameOptions.find((option: any) => option.value === collegeId) || null}
                                    onChange={handleCollegeNameChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.collegeId.label')}`}
                                            placeholder={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.collegeId.placeholder')}`}
                                            variant="standard"
                                            helperText={collegeIdErrorMessage}
                                            error={collegeIdError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />


                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="campusNamesComplete"
                                    options={campusNameOptions}
                                    getOptionLabel={option => option.label}
                                    value={campusNameOptions.find((option: any) => option.value === campusId) || null}
                                    onChange={handleCampusNameChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.campusId.label')}`}
                                            placeholder={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.campusId.placeholder')}`}
                                            variant="standard"
                                            helperText={campusIdErrorMessage}
                                            error={campusIdError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="departmentNamesComplete"
                                    options={departmentNameOptions}
                                    getOptionLabel={option => option.label}
                                    value={departmentNameOptions.find((option: any) => option.value === departmentId) || null}
                                    onChange={handleDepartmentNameChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.departmentId.label')}`}
                                            placeholder={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.departmentId.placeholder')}`}
                                            variant="standard"
                                            helperText={departmentIdErrorMessage}
                                            error={departmentIdError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="programComplete"
                                    options={programNameOptions}
                                    getOptionLabel={option => option.label}
                                    value={programNameOptions.find((option: any) => option.value === programId) || null}
                                    onChange={handleProgramNameChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.programId.label')}`}
                                            placeholder={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.programId.placeholder')}`}
                                            variant="standard"
                                            helperText={programIdErrorMessage}
                                            error={programIdError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="instructorIdTextField"
                                    label={t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.instructorId.label')}
                                    placeholder={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.instructorId.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={instructorIdError}
                                    helperText={instructorIdErrorMessage}
                                    value={instructorId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (instructorIdError) {
                                            setInstructorIdError(false);
                                        }
                                        setInstructorId(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="noOfStudentTextField"
                                    label={t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.noOfStudent.label')}
                                    placeholder={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.noOfStudent.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={noOfStudentError}
                                    helperText={noOfStudentErrorMessage}
                                    value={noOfStudent}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (noOfStudentError) {
                                            setNoOfStudentError(false);
                                        }
                                        setNoOfStudent(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="sectionTextField"
                                    label={t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.section.label')}
                                    placeholder={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.section.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={sectionError}
                                    helperText={sectionErrorMessage}
                                    value={section}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (sectionError) {
                                            setSectionError(false);
                                        }
                                        setSection(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                sx={{mt:2}}>

                                <Autocomplete
                                    id="sessionTypeComplete"
                                    options={sessionTypeOptions}
                                    getOptionLabel={option => option.label}
                                    value={sessionTypeOptions.find((option: any) => option.value === sessionType) || null}
                                    onChange={handleSessionTypeNameChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.sessionType.label')}`}
                                            placeholder={`${t('Home.Header.Modals.ManualScheduleCourse.policy.Inputs.sessionType.placeholder')}`}
                                            variant="standard"
                                            helperText={sessionTypeErrorMessage}
                                            error={sessionTypeError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                               
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>

                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />

                                {/* <Switch
                                    checked={isChecked}
                                    onChange={handleSwitchChange}
                                    inputProps={{ 'aria-label': 'Toggle' }}
                                /> */}
                            </Grid>










                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateManualScheduleCourse;