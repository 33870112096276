import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
    Box,
    MenuItem as MUIMenuItem,
} from "@mui/material";
import styles from "./style.module.css";
interface CompletedSurveyProps {
    isMinified: Boolean,
    setIsMinified: any,
    currentLang: string,
    setCurrentLang: any,
}

const CompletedSurvey: React.FC<CompletedSurveyProps> = ({
    isMinified,
    setIsMinified,
    currentLang,
    setCurrentLang,
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);


    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);


    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });


    

    return (
        <div
            className={styles.container}
        >
                <h1 style={{color: "red"}}>"Survey has been completed already or Survey URL is expired."</h1>
        </div>
    )
}
export default CompletedSurvey;