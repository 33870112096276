import { BASE_URL } from "../../../../../constants";
const readAPI = {
    FetchUsers: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUsers`,
    Department: `${BASE_URL}/EQACORE-SERVICE/department`,
    AllMeetingDetailsListType : `${BASE_URL}/EQAMOM-SERVICE/mom/meeting`,
    AllMeetingDetailsByCriteria : `${BASE_URL}/EQAMOM-SERVICE/mom/meeting/byCriteria`,
    AllLocation : `${BASE_URL}/EQAMOM-SERVICE/mom/location/getAllLocation`,
    GetMeetingDetailsByMeetingId : `${BASE_URL}/EQAMOM-SERVICE/mom/meeting`,
    DeleteMeetingDetailsByMeetingId : `${BASE_URL}/EQAMOM-SERVICE/mom/meeting`,
    GetMOMByMeetingId : `${BASE_URL}/EQAMOM-SERVICE/mom/meetingId`
  };
  
  export default readAPI;
  