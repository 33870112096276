import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import axios from "axios";
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import 'jspdf-autotable';
import { alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import {
    Box,
    Typography,
    Grid,
    MenuItem as MUIMenuItem,
    RadioGroup, FormControlLabel, Radio, FormControl
} from "@mui/material";
import styles from "./style.module.css";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SOEvidenceService, StateCLOSOMappingType, CourseType } from "../../../report/service/SOEvidenceService";
import { IOfferedCourse, ICourseCLO, ICourse } from "../../../report/model/SOEvidence";
import { useSelector } from 'react-redux';
import { RootState } from "../../../../redux/store";
import createAPI from "../../data/api/create";
import SnackBar from "../../../../Components/SnackBar";
import ThankYou from "../ThankYou";

interface SurveyFormProps {
    // setIsOpen: any,
    // isOpen: Boolean,
    // For minified sidebar
    isMinified: Boolean,
    setIsMinified: any,
    currentLang: string,
    setCurrentLang: any,
}

const SurveyForm: React.FC<SurveyFormProps> = ({
    // setIsOpen,
    // isOpen,
    // For minified sidebar
    isMinified,
    setIsMinified,
    currentLang,
    setCurrentLang,
}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);



    type StateCourseCodeListType = {
        data: IOfferedCourse[],
        errMessage: string
    }

    type StateCourseCLOType = {
        data: ICourseCLO[],
        errMessage: string
    }


    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    // const [courseCodeList, setCourseCodeList] = useState<StateCourseCodeListType>({
    //     data: [],
    //     errMessage: ""
    // });


    const [courseCLO, setCourseCLO] = useState<StateCourseCLOType>({
        data: [],
        errMessage: ""
    });
    // const [cloSoMapping, setCloSoMapping] = useState<StateCLOSOMappingType>({
    //     data: [],
    //     errMessage: ""
    // });
    const [course, setCourse] = useState<CourseType>({
        data: {} as ICourse,
        errMessage: ""
    });




    const [courseCode, setCourseCode] = useState<any>(null);

    const { restUrl } = useParams<{ restUrl: string }>() as { restUrl: string };

    const first_part = restUrl.split('_')[0]
    const section = restUrl.split('_')[1];
    const surveyId = restUrl.split('_').pop();
    useEffect(() => {
        setCourseCode(first_part)
    }, [])


    console.log("courseCLO>>>>>>>", section);

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);


    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });


    const handleClickInstruction = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
    // let accessToken: any = Cookies.get("accessToken");
    if (accessToken === undefined || accessToken === null) {
        accessToken = null;
    }

    // Fetching all offered course by loggedIn user on component load

    // useEffect(() => {
    //     if (accessToken !== null) {
    //         const fetchData = async () => {
    //             const offeredData = await SOEvidenceService.getOfferedCourseByLoggedInUser(accessToken);
    //             setCourseCodeList(offeredData);
    //         }
    //         fetchData();
    //     }
    // }, []);



    //@fetching course clo by course code
    const fetchCourseCLO = async () => {
        if (accessToken !== null) {
            const courseCloData = await SOEvidenceService.getCourseCLO(accessToken, courseCode);
            setCourseCLO(courseCloData);
        }
    }

    //@fetching course closo mapping by course code
    // const fetchCourseCLOSOMapping = async () => {
    //     if (accessToken !== null) {
    //         const courseCLoSoMappingData = await SOEvidenceService.getCourseCLOSOMapping(accessToken, courseCode);
    //         console.log("courseCLoSoMappingData", courseCLoSoMappingData);
    //         setCloSoMapping(courseCLoSoMappingData);
    //     }
    // }

    //@fetching course by course code
    const fetchCourseByCourseCode = async () => {
        if (accessToken !== null) {
            const courseData = await SOEvidenceService.getCourseByCourseCode(accessToken, courseCode);
            console.log("courseData", courseData);
            setCourse(courseData);
        }
    }


    useEffect(() => {
        fetchCourseByCourseCode();
        fetchCourseCLO();
        // fetchCourseCLOSOMapping();
    }, [courseCode])



    const [selectedValue, setSelectedValue] = useState<any>({});
    const [errors, setErrors] = useState(Array(courseCLO?.data?.length).fill(''));


    console.log("errors=-=-=-", errors)

    const handleRadioChange = (index: any, value: any) => {
        // setSelectedValue((prevState: any) => ({
        //     ...prevState,
        //     [index]: value
        // }));

        setSelectedValue((prevState: any) => {
            return {
                ...prevState, // Spread the previous state object
                [index]: value // Update the value at the specified index
            };
        });

        // Clear the error message for this row when the user selects a value
        setErrors(prevState => {
            const newErrors = [...prevState];
            newErrors[index] = ''; // Clear error for this row
            return newErrors;
        });
       
    };

    // console.log("Course Code: from params", selectedValue);
    const extractNumericPart = (cloId: any) => {
        return cloId.replace(/^\D+/g, '');
    };

    const handleSubmitSurveyForm = () => {
        // Validation checks
        let isValid = true;
        const newErrors = Array(courseCLO.data.length).fill('');

        courseCLO.data.forEach((item, index) => {
            if (selectedValue[index] === undefined) {
                newErrors[index] = 'Please select hilighted clo value';
                isValid = false;
            }
        });

        setErrors(newErrors);

        if (!isValid) {
            // console.error("Please select a value for each item.");
            return;
        }

        // Clear errors when the form is submitted

        // Prepare form data

        const formData = courseCLO.data?.map((item: any, index: any) => ({
            surveyId: surveyId,
            cloId: extractNumericPart(item.cloId),
            value: selectedValue[index]
        }));
        const payload = { "payload": formData }
        axios.post(createAPI.postSurveyFormData,
            payload
            , {
                headers: {
                    'x-api-key': accessToken,
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": '*'
                }
            })
            .then(function (response) {
                if (response.status === 201) {
                    setSnackBarHandler({
                        open: true,
                        message: (response.data.code === "201") ? (`Survey Data has been Submitted Successfully`) : (response.data.message),
                        severity: (response.data.code === "201") ? ("success") : ("error")
                    })
                    const m = response.data.message;
                    
                    if (response.data.code === "201") {
                        navigate("/survey/thankyou")
                        // setFetchUpdate(true);
                        setTimeout(() => {
                            // refreshFun(formState);
                        }, 3000);
                    }
                }
            })
            .catch(function (error) {
                navigate("/survey/completed")
                setSnackBarHandler({
                    open: true,
                    message: error.message,
                    severity: "error"
                })
            });
    }

    return (
        <div
            className={styles.container}
        >
            <Box
                sx={{
                    padding:
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (0),
                    boxShadow: "0 0 10px grey",
                    backgroundColor: "rgba(211, 211, 211, 0.3)",
                    marginTop: 5, marginBottom: 10,
                    borderRadius: "20px"
                }}
            >


                <Box sx={{ backgroundColor: "white", flexGrow: 1, padding: 5, flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row") }} >
                    <Grid container sx={{ paddingLeft: 3 }} spacing={
                        (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                    } dir={(currentLang === "ar") ? ('rtl') : ('ltr')}>

                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Box sx={{ flexGrow: 1, padding: 0 }}>
                                                <Grid container spacing={
                                                    (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                                                }>

                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'flex' }}
                                                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                                    >
                                                        <img
                                                            className={styles.universityLogo}
                                                            width={80}
                                                            height={122}
                                                            src={require(`../../../../assets/Images/universityLogo/taifuniversitylogo.jpeg`)}
                                                            alt="University Logo"
                                                        />
                                                        <div className={styles.collegeNameHeading}>
                                                            <h2>Department Of Information Technology</h2>
                                                            <span>College Of Computer and Information Technology</span><br />
                                                            <span>Taif University</span>
                                                        </div>

                                                    </Grid>
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ textDecoration: "underline", textAlign: "center" }}>
                                                        <h4>Course Survey - Fall 2023</h4>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ border: "5px solid #3c6766", width: "50px", height: "auto" }}>
                            <p className={styles.labelFont}>
                                <span style={{ marginRight: "200px" }}>{t('Home.Sidebar.list.report.subMenu.SOEvidence.details.courseCode')}:</span>
                                <span>{courseCode}</span>
                            </p>

                            <p className={styles.labelFont}>
                                <span style={{ marginRight: "194px" }}>{t('Home.Sidebar.list.report.subMenu.SOEvidence.details.courseName')}:</span>
                                <span>{course.data?.courseName}</span>
                            </p>

                            <p className={styles.labelFont}>
                                <span style={{ marginRight: "235px" }}>{t('Home.Sidebar.list.report.subMenu.SOEvidence.details.section')}:</span>
                                <span>{section}</span>
                            </p>

                        </Grid>


                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ border: "5px solid #3c6766", width: "100%", height: "auto", display: "flex", marginTop: "10px" }}>
                            <Grid style={{ flex: 1, borderRight: "3px solid #3c6766" }}>
                                <span>This course has certain learning outcomes. The purpose of this survey is to get your input on.</span>
                                <h4>How well you have learned / mastered these course learning outcomes.</h4>
                                <span>This survey evaluates the CLO's, NOT the course Instructor.</span>
                            </Grid>
                            <Grid style={{ flex: 1, marginLeft: "10px" }}>
                                <span>This course has certain learning outcomes. The purpose of this survey is to get your input on.</span>
                                <h4>How well you have learned / mastered these course learning outcomes.</h4>
                                <span>This survey evaluates the CLO's, NOT the course Instructor.</span>
                            </Grid>
                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ border: "5px solid #3c6766", width: "100%", height: "auto", display: "flex", marginTop: "10px" }}>
                            <Grid style={{ flex: 1, borderRight: "3px solid #3c6766" }}>
                                <span>This course has certain learning outcomes. The purpos of this survey is to get your input on.</span>
                            </Grid>
                            <Grid style={{ flex: 1, marginLeft: "10px" }}>
                                <span>This course has certain learning outcomes. The purpos of this survey is to get your input on.</span>
                            </Grid>
                        </Grid>

                        <TableContainer component={Paper} className={styles.tablecontainer}>
                            <Table className={styles.customtable}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell ><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.clo')}</span></TableCell>
                                        <TableCell style={{ width: "600px" }}><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.description')}</span></TableCell>
                                        <TableCell ><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.stronglyDisagree')}</span></TableCell>
                                        <TableCell ><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.disagree')}</span></TableCell>
                                        <TableCell ><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.neutral')}</span></TableCell>
                                        <TableCell ><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.agree')}</span></TableCell>
                                        <TableCell ><span className={styles.labelFont}>{t('Home.Sidebar.list.survey.surveySetting.details.form.stronglyAgree')}</span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {courseCLO.data?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell style={errors[index] ? { backgroundColor: '#ECECEC' } : {}}>{item.cloId}<br />{errors[index] && <span style={{ color: 'red' }}>{errors[index]}</span>}</TableCell>
                                            <TableCell style={errors[index] ? { backgroundColor: '#ECECEC' } : { width: "600px" }}>{item.description}</TableCell>
                                            <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {}}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        name={`radioGroup-${index}`}
                                                        value={selectedValue[index] || ''}
                                                        onChange={(e) => handleRadioChange(index, e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="1"
                                                            control={<Radio />}
                                                            label=""
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {}}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        name={`radioGroup-${index}`}
                                                        value={selectedValue[index] || ''}
                                                        onChange={(e) => handleRadioChange(index, e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="2"
                                                            control={<Radio />}
                                                            label=""
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {}}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        name={`radioGroup-${index}`}
                                                        value={selectedValue[index] || ''}
                                                        onChange={(e) => handleRadioChange(index, e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="3"
                                                            control={<Radio />}
                                                            label=""
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {}}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        name={`radioGroup-${index}`}
                                                        value={selectedValue[index] || ''}
                                                        onChange={(e) => handleRadioChange(index, e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="4"
                                                            control={<Radio />}
                                                            label=""
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>

                                            <TableCell className={styles.tableStyle} style={errors[index] ? { backgroundColor: '#ECECEC' } : {}}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup
                                                        name={`radioGroup-${index}`}
                                                        value={selectedValue[index] || ''}
                                                        onChange={(e) => handleRadioChange(index, e.target.value)}
                                                    >
                                                        <FormControlLabel
                                                            value="5"
                                                            control={<Radio />}
                                                            label=""
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>


                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
                            <Button
                                dir={currentLang === "ar" ? "rtl" : "ltr"}
                                variant="contained"
                                sx={{
                                    backgroundColor: "#d5b254",
                                    fontWeight: "bold",
                                    height: 40,
                                    mt: 2,
                                    "&:hover": {
                                        backgroundColor: "#d5b254",
                                    },
                                }}
                                fullWidth
                                onClick={handleSubmitSurveyForm}
                            >
                                <Typography style={{ display: "block" }} dir={currentLang === "ar" ? "rtl" : "ltr"}>
                                    {t('Home.Sidebar.list.survey.surveySetting.details.button.submit')}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />
            </Box>
        </div>
    )
}
export default SurveyForm;