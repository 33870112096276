import React, { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Folder } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Directory component to handle each directory item
const Directory = ({ directory }: any) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpand = () => {
        setExpanded(!expanded);
    };
    const navigate = useNavigate();
    const handleNameClick = (e: any, dir: any) => {
        e.stopPropagation();
        navigate("/drive/folders/view/" + dir);
    }

    return (
        <div>
            <div style={{ cursor: 'pointer', paddingLeft: '10px', display: 'flex' }}>
                <div onClick={toggleExpand} >
                    {expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}

                </div>
                <Folder style={{ paddingRight: "10px", marginTop: "4px" }} />
                <span style={{ marginTop: "4px" }} onClick={(e) => handleNameClick(e, directory.dirId)}>{directory.name}</span>
            </div>

            {expanded && directory.subDirectories.length > 0 && (
                <div style={{ paddingLeft: '10px' }}>
                    {directory.subDirectories.map((subDir: any) => (
                        <Directory key={subDir.dirId} directory={subDir} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default memo(Directory);
