import { STUDYPLAN_URL } from "../../../../../constants";
import { GDRIVE_BASE_URL } from "../../../../../constants";
const readAPI = {
    getDirectoryList: "https://69.48.179.170:8766/eqa-gdrive-service/gdrive/v1.0/dir/list/hierarchy",
    // getDirectoryList: "https://69.48.179.170:8766/eqa-gdrive-service/gdrive/v1.0/dir/list",
    getFileList: "https://69.48.179.170:8766/eqa-gdrive-service/gdrive/v1.0/files/list?",
    searchFiles: `${GDRIVE_BASE_URL}/files/search?searchInput=`,
    getBinList: `${GDRIVE_BASE_URL}/dir/trashed-list`,
    getRecentList: `${GDRIVE_BASE_URL}/files/recent?`,
    getStarredList: `${GDRIVE_BASE_URL}/starred/list`,
    getUserStorage: `${GDRIVE_BASE_URL}/user/storage`,
    getOwnerList: `${GDRIVE_BASE_URL}/filter/global-search/owner`,
    getShareList: `${GDRIVE_BASE_URL}/share/list`,
    getModifiedList: `${GDRIVE_BASE_URL}/filter/global-search/modified`,
    getFileType: `${GDRIVE_BASE_URL}/filter/fileType`,
    getLocation: `${GDRIVE_BASE_URL}/filter/fileType`,
    getPeople: `${GDRIVE_BASE_URL}/filter/people`,
    getModified: `${GDRIVE_BASE_URL}/filter/modified`,
    getFileInformation : `${GDRIVE_BASE_URL}/files`,
    getDirectoryInformation : `${GDRIVE_BASE_URL}/dir`,
    getDirectoryListByDirId : `${GDRIVE_BASE_URL}/dir/list`,
    globalSearchType: `${GDRIVE_BASE_URL}/files/search?searchInput=`,

  };
  
  export default readAPI;