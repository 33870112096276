

interface QMSState {
    manageCoordinator: any[];
    newRequest: any[];
}

const initialState: QMSState = {
    manageCoordinator: [],
    newRequest: []
}

const qmsReducer = (state = initialState, action: any): QMSState => {
    switch (action.type) {
        case 'ADD_MANAGE_COORDINATOR':
            return {
                ...state,
                manageCoordinator: [...state.manageCoordinator, action.payload.data],
            }
        case 'ADD_NEW_REQUEST':
            return {
                ...state,
                newRequest: [...state.newRequest, action.payload.data],
            };
        case 'SET_NEW_REQUEST':
            return {
                ...state,
                newRequest: action.payload.data,
            };
        case 'REMOVE_NEW_REQUEST':
            return {
                ...state,
                newRequest: state.newRequest.filter((_, index) => index !== action.payload),
            };
        // case 'UPDATE_NEW_REQUEST':
            // return {
            //     ...state,
            //     newRequest: state.newRequest.map((request, index) =>
            //         index === action.payload.index ? { ...request, ...action.payload.updatedRequest } : request
            //     ),
            // };
        default:
            return state;
    }
}

export default qmsReducer;