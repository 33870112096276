import * as React from 'react';
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import axios from 'axios';
import styles from "./style.module.css";
import readAPI from '../../../data/api/read';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import initiative from "../../../../../assets/Images/strategicPlan/initiative.jpeg"
import { updateInitiativeByIndex } from '../../../../../redux/action/spsActions';


interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    setForUpdate: any,
    currentRowIndex: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateInitiative = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        setForUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });


        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        const [initiativeName, setNameOfInitiative] = useState<string>(originalValues.initiativeName);
        const [manager, setInitiativeManager] = useState<string>(originalValues.manager);
        const [executiveManager, setInitiativeExecutiveManager] = useState<string>(originalValues.executiveManager);
        const convertDateFormat = (date: string) => {
            return date.replace(/\//g, '-');
        };
        const [startDate, setStartDate] = useState<string>(convertDateFormat(originalValues.startDate));
        const [endDate, setEndDate] = useState<string>(convertDateFormat(originalValues.endDate));

        // error state
        const [nameOfInitiativeError, setNameOfInitiativeError] = useState<boolean>(false);
        const [initiativeManagerError, setInitiativeManagerError] = useState<boolean>(false);
        const [initiativeExecutiveManagerError, setInitiativeExecutiveManagerError] = useState<boolean>(false);
        const [startDateError, setStartDateError] = useState<boolean>(false);
        const [endDateError, setEndDateError] = useState<boolean>(false);

        // error message state

        const [nameOfInitiativeErrorMessage, setNameOfInitiativeErrorMessage] = useState("");
        const [initiativeManagerErrorMessage, setInitiativeManagerErrorMessage] = useState("");
        const [initiativeExecutiveManagerErrorMessage, setInitiativeExecutiveManagerErrorMessage] = useState("");
        const [startDateErrorMessage, setStartDateErrorMessage] = useState("");
        const [endDateErrorMessage, setEndDateErrorMessage] = useState("");

        let instructorName = useSelector((state: RootState) => state.userListData.data);
        const instructorNameOptions = instructorName?.map((item: any) => ({
            label: `${item.firstName} ${item.lastName} ${item.emailId}`,
            value: item.userId,
            userName: item.userName
        }));

        const handleInitiativeMangerChange = (event: any, newValue: any) => {
            setInitiativeManager(newValue ? newValue.value : null);
            if (newValue) {
                setInitiativeManagerError(false);
                setInitiativeManagerErrorMessage("");
            }
        }
        const handleExecutiveManagerChange = (event: any, newValue: any) => {
            setInitiativeExecutiveManager(newValue ? newValue.value : null);
            if (newValue) {
                setInitiativeExecutiveManagerError(false);
                setInitiativeExecutiveManagerErrorMessage("");
            }
        }


        const formatDate = (date: any) => {
            return date.replace(/-/g, '/');
        };
        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    const isValidateForm = () => {
                        let hasErrors = false;

                        if (initiativeName === "") {
                            hasErrors = true;
                            setNameOfInitiativeError(true);
                            setNameOfInitiativeErrorMessage("Please Enter Name of Initiative");
                        }
                        if (manager === "") {
                            hasErrors = true;
                            setInitiativeManagerError(true);
                            setInitiativeManagerErrorMessage("Please Enter Description");
                        }
                        if (executiveManager === "") {
                            hasErrors = true;
                            setInitiativeExecutiveManagerError(true);
                            setInitiativeExecutiveManagerErrorMessage("Please Enter Initiative Executive Manager")
                        }
                        if (startDate === "") {
                            hasErrors = true;
                            setStartDateError(true);
                            setStartDateErrorMessage("Please Enter Start Date");
                        }
                        if (endDate === "") {
                            hasErrors = true;
                            setEndDateError(true);
                            setEndDateErrorMessage("Please Enter End Date");
                        }

                        // Check if endDate is greater than startDate
                        if (startDate && endDate && new Date(endDate) <= new Date(startDate)) {
                            setEndDateError(true);
                            hasErrors = true;
                            setEndDateErrorMessage("End Date must be greater than Start Date");
                        } else if (endDate !== "") {
                            setEndDateError(false);
                            setEndDateErrorMessage("");
                        }

                        return !hasErrors;
                    };


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    } else {
                        const formState = {
                            initiativeName,
                            manager,
                            executiveManager,
                            startDate: formatDate(startDate),
                            endDate: formatDate(endDate),
                            strategicPlanId: originalValues.strategicPlanId,
                            status: "NS",
                            remarks: "remarks",
                            "active": true
                        };



                        axios.put(`${url}/${originalValues.initiativeId}`,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    dispatch(updateInitiativeByIndex(currentRowIndex, formState))
                                    setOpenUpdateTableModal(false);
                                    setFetchUpdate(true);
                                    setForUpdate(true);
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>
                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6} style={{ padding: "26px" }}>
                                <Grid container>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            dir="ltr"
                                            value={initiativeName}
                                            placeholder={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.nameOfInitiative.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.nameOfInitiative.label')}`}
                                            error={nameOfInitiativeError}
                                            helperText={nameOfInitiativeErrorMessage}
                                            onChange={(e) => {
                                                if (nameOfInitiativeError) {
                                                    setNameOfInitiativeError(false);
                                                    setNameOfInitiativeErrorMessage("");
                                                }
                                                setNameOfInitiative(e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12} sx={{ mt: 3 }}>
                                        <Autocomplete
                                            options={instructorNameOptions}
                                            getOptionLabel={option => option.label}
                                            value={instructorNameOptions.find((option: any) => option.value === manager) || null}
                                            onChange={handleInitiativeMangerChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.initiativeManager.placeholder')}`}
                                                    label={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.initiativeManager.label')}`}
                                                    variant="outlined"
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    error={initiativeManagerError}
                                                    helperText={initiativeManagerErrorMessage}

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12} sx={{ mt: 3 }}>
                                        <Autocomplete
                                            options={instructorNameOptions}
                                            getOptionLabel={option => option.label}
                                            value={instructorNameOptions.find((option: any) => option.value === executiveManager) || null}
                                            onChange={handleExecutiveManagerChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.initiativeExecutiveManager.placeholder')}`}
                                                    label={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.initiativeExecutiveManager.label')}`}
                                                    variant="outlined"
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    error={initiativeExecutiveManagerError}
                                                    helperText={initiativeExecutiveManagerErrorMessage}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={6} sx={{ mt: 3 }}>
                                            <TextField
                                                placeholder={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.startingDate.placeholder')}`}
                                                label={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.startingDate.label')}`}
                                                variant="outlined"
                                                style={{ backgroundColor: "#fff" }}
                                                margin="normal"
                                                fullWidth
                                                type="date"
                                                dir="ltr"
                                                value={startDate}
                                                error={startDateError}
                                                helperText={startDateErrorMessage}
                                                onChange={(e) => {
                                                    if (startDateError) {
                                                        setStartDateError(false);
                                                        setStartDateErrorMessage("");
                                                    }
                                                    setStartDate(e.target.value);
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={6} sx={{ mt: 3 }}>
                                            <TextField
                                                placeholder={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.endDate.placeholder')}`}
                                                label={`${t('Home.Header.Modals.SPS.Initiative.policy.Inputs.endDate.label')}`}
                                                variant="outlined"
                                                style={{ backgroundColor: "#fff" }}
                                                margin="normal"
                                                fullWidth
                                                type="date"
                                                dir="ltr"
                                                value={endDate}
                                                error={endDateError}
                                                helperText={endDateErrorMessage}
                                                onChange={(e) => {
                                                    if (endDateError) {
                                                        setEndDateError(false);
                                                        setEndDateErrorMessage("");
                                                    }
                                                    setEndDate(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6} style={{ paddingBottom: "35px", paddingTop: "40px" }}>
                                <img src={initiative} height="100%" width="100%" />


                            </Grid>








                        </Grid>
                    </Box>
                </Box>



                {/* <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                /> */}

                <br />
            </Box>
        )
    }
)
export default React.memo(UpdateInitiative);