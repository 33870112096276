import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
    Box,
    Grid,
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../redux/store";
import { MomService } from '../../../service/MomService';

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
}
interface UpdateRef {
    submitForm: any
}

const ViewMeeting = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        originalValues,
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        useEffect(() => {
            //@getting all meeting details list
            if (accessToken !== null) {
                const getAllMeetingDetails = async () => {
                    const meetingListData = await MomService.getAllMeetingDetails(accessToken);
                }
                getAllMeetingDetails()
            }
        }, [])


        return (
            <Box className={styles.container}>
                <Box sx={{
                    padding:
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>
                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Meeting Id: {originalValues.meetingId}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Parent Meeting Id: {originalValues.parentMeetingId}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Organizer: {originalValues.organizer}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Date: {originalValues.date}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Start Time: {originalValues.startTime}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>End Time: {originalValues.endTime}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Location: {originalValues.location}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Department: {originalValues.department}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Agenda Topic: {originalValues.agendaTopic}</span>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <span>Status: {originalValues.status}</span>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default ViewMeeting;