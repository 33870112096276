interface ManageCourseState {
    courseDetails: any | null;
    projectTemplate: any | null;
}

const initialState: ManageCourseState = {
    courseDetails: null,
    projectTemplate: null,
};

const manageCourseReducer = (state = initialState, action: any): ManageCourseState => {
    switch (action.type) {
        case 'COURSE_DETAILS':
            return {
                ...state,
                courseDetails: action.payload.data,
            };
        case 'PROJECT_TEMPLATE':
            return {
                ...state,
                projectTemplate: action.payload.data
            }
        default:
            return state;
    }
};

export default manageCourseReducer;