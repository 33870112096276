import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css"

const Home = lazy(() => import("../../../Pages/Home"));
const SurveyForm = lazy(() => import("../Components/SurveyForm"));
const CreateSurvey = lazy(() => import("../Components/CreateSurvey"));
const ThankYou = lazy(() => import("../Components/ThankYou"));
const CompletedSurvey = lazy(() => import("../Components/CompletedSurvey"));

interface SurveyRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    // courseCodeProps: any,
    // openUniversityModal: boolean,
    // setOpenUniversityModal: any,
}

const SurveyRouter: FC<SurveyRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,

    // courseCodeProps

    // openUniversityModal,
    // setOpenUniversityModal,

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
        <Routes>
            {/* <Route path="survey"> */}
            <Route
                path="view"
                element={<Home
                    setShowHeader={setShowHeader}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    // For minified sidebar
                    isMinified={isMinified}
                    setIsMinified={setIsMinified}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}

                    // Sidebar Apps List
                    sidebarAppsListArray={sidebarAppsListArray}
                    setSidebarAppsListArray={setSidebarAppsListArray}

                    // @subComponent
                    subComponent={
                        <CreateSurvey
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />}
            />
            {/* </Route> */}
            {/* <Route path={"/:restUrl"} element={<SurveyForm
                isMinified={isMinified}
                setIsMinified={setIsMinified}
                currentLang={currentLang}
                setCurrentLang={setCurrentLang}
            />}
            /> */}

            {/* <Route path={"/thankyou"} element={<ThankYou
                isMinified={isMinified}
                setIsMinified={setIsMinified}
                currentLang={currentLang}
                setCurrentLang={setCurrentLang}
            />}
            /> */}

            {/* <Route path={"/completed"} element={<CompletedSurvey
                isMinified={isMinified}
                setIsMinified={setIsMinified}
                currentLang={currentLang}
                setCurrentLang={setCurrentLang}
            />}
            /> */}

            <Route
                path={"view"}
                element={<Home
                    setShowHeader={setShowHeader}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    // For minified sidebar
                    isMinified={isMinified}
                    setIsMinified={setIsMinified}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}

                    // Sidebar Apps List
                    sidebarAppsListArray={sidebarAppsListArray}
                    setSidebarAppsListArray={setSidebarAppsListArray}

                    // @subComponent
                    subComponent={
                        <SurveyForm
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}
                        />
                    }
                />}
            />
            {/* <Route path="survey"> */}
            <Route path={"cloSurvey"}>
                <Route
                    path={"view"}
                    element={
                            <CreateSurvey
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                />
            </Route>
            {/* </Route> */}

        </Routes>
        </Suspense>
    )

}

export default SurveyRouter;
