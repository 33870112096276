interface ScheduleCourseState {
    academicYear: any | null;
    courseCoordinator: any | null;
  }
  
  const initialState: ScheduleCourseState = {
    academicYear: null,
    courseCoordinator: null,
  };

  const scheduleCourseReducer = (state = initialState, action: any): ScheduleCourseState => {
    switch (action.type) {
        case 'ACADEMIC_YEAR':
            return {
                ...state,
                academicYear: action.payload.data,
            };
        case 'COURSE_COORDINATOR':
            return {
                ...state,
                courseCoordinator: action.payload.data
            }
        default:
            return state;
    }
};

export default scheduleCourseReducer;