import { Annual_Program_Report, BASE_URL } from "../../../../../constants";
import { PORTAL_URL } from "../../../../../constants";
const readAPI = {
    Offeredcourses : `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourses`,
    // Offeredcourses : `${BASE_URL}/EQACORE-SERVICE/courses`,
    OfferedCourseByCourseCode: `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourse`,
    Courses: 
    `${BASE_URL}/EQACORE-SERVICE/courses`,
    CourseCLO: `${BASE_URL}/EQACORE-SERVICE/courseCloByCourseCodeOrCloId`,
    CourseCLOSOMappingByCourseCode: `${BASE_URL}/EQACORE-SERVICE/getCloSoMapping/courseCode`,
    CourseByCourseCode: `${BASE_URL}/EQACORE-SERVICE/course`,
    ProgramObjective: `${BASE_URL}/EQACORE-SERVICE/studentOutcome`,
    getAssessmentByOfferedCourseId: `${BASE_URL}/EQACORE-SERVICE/assessment/assessments`,
    fetchMarksDetails: `${BASE_URL}/EQACORE-SERVICE/assessment/fetchMarksDetails`,
    assessmentType: `${BASE_URL}/EQACORE-SERVICE/assessment/assessmentType`,
    fetchFarDetailsByOffereCourseId: `${BASE_URL}/EQACORE-SERVICE/report/far`,
    campus: `${BASE_URL}/EQACORE-SERVICE/campus`,
    Syllabus: `${BASE_URL}/EQACORE-SERVICE/syllabus`,
    college: `${BASE_URL}/EQACORE-SERVICE/colleges`,
    department: `${BASE_URL}/EQACORE-SERVICE/department`,
    program: `${BASE_URL}/EQACORE-SERVICE/programs`,
    fetchAllOfferedCourseByCriteria: `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourseByCriteria`,
    getFarDetailsByOfferedCourseId: `${BASE_URL}/EQACORE-SERVICE/report/far`,
    fetchUsers: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUsers`,
    soEvidence: `${BASE_URL}/EQACORE-SERVICE/report/soEvidence`,
    getOverallGradeSummary : `${BASE_URL}/EQACORE-SERVICE/report/summaryOverAllGrade`,
    getCoordinatorFarSection : `${BASE_URL}/EQACORE-SERVICE/report/coordinatorFARSection`,
    getOverallGradeData : `${BASE_URL}/EQACORE-SERVICE/assessment/fetchOverAllGrades`,
    getSurveyByCriteria: `${PORTAL_URL}/eqa-survey-service/api/v1.0/survey/forCafReport`,
    getSurveyloggedInByUser: `${PORTAL_URL}/eqa-survey-service/api/v1.0/survey/loggedInByUser`,
    generateCAF: `${BASE_URL}/EQAREPORT-SERVICE/caf-report`,
    // generateCAF: `${BASE_URL}/EQAREPORT-SERVICE/cr-report`,
    fetchCourseSOEvidence: `${BASE_URL}/EQACORE-SERVICE/report/soEvidence`,
    fetchSectionByOffereCourse: `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourse/sections`,
    academicYear: `${BASE_URL}/EQACORE-SERVICE/schedule/academicYear`,
    fetchUserAuthorization : `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUserAuthorizationByUserIdOrByUserName`,
    getProgramObjective: `${BASE_URL}/EQACORE-SERVICE/studentOutcome`,
    getAllAssignedTaskReport : `${Annual_Program_Report}/annual-program-report/task-detail`,
    getSectionForAssignTask : `${Annual_Program_Report}/annual-program-report/setting`,
    annualProgramReportData : `${Annual_Program_Report}/annual-program-report/data`,
    annualProgramReportDataFirstPage : `https://datadimens.com:8994/EQACORE-SERVICE/report/getProgramData?`,
    GetAllAnnualProgramReport : `${Annual_Program_Report}/annual-program-report/setting`,
    getReportByCriteria : `${Annual_Program_Report}/annual-program-report/reports?`,
    kpiByDegree: `${Annual_Program_Report}/annual-program-report/kpi/degree/b`,
    taskDetailByReportId : `${Annual_Program_Report}/annual-program-report/task-detail/by-report?reportId=`,
    downloadAnnualProgramReport: `${Annual_Program_Report}/annual-program-report/advance-report/full-report?`
    
    
  };
  
  export default readAPI;
  