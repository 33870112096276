import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    FormControlLabel,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import axios from 'axios';

import styles from "./style.module.css";

import readAPI from '../../../../data/api/read';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";

interface textBookOption {
    label: string;
    value: string;
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateCourseBook = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [courseCodeList, setCourseCodeList] = useState<any>([]);
        const [TextsBook, setTextsBook] = useState<any>([]);
        // const [referenceBookId, setReferenceBookId] = useState<string>(originalValues.referenceBookId);
        // const [textBookId, setTextBookId] = useState<string>(originalValues.textBookId);
        const [courseCode, setCourseCode] = useState<string>(originalValues.courseCode);
        const [electronicMaterials, setElectronicMaterials] = useState<string>(originalValues.electronicMaterials);
        const [otherLearningMaterials, setOtherLearningMaterials] = useState<string>(originalValues.otherLearningMaterials);
        const [otherReferenceBook, setOtherReferenceBook] = useState<string>(originalValues.otherReferenceBook);
        const [referenceBook, setReferenceBook] = useState<string>(originalValues.referenceBookId);
        const [referenceMaterials, setReferenceMaterials] = useState<string>(originalValues.referenceMaterials);
        const [textBook, setTextBook] = useState<string>(originalValues.textBookId);

        const [isChecked, setIsChecked] = useState(originalValues.active);

        // For handling errors


        const [courseCodeError, setCourseCodeError] = useState<boolean>(false);
        const [electronicMaterialsError, setElectronicMaterialsError] = useState<boolean>(false);
        const [otherLearningMaterialsError, setOtherLearningMaterialsError] = useState<boolean>(false);
        const [otherReferenceBookError, setOtherReferenceBookError] = useState<boolean>(false);
        const [referenceBookError, setReferenceBookError] = useState<boolean>(false);
        const [referenceMaterialsError, setReferenceMaterialsError] = useState<boolean>(false);
        const [textBookError, setTextBookError] = useState<boolean>(false);

        const [countryError, setCountryError] = useState<boolean>(false)

        const courseCodeListOptions = courseCodeList.map((courseCode: any) => ({
            label: `${courseCode.courseCode}: ${courseCode.courseName}`,
            value: courseCode.courseCode,
        }));
        const handleCourseCodeChange = (event: any, newValue: any) => {
            setCourseCode(newValue ? newValue.value : null);
        };

        const textBookOptions: textBookOption[] = TextsBook.map((book: any) => ({
            label: `${book.name} `,
            value: book.id
        }));
        const handleTextBookChange = (event: any, newValue: any) => {
            setTextBook(newValue ? newValue.value : null);
        };

        const referenceBookOptions: textBookOption[] = TextsBook.map((book: any) => ({
            label: `${book.name} `,
            value: book.id
        }));

        const handleReferenceBookChange = (event: any, newValue: any) => {
            setReferenceBook(newValue ? newValue.value : null);
        };
        
        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
          };

          let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {
                axios.get(readAPI.Courses, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setCourseCodeList(res.data.obj);
                    })
                    .catch((err) => {
                        setSnackBarHandler({
                            open: true,
                            message: err.message,
                            severity: "error"
                        })
                    });

                // Fetching book data using axios and also pass the header x-api-key for auth
                axios.get(readAPI.Books, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setTextsBook(res.data.obj);

                    })
                    .catch((err) => {
                        setSnackBarHandler({
                            open: true,
                            message: err.message,
                            severity: "error"
                        })
                    });
            }
        }, [])


        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {

                    if (courseCode !== "" && electronicMaterials !== "" && otherLearningMaterials !== "") {
                        const formState = {
                            "id": originalValues.id,
                            "courseCode": courseCode,
                            "electronicMaterials": electronicMaterials,
                            "otherLearningMaterials": otherLearningMaterials,
                            "otherReferenceBook": otherReferenceBook,
                            "referenceBook": referenceBook,
                            "referenceMaterials": referenceMaterials,
                            "textBook": textBook,
                            "active": isChecked
                        };


                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `${textBook} data has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    const m = response.data.message;
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
               

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                   

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>

                                <Autocomplete
                                    id="courseCodeComplete"
                                    options={courseCodeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={courseCodeListOptions.find((option: any) => option.value === courseCode) || null}
                                    onChange={handleCourseCodeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.CourseBook.policy.Inputs.courseCode.label')}`}
                                            placeholder={`${t('Home.Header.Modals.CourseBook.policy.Inputs.courseCode.placeholder')}`}
                                            variant="standard"
                                            // helperText={(coursePrerequisiteErrorMessage) ? ("* Please select any College.") : ("")}
                                            error={courseCodeError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="textBookComplete"
                                    options={textBookOptions}
                                    getOptionLabel={option => option.label}
                                    value={textBookOptions.find(option => option.value === textBook) || null}
                                    onChange={handleTextBookChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.CourseBook.policy.Inputs.textBook.label')}`}
                                            placeholder={`${t('Home.Header.Modals.CourseBook.policy.Inputs.textBook.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}
                                sx={{ mt: 2 }}
                            >
                                <Autocomplete
                                    id="referenceBookComplete"
                                    options={referenceBookOptions}
                                    getOptionLabel={option => option.label}
                                    value={referenceBookOptions.find((option: any) => option.value === referenceBook) || null}
                                    onChange={handleReferenceBookChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.CourseBook.policy.Inputs.referenceBook.label')}`}
                                            placeholder={`${t('Home.Header.Modals.CourseBook.policy.Inputs.referenceBook.placeholder')}`}
                                            variant="standard"
                                            // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                            // error={courseTypeNamesError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="electronicMaterialsTextField"
                                    label={t('Home.Header.Modals.CourseBook.policy.Inputs.electronicMaterials.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseBook.policy.Inputs.electronicMaterials.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={electronicMaterialsError}
                                    // helperText={courseCodeErrorMessage}
                                    value={electronicMaterials}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (electronicMaterialsError) {
                                            setElectronicMaterialsError(false);
                                        }
                                        setElectronicMaterials(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="otherLearningMaterialsTextField"
                                    label={t('Home.Header.Modals.CourseBook.policy.Inputs.otherLearningMaterials.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseBook.policy.Inputs.otherLearningMaterials.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={otherLearningMaterialsError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={otherLearningMaterials}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (otherLearningMaterialsError) {
                                            setOtherLearningMaterialsError(false);
                                        }
                                        setOtherLearningMaterials(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="otherReferenceBookTextField"
                                    label={t('Home.Header.Modals.CourseBook.policy.Inputs.otherReferenceBook.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseBook.policy.Inputs.otherReferenceBook.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={otherReferenceBookError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={otherReferenceBook}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (otherReferenceBookError) {
                                            setOtherReferenceBookError(false);
                                        }
                                        setOtherReferenceBook(e.target.value);
                                    }}
                                />
                            </Grid>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="referenceMaterialsTextField"
                                    label={t('Home.Header.Modals.CourseBook.policy.Inputs.referenceMaterials.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseBook.policy.Inputs.referenceMaterials.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={referenceMaterialsError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={referenceMaterials}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (referenceMaterialsError) {
                                            setReferenceMaterialsError(false);
                                        }
                                        setReferenceMaterials(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                    <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

               

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCourseBook;