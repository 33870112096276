import { CourseOfferingTypes } from './types';

export const data: CourseOfferingTypes[] = [
  {
    id: '502346-1',
    name: 'Operating System',
    section: 3011,
    noofstudent: 76,
    coordinator: 'Dr. Alajmi',
    instructor: "Dr. Khalid",
    campus: "Hawiya",
    semester: "Fall/2020"
  },
  {
    id: '502372-2',
    name: 'Fundamentals of Database',
    section: 2012,
    noofstudent: 45,
    coordinator: 'Dr. Sultan',
    instructor: "Dr. Rokaya",
    campus: "Hawiya",
    semester: "Fall/2020"
  },
  {
    id: '502372-3',
    name: 'Object Oriented Programming',
    section: 2023,
    noofstudent: 97,
    coordinator: 'Mr. Amjad Majeed',
    instructor: "Dr Shehzad Asif",
    campus: "UET",
    semester: "Fall/2019"
  },
  {
    id: '502372-4',
    name: 'Fundamentals of Databases',
    section: 2024,
    noofstudent: 56,
    coordinator: 'Dr. Asif Iqbal',
    instructor: "Dr. Ishaq shafeeeq",
    campus: "PUCIT",
    semester: "Fall/2018"
  },
  {
    id: '502372-5',
    name: 'Design Patterns',
    section: 2018,
    noofstudent: 50,
    coordinator: 'Dr. Sheeraz Asif',
    instructor: "Dr. Ali Moen",
    campus: "FAST",
    semester: "Fall/2017"
  }
];

//50 us states array
export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Puerto Rico',
];

export const columnValues = [
  "id",
  "name",
  "section",
  "noofstudent",
  "coordinator",
  "instructor",
  "campus",
  "semester"
];