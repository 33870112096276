import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import gulfCountry from "../../../../../../country.json"

import readAPI from '../../../../data/api/read';
import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";
import { useDispatch } from 'react-redux';
import { tableData } from "../../../../../../redux/action/authActions";

interface Country {
    name: string;
    capital: string;
    population: string;
    area_sq_km: string;
    currency: string;
    languages: string[];
    flag: string;
}

interface ViewAllCollegeData {
    timestamp: string;
    transactionId: string;
    status: string;
    code: string;
    message: string[];
    obj: {
        createdBy: string;
        creationDateAndTime: number[];
        updatedBy: string | null;
        updateDateAndTime: number[] | null;
        collegeId: string;
        collegeName: string;
        collegeEmailId: string;
        collegeWebAddress: string;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        zipCode: string;
        province: string;
        country: string;
        collegePhoneNumber: string;
        collegeFaxNumber: string;
        collegeMission: string;
        collegeVision: string;
        active: boolean;
    }[];
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateCollege = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        let dispatch = useDispatch();
       

        // Fetching data using axios

        const [viewAllCollegeData, setViewAllCollegeData] = useState<ViewAllCollegeData | null>(null);

        const [countryList, setCountryList] = useState<Country[]>(gulfCountry.countries);
        const [countryDropDownOpen, setCountryDropDownOpen] = useState(false);
        const getOptionLabel = (option: Country) => option.name;
        const handleChange = (event: React.ChangeEvent<{}>, value: Country | null) => {
            if (value) {
                setCountry(value.name);
                if (countryError) {
                    setCountryError(false);
                }
            } else {
                console.log("No country selected.");
            }
        };

        const [collegeName, setCollegeName] = useState<string>(originalValues.collegeName);
        const [collegeEmailId, setCollegeEmailId] = useState<string>(originalValues.collegeEmailId);
        const [collegePhoneNumber, setCollegePhoneNumber] = useState<string>(originalValues.collegePhoneNumber);
        const [collegeFaxNumber, setCollegeFaxNumber] = useState<string>(originalValues.collegeFaxNumber);
        const [collegeMission, setCollegeMission] = useState<string>(originalValues.collegeMission);
        const [collegeVision, setCollegeVision] = useState<string>(originalValues.collegeVision);
        const [collegeWebAddress, setCollegeWebAddress] = useState<string>(originalValues.collegeWebAddress);
        const [addressLine1, setAddressLine1] = useState<string>(originalValues.addressLine1);
        const [addressLine2, setAddressLine2] = useState<string>(originalValues.addressLine2);
        const [addressLine3, setAddressLine3] = useState<string>(originalValues.addressLine3);
        const [zipCode, setZipCode] = useState<string>(originalValues.zipCode);
        const [province, setProvince] = useState<string>(originalValues.province);
        const [country, setCountry] = useState<string>(originalValues.country);

        // For handling errors

        const [CollegeNameError, setCollegeNameError] = useState<boolean>(false);
        const [CollegeEmailIdError, setCollegeEmailIdError] = useState<boolean>(false);
        const [CollegePhoneNumberError, setCollegePhoneNumberError] = useState<boolean>(false);
        const [CollegeFaxNumberError, setCollegeFaxNumberError] = useState<boolean>(false);
        const [CollegeMissionError, setCollegeMissionError] = useState<boolean>(false);
        const [CollegeVisionError, setCollegeVisionError] = useState<boolean>(false);
        const [CollegeWebAddressError, setCollegeWebAddressError] = useState<boolean>(false);
        const [addressLine1Error, setAddressLine1Error] = useState<boolean>(false);
        const [addressLine2Error, setAddressLine2Error] = useState<boolean>(false);
        const [addressLine3Error, setAddressLine3Error] = useState<boolean>(false);
        const [zipCodeError, setZipCodeError] = useState<boolean>(false);
        const [provinceError, setProvinceError] = useState<boolean>(false);
        const [countryError, setCountryError] = useState<boolean>(false);

        // For handling error messages
        const [CollegeNameErrorMessage, setCollegeNameErrorMessage] = useState("");
        const [CollegeWebAddressErrorMessage, setCollegeWebAddressErrorMessage] = useState("");
        const [addressLine1ErrorMessage, setAddressLine1ErrorMessage] = useState("");
        const [addressLine2ErrorMessage, setAddressLine2ErrorMessage] = useState("");
        const [addressLine3ErrorMessage, setAddressLine3ErrorMessage] = useState("");
        const [CollegeEmailIdErrorMessage, setCollegeEmailIdErrorMessage] = useState("");
        const [CollegePhoneNumberErrorMessage, setCollegePhoneNumberErrorMessage] = useState("");
        const [CollegeFaxNumberErrorMessage, setCollegeFaxNumberErrorMessage] = useState("");
        const [zipCodeErrorMessage, setCollegeZipCodeErrorMessage] = useState("")
        const [CollegeMissionErrorMessage, setCollegeMissionErrorMessage] = useState("")
        const [CollegeVisionErrorMessage, setCollegeVisionErrorMessage] = useState("")

        
        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);
        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            // setIsChecked(newValue);
            setIsChecked(!isChecked);
            // console.log('Switch value:', newValue);
          };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }
            if (accessToken !== null) {
                // @1) Fetching Users
                axios.get(readAPI.College, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        if (res.data.code === "200.200") {
                            setViewAllCollegeData(res.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (viewAllCollegeData !== null && accessToken !== null) {
                    // Set the validation errors
                    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    const numberRegex = /^[0-9]{10}/;
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

                    if (collegeFaxNumber === "") {
                        setCollegeFaxNumberError(true);
                    }
                    if (!numberRegex.test(collegeFaxNumber) || collegeFaxNumber.length !== 10) {
                        setCollegeFaxNumberErrorMessage("Please enter a valid fax number");
                        setCollegeFaxNumberError(true);
                    }
                    else {
                        setCollegeFaxNumberErrorMessage("");
                        setCollegeFaxNumberError(false);
                    }
                    if (collegeEmailId === "") {
                        setCollegeEmailIdError(true);
                    }
                    if (!emailRegex.test(collegeEmailId) && collegeEmailId.length !== 0) {
                        setCollegeEmailIdErrorMessage("Please enter valid email")
                        setCollegeEmailIdError(true)
                    }
                    else {
                        setCollegeEmailIdErrorMessage("")
                    }
                    if (collegeMission === "") {
                        setCollegeMissionError(true)
                    }
                    if (collegeVision === "") {
                        setCollegeVisionError(true)
                    }
                    if (collegePhoneNumber === "") {
                        setCollegePhoneNumberError(true)
                    }
                    if (!numberRegex.test(collegePhoneNumber) || collegePhoneNumber.length !== 10) {
                        setCollegePhoneNumberErrorMessage("Please enter valid phone number")
                        setCollegePhoneNumberError(true);
                    }
                    else {
                        setCollegePhoneNumberErrorMessage("")
                        setCollegePhoneNumberError(false);
                    }
                    if (collegeName === "") {
                        setCollegeNameErrorMessage("College Name is required")
                        setCollegeNameError(true);
                    }

                    if (!textRegex.test(collegeMission) && collegeMission.length !== 0) {
                        setCollegeMissionErrorMessage("Please Enter Only Alphabets")
                        setCollegeMissionError(true);
                    } else {
                        setCollegeMissionErrorMessage("")
                    }
                    if (!textRegex.test(collegeVision) && collegeVision.length !== 0) {
                        setCollegeVisionErrorMessage("Please Enter Only Alphabets")
                        setCollegeVisionError(true);
                    } else {
                        setCollegeVisionErrorMessage("")
                    }
                    if (collegeWebAddress === "") {
                        setCollegeWebAddressError(true);
                    }
                    if (!urlRegex.test(collegeWebAddress) && collegeWebAddress.length !== 0) {
                        setCollegeWebAddressErrorMessage("Invalid URL format")
                        setCollegeWebAddressError(true);
                    }
                    else {
                        setCollegeWebAddressErrorMessage("")
                    }
                    if (addressLine1 === "") {
                        setAddressLine1Error(true);
                    }
                    if (!textRegex.test(addressLine1) && addressLine1.length !== 0) {
                        setAddressLine1ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine1Error(true);
                    } else {
                        setAddressLine1ErrorMessage("")
                    }
                    if (addressLine1.length > 100) {
                        setAddressLine1ErrorMessage("Character Limit Exceeded Enter Less Than 100 Characters")
                        setAddressLine1Error(true);
                    }
                    if (addressLine2 === "") {
                        setAddressLine2Error(true);
                    }
                    if (addressLine2.length > 100) {
                        setAddressLine2ErrorMessage("Character Limit Exceeded enter less than 100 characters")
                        setAddressLine2Error(true);
                    }
                    if (!textRegex.test(addressLine2) && addressLine2.length !== 0) {
                        setAddressLine2ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine2Error(true);
                    } else {
                        setAddressLine2ErrorMessage("")
                    }
                    if (addressLine3 === "") {
                        setAddressLine3Error(true);
                    }
                    if (addressLine3.length > 100) {
                        setAddressLine3ErrorMessage("Character Limit Exceeded Enter Less Than 100 Characters")
                        setAddressLine3Error(true);
                    }
                    if (!textRegex.test(addressLine3) && addressLine3.length !== 0) {
                        setAddressLine3ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine3Error(true);
                    } else {
                        setAddressLine3ErrorMessage("")
                    }

                    if (zipCode === "") {
                        setZipCodeError(true)
                    }
                    if (province === "") {
                        setProvinceError(true);
                    }
                    if (country === "") {
                        setCountryError(true);
                    }

                    // Set the validation errors

                    if (collegeName !== "" && collegeEmailId !== "" && collegeFaxNumber !== "" && collegeMission !== "" &&
                        collegeVision !== "" && collegePhoneNumber !== "" && collegeWebAddress !== "" && addressLine1 !== ""
                        && addressLine2 !== "" && addressLine3 !== "" && zipCode !== "" && province !== "" && country !== ""
                    ) {
                        const formState = {
                            "collegeId": originalValues.collegeId,
                            "collegeName": collegeName,
                            "collegeEmailId": collegeEmailId,
                            "collegeMission": collegeMission,
                            "collegeVision": collegeVision,
                            "collegeFaxNumber": collegeFaxNumber,
                            "collegePhoneNumber": collegePhoneNumber,
                            "collegeWebAddress": collegeWebAddress,
                            "addressLine1": addressLine1,
                            "addressLine2": addressLine2,
                            "addressLine3": addressLine3,
                            "province": province,
                            "zipCode": zipCode,
                            "country": country,
                            "active": isChecked
                        };


                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `${collegeName} data has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                  

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CollegeNameTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.CollegeName.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.CollegeName.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CollegeNameError}
                                    helperText={CollegeNameErrorMessage}
                                    value={collegeName}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CollegeNameError) {
                                            setCollegeNameError(false);
                                        }
                                        setCollegeName(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CollegeEmailIdTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.CollegeEmailId.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.CollegeEmailId.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CollegeEmailIdError}
                                    helperText={CollegeEmailIdErrorMessage}
                                    value={collegeEmailId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CollegeEmailIdError) {
                                            setCollegeEmailIdError(false);
                                        }
                                        setCollegeEmailId(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CollegeWebAddressTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.CollegeWebAddress.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.CollegeWebAddress.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CollegeWebAddressError}
                                    helperText={CollegeWebAddressErrorMessage}
                                    value={collegeWebAddress}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CollegeWebAddressError) {
                                            setCollegeWebAddressError(false);
                                        }
                                        setCollegeWebAddress(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CollegePhoneNumberTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.CollegePhoneNumber.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.CollegePhoneNumber.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CollegePhoneNumberError}
                                    helperText={CollegePhoneNumberErrorMessage}
                                    value={collegePhoneNumber}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CollegePhoneNumberError) {
                                            setCollegePhoneNumberError(false);
                                        }
                                        setCollegePhoneNumber(e.target.value);
                                    }}
                                />
                            </Grid>

                            

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CollegeMissionTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.CollegeMission.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.CollegeMission.placeholder')}`}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={CollegeMissionError}
                                    helperText={CollegeMissionErrorMessage}
                                    value={collegeMission}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CollegeMissionError) {
                                            setCollegeMissionError(false);
                                        }
                                        setCollegeMission(e.target.value);
                                    }}
                                    multiline
                                    rows={10}
                                />
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CollegeVisionTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.CollegeVision.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.CollegeVision.placeholder')}`}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={CollegeVisionError}
                                    helperText={CollegeVisionErrorMessage}
                                    value={collegeVision}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CollegeVisionError) {
                                            setCollegeVisionError(false);
                                        }
                                        setCollegeVision(e.target.value);
                                    }}
                                    multiline
                                    rows={10}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="CollegeFaxIdTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.CollegeFaxNumber.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.CollegeFaxNumber.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={CollegeFaxNumberError}
                                    helperText={CollegeFaxNumberErrorMessage}
                                    value={collegeFaxNumber}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (CollegeFaxNumberError) {
                                            setCollegeFaxNumberError(false);
                                        }
                                        setCollegeFaxNumber(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine1TextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.AddressLine1.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.AddressLine1.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine1Error}
                                    helperText={addressLine1ErrorMessage}
                                    value={addressLine1}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine1Error) {
                                            setAddressLine1Error(false);
                                        }
                                        setAddressLine1(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine2TextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.AddressLine2.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.AddressLine2.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine2Error}
                                    helperText={addressLine2ErrorMessage}
                                    value={addressLine2}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine2Error) {
                                            setAddressLine2Error(false);
                                        }
                                        setAddressLine2(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine3TextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.AddressLine3.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.AddressLine3.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine3Error}
                                    helperText={addressLine3ErrorMessage}
                                    value={addressLine3}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine3Error) {
                                            setAddressLine3Error(false);
                                        }
                                        setAddressLine3(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="zipCodeTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.ZipCode.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.ZipCode.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={zipCodeError}
                                    // helperText={zipCodeErrorMessage}
                                    value={zipCode}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (zipCodeError) {
                                            setZipCodeError(false);
                                        }
                                        setZipCode(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="provinceTextField"
                                    label={t('Home.Header.Modals.College.policy.Inputs.Province.label')}
                                    placeholder={`${t('Home.Header.Modals.College.policy.Inputs.Province.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={provinceError}
                                    // helperText={provinceErrorMessage}
                                    value={province}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (provinceError) {
                                            setProvinceError(false);
                                        }
                                        setProvince(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>

                                <Autocomplete
                                    // {...countryDefaultProps}
                                    options={countryList}
                                    getOptionLabel={getOptionLabel}
                                    id="countryAutoComplete"
                                    autoHighlight
                                    value={countryList.find((Country) => Country.name === country)}
                                    open={countryDropDownOpen}
                                    // onOpen={() => {
                                    //     setcountryDropDownOpen(!countryDropDownOpen);
                                    // }}
                                    onClick={() => {
                                        setCountryDropDownOpen(true);
                                    }}
                                    onClose={() => {
                                        setCountryDropDownOpen(!countryDropDownOpen);
                                    }}
                                    // onOpen={() => {
                                    //     setOpen(true);
                                    // }}
                                    // onClose={() => {
                                    //     setOpen(false);
                                    // }}
                                    onChange={handleChange}
                                    
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label={t('Home.Header.Modals.College.policy.Inputs.Country.label')}
                                            variant="standard"
                                            placeholder='Select Country ...'
                                            helperText={(countryError) ? ("* Please select any Country.") : ("")}
                                            error={countryError}
                                            onClick={() => {
                                                setCountryDropDownOpen(true);
                                            }}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                    <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            // value={isChecked}
                                            // onChange={()=> setIsChecked(!isChecked)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

               

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCollege;