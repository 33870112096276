import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    Autocomplete,
    FormControl,
    FormControlLabel,
    Checkbox,
    FormHelperText,
    ListItemText,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FaPlus, FaWindowClose, FaEllipsisV } from "react-icons/fa";
import SnackBar from '../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import styles from "./style.module.css";
import readAPI from '../../data/api/read';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import levelJson from "../../../studyPlan/data/json/level.json";
import semesterJson from "../../../studyPlan/data/json/semester.json"
import yearJson from "../../../studyPlan/data/json/year.json"
import { CourseCodeListType, SOEvidenceService } from '../../../report/service/SOEvidenceService';
import { setYear } from 'date-fns';
import { CampusDetails, CollegeDetails, CommitteeDetails, DepartmentDetails, GlobalService, ProgramDetails, UserDetails } from '../../../../Service/GlobalService';
import { styled } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    '& > :not(style) ~ :not(style)': {
        marginTop: theme.spacing(5),
    },
}));

interface Row {
    collegeId: string;
    campusId: string;
    departmentId: string;
}

interface collegeOption {
    label: string;
    value: string;
}

interface campusOption {
    label: string;
    value: string;
}

interface departmentOption {
    label: string;
    value: string;
}

interface UpdateCommitteeMemberProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
    setFetchUpdate: any,
    studyPlanOfferedProgram?: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}
const UpdateCommitteeMember = React.forwardRef<UpdateRef, UpdateCommitteeMemberProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        studyPlanOfferedProgram
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);

        const [committeeList, setCommitteeList] = useState<CommitteeDetails>({
            data: [],
            errMessage: ""
        })
        const [member, setMember] = useState<any>(originalValues.id);
        const [committeeId, setCommitteeId] = useState(originalValues.committeeId);

        const [committeeNameError, setCommitteeNameError] = useState<boolean>(false);
        const [memberError, setMemberError] = useState<boolean>(false);

        const [instructorName, setInstructorName] = useState<any>([]);

        //@ committee list options
        const committeeListOptions = committeeList && committeeList?.data?.map((user: any) => ({
            key: user.committeeId,
            label: `${user.committeeName}`,
            value: user.committeeId

        }));
        const handleCommitteeChange = (event: any, newValue: any) => {
            setCommitteeId(newValue ? newValue.value : null);
        };

        //@ user list options
        const userListOptions = instructorName.map((user: any) => ({
            key: user.userId,
            label: `${user.firstName} ${user.lastName} : ${user.emailId}`,
            value: user.userId

        }));
        const handleMemberChange = (event: any, newValue: any) => {
            setMember(newValue);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        useEffect(() => {
            if (accessToken !== null) {
                const fetchCommittee = async () => {
                    const committeeData = await GlobalService.getCommittee(accessToken);
                    setCommitteeList(committeeData);
                }

                //calling function
                fetchCommittee();
            }
        }, []);

        const submitForm = () => {
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken !== null) {
                    setIsLoadingSpinner(true);
                    let hasError = false;
                    const isValidateForm = () => {
                        if (committeeId === "") {
                            setCommitteeNameError(true);
                            hasError = true;
                        }
                        else {
                            setCommitteeNameError(false);
                        }
                        if (member === "") {
                            setMemberError(true);
                            hasError = true;
                        }
                        else {
                            setMemberError(false);
                        }
                        return !hasError;
                    }


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    }
                    else {

                        const formState: any = {
                            memberId: originalValues.id,
                            committeeId: originalValues.committeeId,
                            active: true
                        };

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken,
                                    'Accept-Language': 'en'
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.status === 200) ? "success" : "error",
                                        message: (response.status === 200) ? `Committe Member has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    if (response.status === 200) {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                            setFetchUpdate(true);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    }


                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ paddingLeft: "30px" }}>
                                <Autocomplete
                                    options={committeeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={committeeListOptions.find((option: any) => option.value === committeeId) || null}
                                    onChange={handleCommitteeChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.committee.committeeMember.policy.Inputs.committeeName.label')}`}
                                            placeholder={`${t('Home.Header.Modals.committee.committeeMember.policy.Inputs.committeeName.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={committeeNameError}
                                            helperText={(committeeNameError) ? ("* Please Select Any Committee.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>

                            {/* <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ paddingLeft: "30px" }}>
                                <Autocomplete
                                    multiple
                                    options={userListOptions}
                                    getOptionLabel={option => option.label}
                                    value={userListOptions.find((option: any) => option.value === member)}
                                    onChange={handleMemberChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.committeeChair.label')}`}
                                            placeholder={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.committeeChair.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={memberError}
                                            helperText={(memberError) ? ("* Please Select Any user.") : ("")}
                                        />
                                    )}
                                />


                            </Grid> */}


                            {/* <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 2 }}>
                                <FormControlLabel
                                    label="Active"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }
                                />
                            </Grid> */}










                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCommitteeMember;