import * as React from 'react';
import styles from "./style.module.css";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { AiOutlineAim } from "react-icons/ai";
import {
    Typography,
    Button,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    Box
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { styled } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#3C6766',
    color: theme.palette.common.white,
    fontWeight: 'bold',
}));

interface ViewGoalModalProps {
    openViewGoalModal: any,
    currentLang: string,
    setOpenViewGoalModal: any,
    goalData: any
}

const ViewGoalModal: React.FC<ViewGoalModalProps> = ({
    openViewGoalModal,
    currentLang,
    setOpenViewGoalModal,
    goalData
}) => {



    const handleCloseViewGoalModal = () => {
        setOpenViewGoalModal(false)
    };

    const kpiList = useSelector((state: RootState) => state.sps.kpis);
    const user = useSelector((state: RootState) => state.userListData.data);
    const userMap: any = {};
    user?.forEach((userItem: any) => {
        userMap[userItem.userId] = userItem.firstName + " " + userItem.lastName;
    });


    return (
        <>
            <Dialog style={{ whiteSpace: 'normal', wordBreak: 'break-word' }} open={openViewGoalModal} onClose={handleCloseViewGoalModal} maxWidth="lg" fullWidth>
                <Box className={styles.modalStyle}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <DialogTitle>
                            <Box sx={{
                                display: "flex",
                                // alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                                flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                            }}>
                                <Box sx={{
                                    boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                                    width: 60,
                                    height: 60,
                                    borderRadius: 1.25,
                                    backgroundColor: "#fffefe",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <AiOutlineAim style={{ height: "35px", width: "35px", color: "#4f747a" }} />
                                </Box>
                                <Box sx={{ ml: 3, mt: 2 }}>
                                    <Typography variant="h5" sx={{
                                        color: "#3c6766",
                                        fontWeight: 500,
                                        // marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                                        display: "flex",
                                        flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                                    }}>
                                        Goal
                                    </Typography>

                                </Box>
                            </Box>
                        </DialogTitle>
                        <span style={{ padding: "30px" }}>
                            {new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                        </span>
                    </div>

                    <hr />

                    <Box sx={{
                        boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                    }}>

                        <DialogContent>

                            {/* <span style={{ fontWeight: "500", fontSize: "16px" }}>STRATEGIC PLAN DETAILS</span> */}

                            <TableContainer style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <Table style={{ border: "1px solid grey" }}>
                                    <TableBody>
                                        <TableRow className={styles.tableRow}>
                                            <TableCell component="th" scope="row" style={{ width: "200px" }}>
                                                Goal Name :
                                            </TableCell>
                                            <TableCell>{goalData?.goalName}</TableCell>
                                        </TableRow>
                                        <TableRow className={styles.tableRow}>
                                            <TableCell component="th" scope="row" style={{ width: "200px" }}>
                                                Goal Description :
                                            </TableCell>
                                            <TableCell>{goalData?.goalDescription}</TableCell>
                                        </TableRow>
                                        <TableRow className={styles.tableRow}>
                                            <TableCell component="th" scope="row" style={{ width: "200px" }}>
                                                Goal Manager :
                                            </TableCell>
                                            <TableCell>{userMap[goalData?.manager]}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <span style={{ fontWeight: "500", fontSize: "16px" }}>SUB GOAL DETAILS</span>
                            <TableContainer style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <Table style={{ border: "1px solid grey" }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell style={{ width: "200px" }}>Sub Goal Name</StyledTableCell>
                                            <StyledTableCell>Sub Goal Description</StyledTableCell>
                                            <StyledTableCell>Sub Goal Manager</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {goalData?.subGoal.map((item: any) => (
                                            <TableRow className={styles.tableRow}>
                                                <TableCell component="th" scope="row">
                                                    {item.goalName}
                                                </TableCell>
                                                <TableCell>{item.goalDescription}</TableCell>
                                                <TableCell>{item.manager}</TableCell>
                                            </TableRow>
                                        ))}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <span style={{ fontWeight: "500", fontSize: "16px" }}>KPI DETAILS</span>
                            <TableContainer style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <Table style={{ border: "1px solid grey" }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell >KPI Name</StyledTableCell>
                                            <StyledTableCell>KPI Description</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {goalData?.mappedKpi?.map((kpi: any, index: any) => (
                                            <TableRow key={index} className={styles.tableRow}>
                                                <TableCell component="th" scope="row" style={{width: "150px"}}>
                                                    {kpi.kpiName}
                                                </TableCell>
                                                <TableCell>{kpi.description}</TableCell>
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>

                    </Box>
                    <DialogActions>
                        <Button
                            // variant="contained"
                            sx={{
                                backgroundColor: '#fff',
                                // border: '1px solid #3c6766',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: '#fff',
                                },
                            }}
                            onClick={handleCloseViewGoalModal}
                        >
                            <Typography sx={{ color: "black" }} dir={currentLang === 'ar' ? 'rtl' : 'ltr'}>
                                Close
                            </Typography>
                        </Button>


                    </DialogActions>
                </Box>

            </Dialog>



        </>

    )

}
export default ViewGoalModal;