

import { GDRIVE_BASE_URL } from "../../../../../constants";
const createAPI = {
    UploadFileInFolder: `${GDRIVE_BASE_URL}/files/upload?uploadDirId=`,
    UploadFiles: `${GDRIVE_BASE_URL}/files/upload`,
    UploadFolder: `${GDRIVE_BASE_URL}/dir/upload`,
    DownloadFiles: `${GDRIVE_BASE_URL}/files/download`,
    RenameFileName : `${GDRIVE_BASE_URL}/files/rename`,
    RenameDirectoryName : `${GDRIVE_BASE_URL}/dir/rename`,
    MoveToBin: `${GDRIVE_BASE_URL}/dir/trash`,
    MakeACopy: `${GDRIVE_BASE_URL}/files/copy`,
    Restore: `${GDRIVE_BASE_URL}/dir/restore`,
    DeleteForever: `${GDRIVE_BASE_URL}/dir/delete`,
    DeleteAllForever : `${GDRIVE_BASE_URL}/dir/empty-trash`,
    CreateDirectory: `${GDRIVE_BASE_URL}/dir/create`,
    MoveToBinDirectory: `${GDRIVE_BASE_URL}/dir/trash`,
    AddToStarred: `${GDRIVE_BASE_URL}/starred/mark`,
    RemoveFromStarred: `${GDRIVE_BASE_URL}/starred/mark`,
    ChangeDirectoryColor: `${GDRIVE_BASE_URL}/dir/color`,
    LockFile : `${GDRIVE_BASE_URL}/files/lock/`,
    SearchFile : `${GDRIVE_BASE_URL}/files/search?searchInput`,
    ShareFile : `${GDRIVE_BASE_URL}/share`,
    globalSearch: `${GDRIVE_BASE_URL}/global/search`,
    moveFile: `${GDRIVE_BASE_URL}/files/move`,
    ShortCut: `${GDRIVE_BASE_URL}/files/short-cut`,
    
  };
  
  export default createAPI;
  