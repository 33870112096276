import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css"

const Inbox = lazy(() => import("../Components/Inbox"));
const Starred = lazy(() => import("../Components/Starred"));
const Snoozed = lazy(() => import("../Components/Snoozed"));
const Important = lazy(() => import("../Components/Important"));
const Drafts = lazy(() => import("../Components/Drafts"));
const Sent = lazy(() => import("../Components/Sent"));
const Social = lazy(() => import("../Components/Social"));
const Updates = lazy(() => import("../Components/Updates"));
const Forums = lazy(() => import("../Components/Forums"));
const Promotions = lazy(() => import("../Components/Promotions"));
const Chats = lazy(() => import("../Components/Chats"));
const AllMail = lazy(() => import("../Components/AllMail"));
const Bin = lazy(() => import("../Components/Bin"));
const Spam = lazy(() => import("../Components/Spam"));
const ManageLabels = lazy(() => import("../Components/ManageLabels"));


interface EqaMailRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    // courseCodeProps: any,
    // openUniversityModal: boolean,
    // setOpenUniversityModal: any,
}

const EqaMailRouter: FC<EqaMailRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,

    // courseCodeProps

    // openUniversityModal,
    // setOpenUniversityModal,

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
            <Routes>
                <Route
                    path="view"
                    element={
                        <Inbox
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
                />



                <Route path="inbox">
                    <Route
                        path="view"
                        element={
                            <Inbox
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="starred">
                    <Route
                        path="view"
                        element={
                            <Starred
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="snoozed">
                    <Route
                        path="view"
                        element={
                            <Snoozed
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="important">
                    <Route
                        path="view"
                        element={
                            <Important
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="drafts">
                    <Route
                        path="view"
                        element={
                            <Drafts
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="sent">
                    <Route
                        path="view"
                        element={
                            <Sent
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>

                <Route path="social">
                    <Route
                        path="view"
                        element={
                            <Social
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="updates">
                    <Route
                        path="view"
                        element={
                            <Updates
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="forums">
                    <Route
                        path="view"
                        element={
                            <Forums
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="promotions">
                    <Route
                        path="view"
                        element={
                            <Promotions
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="chats">
                    <Route
                        path="view"
                        element={
                            <Chats
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>



                <Route path="allMail">
                    <Route
                        path="view"
                        element={
                            <AllMail
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="spam">
                    <Route
                        path="view"
                        element={
                            <Spam
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="bin">
                    <Route
                        path="view"
                        element={
                            <Bin
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>


                <Route path="manageLabels">
                    <Route
                        path="view"
                        element={
                            <ManageLabels
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                    />
                </Route>















            </Routes>
        </Suspense>
    )

}

export default EqaMailRouter;
