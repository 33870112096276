import { BASE_URL } from "../../../../../constants";
import { PORTAL_URL } from "../../../../../constants";
const readAPI = {
    College: 
    `${BASE_URL}/EQACORE-SERVICE/colleges`,
    University: `${BASE_URL}/EQACORE-SERVICE/university`,
    Campus: 
    `${BASE_URL}/EQACORE-SERVICE/campus`,
    Department: 
    `${BASE_URL}/EQACORE-SERVICE/department`,
    Program: 
    `${BASE_URL}/EQACORE-SERVICE/programs`,
    ProgramType: 
    `${BASE_URL}/EQACORE-SERVICE/programTypes`,
    ProgramObjective: 
    `${BASE_URL}/EQACORE-SERVICE/StudentOutcome`,
    Courses: 
    `${BASE_URL}/EQACORE-SERVICE/courses`,
    CourseTypes: 
    `${BASE_URL}/EQACORE-SERVICE/courseTypes`,
    CourseObjective: 
    `${BASE_URL}/EQACORE-SERVICE/courseObjective`,
    CourseLearningOutcome: 
    `${BASE_URL}/EQACORE-SERVICE/courseClo`,
    CourseBook: 
    `${BASE_URL}/EQACORE-SERVICE/courseBook`,
    CourseBookByCourseCode: 
    `${BASE_URL}/EQACORE-SERVICE/courseBook/course`,
    Books: 
    `${BASE_URL}/EQACORE-SERVICE/books`,
    Topics: 
    `${BASE_URL}/EQACORE-SERVICE/courseTopic`,
    CourseCLOByCourseCodeOrCloId : `${BASE_URL}/EQACORE-SERVICE/courseCloByCourseCodeOrCloId`,
    CourseCLO : `${BASE_URL}/EQACORE-SERVICE/courseClo`,
    CourseCloSoMappingByCourseCode : `${BASE_URL}/EQACORE-SERVICE/getCloSoMapping/courseCode`,
    Assessments: 
    `${BASE_URL}/EQACORE-SERVICE/assessment/assessments/byLoggedInUser`,
    AssessmentType: `${BASE_URL}/EQACORE-SERVICE/assessment/assessmentTypes`,
    CloCovered: `${BASE_URL}/EQACORE-SERVICE/courseCloByCourseCodeOrCloId`,
    Syllabus: `${BASE_URL}/EQACORE-SERVICE/syllabus`,
    Offeredcourse : `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourses`,
    ActiveOfferedcourse : `${BASE_URL}/EQACORE-SERVICE/schedule/activeOfferedCourse`,
    OverallGradeByOfferedCourseId: `${BASE_URL}/EQACORE-SERVICE/assessment/fetchOverAllGrades/`,
    CourseByCourseCode: `${BASE_URL}/EQACORE-SERVICE/course`,
    SessionType: `${BASE_URL}/EQACORE-SERVICE/schedule/sessionTypes`,
    ProjectComponent: `${BASE_URL}/EQACORE-SERVICE/configuration/projectComponent`,
    SessionType: `${BASE_URL}/EQACORE-SERVICE/schedule/sessionTypes`,
    FetchUsers: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUsers`,
    AssessmentMethod: `${BASE_URL}/EQACORE-SERVICE/assessmentMethod/`,
    ReadCoordinator: `${BASE_URL}/EQACORE-SERVICE/core/coordinator`,
    FetchDepartment: `${BASE_URL}/EQACORE-SERVICE/departmentBy`,
    FetchCollege: `${BASE_URL}/EQACORE-SERVICE/getCollegeByCollegeId`,
    fetchOverAllGradesWithSummary : `${BASE_URL}/EQACORE-SERVICE/assessment/fetchOverAllGradesWithSummary`
    
  };
  
  export default readAPI;
  