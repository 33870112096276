interface Row {
    kpiName: string;
    description: string;
    degree: string;
}

interface Rowb {
    program: any;
    kpiName: any;
    programId: any;
    kpiId: any;
    target: any;
    benchMark: any;
    newTarget: any;
    minimumTarget: any;
}

interface SPSState {
    strategicPlans: any[];
    initiatives: any[];
    kpis: Row[];
    kpiValue: any[];
    goals: any[];
    strategicPlanDetailed: any[];
}

const initialState: SPSState = {
    strategicPlans: [],
    initiatives: [],
    kpis: [],
    kpiValue: [],
    goals: [],
    strategicPlanDetailed: []
};

const spsReducer = (state = initialState, action: any): SPSState => {
    switch (action.type) {
        case 'ADD_STRATEGIC_PLAN_DETAILED':
            return {
                ...state,
                strategicPlanDetailed: [...state.strategicPlanDetailed, action.payload.data]
            }
        case 'CLEAR_STRATEGIC_PLAN_DETAILED':
            return {
                ...state,
                strategicPlanDetailed: []
            }
        case 'ADD_STRATEGIC_PLAN':
            return {
                ...state,
                strategicPlans: [...state.strategicPlans, action.payload.data],
            };
        case 'CLEAR_STRATEGIC_PLANS':
            return {
                ...state,
                strategicPlans: [],
            };
        case 'CLEAR_KPI':
            return {
                ...state,
                kpis: [],
            };
        case 'CLEAR_KPI_VALUE':
            return {
                ...state,
                kpiValue: [],
            };
        case 'CLEAR_INITIATIVE':
            return {
                ...state,
                initiatives: [],
            };
        case 'CLEAR_GOAL':
            return {
                ...state,
                goals: [],
            };


        case 'SET_STRATEGIC_PLANS':
            return {
                ...state,
                strategicPlans: action.payload.data,
            };
        case 'REMOVE_STRATEGIC_PLAN':
            return {
                ...state,
                strategicPlans: state.strategicPlans.filter((_, index) => index !== action.payload),
            };
        case 'UPDATE_STRATEGIC_PLAN':
            return {
                ...state,
                strategicPlans: state.strategicPlans.map((plan, index) =>
                    index === action.payload.index ? { ...plan, ...action.payload.updatedPlan } : plan
                ),
            };
        case 'ADD_GOAL':
            return {
                ...state,
                goals: [...state.goals, action.payload.data],
            };
        case 'REMOVE_GOAL':
            return {
                ...state,
                goals: state.goals.filter((_, index) => index !== action.payload),
            };
        case 'UPDATE_GOAL':
            return {
                ...state,
                goals: state.goals.map((goal, index) =>
                    index === action.payload.index ? { ...goal, ...action.payload.updatedGoal } : goal
                ),
            };
        case 'ADD_INITIATIVE':
            return {
                ...state,
                initiatives: [...state.initiatives, action.payload.data],
            }
        case 'SET_INITIATIVE':
            return {
                ...state,
                initiatives: action.payload.data
            }
        case 'REMOVE_INITIATIVE':
            return {
                ...state,
                initiatives: state.initiatives.filter((_, index) => index !== action.payload),
            };
        case 'UPDATE_INITIATIVE':
            return {
                ...state,
                initiatives: state.initiatives.map((initiative, index) =>
                    index === action.payload.index ? { ...initiative, ...action.payload.updatedInitiative } : initiative
                ),
            };
        case 'ADD_KPI':
            return {
                ...state,
                kpis: [...state.kpis, ...(Array.isArray(action.payload.data) ? action.payload.data : [])],
            }
        case 'SET_KPI':
            return {
                ...state,
                kpis: action.payload
            }
        case 'REMOVE_KPI':
            return {
                ...state,
                kpis: state.kpis.filter((_, index) => index !== action.payload),
            };
        case 'UPDATE_KPI':
            return {
                ...state,
                kpis: state.kpis.map((kpi, index) =>
                    index === action.payload.index ? { ...kpi, ...action.payload.updatedKpi } : kpi
                ),
            };
        case 'ADD_KPI_VALUE':
            return {
                ...state,
                kpiValue: [...state.kpiValue, ...(Array.isArray(action.payload.data) ? action.payload.data : [])],
            }
        case 'SET_KPI_VALUE':
            return {
                ...state,
                kpiValue: action.payload
            }
        case 'REMOVE_KPI_VALUE':
            return {
                ...state,
                kpiValue: state.kpiValue.filter((_, index) => index !== action.payload),
            };
        case 'UPDATE_KPI_VALUE':
            return {
                ...state,
                kpiValue: state.kpiValue.map((plan, index) =>
                    index === action.payload.index ? { ...plan, ...action.payload.updatedKpiValue } : plan
                ),
            };
        default:
            return state;
    }
};

export default spsReducer;