import axios from 'axios';
import readAPI from '../data/api/read';
import { ICourseCLO, ISOEvidence, IOfferedCourse, IProgramObjective, ICLOSoMapping, ICourse } from '../model/SOEvidence';
export type CourseCodeListType = {
    data: IOfferedCourse[],
    errMessage: string
}

type StateSOEvidenceType = {
    data: ISOEvidence[],
    errMessage: string
}

type StateProgramObjectiveType = {
    data: IProgramObjective[],
    errMessage: string
}

export type StateCourseCLOType = {
    data: ICourseCLO[],
    errMessage: string
}

export type StateCLOSOMappingType = {
    data: ICLOSoMapping[],
    errMessage: string
}

export type CourseType = {
    data: ICourse,
    errMessage: string
}

//@function for fetching offered course by loggedIn user
async function getOfferedCourseByLoggedInUser(accessToken: string): Promise<CourseCodeListType> {
    let url: string = readAPI.Offeredcourses;
    let header: string = accessToken;
    let courseCodeListType: CourseCodeListType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            courseCodeListType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                courseCodeListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            courseCodeListType.errMessage = err.message;
            courseCodeListType.data = [];
        })
    return courseCodeListType;
}

//@function for fetching so evidence
async function getSOEvidence(accessToken: string, offeredCourseId: string): Promise<StateSOEvidenceType> {
    let url: string = readAPI.soEvidence;
    let header: string = accessToken;
    let soEvidenceType: StateSOEvidenceType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}/${offeredCourseId}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            soEvidenceType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                soEvidenceType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            soEvidenceType.errMessage = err.message;
            soEvidenceType.data = [];
        })

    return soEvidenceType;
}

//@ function for fetching program objective by program id
async function getProgramObjective(accessToken: string, programId: string): Promise<StateProgramObjectiveType> {
    let url: string = readAPI.ProgramObjective;
    let header: string = accessToken;
    let programObjectiveType: StateProgramObjectiveType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}/${programId}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            programObjectiveType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                programObjectiveType.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            programObjectiveType.errMessage = err.message;
            programObjectiveType.data = [];
        })

    return programObjectiveType;
}

//@ function for fetchig course CLO
async function getCourseCLO(accessToken: string, courseCode: string): Promise<StateCourseCLOType> {
    let url: string = readAPI.CourseCLO;
    let header: string = accessToken;
    let courseCLOType: StateCourseCLOType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}?courseCode=${courseCode}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            courseCLOType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                courseCLOType.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            courseCLOType.errMessage = err.message;
            courseCLOType.data = [];
        })

    return courseCLOType;
}


//@ function for fetchig CLO SO Mapping
async function getCourseCLOSOMapping(accessToken: string, courseCode: string): Promise<StateCLOSOMappingType> {
    let url: string = readAPI.CourseCLOSOMappingByCourseCode;
    let header: string = accessToken;
    let CLOSOMappingType: StateCLOSOMappingType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}/${courseCode}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            CLOSOMappingType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                CLOSOMappingType.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            CLOSOMappingType.errMessage = err.message;
            CLOSOMappingType.data = [];
        })

    return CLOSOMappingType;
}


//@ function for fetchig course by course code
async function getCourseByCourseCode(accessToken: string, courseCode: string): Promise<CourseType> {
    let url: string = readAPI.CourseByCourseCode;
    let header: string = accessToken;
    let courseType: CourseType = {
        data: {} as ICourse,
        errMessage: ""
    }
    await axios.get(`${url}/${courseCode}`, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            courseType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                courseType.errMessage = res.data.error;
            }

        })
        .catch((err) => {
            courseType.errMessage = err.message;
        })

    return courseType;
}

export const SOEvidenceService = {
    getOfferedCourseByLoggedInUser,
    getSOEvidence,
    getProgramObjective,
    getCourseCLO,
    getCourseCLOSOMapping,
    getCourseByCourseCode
}

