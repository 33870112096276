import { Annual_Program_Report, BASE_URL } from "../../../../../constants";
import { PORTAL_URL } from "../../../../../constants";
import { STUDYPLAN_URL } from "../../../../../constants";
const deleteAPIS = {
      DeleteCollege:
      `${BASE_URL}/EQACORE-SERVICE/deleteCollege/`,
      DeleteCampus:
      `${BASE_URL}/EQACORE-SERVICE/deleteCampus/`,
      DeleteDepartment:
      `${BASE_URL}/EQACORE-SERVICE/deleteDepartment/`,
      DeleteProgram:
      `${BASE_URL}/EQACORE-SERVICE/deleteProgram/`,
      DeleteProgramType:
      `${BASE_URL}/EQACORE-SERVICE/deleteProgramType/`,
      DeleteUniversity:
      `${BASE_URL}/EQACORE-SERVICE/deleteUniversity/`,
      DeleteObjective:
      `${BASE_URL}/EQACORE-SERVICE/deleteStudentOutcome/`,
      DeleteProgramObjective: 
      `${BASE_URL}/EQACORE-SERVICE/deleteProgramObjective/`,
      DeleteCourse: 
      `${BASE_URL}/EQACORE-SERVICE/deleteCourse/`,
      DeleteCourseType: 
      `${BASE_URL}/EQACORE-SERVICE/deleteCourseType/`,
      DeleteCourseObjective: 
      `${BASE_URL}/EQACORE-SERVICE/deleteCourseObjective/`,
      DeleteCLO: 
      `${BASE_URL}/EQACORE-SERVICE/deleteCourseClo/`,
      DeleteCourseBook: 
      `${BASE_URL}/EQACORE-SERVICE/deleteCourseBook/`,
      DeleteBook: 
      `${BASE_URL}/EQACORE-SERVICE/deleteBook/`,
      DeleteTopic: 
      `${BASE_URL}/EQACORE-SERVICE/deleteCourseTopicByCriteria`,
      DeleteCLOSOMapping: 
      `${BASE_URL}/EQACORE-SERVICE/deleteCloSoMapping/`,
      DeleteAssessment: 
      `${BASE_URL}/EQACORE-SERVICE/assessment/deleteAssessment/`,
      DeleteAssessmentType: 
      `${BASE_URL}/EQACORE-SERVICE/assessment/deleteAssessmentType/`,
      DeleteOverAllGrade : `${BASE_URL}/EQACORE-SERVICE/assessment/deleteOverAllGrade`,
      DeleteProjectSubComponent : `${BASE_URL}/EQACORE-SERVICE/configuration/projectSubComponent/`,
      DeleteAssessmentMethod : `${BASE_URL}/EQACORE-SERVICE/deleteAssessmentMethod/`,
      DeleteCoordinator: `${BASE_URL}/EQACORE-SERVICE/core/coordinator`,
      DeleteSurveyLinkDetails: "https://datadimens.com:8766/eqa-survey-service/api/v1.0/survey?ids=",
      DeleteDocumentType : `${PORTAL_URL}/portal-service/document/type/permanent`,
      DeleteStudyPlan : `${STUDYPLAN_URL}/study-plan?ids=`,
      DeleteCourseStudyPlan : `${STUDYPLAN_URL}/study-course-detail?ids=`,
      DeleteLocation : `${BASE_URL}/EQAMOM-SERVICE/mom/location/deleteLocationByLocId/`,
      DeleteCommittee : `${BASE_URL}/EQAMOM-SERVICE/mom/committee/`,
      DeleteAnnualProgramReport : `${Annual_Program_Report}/annual-program-report/task-detail?ids=`
  };
  
  export default deleteAPIS;