interface TajweedCoreReducerState {
    college: any | null;
    university: any | null;
    campus: any | null;
    department: any | null;
    program: any | null;
    programType: any | null;
    programGoal: any | null;
}

const initialState: TajweedCoreReducerState = {
    college: null,
    university: null,
    campus: null,
    department: null,
    program: null,
    programType: null,
    programGoal: null,
};

const tajweedReducer = (state = initialState, action: any): TajweedCoreReducerState => {
    switch (action.type) {
        case 'COLLEGE':
            return {
                ...state,
                college: action.payload.data,
            };
        case 'UNIVERSITY':
            return {
                ...state,
                university: action.payload.data
            }
        case 'CAMPUS':
            return {
                ...state,
                campus: action.payload.data
            }
        case 'DEPARTMENT':
            return {
                ...state,
                department: action.payload.data
            }
        case 'PROGRAM':
            return {
                ...state,
                program: action.payload.data
            }
        case 'PROGRAM_TYPE':
            return {
                ...state,
                programType: action.payload.data
            }
        case 'PROGRAM_GOAL':
            return {
                ...state,
                programGoal: action.payload.data
            }
        default:
            return state;
    }
};

export default tajweedReducer;