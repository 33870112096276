import { useState, FC, Dispatch, SetStateAction } from "react";
// This is a React Router v6 app
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

// $$$$$$$$$$$$$$$$$$$$ Importing Components and Pages $$$$$$$$$$$$$$$$$$$$

// 1) Home Page
import Home from "../Pages/Home";
// 2) Login Page
import Login from "../Pages/Login";
// 3) Login2 Page
import Login2 from "../Pages/Login2";
// 4) Forgot Password Page
import ForgetPassWord from "../Pages/ForgetPassWord";
// 8) Roles Page
import ViewRoles from '../Components/Pages/Home/UserManagement/Roles/ViewRoles';
// 9) Settings Page
import Settings from '../Components/Pages/Home/Settings/Main';
import SurveyRoutes from "../Apps/survey/SurveyRouter";
import PortalRouter from "../Apps/portal/PortalRouter";
import SystemPreferenceRouter from "../Apps/systemPreferences/SystemPreferencesRouter";
import ReportRouter from "../Apps/report/ReportRouter";
import ScheduleCourseRouter from "../Apps/scheduleCourse/ScheduleCourseRouter";
import AssessmentRouter from "../Apps/assessment/AssessmentRouter";
import AlarmRouter from "../Apps/alarm/AlarmRouter";
import EqaCoreRouter from "../Apps/eqa-core/EqaCoreRouter";
import AccountRouter from "../Components/Pages/Home/UserManagement/AccountRouter";
import StudyPlanRouter from "../Apps/studyPlan/StudyPlanRouter";
import AnnouncementRouter from "../Apps/announcement/AnnouncementRouter";
import MoMRouter from "../Apps/mom/MomRouter";
import EqaFormRouter from "../Apps/eqaForm/EqaFormRouter";
import EqaDriveRouter from "../Apps/eqaDrive/EqaDriveRouter";
import EqaMailRouter from "../Apps/eqaMail/eqaMailRouter";
import CommitteRouter from "../Apps/committee/CommitteRouter";
import Home1 from "../Pages/Home copy";
import ThankYou from "../Apps/survey/Components/ThankYou";
import CompletedSurvey from "../Apps/survey/Components/CompletedSurvey";
import SurveyForm from "../Apps/survey/Components/SurveyForm";
import ResetPassword from "../Pages/ResetPassword";
import SpsRouter from "../Apps/sps/SpsRouter";
import QmsRouter from "../Apps/qms/QmsRouter";
// import ResetPassword from "../Pages/ForgetPassWord copy";

// $$$$$$$$$$$$$$$$$$$$ Importing Components and Pages $$$$$$$$$$$$$$$$$$$$

interface AppRouterProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    courseCodeProps: any,
    // openUniversityModal: boolean,
    // setOpenUniversityModal: any,
}

interface HomeWrapperProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    isMinified: boolean;
    setIsMinified: Dispatch<SetStateAction<boolean>>;
    setShowHeader: any;
    currentTab: any;
    setCurrentTab: any;
    mobileViewContainer: any;
    currentLang: string;
    setCurrentLang: any;
    courseCodeProps: any;
    sidebarAppsListArray: any;
    setSidebarAppsListArray: any;
}

const AppRouter: FC<AppRouterProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,

    courseCodeProps

    // openUniversityModal,
    // setOpenUniversityModal,

}): JSX.Element => {

    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<boolean>(false);

    const [openUniversityModal, setOpenUniversityModal] = useState<boolean>(false);

    const [openAssessmentModal, setOpenAssessmentModal] = useState<boolean>(false);

    const [openCollegeModal, setOpenCollegeModal] = useState<boolean>(false);

    const [openCampusModal, setOpenCampusModal] = useState<boolean>(false);

    const [openDepartmentModal, setOpenDepartmentModal] = useState<boolean>(false);

    const [openProgramModal, setOpenProgramModal] = useState<boolean>(false);

    const [openProgramTypeModal, setOpenProgramTypeModal] = useState<boolean>(false);

    const [openProgramObjectiveModal, setOpenProgramObjectiveModal] = useState<boolean>(false);

    const [openManualScheduleCourseModal, setOpenManualScheduleCourseModal] = useState<boolean>(false);

    const [openManageLocationModal, setOpenManageLocationModal] = useState<boolean>(false);

    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);

    return (
        <Router>
            <Routes>
                <Route path="/login" element={
                    <LoginWrapper
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        sidebarAppsListArray={sidebarAppsListArray}
                        setSidebarAppsListArray={setSidebarAppsListArray}
                    />}
                />
                <Route path="/" element={
                    <Login2Wrapper
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        sidebarAppsListArray={sidebarAppsListArray}
                        setSidebarAppsListArray={setSidebarAppsListArray}
                    />}
                />
                <Route path="/forgetpassword" element={<ForgetPassWord currentLang={currentLang} setShowHeader={setShowHeader} />} />
                <Route path="/resetpassword?" element={<ResetPassword currentLang={currentLang} setShowHeader={setShowHeader} />} />

                <Route path={"survey/thankyou"} element={<ThankYou
                    isMinified={isMinified}
                    setIsMinified={setIsMinified}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}
                />}
                />
                <Route path={"survey/completed"} element={<CompletedSurvey
                    isMinified={isMinified}
                    setIsMinified={setIsMinified}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}
                />}
                />

                <Route path={"survey/:restUrl"} element={<SurveyForm
                    isMinified={isMinified}
                    setIsMinified={setIsMinified}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}
                />}
                />

                <Route path="*" element={
                    <HomeWrapper
                        setShowHeader={setShowHeader}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        // For minified sidebar
                        isMinified={isMinified}
                        setIsMinified={setIsMinified}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        courseCodeProps={courseCodeProps}

                        // Sidebar Apps List
                        sidebarAppsListArray={sidebarAppsListArray}
                        setSidebarAppsListArray={setSidebarAppsListArray}
                    />
                } />
            </Routes>


        </Router >
    )
}

const LoginWrapper: FC<{
    setShowHeader: any;
    currentTab: any;
    setCurrentTab: any;
    mobileViewContainer: any;
    sidebarAppsListArray: any;
    setSidebarAppsListArray: any;

}> = ({
    setShowHeader,
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    sidebarAppsListArray,
    setSidebarAppsListArray
}): JSX.Element => {
        // const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
        return (
            <Login
                setShowHeader={setShowHeader}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                mobileViewContainer={mobileViewContainer}
                sidebarAppsListArray={sidebarAppsListArray}
                setSidebarAppsListArray={setSidebarAppsListArray}
            />
        );
    };


const Login2Wrapper: FC<{
    setShowHeader: any;
    currentTab: any;
    setCurrentTab: any;
    mobileViewContainer: any
    sidebarAppsListArray: any;
    setSidebarAppsListArray: any;
}> = ({
    setShowHeader,
    currentTab,
    setCurrentTab,
    mobileViewContainer,

}): JSX.Element => {
        const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
        return (
            <Login2
                setShowHeader={setShowHeader}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                mobileViewContainer={mobileViewContainer}
                sidebarAppsListArray={sidebarAppsListArray}
                setSidebarAppsListArray={setSidebarAppsListArray}
            />
        );
    };


const HomeWrapper: FC<HomeWrapperProps> = ({
    isOpen,
    setIsOpen,
    isMinified,
    setIsMinified,
    setShowHeader,
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    currentLang,
    setCurrentLang,
    courseCodeProps,
    sidebarAppsListArray,
    setSidebarAppsListArray
}) => {
    // const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    // const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    // const [isMinified, setIsMinified] = useState<Boolean>(false);

    const [openUniversityModal, setOpenUniversityModal] = useState<boolean>(false);

    const [openAssessmentModal, setOpenAssessmentModal] = useState<boolean>(false);

    const [openCollegeModal, setOpenCollegeModal] = useState<boolean>(false);

    const [openCampusModal, setOpenCampusModal] = useState<boolean>(false);

    const [openDepartmentModal, setOpenDepartmentModal] = useState<boolean>(false);

    const [openProgramModal, setOpenProgramModal] = useState<boolean>(false);

    const [openProgramTypeModal, setOpenProgramTypeModal] = useState<boolean>(false);

    const [openProgramObjectiveModal, setOpenProgramObjectiveModal] = useState<boolean>(false);

    const [openManualScheduleCourseModal, setOpenManualScheduleCourseModal] = useState<boolean>(false);

    const [openManageLocationModal, setOpenManageLocationModal] = useState<boolean>(false);

    const [openAddInitiativeModal, setOpenAddInitiativeModal] = useState<boolean>(false);

    const [openAddGoalModal, setOpenAddGoalModal] = useState<boolean>(false);

    const [openAddStrategicPlanModal, setOpenAddStrategicPlanModal] = useState<boolean>(false);
    return (

        <Home
            setShowHeader={setShowHeader}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            // For minified sidebar
            isMinified={isMinified}
            setIsMinified={setIsMinified}
            currentLang={currentLang}
            setCurrentLang={setCurrentLang}

            // Sidebar Apps List
            sidebarAppsListArray={sidebarAppsListArray}
            setSidebarAppsListArray={setSidebarAppsListArray}
            subComponent={
                <Routes>
                    {/* <Route index path={"/login"} element={<Login
                            setShowHeader={setShowHeader}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            mobileViewContainer={mobileViewContainer}

                            // Sidebar Apps List
                            sidebarAppsListArray={sidebarAppsListArray}
                            setSidebarAppsListArray={setSidebarAppsListArray}
                        />}
                        />
                        <Route index path={"/"} element={<Login2
                            setShowHeader={setShowHeader}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            mobileViewContainer={mobileViewContainer}

                            // Sidebar Apps List
                            sidebarAppsListArray={sidebarAppsListArray}
                            setSidebarAppsListArray={setSidebarAppsListArray}
                        />}
                        /> */}

                    {/* @ Survey app routes */}

                    <Route path="/survey/*" element={<SurveyRoutes
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />


                    {/* @ Portal app routes */}

                    <Route path="/portal/*" element={<PortalRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />


                    {/* @ System preferences app routes */}

                    <Route path="/systemPreferences/*" element={<SystemPreferenceRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        openManageLocationModal={openManageLocationModal}
                        setOpenManageLocationModal={setOpenManageLocationModal}
                    />} />

                    {/* @ Report app routes */}

                    <Route path="/report/*" element={<ReportRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />

                    {/* @ Schedule course app routes */}

                    <Route path="/scheduleCourse/*" element={<ScheduleCourseRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        openManualScheduleCourseModal={openManualScheduleCourseModal}
                        setOpenManualScheduleCourseModal={setOpenManualScheduleCourseModal}
                    />} />


                    {/* @ Assessment app routes */}

                    <Route path="/assessment/*" element={<AssessmentRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        openAssessmentModal={openAssessmentModal}
                        setOpenAssessmentModal={setOpenAssessmentModal}
                    />} />

                    {/* @ Alarm app routes */}

                    <Route path="/alarm/*" element={<AlarmRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        openAssessmentModal={openAssessmentModal}
                        setOpenAssessmentModal={setOpenAssessmentModal}
                    />} />

                    {/* @ Eqa-Core app routes */}
                    <Route path="/core/*" element={<EqaCoreRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        openUniversityModal={openUniversityModal}
                        setOpenUniversityModal={setOpenUniversityModal}
                        openCollegeModal={openCollegeModal}
                        setOpenCollegeModal={setOpenCollegeModal}
                        openCampusModal={openCampusModal}
                        setOpenCampusModal={setOpenCampusModal}
                        openDepartmentModal={openDepartmentModal}
                        setOpenDepartmentModal={setOpenDepartmentModal}
                        openProgramModal={openProgramModal}
                        setOpenProgramModal={setOpenProgramModal}
                        openProgramTypeModal={openProgramTypeModal}
                        setOpenProgramTypeModal={setOpenProgramTypeModal}
                        openProgramObjectiveModal={openProgramObjectiveModal}
                        setOpenProgramObjectiveModal={setOpenProgramObjectiveModal}
                        courseCodeProps={courseCodeProps}
                    />} />


                    <Route path="/account/*" element={<AccountRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />

                    <Route path="/studyPlan/*" element={<StudyPlanRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />


                    <Route path="/committee/*" element={<CommitteRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />

                    {/* @ Announcement app routes */}

                    <Route path="/announcement/*" element={<AnnouncementRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />



                    {/* @ Minutes of Meeting app routes */}

                    <Route path="/mom/*" element={<MoMRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />

                    <Route path="/sps/*" element={<SpsRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        openAddInitiativeModal={openAddInitiativeModal}
                        setOpenAddInitiativeModal={setOpenAddInitiativeModal}
                        openAddGoalModal={openAddGoalModal}
                        setOpenAddGoalModal={setOpenAddGoalModal}
                        openAddStrategicPlanModal={openAddStrategicPlanModal}
                        setOpenAddStrategicPlanModal={setOpenAddStrategicPlanModal}
                    />} />

                    <Route path="/qms/*" element={<QmsRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                        openAddInitiativeModal={openAddInitiativeModal}
                        setOpenAddInitiativeModal={setOpenAddInitiativeModal}
                        openAddGoalModal={openAddGoalModal}
                        setOpenAddGoalModal={setOpenAddGoalModal}
                        openAddStrategicPlanModal={openAddStrategicPlanModal}
                        setOpenAddStrategicPlanModal={setOpenAddStrategicPlanModal}
                    />} />


                    {/* @ EQA Form app routes */}

                    <Route path="/form/*" element={<EqaFormRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />

                    <Route path="/drive/*" element={<EqaDriveRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />

                    <Route path="/mail/*" element={<EqaMailRouter
                        setShowHeader={setShowHeader}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        mobileViewContainer={mobileViewContainer}
                        currentLang={currentLang}
                        setCurrentLang={setCurrentLang}
                    />} />



                    {/* (((((((((((((((((((((( For Viewing )))))))))))))))))))))) */}

                    {/* 3) */}

                    {/* 4) */}
                    <Route path="role">
                        <Route
                            path={"view"}
                            element={
                                <ViewRoles
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    // For minified sidebar
                                    isMinified={isMinified}
                                    setIsMinified={setIsMinified}
                                    currentLang={currentLang}
                                    setCurrentLang={setCurrentLang}

                                />
                            }
                        />
                    </Route>

                    {/* (((((((((((((((((((((( For Viewing )))))))))))))))))))))) */}


                    <Route path="settings">
                        <Route
                            path={"general"}
                            element={
                                <Settings
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    // For minified sidebar
                                    isMinified={isMinified}
                                    setIsMinified={setIsMinified}
                                    currentLang={currentLang}
                                    setCurrentLang={setCurrentLang}
                                />
                            }
                        />
                    </Route>
                    {/* <Route path={"/login"} element={<Login setShowHeader={setShowHeader} />} /> */}
                    {/* <Route path={"/forgetpassword"} element={<ForgetPassWord setShowHeader={setShowHeader} />} /> */}
                    <Route
                        path={"/*"}
                        element={
                            <div>
                                <br />
                                <br />
                                <h1 className='text-center text-dark mt-4'>404: Not Found</h1>
                            </div>
                        }
                    />
                </Routes>
            }
        />
    );
};

export default AppRouter;