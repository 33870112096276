import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css";

const ManageCommittee = lazy(() => import("../pages/ManageCommittee"))
interface CommitteeProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any
}

const CommitteRouter: FC<CommitteeProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
        <Routes>
            <Route path="manageCommittee">
                <Route
                    path={"view"}
                    element={
                            <ManageCommittee
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                setCurrentLang={setCurrentLang}

                            />
                        }
                />
            </Route>

        </Routes>
        </Suspense>
    )

}

export default CommitteRouter;
