const initialState = {
    data: null,
  };
  
  const assessmentByLoggedInUserReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'ASSESSMENT_BY_LOGGEDIN_USER':
        return {
          ...state,
          data: action.payload.data,
        };
      default:
        return state;
    }
  };
  
  export default assessmentByLoggedInUserReducer;