import {BASE_URL} from '../../../../../../src/constants'
const readAPI = {
    Assessment: 
    `${BASE_URL}/EQACORE-SERVICE/assessment/assessments/byLoggedInUser`,
    FetchCourseName : `${BASE_URL}/EQACORE-SERVICE/course`,
    AssessmentType: `${BASE_URL}/EQACORE-SERVICE/assessment/assessmentTypes`,
    FetchMarksDetails : `${BASE_URL}/EQACORE-SERVICE/assessment/fetchMarksDetails`,
    OfferedCourse : `${BASE_URL}/EQACORE-SERVICE/schedule/offeredCourse`,
    AssessmentById : `${BASE_URL}/EQACORE-SERVICE/assessment/assessments`,
    ProjectTemplate : `${BASE_URL}/EQACORE-SERVICE/configuration/projectComponent`,
    SessionTypeById: `${BASE_URL}/EQACORE-SERVICE/schedule/sessionType`,
    // AssessmentType: `${BASE_URL}/EQACORE-SERVICE/assessment/assessmentTypes`,
    CourseByCourseCode: `${BASE_URL}/EQACORE-SERVICE/course`,
    FetchUsers: `${BASE_URL}/IDENTITY-SERVICE/privileges/fetchUsers`,
    FetchDepartment: `${BASE_URL}/EQACORE-SERVICE/departmentBy`,
    FetchCollege: `${BASE_URL}/EQACORE-SERVICE/getCollegeByCollegeId`

  };
  
  export default readAPI;
  