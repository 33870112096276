import { BASE_URL } from "../../../../../constants";
const deleteAPIScheduleCourse = {
      DeleteOfferedCourse:
      `${BASE_URL}/EQACORE-SERVICE/schedule/deleteOfferedCourse/`,
      DeleteCourseCoordinator: 
      `${BASE_URL}/EQACORE-SERVICE/core/coordinator/`,
      DeleteSessionType: 
      `${BASE_URL}/EQACORE-SERVICE/schedule/deleteSessionType/`,
      DeleteAcademicYear: 
      `${BASE_URL}/EQACORE-SERVICE/schedule/academicYear/`,
      
  };
  
  export default deleteAPIScheduleCourse;