interface SurveyState {
    surveyLink: any | null;
  }
  
  const initialState: SurveyState = {
    surveyLink: null,
  };

  const surveyReducer = (state = initialState, action: any): SurveyState => {
    switch (action.type) {
        case 'GET_SURVEY_LINK':
            return {
                ...state,
                surveyLink: action.payload.data,
            };
        default:
            return state;
    }
};

export default surveyReducer;