import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";


// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    Autocomplete,
    FormControlLabel
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import courseJson from "../../../../../../courseCode.json"

import readAPI from '../../../../data/api/read';
import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";
interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateCLO = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [courseCodeList, setCourseCodeList] = useState<any>([]);


        const courseCodeListOptions = courseCodeList.map((courseCode: any) => ({
            label: `${courseCode.courseCode}: ${courseCode.courseName}`,
            value: courseCode.courseCode,
        }));
        const handleCourseCodeChange = (event: any, newValue: any) => {
            setCourseCode(newValue ? newValue.value : null);
        };

        const handleCloIdChange = (event: any, newValue: any) => {
            setCloId(newValue ? newValue.cloId : null);
        };
        const handleCloCategoryChange = (event: any, newValue: any) => {
            setCloCategory(newValue ? newValue.cloCategory : null);
        };



        const [description, setDescription] = useState<string>(originalValues.description);
        const [assessmentMethod, setAssessmentMethod] = useState<string>(originalValues.assessmentMethod);
        const [cloCategory, setCloCategory] = useState<string>(originalValues.cloCategory);
        const [cloId, setCloId] = useState<string>(originalValues.cloId);
        const [courseCode, setCourseCode] = useState<string>(originalValues.courseCode);
        const [teachingStrategies, setTeachingStrategies] = useState<string>(originalValues.teachingStrategies);
        const [isChecked, setIsChecked] = useState(true);


        // For handling errors

        const [descriptionError, setDescriptionError] = useState<boolean>(false);
        const [assessmentMethodError, setAssessmentMethodError] = useState<boolean>(false);
        const [cloCategoryError, setCloCategoryError] = useState<boolean>(false);
        const [cloIdError, setCloIdError] = useState<boolean>(false);
        const [courseCodeError, setCourseCodeError] = useState<boolean>(false);
        const [teachingStrategiesError, setTeachingStrategiesError] = useState<boolean>(false);


        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
        };
        // For handling error messages
        // const [courseCodeErrorMessage, setCourseCodeErrorMessage] = useState("");

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }
            if (accessToken !== null) {
                // @1) Fetching course
                axios.get(readAPI.Courses, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setCourseCodeList(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    // Set the validation errors

                    if (courseCode !== "" && assessmentMethod !== "" && description !== "") {
                        const formState = [{
                            "id": originalValues.id,
                            "cloId": cloId,
                            "courseCode": courseCode,
                            "description": description,
                            "cloCategory": cloCategory,
                            "teachingStrategies": teachingStrategies,
                            "assessmentMethod": assessmentMethod,
                            "active": isChecked
                        }];

                        axios.put(url,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    setSnackBarHandler({
                                        severity: (response.data.code === "200.200") ? "success" : "error",
                                        message: (response.data.code === "200.200") ? `${cloId} data has been updated successfully` : (response.data.message),
                                        open: true
                                    })
                                    const m = response.data.message;
                                    if (response.data.code === "200.200") {
                                        setTimeout(() => {
                                            setOpenUpdateTableModal(false);
                                        }, 3000);
                                    }
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>

                                <Autocomplete
                                    id="courseCodeComplete"
                                    options={courseCodeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={courseCodeListOptions.find((option: any) => option.value === courseCode) || null}
                                    onChange={handleCourseCodeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.courseCode.label')}`}
                                            placeholder={`${t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeName.placeholder')}`}
                                            variant="standard"
                                            // helperText={(coursePrerequisiteErrorMessage) ? ("* Please select any College.") : ("")}
                                            error={courseCodeError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    value={courseJson.cloId.find(course => course.cloId === cloId)}
                                    onChange={handleCloIdChange}
                                    options={courseJson.cloId}
                                    getOptionLabel={(option: any) => option.cloId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label={`${t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.cloId.label')}`}
                                            placeholder={`${t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.cloId.placeholder')}`}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="descriptionTextField"
                                    label={t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.description.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.description.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={descriptionError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={description}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (descriptionError) {
                                            setDescriptionError(false);
                                        }
                                        setDescription(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                sx={{mt: 2}}>



                                <Autocomplete
                                    value={courseJson.cloCategory.find(clo => clo.cloCategory === cloCategory)}
                                    onChange={handleCloCategoryChange}
                                    options={courseJson.cloCategory}
                                    getOptionLabel={(option: any) => option.cloCategoryName}
                                    autoHighlight
                                    // value={courseName}
                                    // onChange={(event, newValue: string) => {
                                    //     setCollegeId(newValue);
                                    //     if (collegeIdError) {
                                    //         setCollegeIdError(false);
                                    //     }
                                    // }}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.cloCategory.label')}`}
                                            variant="standard"
                                            placeholder={`${t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.cloCategory.placeholder')}`}
                                            // helperText={(collegeIdError) ? ("* Please select any College.") : ("")}
                                            // error={collegeIdError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="assessmentMethodTextField"
                                    label={t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.assessmentMethod.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.assessmentMethod.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={assessmentMethodError}
                                    // helperText={courseCodeErrorMessage}
                                    value={assessmentMethod}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (assessmentMethodError) {
                                            setAssessmentMethodError(false);
                                        }
                                        setAssessmentMethod(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="teachingStrategiesTextField"
                                    label={t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.teachingStrategies.label')}
                                    placeholder={`${t('Home.Header.Modals.CourseLearningOutcome.policy.Inputs.teachingStrategies.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={teachingStrategiesError}
                                    // helperText={CampusEmailIdErrorMessage}
                                    value={teachingStrategies}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (teachingStrategiesError) {
                                            setTeachingStrategiesError(false);
                                        }
                                        setTeachingStrategies(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>

                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />

                                {/* <Switch
                                    checked={isChecked}
                                    onChange={handleSwitchChange}
                                    inputProps={{ 'aria-label': 'Toggle' }}
                                /> */}
                            </Grid>






                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCLO;