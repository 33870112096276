import { BASE_URL } from "../../../constants";
const createAPI = {
  Users:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/createUser`,
  Apps: `${BASE_URL}/IDENTITY-SERVICE/privileges/saveAppDetails`,
  Groups:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/saveGroup`,
  Roles:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/createRole`,
  AppForm:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/saveAppForm`,
  RoleApp:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/savePrivilege`,
  GroupRole:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/saveGroupRole`,
  UserGroup:
    `${BASE_URL}/IDENTITY-SERVICE/privileges/createUserGroup`,
  Login: `${BASE_URL}/IDENTITY-SERVICE/login/permissions`,
  Logout: `${BASE_URL}/IDENTITY-SERVICE/user/logout`,
  ForgotPassword : `${BASE_URL}/IDENTITY-SERVICE/login/forgetPassword`,
  ResetPassword : `${BASE_URL}/IDENTITY-SERVICE/login/resetPassword`,
  AddUniversity: `${BASE_URL}/EQACORE-SERVICE/saveUniversity`
};

export default createAPI;
