import { Annual_Program_Report, BASE_URL, SPS } from "../../../../../constants";
import { PORTAL_URL } from "../../../../../constants";
import { STUDYPLAN_URL } from "../../../../../constants";
const updateAPIS = {
    UpdateCollege: `${BASE_URL}/EQACORE-SERVICE/updateCollege`,
    UpdateCampus: `${BASE_URL}/EQACORE-SERVICE/updateCampus`,
    UpdateDepartment: `${BASE_URL}/EQACORE-SERVICE/updateDepartment`,
    UpdateProgram: `${BASE_URL}/EQACORE-SERVICE/updateProgram`,
    UpdateUniversity: `${BASE_URL}/EQACORE-SERVICE/updateUniversity`,
    UpdateProgramType: `${BASE_URL}/EQACORE-SERVICE/updateProgramType`,
    UpdateProgramObjective: `${BASE_URL}/EQACORE-SERVICE/updateStudentOutcome`,
    UpdateCourse: `${BASE_URL}/EQACORE-SERVICE/updateCourse`,
    UpdateCourseType: `${BASE_URL}/EQACORE-SERVICE/updateCourseType`,
    UpdateBook: `${BASE_URL}/EQACORE-SERVICE/updateBook`,
    UpdateCourseBook: `${BASE_URL}/EQACORE-SERVICE/updateCourseBook`,
    UpdateClo: `${BASE_URL}/EQACORE-SERVICE/updateCourseClo`,
    UpdateAssessment: `${BASE_URL}/EQACORE-SERVICE/assessment/updateAssessment`,
    UpdateAssessmentType: `${BASE_URL}/EQACORE-SERVICE/assessment/updateAssessmentType`,
    UpdateCourseObjective: `${BASE_URL}/EQACORE-SERVICE/updateCourseObjective`,
    UpdateTopics: `${BASE_URL}/EQACORE-SERVICE/updateCourseTopic`,
    UpdateCloSoMapping : `${BASE_URL}/EQACORE-SERVICE/updateCloSoMapping`,
    UpdateProjectSubComponent: `${BASE_URL}/EQACORE-SERVICE/configuration/projectSubComponent`,
    UpdateAssessmentMethod : `${BASE_URL}/EQACORE-SERVICE/updateAssessmentMethod`,
    UpdateCoordinator: `${BASE_URL}/EQACORE-SERVICE/core/coordinator`,
    UpdateSurveyFormLink : "https://datadimens.com:8766/eqa-survey-service/api/v1.0/survey",
    UpdateDocumentType: `${PORTAL_URL}/portal-service/document/type`,
    UpdateStudyPlan : `${STUDYPLAN_URL}/study-plan`,
    UpdateCourseStudyPlan: `${STUDYPLAN_URL}/study-course-detail`,
    UpdateLocation: `${BASE_URL}/EQAMOM-SERVICE/mom/location`,
    UpdateCommittee: `${BASE_URL}/EQAMOM-SERVICE/mom/committee`,
    UpdateCommitteeMember : `${BASE_URL}/EQAMOM-SERVICE/mom/committee/member`,
    UpdateAnnualProgramReport : `${Annual_Program_Report}/annual-program-report/setting`,
    UpdateAssignTaskReport : `${Annual_Program_Report}/annual-program-report/task-detail`,
    UpdateKPI : `${SPS}/kpi`,
    UpdateInitiative : `${SPS}/initiative`,
    UpdateKpiValue : `${SPS}/kpi-value`,
  };
  
  export default updateAPIS;
  