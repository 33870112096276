import { useState, FC, Suspense, lazy } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css"

// import ManagePortal from "../Pages/ManagePortal";

const ManagePortal = lazy(() => import("../Pages/ManagePortal"))


interface PortalRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    // courseCodeProps: any,
    // openUniversityModal: boolean,
    // setOpenUniversityModal: any,
}

const PortalRouter: FC<PortalRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,

    // courseCodeProps

    // openUniversityModal,
    // setOpenUniversityModal,

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
        <Routes>
            {/* <Route path="portal"> */}
            <Route path={"upload"}>
                <Route
                    path={"view"}
                    element={
                        <ManagePortal
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}
                        />
                    }
                />
            </Route>
            {/* </Route> */}

        </Routes>
        </Suspense>
    )

}

export default PortalRouter;
