import { Annual_Program_Report, BASE_URL } from "../../../../../constants";
import { PORTAL_URL } from "../../../../../constants";
const readAPI = {
    sessionType : `${BASE_URL}/EQACORE-SERVICE/schedule/sessionTypes`,
    GetAllDocumentType: `${PORTAL_URL}/portal-service/document/type/all`,
    location : `${BASE_URL}/EQAMOM-SERVICE/mom/location/getAllLocation`,
    GetAllAnnualProgramReport : `${Annual_Program_Report}/annual-program-report/setting`,
    getAllAssignedTaskReport : `${Annual_Program_Report}/annual-program-report/task-detail`,
  };
  
  export default readAPI;
  