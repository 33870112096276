import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css";


const ViewUniversity = lazy(() => import("../Components/Profile/University/ViewUniversity"));
const Coordinator = lazy(() => import("../Components/Coordinator/ManageCoordinator/AddCoordinator"));
const ViewCollege = lazy(() => import("../Components/Profile/College/ViewCollege"));
const ViewCampus = lazy(() => import("../Components/Profile/Campus/ViewCampus"));
const ViewDepartment = lazy(() => import("../Components/Profile/Department/ViewDepartment"));
const ViewProgram = lazy(() => import("../Components/Profile/Program/ViewProgram"));
const ViewProgramType = lazy(() => import("../Components/Profile/ProgramType/ViewProgramType"));
const ViewProgramObjective = lazy(() => import("../Components/Profile/ProgramObjective/ViewProgramObjective"));
const ManageCourse = lazy(() => import("../Pages/Course/ManageCourse"));
const ViewProjectTemplate = lazy(() => import("../Components/ProjectConfiguration/ViewProjectTemplate"));
const ManageProjectTemplate = lazy(() => import("../Components/ProjectConfiguration/ManageProjectTemplate"));


interface EqaCoreProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
    openUniversityModal: any,
    setOpenUniversityModal: any
    openCollegeModal: any,
    setOpenCollegeModal: any
    openCampusModal: any,
    setOpenCampusModal: any,
    openDepartmentModal: any,
    setOpenDepartmentModal: any
    openProgramModal: any,
    setOpenProgramModal: any,
    openProgramTypeModal: any,
    setOpenProgramTypeModal: any,
    openProgramObjectiveModal: any,
    setOpenProgramObjectiveModal: any,
    courseCodeProps: any,
}

const EqaCoreRouter: FC<EqaCoreProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,
    openUniversityModal,
    setOpenUniversityModal,
    openCollegeModal,
    setOpenCollegeModal,
    openCampusModal,
    setOpenCampusModal,
    openDepartmentModal,
    setOpenDepartmentModal,
    openProgramModal,
    setOpenProgramModal,
    openProgramTypeModal,
    setOpenProgramTypeModal,
    openProgramObjectiveModal,
    setOpenProgramObjectiveModal,
    courseCodeProps

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
            <Routes>
                <Route
                    path="view"
                    element={<ViewUniversity
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        // For minified sidebar
                        isMinified={isMinified}
                        setIsMinified={setIsMinified}
                        currentLang={currentLang}

                        openUniversityModal={openUniversityModal}
                        setOpenUniversityModal={setOpenUniversityModal}
                        setCurrentLang={setCurrentLang}
                    />
                    }
                />

                <Route path={"course"}>
                    <Route path={"coordinator"}>
                        <Route
                            path={"view"}
                            element={
                                <Coordinator
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    // For minified sidebar
                                    // isMinified={isMinified}
                                    // setIsMinified={setIsMinified}
                                    currentLang={currentLang}
                                    // openProgramObjectiveModal={openProgramObjectiveModal}
                                    // setOpenProgramObjectiveModal={setOpenProgramObjectiveModal}
                                    setCurrentLang={setCurrentLang}
                                />
                            }
                        />
                    </Route>
                </Route>

                <Route path={"college"}>
                    <Route
                        path={"view"}
                        element={
                            <ViewCollege
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}

                                openCollegeModal={openCollegeModal}
                                setOpenCollegeModal={setOpenCollegeModal}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>

                <Route path={"university"}>
                    <Route
                        path={"view"}
                        element={
                            <ViewUniversity
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}

                                openUniversityModal={openUniversityModal}
                                setOpenUniversityModal={setOpenUniversityModal}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>

                <Route path={"campus"}>
                    <Route
                        path={"view"}
                        element={
                            <ViewCampus
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}

                                openCampusModal={openCampusModal}
                                setOpenCampusModal={setOpenCampusModal}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>

                <Route path={"department"}>
                    <Route
                        path={"view"}
                        element={
                            <ViewDepartment
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}

                                openDepartmentModal={openDepartmentModal}
                                setOpenDepartmentModal={setOpenDepartmentModal}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>

                <Route path={"program"}>
                    <Route
                        path={"view"}
                        element={
                            <ViewProgram
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                openProgramModal={openProgramModal}
                                setOpenProgramModal={setOpenProgramModal}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>

                <Route path={"programType"}>
                    <Route
                        path={"view"}
                        element={
                            <ViewProgramType
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                openProgramTypeModal={openProgramTypeModal}
                                setOpenProgramTypeModal={setOpenProgramTypeModal}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>

                <Route path={"programObjective"}>
                    <Route
                        path={"view"}
                        element={
                            <ViewProgramObjective
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                openProgramObjectiveModal={openProgramObjectiveModal}
                                setOpenProgramObjectiveModal={setOpenProgramObjectiveModal}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>


                <Route path={"course"}>
                    <Route
                        path={"view"}
                        element={
                            <ManageCourse
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                                // For minified sidebar
                                isMinified={isMinified}
                                setIsMinified={setIsMinified}
                                currentLang={currentLang}
                                courseCodeProps={courseCodeProps}
                                setCurrentLang={setCurrentLang}
                            />
                        }
                    />
                </Route>


                <Route path={"configuration"}>
                    <Route path={"projectTemplate"}>
                        <Route
                            path={"view"}
                            element={
                                <ViewProjectTemplate
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    // For minified sidebar
                                    isMinified={isMinified}
                                    setIsMinified={setIsMinified}
                                    currentLang={currentLang}
                                    setCurrentLang={setCurrentLang}
                                />
                            }
                        />
                    </Route>
                </Route>


                <Route path={"configuration"}>
                    <Route path={"projectTemplate"}>
                        <Route
                            path={"ManageProjectTemplate"}
                            element={
                                <ManageProjectTemplate
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    // For minified sidebar
                                    isMinified={isMinified}
                                    setIsMinified={setIsMinified}
                                    currentLang={currentLang}
                                    setCurrentLang={setCurrentLang}
                                />
                            }
                        />
                    </Route>
                </Route>
            </Routes>
        </Suspense>
    )

}

export default EqaCoreRouter;
