import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
import { Download, TimesOneMobiledata } from '@mui/icons-material';
import { FaBook } from "react-icons/fa";
// import createAPI from "../../../../data/api/create";
import readAPI from "../../../data/api/read";
// import jsPDF from 'jspdf-autotable';
// import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';


// importing from material ui

import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../redux/store";

import {
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Autocomplete
} from "@mui/material";

import SnackBar from "../../../../../Components/SnackBar";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";
import { TfiThemifyFavicon } from "react-icons/tfi";


interface SyllabusProps {
    // openProgramModal: boolean;
    // setOpenProgramModal: React.Dispatch<React.SetStateAction<boolean>>;

    setIsOpen: any,
    isOpen: Boolean,

    // Current Language
    currentLang: string;
    // setCurrentLang: React.Dispatch<React.SetStateAction<string>>;
    courseCodes?: any
}



const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));



const Syllabus: React.FC<SyllabusProps> = ({
    // openProgramModal,
    // setOpenProgramModal,

    setIsOpen,
    isOpen,

    // Current Language
    currentLang,
    // setCurrentLang,
    courseCodes
}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [courseCode, setCourseCode] = useState<string>("");

    const [courseCodeError, setCourseCodeError] = useState<boolean>(false);

    const [courseCodeErrorMessage, setCourseCodeErrorMessage] = useState<string>("")

    const [bookData, setBookData] = useState<any>([]);
    const [courseData, setCourseData] = useState<any>([]);
    const [courseObjectives, setCourseOBjectives] = useState<any>([])
    const [courseClos, setCourseClos] = useState<any>([])
    const [courseTopics, setCourseTopics] = useState<any>([])
    const [cloSoMapping, setCloSoMapping] = useState<any>([])
    const [assessmentMethods, setAssessmentMethods] = useState<any>([])

    const [showMessage, setShowMessage] = useState<boolean>(false)
    const [showErrorMessage, setShowErrorMessage] = useState("")
    const [showErrorMessageForAbet, setShowErrorMessageForAbet] = useState("")
    const [showErrorMessageForNCAAA, setShowErrorMessageForNCAAA] = useState("")
    const [responseData, setResponseData] = useState<any>([]);

    const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

    ///////////////////////////////// Snackbar State /////////////////////////////////
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    ///////////////////////////////// Snackbar State /////////////////////////////////

    const [isLoadingSpinnerBasic, setIsLoadingSpinnerBasic] = useState(false);
    const [isLoadingSpinnerNcaaa, setIsLoadingSpinnerNcaaa] = useState(false);
    const [isLoadingSpinnerAbet, setIsLoadingSpinnerAbet] = useState(false);

    const universityLogo = require(`../../../../../assets/Images/universityLogo/taifuniversitylogo.jpeg`);
    const basicImage = require(`../../../../../assets/Images/Syllabus/basic.png`);
    const abetImage = require(`../../../../../assets/Images/Syllabus/ABET.png`);
    const ncaaaImage = require(`../../../../../assets/Images/Syllabus/NCAAA.png`);

    const [selectedOption, setSelectedOption] = useState<any>('');

    const [showAnchorEl, setShowAnchorEl] = useState(null);
    const showOpen = Boolean(showAnchorEl);

    const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
    const downloadOpen = Boolean(downloadAnchorEl);
    const [courseCodeList, setCourseCodeList] = useState<any>([]);

    // coursecode autocomplete

    const courseCodeListOptions = courseCodeList.map((courseCode: any) => ({
        label: `${courseCode.courseCode}: ${courseCode.courseName}`,
        value: courseCode.courseCode,
    }));
    const handleCourseCodeChange = (event: any, newValue: any) => {
        setCourseCode(newValue ? newValue.value : null);
    };

    const handleShowClick = (event: any) => {
        setShowAnchorEl(event.currentTarget);
    };

    const handleDownloadClick = (event: any) => {
        setDownloadAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setShowAnchorEl(null);
        setDownloadAnchorEl(null);
    };

    useEffect(() => {
        if(courseCodes !== ""){
            setCourseCode(courseCodes);
        }
    }, [courseCodes])


    //show syllabus table
    // const gettingSyllabusData = () => {
    //     let accessToken: any = Cookies.get("accessToken");
    //     if (accessToken !== null) {
    //         // @1) Fetching Syllabus
    //         axios
    //             .get(`${readAPI.Syllabus}/${courseCode}`, {
    //                 headers: {
    //                     "x-api-key": accessToken,
    //                 },
    //             })
    //             .then((res) => {
    //                 var data = res.data;
    //                 if (data && data.obj && data.obj.course) {
    //                     // Access properties only if they exist
    //                     data.obj.course.courseTypeName =
    //                         data.obj.course.courseType?.courseTypeName || 'Default Value';
    //                     setCourseData(data.obj.course);
    //                     setCourseOBjectives(data.obj.courseObjectives);
    //                     setCourseClos(data.obj.courseClos);
    //                     setCourseTopics(data.obj.courseTopics);
    //                     setCloSoMapping(data.obj.cloSoMapping);
    //                     setBookData(data.obj.courseBooks);
    //                     console.log('res', data);
    //                     if (data.code === "200.200") {
    //                         setResponseData(data);
    //                         setShowMessage(false);
    //                     }
    //                 } else {
    //                     setShowMessage(true);
    //                     setShowErrorMessage(data.message)
    //                     console.error("Invalid response data structure:", data.message);
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error("API request failed:", err);
    //             });
    //     }
    // }
    let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

    const showBasicSyllabus = () => {
        setIsLoadingSpinnerBasic(true);
        setResponseData([])
        setSelectedOption("basic")
        setShowErrorMessageForAbet("");
        setShowErrorMessageForNCAAA("");
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setShowAnchorEl(null);
        } else {
            setShowAnchorEl(null);
            // gettingSyllabusData();
            // let accessToken: any = Cookies.get("accessToken");
            if (accessToken !== null) {
                // @1) Fetching Syllabus
                axios
                    .get(`${readAPI.Syllabus}/${courseCode}`, {
                        headers: {
                            "x-api-key": accessToken,
                        },
                    })
                    .then((res) => {
                        var data = res.data;
                        setIsLoadingSpinnerBasic(false);
                        if (data && data.obj && data.obj.course) {
                            // Access properties only if they exist
                            data.obj.course.courseTypeName =
                                data.obj.course.courseType?.courseTypeName || 'Default Value';
                            setCourseData(data.obj.course);
                            setCourseOBjectives(data.obj.courseObjectives);
                            setCourseClos(data.obj.courseClos);
                            setCourseTopics(data.obj.courseTopics);
                            setCloSoMapping(data.obj.cloSoMapping);
                            setBookData(data.obj.courseBooks);
                            setAssessmentMethods(data.obj.assessmentMethods);
                            if (data.code === "200.200") {
                                setResponseData(data);
                                setShowMessage(false);
                            }
                        } else {
                            setShowMessage(true);
                            setShowErrorMessage(data.message)
                            console.error("Invalid response data structure:", data.message);
                        }
                    })
                    .catch((err) => {
                        console.error("API request failed:", err);
                    });
            }
        }

    }
    const showNcaaaSyllabus = () => {
        setResponseData([])
        setSelectedOption("ncaaa");
        setShowErrorMessage("");
        setShowErrorMessageForAbet("");
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setShowAnchorEl(null);
        } else {
            setShowAnchorEl(null);
            // gettingSyllabusData();
            // let accessToken: any = Cookies.get("accessToken");
            if (accessToken !== null) {
                // @1) Fetching Syllabus
                axios
                    .get(`${readAPI.Syllabus}/${courseCode}`, {
                        headers: {
                            "x-api-key": accessToken,
                        },
                    })
                    .then((res) => {
                        var data = res.data;
                        if (data && data.obj && data.obj.course) {
                            // Access properties only if they exist
                            data.obj.course.courseTypeName =
                                data.obj.course.courseType?.courseTypeName || 'Default Value';
                            setCourseData(data.obj.course);
                            setCourseOBjectives(data.obj.courseObjectives);
                            setCourseClos(data.obj.courseClos);
                            setCourseTopics(data.obj.courseTopics);
                            setCloSoMapping(data.obj.cloSoMapping);
                            setBookData(data.obj.courseBooks);
                            setAssessmentMethods(data.obj.assessmentMethods);
                            if (data.code === "200.200") {
                                setResponseData(data);
                                setShowMessage(false);
                            }
                        } else {
                            setShowMessage(true);
                            setShowErrorMessageForNCAAA(data.message)
                            console.error("Invalid response data structure:", data.message);
                        }
                    })
                    .catch((err) => {
                        console.error("API request failed:", err);
                    });
            }
        }
    }
    const showAbetSyllabus = () => {
        setResponseData([])
        setSelectedOption("abet");
        setShowErrorMessage("");
        setShowErrorMessageForNCAAA("");
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setShowAnchorEl(null);
        } else {
            setShowAnchorEl(null);
            // gettingSyllabusData()
            // let accessToken: any = Cookies.get("accessToken");
            if (accessToken !== null) {
                // @1) Fetching Syllabus
                axios
                    .get(`${readAPI.Syllabus}/${courseCode}`, {
                        headers: {
                            "x-api-key": accessToken,
                        },
                    })
                    .then((res) => {
                        var data = res.data;
                        if (data && data.obj && data.obj.course) {
                            // Access properties only if they exist
                            data.obj.course.courseTypeName =
                                data.obj.course.courseType?.courseTypeName || 'Default Value';
                            setCourseData(data.obj.course);
                            setCourseOBjectives(data.obj.courseObjectives);
                            setCourseClos(data.obj.courseClos);
                            setCourseTopics(data.obj.courseTopics);
                            setCloSoMapping(data.obj.cloSoMapping);
                            setBookData(data.obj.courseBooks);
                            setAssessmentMethods(data.obj.assessmentMethods);
                            if (data.code === "200.200") {
                                setResponseData(data);
                                setShowMessage(false);
                            }
                        } else {
                            setShowMessage(true);
                            setShowErrorMessageForAbet(data.message)
                            console.error("Invalid response data structure:", data.message);
                        }
                    })
                    .catch((err) => {
                        console.error("API request failed:", err);
                    });
            }
        }
    }


    const downloadBasicSyllabus = () => {
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setDownloadAnchorEl(null);
        }
        else {

            const pdf = new jsPDF('p', 'mm', 'a4');
            const content = document.getElementById('basic');

            if (content) {
                html2canvas(content, { scale: 2 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 10, 10, 190, 277); // Adjust dimensions as needed
                    pdf.save('syllabus.pdf');
                });
            }

            setDownloadAnchorEl(null);
            // const pdf = new jsPDF('p', 'mm', 'a4');
            // const headerImage = new Image();
            // headerImage.src = 'https://upload.wikimedia.org/wikipedia/commons/b/b6/Image_created_with_a_mobile_phone.png'

            // const headerTitle = 'College Of Computer and Information Technology';
            // headerImage.onload = () => {
            //     console.log('adskfasdf')
            //     pdf.addImage(headerImage, 'JPEG', 10, 10, 30, 30);
            //     pdf.text(headerTitle, 60, 30);
            //     const content = document.getElementById('basic');

            //     if (content) {
            //         html2canvas(content, { scale: 2 }).then(canvas => {
            //             const imgData = canvas.toDataURL('image/png');
            //             pdf.addImage(imgData, 'PNG', 10, 60, 190, 277); // Adjust dimensions and positions as needed
            //             pdf.save('syllabus.pdf');
            //         });
            //     }
            // };
        }
    }

    const downloadNcaaSyllabus = () => {
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setDownloadAnchorEl(null);
        }
        else {
            setDownloadAnchorEl(null);
        }
    }
    const downloadAbetSyllabus = () => {
        if (courseCode === "") {
            setCourseCodeError(true);
            setCourseCodeErrorMessage("Please enter course code");
            setDownloadAnchorEl(null);
        }
        else {
            const pdf = new jsPDF('p', 'mm', 'a4');
            const content = document.getElementById('basic');

            if (content) {
                html2canvas(content, { scale: 2 }).then(canvas => {
                    const imgData = canvas.toDataURL('image/png');
                    pdf.addImage(imgData, 'PNG', 10, 10, 190, 277); // Adjust dimensions as needed
                    pdf.save('syllabus.pdf');
                });
            }

            setDownloadAnchorEl(null);
            setDownloadAnchorEl(null);
        }
    }

    // const submitForm = () => {
    //     let accessToken: any = Cookies.get("accessToken");
    //     if (accessToken !== null) {
    //         // @1) Fetching Syllabus
    //         axios.get(`${readAPI.Syllabus}/${courseCode}`, {
    //             headers: {
    //                 "x-api-key": accessToken
    //             }
    //         })
    //             .then((res) => {
    //                 var data = res.data;
    //                 data.obj.course.courseTypeName = res.data.obj.course.courseType.courseTypeName;
    //                 setCourseData(data.obj.course);
    //                 setCourseOBjectives(res.data.obj.courseObjectives);
    //                 setCourseClos(res.data.obj.courseClos);
    //                 setCourseTopics(res.data.obj.courseTopics);
    //                 setCloSoMapping(res.data.obj.cloSoMapping);
    //                 setBookData(res.data.obj.courseBooks);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //             });
    //     }

    // }





    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    // wwindow size
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // let accessToken: any = Cookies.get("accessToken");

    useEffect(() => {
        if (accessToken === undefined || accessToken === null) {
            accessToken = null;
        }


        if (accessToken !== null) {
            // @1) Fetching courses
            axios.get(readAPI.Courses, {
                headers: {
                    "x-api-key": accessToken
                }
            })
                .then((res) => {
                    setCourseCodeList(res.data.obj);
                })
                .catch((err) => {
                    console.log(err);
                });
            }

        }, []);

    return (
        <>

            <Box sx={{
                // border: "1px solid red",
                padding:
                    // Categorize according to small, medium, large screen
                    (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
            }}>

                <Box sx={{
                    // border: "1px solid red",
                    display: "flex",
                    marginBottom: 2,
                    alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                    flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                }}>
                    <Box sx={{
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                        width: 60,
                        height: 60,
                        borderRadius: 1.25,
                        backgroundColor: "#fffefe",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>

                        <FaBook size={27} style={{ marginTop: "3px" }} color="#4f747a" />
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Typography variant="h5" sx={{
                            // color: "#312a2c",
                            color: "#3c6766",
                            fontWeight: 500,
                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                            display: "flex",
                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                        }}>
                            {t('Home.Header.Modals.Syllabus.title')}
                        </Typography>
                        <Typography variant="body1" sx={{
                            // color: "#4f747a" 
                            // color: "#C0C0C0"
                            color: "#696969",
                            fontWeight: 300
                        }}>
                            {t('Home.Header.Modals.Syllabus.subTitle')}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ flexGrow: 1, mt: 5 }}>
                    <Grid container spacing={
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                    }
                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                    >

                        <Grid item xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{marginTop: 2}}>

                            <Autocomplete
                                id="courseCodeComplete"
                                options={courseCodeListOptions}
                                getOptionLabel={(option: any) => option.label}
                                value={courseCodeListOptions.find((option: any) => option.value === courseCode) || null}
                                onChange={handleCourseCodeChange}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        label={`${t('Home.Header.Modals.Topics.policy.Inputs.courseCode.label')}`}
                                        placeholder={`${t('Home.Header.Modals.Topics.policy.Inputs.courseCode.placeholder')}`}
                                        variant="standard"
                                        error={courseCodeError}
                                        helperText={courseCodeErrorMessage}
                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    />
                                )}
                            />

                            {/* <TextField
                                id="courseCodeTextField"
                                label={t('Home.Header.Modals.Syllabus.policy.Inputs.courseCode.label')}
                                placeholder={`${t('Home.Header.Modals.Syllabus.policy.Inputs.courseCode.placeholder')}`}
                                variant="standard"
                                margin="normal"
                                fullWidth
                                error={courseCodeError}
                                helperText={courseCodeErrorMessage}
                                value={courseCode}
                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                onChange={(e) => {
                                    if (courseCodeError) {
                                        setCourseCodeError(false);
                                    }
                                    setCourseCode(e.target.value);
                                }}
                            /> */}
                        </Grid>
                        <Grid item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}>
                            {/* button */}

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: (currentLang === "ar") ? ('row-reverse') : ('row')
                                }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >

                                <Button
                                    id="demo-customized-button"
                                    aria-controls={showOpen ? 'demo-customized-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={showOpen ? 'true' : undefined}
                                    variant="contained"
                                    disableElevation
                                    onClick={handleShowClick}
                                    endIcon={<KeyboardArrowDownIcon />}
                                    sx={{
                                        backgroundColor: "#d5b254",
                                        // textTransform: "none",
                                        fontWeight: "bold",
                                        ml: 1,
                                        height: 40,
                                        mt:
                                        // Give margins based on screen size
                                        {
                                            xs: 3, // theme.breakpoints.up('xs')
                                            sm: 2, // theme.breakpoints.up('sm')
                                            md: 2, // theme.breakpoints.up('md')
                                            lg: 3, // theme.breakpoints.up('lg')
                                            xl: 3, // theme.breakpoints.up('xl')
                                        },
                                        display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#4f747ac3",
                                        },
                                    }}
                                >
                                    <Typography
                                        style={{ display: "block" }}
                                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                    >
                                        {t('Home.Header.Modals.Syllabus.policy.Inputs.show.label')}
                                    </Typography>
                                </Button>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                    }}
                                    anchorEl={showAnchorEl}
                                    open={showOpen}
                                    onClose={handleClose}
                                    sx={{ paddingTop: '0px' }}
                                >
                                    <MenuItem onClick={showBasicSyllabus} disableRipple sx={{
                                        backgroundColor: "#3c6766", color: "#fff", pt: 1, display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#4f747ac3",
                                        },
                                    }}>
                                        <img src={basicImage} style={{
                                            marginRight: "10px",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%"
                                        }}
                                            width={33}
                                            height={33}
                                            alt="avatar"
                                            loading="lazy" />
                                        Basic
                                    </MenuItem>
                                    <MenuItem onClick={showNcaaaSyllabus} disableRipple sx={{
                                        backgroundColor: "#3c6766", color: "#fff", display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#4f747ac3",
                                        },
                                    }}>
                                        <img src={ncaaaImage} style={{
                                            marginRight: "10px",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%"
                                        }}
                                            width={33}
                                            height={33}
                                            alt="avatar"
                                            loading="lazy" />
                                        NCAAA
                                    </MenuItem>
                                    <MenuItem onClick={showAbetSyllabus} disableRipple sx={{
                                        backgroundColor: "#3c6766", color: "#fff", display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#4f747ac3",
                                        },
                                    }}>
                                        <img src={abetImage} style={{
                                            marginRight: "10px",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%"
                                        }}
                                            width={33}
                                            height={33}
                                            alt="avatar"
                                            loading="lazy" />
                                        ABET
                                    </MenuItem>
                                </StyledMenu>

                                {/* <Button
                                    dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "#286e0a",
                                        // textTransform: "none",
                                        fontWeight: "bold",
                                        ml: 1,
                                        height: 40,
                                        mt:
                                        // Give margins based on screen size
                                        {
                                            xs: 3, // theme.breakpoints.up('xs')
                                            sm: 2, // theme.breakpoints.up('sm')
                                            md: 2, // theme.breakpoints.up('md')
                                            lg: 3, // theme.breakpoints.up('lg')
                                            xl: 3, // theme.breakpoints.up('xl')
                                        },
                                        display: "flex",
                                        "&:hover": {
                                            backgroundColor: "#d5b254",
                                        },
                                    }}
                                    fullWidth={(
                                        windowSize[0] < 600
                                    ) ? true : false}
                                    startIcon={
                                        (currentLang === "ar") ? (
                                            null
                                        ) : (
                                            <Download />
                                        )
                                    }
                                    endIcon={
                                        (currentLang === "ar") ? (
                                            <Download />
                                        ) : (
                                            null
                                        )
                                    }
                                    onClick={downloadBasicSyllabus}
                                >
                                    <Typography
                                        style={{ display: "block" }}
                                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                    >
                                        {t('Home.Header.Modals.Syllabus.policy.Inputs.download.label')}
                                    </Typography>
                                </Button> */}


                                {/* menu item button */}

                                {selectedOption ? (
                                    <>
                                        <Button
                                            id="demo-customized-button"
                                            aria-controls={downloadOpen ? 'demo-customized-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={downloadOpen ? 'true' : undefined}
                                            variant="contained"
                                            disableElevation
                                            onClick={handleDownloadClick}
                                            endIcon={<KeyboardArrowDownIcon />}
                                            sx={{
                                                backgroundColor: "#4f747ac3",
                                                // textTransform: "none",
                                                fontWeight: "bold",
                                                ml: 1,
                                                height: 40,
                                                mt:
                                                // Give margins based on screen size
                                                {
                                                    xs: 3, // theme.breakpoints.up('xs')
                                                    sm: 2, // theme.breakpoints.up('sm')
                                                    md: 2, // theme.breakpoints.up('md')
                                                    lg: 3, // theme.breakpoints.up('lg')
                                                    xl: 3, // theme.breakpoints.up('xl')
                                                },
                                                display: "flex",
                                                "&:hover": {
                                                    backgroundColor: "#4f747ac3",
                                                },
                                            }}
                                        >
                                            <Typography
                                                style={{ display: "block" }}
                                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                                            >
                                                {t('Home.Header.Modals.Syllabus.policy.Inputs.download.label')}
                                            </Typography>
                                        </Button>
                                        <StyledMenu
                                            id="demo-customized-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'demo-customized-button',
                                            }}
                                            anchorEl={downloadAnchorEl}
                                            open={downloadOpen}
                                            onClose={handleClose}
                                            sx={{ paddingTop: '0px' }}
                                        >
                                            <MenuItem onClick={downloadBasicSyllabus} disableRipple sx={{
                                                backgroundColor: "#3c6766", color: "#fff", pt: 1, display: "flex",
                                                "&:hover": {
                                                    backgroundColor: "#4f747ac3",
                                                },
                                            }}>
                                                <img src={basicImage} style={{
                                                    marginRight: "10px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%"
                                                }}
                                                    width={33}
                                                    height={33}
                                                    alt="avatar"
                                                    loading="lazy" />
                                                Basic
                                            </MenuItem>
                                            <MenuItem onClick={downloadNcaaSyllabus} disableRipple sx={{
                                                backgroundColor: "#3c6766", color: "#fff", display: "flex",
                                                "&:hover": {
                                                    backgroundColor: "#4f747ac3",
                                                },
                                            }}>
                                                <img src={abetImage} style={{
                                                    marginRight: "10px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%"
                                                }}
                                                    width={33}
                                                    height={33}
                                                    alt="avatar"
                                                    loading="lazy" />
                                                NCAAA
                                            </MenuItem>
                                            <MenuItem onClick={downloadAbetSyllabus} disableRipple sx={{
                                                backgroundColor: "#3c6766", color: "#fff", display: "flex",
                                                "&:hover": {
                                                    backgroundColor: "#4f747ac3",
                                                },
                                            }}>
                                                <img src={ncaaaImage} style={{
                                                    marginRight: "10px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%"
                                                }}
                                                    width={33}
                                                    height={33}
                                                    alt="avatar"
                                                    loading="lazy" />
                                                ABET
                                            </MenuItem>
                                        </StyledMenu>
                                    </>
                                ) : (null)}

                            </Box>
                        </Grid>

                    </Grid>
                </Box>

                <Box sx={{ flexGrow: 1, mt: 5 }}>

                    {responseData.length !== 0 && selectedOption === 'basic' ? (
                        <div id="basic">
                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* COURSE DETAILS */}
                            </Typography>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ paddingRight: "-20px" }}>
                                                {/* <img src={universityLogo}
                                                width={120}
                                                height={120}
                                                alt="avatar"
                                                loading="lazy" /> */}

                                                <Box sx={{ flexGrow: 1, padding: 0 }}>
                                                    <Grid container sx={{ alignItems: 'center' }} spacing={
                                                        // Categorize according to small, medium, large screen
                                                        (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                                                    }>

                                                        {/* University Logo */}
                                                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} sx={{ mt: -2 }}>
                                                            <img
                                                                // className={styles.universityLogo}
                                                                width={120}
                                                                height={120}
                                                                src={require(`../../../../../assets/Images/universityLogo/taifuniversitylogo.jpeg`)}
                                                                alt="University Logo"
                                                            // style={{ width: '100%', height: 'auto', display: 'block'}}
                                                            />
                                                        </Grid>

                                                        <Grid item xl={10} lg={10} md={10} sm={12} xs={12} sx={{ padding: 0 }}>
                                                            <p className={styles.collegeNameHeading}>Taif University</p>
                                                            <p className={styles.collegeNameHeading}>College Of Computer and Information Technology</p>

                                                        </Grid>

                                                    </Grid>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                </Table>
                            </TableContainer>

                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>Course Specification</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>COURE CODE</span></TableCell>
                                            <TableCell>{courseData.courseCode}</TableCell>
                                            <TableCell className={styles.label}><span>COURSE NAME</span></TableCell>
                                            <TableCell>{courseData.courseName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>COURSE TYPE</span></TableCell>
                                            <TableCell>{courseData.courseTypeName}</TableCell>
                                            <TableCell className={styles.label}><span>ELECTIVE REQUIRED</span></TableCell>
                                            <TableCell>{courseData.requiredElective}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>COURSE DESCRIPTION</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.courseDesc}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>CREDIT HOURS</span></TableCell>
                                            <TableCell>{courseData.courseCreditHours}</TableCell>
                                            <TableCell className={styles.label}><span>COURSE LEVEL</span></TableCell>
                                            <TableCell>{courseData.courseLevel}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>TEACHING HOURS PER WEEK</span></TableCell>
                                            <TableCell>{courseData.teachingHoursPerWeek}</TableCell>
                                            <TableCell className={styles.label}><span>LAB HOURS PER WEEK</span></TableCell>
                                            <TableCell>{courseData.labHoursPerWeek}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>ACADEMIC YEAR OFFERED</span></TableCell>
                                            <TableCell>{courseData.academicYearOffered}</TableCell>
                                            <TableCell className={styles.label}><span>COURSE PREREQUISITE</span></TableCell>
                                            <TableCell>{courseData.coursePrerequisite}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>COURSE COREQUISITE</span></TableCell>
                                            <TableCell>{courseData.courseCoRequisite}</TableCell>
                                            <TableCell className={styles.label}><span>STUDY PLAN</span></TableCell>
                                            <TableCell>{courseData.studyPlan}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>MODE OF INSTRUCTION</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>TRADITIONAL CLASSROOM</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.moiTraditionalClassroom}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>BLENDED</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.moiBlended}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>ELEARNING</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.moiElearning}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>CORRESPONDENCE</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.moiCorrespondence}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>OTHERS</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.moiOthers}</TableCell>
                                        </TableRow>


                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>ACTUAL LEARNING HOURS</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>Contact Hours:</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>LECTURE</span></TableCell>
                                            <TableCell>{courseData.alhChLecture}</TableCell>
                                            <TableCell className={styles.label}><span>LABORATORY</span></TableCell>
                                            <TableCell>{courseData.alhChLaboratory}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell className={styles.label}><span>TUTORIAL</span></TableCell>
                                            <TableCell>{courseData.alhChTutorial}</TableCell>
                                            <TableCell className={styles.label}><span>OTHERS</span></TableCell>
                                            <TableCell>{courseData.alhChOther}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>Other Learning Hours:</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>STUDY</span></TableCell>
                                            <TableCell>{courseData.alhOlhStudy}</TableCell>
                                            <TableCell className={styles.label}><span>ASSIGNMENT</span></TableCell>
                                            <TableCell>{courseData.alhOlhAssignment}</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell className={styles.label}><span>LIBRARY</span></TableCell>
                                            <TableCell>{courseData.alhOlhLibrary}</TableCell>
                                            <TableCell className={styles.label}><span>RESEARCH</span></TableCell>
                                            <TableCell>{courseData.alhOlhResearch}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2} className={styles.label}><span>OTHER</span></TableCell>
                                            <TableCell colSpan={2}>{courseData.alhOlhOther}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.heading}><span>STUDENT ACADEMIC SUPPORT</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.studentAcademicSupport}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>FACILITIES REQUIRED</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>ACCOMODATION</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.frAccomodation}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>TECHNOLOGY RESOURCES</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.frTechnologyResources}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>OTHER RESOURCES</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.frOtherResources}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>SPECIFICATION APPROVAL DATA</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>SAD COMMITTEE</span></TableCell>
                                            <TableCell>{courseData.sadCommittee}</TableCell>
                                            <TableCell className={styles.label}><span>SAD DATE</span></TableCell>
                                            <TableCell>{courseData.sadDate}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>SAD REFERENCE NO</span></TableCell>
                                            <TableCell colSpan={3}>{courseData.sadReferenceNo}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* COURSE OBJECTIVE */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2} className={styles.heading}><span>COURSE OBJECTIVE</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>Id</span></TableCell>
                                            <TableCell className={styles.label}><span>DESCRIPTION</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {courseObjectives && courseObjectives.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.id}</TableCell>
                                                        <TableCell colSpan={3}>{item.description}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* COURSE LEARNING OUTCOME */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>COURSE LEARNING OUTCOME</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>CLO</span></TableCell>
                                            <TableCell colSpan={2} className={styles.label}><span>DESC</span></TableCell>
                                            <TableCell className={styles.label}><span>CLO CATEGORY</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {courseClos && courseClos.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.cloId}</TableCell>
                                                        <TableCell colSpan={2}>{item.description}</TableCell>
                                                        <TableCell>{item.cloCategory}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* Assessment Method */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={6} className={styles.heading}><span>ASSESSMENT METHOD</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                        <TableCell className={styles.label}><span>METHOD NAME</span></TableCell>
                                            <TableCell colSpan={4} className={styles.label}><span>DESCRIPTION</span></TableCell>
                                            <TableCell className={styles.label}><span>DISTRIBUTION</span></TableCell>
                                            
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {assessmentMethods && assessmentMethods.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.methodName}</TableCell>
                                                        <TableCell colSpan={4}>{item.description}</TableCell>
                                                        <TableCell>{item.distribution}</TableCell>
                                                        
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* TOPICS */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>TOPICS</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2} className={styles.label}><span>WEEKLY OUTLINE</span></TableCell>
                                            <TableCell className={styles.label}><span>TOPIC</span></TableCell>
                                            <TableCell className={styles.label}><span>CLO COVERED</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {courseTopics && courseTopics.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.weeksDelivered}</TableCell>
                                                        <TableCell colSpan={2}>{item.topic}</TableCell>
                                                        <TableCell>{item.closCovered}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* BOOKS */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>BOOKS</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bookData && bookData.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell className={styles.label}><span>COURSE CODE</span></TableCell>
                                                        <TableCell>{item.courseCode}</TableCell>
                                                        <TableCell className={styles.label}><span>TEXT BOOK</span></TableCell>
                                                        <TableCell>{item.textBook.name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={styles.label}><span>REFERENCE BOOK</span></TableCell>
                                                        <TableCell>{item.referenceBook.name}</TableCell>
                                                        <TableCell className={styles.label}><span>OTHER REFERENCE BOOK</span></TableCell>
                                                        <TableCell>{item.otherReferenceBook}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={styles.label}><span>ELECTRONIC MATERIALS</span></TableCell>
                                                        <TableCell>{item.electronicMaterials}</TableCell>
                                                        <TableCell className={styles.label}><span>OTHER LEARNING MATERIALS</span></TableCell>
                                                        <TableCell>{item.otherLearningMaterials}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={styles.label}><span>REFERENCE MATERIALS</span></TableCell>
                                                        <TableCell colSpan={3}>{item.referenceMaterials}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* CLOSO MAPPING */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>CLOSO MAPPING</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            
                                            <TableCell className={styles.label}><span>CLO ID</span></TableCell>
                                            <TableCell className={styles.label}><span>Description</span></TableCell>
                                            <TableCell className={styles.label}><span>SO ID</span></TableCell>
                                            <TableCell className={styles.label}><span>SO VALUE</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {cloSoMapping && cloSoMapping.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        
                                                        <TableCell>{item.cloId}</TableCell>
                                                        <TableCell>{item.description}</TableCell>
                                                        <TableCell>{item.soId}</TableCell>
                                                        <TableCell>{item.soValue}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                GRADE STRUCTURE
                            </Typography>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                POLICIES
                            </Typography>


                            {/* Repeat similar blocks for other sections like courseClos, courseTopics, etc. */}
                        </div>
                    ) : showMessage ? (
                        <p>{showErrorMessage}</p>
                    ) : null}

                    {responseData.length !== 0 && selectedOption === 'abet' ? (
                        <div id="abet">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ paddingRight: "-20px" }}>
                                                <Box sx={{ flexGrow: 1, padding: 0 }}>
                                                    <Grid container sx={{ alignItems: 'center' }} spacing={
                                                        // Categorize according to small, medium, large screen
                                                        (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                                                    }>

                                                        {/* University Logo */}
                                                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} sx={{ mt: -2 }}>
                                                            <img
                                                                // className={styles.universityLogo}
                                                                width={120}
                                                                height={120}
                                                                src={require(`../../../../../assets/Images/universityLogo/taifuniversitylogo.jpeg`)}
                                                                alt="University Logo"
                                                            // style={{ width: '100%', height: 'auto', display: 'block'}}
                                                            />
                                                        </Grid>

                                                        <Grid item xl={10} lg={10} md={10} sm={12} xs={12} sx={{ padding: 0 }}>
                                                            <p className={styles.collegeNameHeading}>Taif University</p>
                                                            <p className={styles.collegeNameHeading}>College Of Computer and Information Technology</p>

                                                        </Grid>

                                                    </Grid>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                </Table>
                            </TableContainer>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={6} className={styles.heading}><span>Course Specification</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>COURSE CODE</span></TableCell>
                                            <TableCell>{courseData.courseCode}</TableCell>
                                            <TableCell className={styles.label}><span>COURSE NAME</span></TableCell>
                                            <TableCell colSpan={4}>{courseData.courseName}</TableCell>

                                        </TableRow>


                                        <TableRow>
                                            <TableCell className={styles.label}><span>SEMESTER</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>ACADEMIC YEAR</span></TableCell>
                                            <TableCell>{courseData.academicYearOffered}</TableCell>
                                            <TableCell className={styles.label}><span>CREDIT HOURS</span></TableCell>
                                            <TableCell>{courseData.courseCreditHours}</TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>ENROLMENT</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>WITHDRAWL</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>COURSE COREQUISITE</span></TableCell>
                                            <TableCell>{courseData.courseCoRequisite}</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                            </Typography>

                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>INSTRUCTOR</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>EMAIL</span></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>OFFICE HOURSE</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>LOCATION</span></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>TEACHING ASSISTANS</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>PHONE</span></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>COURSE CO-ORDINATOR</span></TableCell>
                                            <TableCell colSpan={3}></TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>


                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                            </Typography>

                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>COURSE FORMAT</span></TableCell>
                                            <TableCell className={styles.label}><span>LECTURE TIME/VENUE</span></TableCell>
                                            <TableCell className={styles.label}><span>SECTION</span></TableCell>
                                            <TableCell className={styles.label}><span>COURSE WEBSITE</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                            </Typography>

                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>DESCRIPTION</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={3}>kkkkk</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* COURSE OBJECTIVE */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2} className={styles.heading}><span>COURSE OBJECTIVE DETAILS</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>Id</span></TableCell>
                                            <TableCell className={styles.label}><span>DESCRIPTION</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {courseObjectives && courseObjectives.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.id}</TableCell>
                                                        <TableCell colSpan={3}>{item.description}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* COURSE LEARNING OUTCOME */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>COURSE CLO DETAILS</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>Id</span></TableCell>
                                            <TableCell colSpan={3} className={styles.label}><span>DESC</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {courseClos && courseClos.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.id}</TableCell>
                                                        <TableCell colSpan={2}>{item.description}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>


                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* Assessment Method */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={6} className={styles.heading}><span>ASSESSMENT METHOD</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>Id</span></TableCell>
                                            <TableCell className={styles.label}><span>COURSE CODE</span></TableCell>
                                            <TableCell colSpan={2} className={styles.label}><span>DESCRIPTION</span></TableCell>
                                            <TableCell className={styles.label}><span>DISTRIBUTION</span></TableCell>
                                            <TableCell className={styles.label}><span>METHOD NAME</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {assessmentMethods && assessmentMethods.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.id}</TableCell>
                                                        <TableCell>{item.courseCode}</TableCell>
                                                        <TableCell colSpan={2}>{item.description}</TableCell>
                                                        <TableCell>{item.distribution}</TableCell>
                                                        <TableCell>{item.methodName}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >

                            </Typography>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                TOPICS
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={2} className={styles.label}><span>WEEKLY OUTLINE</span></TableCell>
                                            <TableCell className={styles.label}><span>TOPIC</span></TableCell>
                                            <TableCell className={styles.label}><span>CLO COVERED</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {courseTopics && courseTopics.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.weeksDelivered}</TableCell>
                                                        <TableCell colSpan={2}>{item.topic}</TableCell>
                                                        <TableCell>{item.closCovered}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>


                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* BOOKS */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>BOOK DETAILS</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bookData && bookData.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell className={styles.label}><span>COURSE CODE</span></TableCell>
                                                        <TableCell>{item.courseCode}</TableCell>
                                                        <TableCell className={styles.label}><span>TEXT BOOK</span></TableCell>
                                                        <TableCell>{item.textBook.name}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={styles.label}><span>REFERENCE BOOK</span></TableCell>
                                                        <TableCell>{item.referenceBook.name}</TableCell>
                                                        <TableCell className={styles.label}><span>OTHER REFERENCE BOOK</span></TableCell>
                                                        <TableCell>{item.otherReferenceBook}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={styles.label}><span>ELECTRONIC MATERIALS</span></TableCell>
                                                        <TableCell>{item.electronicMaterials}</TableCell>
                                                        <TableCell className={styles.label}><span>OTHER LEARNING MATERIALS</span></TableCell>
                                                        <TableCell>{item.otherLearningMaterials}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell className={styles.label}><span>REFERENCE MATERIALS</span></TableCell>
                                                        <TableCell colSpan={3}>{item.referenceMaterials}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* CLOSO MAPPING */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={6} className={styles.heading}><span>COURSE ASSESSMENT (STRUCTURE/DATES)</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>COMPONENTS</span></TableCell>
                                            <TableCell className={styles.label}><span>PERCENT</span></TableCell>
                                            <TableCell className={styles.label}><span>DATE</span></TableCell>
                                            <TableCell className={styles.label}><span>COMPONENTS</span></TableCell>
                                            <TableCell className={styles.label}><span>PERCENT</span></TableCell>
                                            <TableCell className={styles.label}><span>DATE</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>QUIZE(S)</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>QUIZE(S)</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>ASSIGNMENT(S)</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>ASSIGNMENT(S)</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>PROJECTS(S)</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell className={styles.label}><span>PROJECTS(S)</span></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* COURSE LEARNING OUTCOME */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>COURSE SCHEDULE</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>WEEK</span></TableCell>
                                            <TableCell colSpan={3} className={styles.label}><span>TOPICS</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {courseClos && courseClos.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.week}</TableCell>
                                                        <TableCell colSpan={2}>{item.topics}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography
                                style={{ display: "block", fontWeight: "bold", marginTop: "20px", marginBottom: "20px" }}
                                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                            >
                                {/* COURSE LEARNING OUTCOME */}
                            </Typography>
                            <TableContainer component={Paper} className={styles.tablecontainer}>
                                <Table className={styles.customtable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell colSpan={4} className={styles.heading}><span>COURSE POLICY</span></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={styles.label}><span>ID</span></TableCell>
                                            <TableCell colSpan={3} className={styles.label}><span>COURSE POLICY DESCRIPTION</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {courseClos && courseClos.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{item.id}</TableCell>
                                                        <TableCell colSpan={2}>{item.policy}</TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}

                                    </TableBody>
                                </Table>
                            </TableContainer>


                            {/* Repeat similar blocks for other sections like courseClos, courseTopics, etc. */}
                        </div>
                    ) : showMessage ? (
                        <p>{showErrorMessageForAbet}</p>
                    ) : null}

                    {responseData.length !== 0 && selectedOption === 'ncaaa' ? (
                        <div>
                            <p>Coming Soon</p>
                        </div>
                    ) : showMessage ? (
                        <p>{showErrorMessageForNCAAA}</p>
                    ) : null}


                </Box>
            </Box>










            <SnackBar
                isOpen={snackBarHandler.open}
                message={snackBarHandler.message}
                severity={snackBarHandler.severity}
                setIsOpen={
                    // Only pass the setIsOpen function to the SnackBar component
                    // and not the whole state object
                    (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                }
            />

            <br /><br /> <br />



        </>
    )
}
export default Syllabus;