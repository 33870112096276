interface StudyPlanState {
    studyPlan: any | null;
    studyPlanCourse: any | null;
  }
  
  const initialState: StudyPlanState = {
    studyPlan: null,
    studyPlanCourse: null,
  };

  const studyPlanReducer = (state = initialState, action: any): StudyPlanState => {
    switch (action.type) {
        case 'STUDY_PLAN':
            return {
                ...state,
                studyPlan: action.payload.data,
            };
        case 'STUDY_PLAN_COURSE' :
            return {
                ...state,
                studyPlanCourse: action.payload.data,
            };
        default:
            return state;
    }
};

export default studyPlanReducer;