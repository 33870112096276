import { useState, FC, lazy, Suspense } from "react";
import { Routes, Route } from 'react-router-dom';
import styles from "./style.module.css";

const ViewReport = lazy(() => import("../components/ManageReport/ViewReport"));
const PACSubmission = lazy(() => import("../components/ManageReport/PACSubmissionStatus"));
const DataEntryStatus = lazy(() => import("../components/ManageReport/DataEntryStatusReport"));
const T7CourseReport = lazy(() => import("../components/ManageReport/T7CourseReport"));
const CourseReport = lazy(() => import("../components/ManageReport/CourseReport"));
const FARCoordinator = lazy(() => import("../components/ManageReport/FARCoordinator"));
const FAR = lazy(() => import("../components/ManageReport/FAR"));
const SOEvidence = lazy(() => import("../components/ManageReport/SOEvidence"));
const CAF = lazy(() => import("../components/ManageReport/CAF"));
const CourseSOEvidence = lazy(() => import("../components/ManageReport/CourseSOEvidence"));
const PLRReport = lazy(() => import("../components/ManageReport/PLRReport"));
const ManageAnnualProgram = lazy(() => import("../pages/ManageAnnualProgram"));


interface PortalRouteProps {
    mobileViewContainer: any,
    currentTab: any,
    setCurrentTab: any,
    // showHeader: boolean,
    setShowHeader: any,
    currentLang: string,
    setCurrentLang: any,
}

const ReportRouter: FC<PortalRouteProps> = ({
    currentTab,
    setCurrentTab,
    mobileViewContainer,
    // showHeader,
    setShowHeader,
    currentLang,
    setCurrentLang,

}): JSX.Element => {
    // For simple open/close sidebar
    const [isOpen, setIsOpen] = useState<Boolean>(true);
    // For minified sidebar
    const [isMinified, setIsMinified] = useState<Boolean>(false);
    const [sidebarAppsListArray, setSidebarAppsListArray] = useState<any>([]);
    return (
        <Suspense
            fallback={
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <h1>Please wait...</h1>
                </div>
            }>
        <Routes>
            <Route
                path="view"
                element={
                        <ViewReport
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />


            <Route
                path="CAF"
                element={
                        <CAF
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />

            <Route
                path="SOEvidence"
                element={
                        <SOEvidence
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />

            <Route
                path="FAR"
                element={
                        <FAR
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />

            <Route
                path="FARCoordinator"
                element={
                        <FARCoordinator
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />

            <Route
                path="courseReport"
                element={
                        <CourseReport
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />
            <Route
                path="T7courseReport"
                element={
                        <T7CourseReport
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />

            <Route
                path="CourseSoEvidence"
                element={
                        <CourseSOEvidence
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />

            <Route
                path="annualProgramReport"
                element={
                        <ManageAnnualProgram
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />


            <Route
                path="plrReport"
                element={
                        <PLRReport
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />

            <Route
                path="dataEntryStatus"
                element={
                        <DataEntryStatus
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />
            <Route
                path="pacSubmissionStatus"
                element={
                        <PACSubmission
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            // For minified sidebar
                            isMinified={isMinified}
                            setIsMinified={setIsMinified}
                            currentLang={currentLang}
                            setCurrentLang={setCurrentLang}

                        />
                    }
            />




        </Routes>
        </Suspense>
    )

}

export default ReportRouter;
