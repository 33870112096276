import axios from 'axios'
import readAPI from '../data/api/read'
import {ISession} from "../model/ReportSetting"


export type SessionType = {
    data: ISession[],
    errMessage: ""
}


async function getSessionType(accessToken: string): Promise<SessionType> {
    let url: string = readAPI.sessionType;
    let header: string = accessToken;
    const sessionType: SessionType = {
        data: [],
        errMessage: ""
    }
    await axios.get(url, {
        headers: {
            "x-api-key": header
        }
    })
        .then((res) => {
            sessionType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                sessionType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            sessionType.errMessage = err.message;
        })

    return sessionType;
}

export const ReportSettingService = {
    getSessionType
}